<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<form [formGroup]="form" class="formClass">
  <form [formGroup]="addForm">
    <div class="">
      <div (click)="showModal = !showModal" *ngIf="showModal"
           class="w-full h-screen bg-black top-0 left-0 z-20 bg-opacity-25 fixed"></div>
      <div *ngIf="showModal" class="border-2 p-4 top-0 left-0 z-20 classA fixed z-30 bg-white">
        <div class="">
          <div class="text-3xl text-center"> Добавить тестируемого </div>
          <div class="flex">
            <div class="flex mt-5" style="width: 500px;">
              <p class="ml-3" style="width: 195px;">
                Дата тестирования
              </p>
              <select class="rounded-lg bg-gray-300 ml-2" [value]="0" [(ngModel)]="testPeriodId"
                      [ngModelOptions]="{standalone: true}">
                <option [value]="0" disabled selected>
                  Дата тестирования
                </option>
                <option [value]="item.id" *ngFor="let item of testPeriodList">
                  {{ item.name }}
                </option>

              </select>
            </div>
          </div>
          <div class="mt-5 flex">
            <label class="cursor-pointer w-auto mt-1"> {{ 'SearchByIIN' | translate }} </label>
            <input [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}" id="iin"
                   class="rounded-lg bg-white border-2 border-gray-300 ml-3" type="text" placeholder="ИИН">
            <button (click)="closePeresad($event)" type="button" style="height: 30px;"
                    class="cursour-pointer w-40 ml-3 bg-gray-300 hover:bg-gray-300 rounded-lg text-grey-dark">
              Закрыть
            </button>
          </div>
          <div style="height: 260px;" class="px-3 py-4 scroll-table-body overflow-x-auto flex">
            <table class="table h-96 min-w-full">
              <thead>
              <tr>
                <th scope="col" class="py-3 text-center text-xs font-medium text-gray-500 uppercase">
                  {{ 'IIN' | translate }}
                </th>
                <th scope="col" class="py-3 text-center text-xs font-medium text-gray-500 uppercase">
                  ФИО
                </th>
                <th scope="col" class="py-3 text-center text-xs font-medium text-gray-500 uppercase">
                  {{ 'LangTest' | translate }}
                </th>
                <th scope="col" class="py-3 text-center text-xs font-medium text-gray-500 uppercase">
                  {{ 'StudentStatus' | translate }}
                </th>
                <th scope="col" class="py-3 text-center text-xs font-medium text-gray-500 uppercase">
                  Адрес
                </th>
                <th scope="col" class="py-3 text-center text-xs font-medium text-gray-500 uppercase">
                  Дата тестирования
                </th>
                <th scope="col" class="py-3 text-center text-xs font-medium text-gray-500 uppercase">
                  Добавить
                </th>
              </tr>
              </thead>
              <tbody class="bg-white border-2.border-gray-200">
              <tr class="border-2 border-gray-200" *ngFor="let students of studentInnList | filter: searchText; index as i ">
                <td class="py-4 text-center">
                  <div class="text-sm text-gray-900">
                    {{ students.iin }}
                  </div>
                </td>
                <td class="py-4 text-center">
                  <div class="text-sm text-gray-900 w-60">
                    {{ students.lastname }} {{ students.firstname }} {{ students.patronymic }}
                  </div>
                </td>
                <td class="py-4 text-center">
                  <div class="w-60 text-sm text-gray-900">
                    {{ students.testLangName }}
                  </div>
                </td>
                <td class="py-4 text-center">
                  <div class="text-sm text-gray-900 w-60">
                    {{ students.studentTestStatusTypeName }}
                  </div>
                </td>
                <td class="py-4 text-center">
                  <div class="text-sm text-gray-900 w-60">
                    {{ students.testOrgName }}
                  </div>
                </td>
                <td class="py-4 text-center">
                  <div class="text-sm text-gray-900 w-60">
                    {{ students.testDate }}
                  </div>
                </td>
                <td class="py-4 text-center">
                  <button (click)="addTest(students)" class="bg-blue-300 rounded-lg p-1">Добавить
                    тестирование</button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div id="whoobe-1yakq"
       class="bg-white text-base w-2/3 rounded-lg shadow whoobeClass m-auto -brown-400 border border-gray-300 mt-10 flex flex-col">
    <div id="whoobe-pa28x" class="p-4 flex boxClass flex-col">
      <div class="flex ml-48 text-center mt-5 mb-10">
        <div class="w-2/3 text-2xl font-hairline uppercase tracking-widest"> Данные об внештатной ситуации </div>
      </div>
      <div class="">
        <div class="mx-3 my-5">
          <div class="flex">
            <label class="w-44"> Код </label>
            <input formControlName="statementId" type="text" class="ml-3 pl-2 bg-gray-300 w-12 rounded-lg">
          </div>
        </div>
        <div class="mx-3 my-5">
          <div class="flex">
            <label class="w-44"> Пункт </label>
            <div class="ml-3 px-2 bg-gray-300 rounded-lg">
              <span *ngIf="translate.currentLang == 'kz'"> {{ allInc?.testVptEntity.codeId || '' }} - {{
                  allInc?.testVptEntity.nameKaz || '' }} </span>
              <span *ngIf="translate.currentLang == 'ru'"> {{ allInc?.testVptEntity.codeId || '' }} - {{
                  allInc?.testVptEntity.nameRus || '' }} </span>
            </div>
          </div>
        </div>
        <div class="mx-3 my-5">
          <div class="flex">
            <label class="w-44"> Поток </label>
            <div class="ml-3 px-2 bg-gray-300 rounded-lg">
              {{ allInc?.testPeriod.testDate || '' }} - {{ allInc?.testPeriod.startTestTime || '' }}
            </div>
          </div>
        </div>
        <div class="mx-3 my-5">
          <div class="flex">
            <label class="w-44"> Время внештатной ситуации </label>
            <input formControlName="statementsTime" placeholder="00:00" type="text"
                   class="h-7 ml-3 px-2 rounded-lg bg-gray-300">
          </div>
        </div>
        <div class="mx-3 my-5">
          <div class="flex">
            <label class="w-48"> Внештатная ситуация </label>
            <select formControlName="resetSessionTypeId" class="w-64 bg-gray-300 rounded-lg border-2 ml-2 mb-5">
              <option disabled selected [value]="0">
                Внештатная ситуация
              </option>
              <option *ngFor="let item of resetSessionList" [value]="item.id">
                <span *ngIf="translate.currentLang == 'kz'"> {{ item.nameKaz }} </span>
                <span *ngIf="translate.currentLang == 'ru'"> {{ item.nameRus }} </span>
              </option>
            </select>
          </div>
        </div>
        <div class="mx-3 my-5">
          <div>Запрос для внештатной ситуации
            <div class="mr-3 p-6">
              <div style="display: flex;" *ngFor="let actions of ActionTreeList; index as i">
                <fieldset>
                  <div>
                    <input (click)="findChildActionByParentId(actions.parentActionId)" formControlName="parentActionId"
                           class="mr-1 w-4 h-4" [value]="actions.parentActionId" type="radio">
                    <label>
                      <span *ngIf="translate.currentLang == 'kz'"> - {{ actions.parentActionNameKaz }}
                      </span>
                      <span *ngIf="translate.currentLang == 'ru'"> - {{ actions.parentActionNameRus }}
                      </span>
                    </label>
                  </div>
                </fieldset>
              </div>
              <div *ngIf="this.form.get('parentActionId')!.value != 0">
                <div class="mt-2">
                  <div style="display: flex;" *ngFor="let act of parentList; index as i">
                    <input (click)="returnFunc(act)" [value]="act.checked" class="mr-1 w-4 h-4" [checked]="act.checked"
                           type="checkbox" (change)="childcheckchange($event,act)">
                    <label>
                      <span *ngIf="translate.currentLang == 'kz'"> - {{ act.childActionNameKaz }} </span>
                      <span *ngIf="translate.currentLang == 'ru'"> - {{ act.childActionNameRus }} </span>
                    </label>
                    <div *ngIf="this.form.get('parentActionId')!.value == 2">
                      <input formControlName="addTime"
                             onkeyup="this.value = this.value.replace(/[^\d]/g,'');" [step]="5" [min]="5" [max]="160"
                             type="text" class="border-2 w-24 mx-2 px-2 rounded-lg bg-gray-300">
<!--                      [mask]="timeForTestMask"-->
                      Кол-во добавочного времени в минутах
                    </div>
                    <div *ngIf="this.form.get('parentActionId')!.value == 9">
                      <input formControlName="addAppealTime"
                             onkeyup="this.value = this.value.replace(/[^\d]/g,'');" [step]="5" [min]="5" [max]="160"
                             type="text" class="border-2 mx-2 w-24 px-2 rounded-lg bg-gray-300">
<!--                      [mask]="timeForTestMask"-->
                      Кол-во добавочного времени апелляция
                    </div>
                    <div *ngIf="this.form.get('parentActionId')!.value == 12" class="mx-3 my-1">
                      <div class="flex">
                        Добавочное время тестирования
                        <input  onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
                               [step]="5" [min]="5" [max]="160" formControlName="addTime" class="ml-3 bg-gray-300 rounded-lg"
                               type="number">
<!--                        [mask]="timeForTestMask"-->
                      </div>
                    </div>
                    <div *ngIf="this.form.get('parentActionId')!.value == 13" class="mx-3 my-1">
                      <div class="flex">
                        Добавочное время для апелляции
                        <input  onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
                               [step]="5" [min]="5" [max]="160" formControlName="addAppealTime"
                               class="ml-3 bg-gray-300 rounded-lg" type="number">
<!--                        [mask]="timeForTestMask"-->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mx-3 my-5">
          <div class="flex">
            Причина <textarea class="ml-3 pl-2 bg-gray-300 w-96 h-32" formControlName="notice"></textarea>
          </div>
        </div>
        <div class="modal-window mt-8 p-2 bg-white rounded-sm shadow-lg">
          <div class="flex">
            <button type="button" class="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded"
                    (click)="addFile()">{{ 'addFile' | translate }}</button>
            <button type="button" class="text-white py-2 px-4 rounded">{{ 'addFile' | translate }}</button>
          </div>
          <span class="add-files-scroll">
            <div formArrayName="additionalFiles" class="w-full mt-4 controls-border"
                 *ngFor="let item of files.controls; index as i">
              <div [formGroup]="files.controls[i]">
                <span> {{ 'NameFile' | translate }}: <input [disabled]="true" class="mb-4 p-2 w-full" type="text"
                                                            readonly formControlName="filename"></span>
                <div class="f-btn-group">
                  <div class="relative flex">
                    <input (change)="changeListener($event, i)"
                           class="w-full absolute top-0 left-0 h-full opacity-0 mb-4" type="file" />

                    <div class="update-btn bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded">
                      <p>
                        <!-- <span *ngIf="dwnldCheck(i); else elseBlock"> {{ 'ReplaceFile' | translate }}</span> -->
                        <span> {{ 'UploadFile' | translate }}</span>
                      </p>
                    </div>

                    <div *ngIf="dwnldCheck(i)"
                         class="update-btn bg-blue-500 relative hover:bg-blue-700 text-white py-2 px-4 rounded">

                      <button (click)="downloadFile(files.controls[i].value.id, files.controls[i].value.filename, i)"
                              class="w-full absolute top-0 left-0 h-full opacity-0 mb-4" type="file"> </button>
                      <p>
                        <span class=""> {{ 'DownloadFile' | translate }} </span>
                        <ng-template #elseBlock><span> {{ 'UploadFile' | translate }}</span></ng-template>
                      </p>
                    </div>

                    <p *ngIf="!dwnldCheck(i)" class="text-red-500 text-sm text-left">* {{ 'FileNotAdd' | translate }}
                    </p>
                  </div>
                </div>

                <!-- <div class="s-btn-group">
                <button type="button" title="Удалить файл" class="x-btn bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" (click)="removeFile()">
                  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash" class="svg-inline--fa fa-trash fa-w-14" role="img" viewBox="0 0 448 512"><path fill="currentColor" d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"/></svg>
                </button>
              </div> -->

              </div>
            </div>
          </span>
        </div>
        <div class="my-5 flex">
          <label class="cursor-pointer w-auto mt-1"> {{ 'SearchByIIN' | translate }} </label>
          <input [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}" id="iin"
                 class="rounded-lg bg-white border-2 border-gray-300 ml-3" type="text" placeholder="ИИН">
        </div>
      </div>
    </div>
    <div class="flex">
      <div class="flex">
        <div class="ml-2">
          <button *ngIf="allInc.sprStatementsStatusEntity.id == 1" (click)="operatorSendStatement(true)"
                  class="w-64 rounded-lg h-8 bg-blue-300">
            Отправить в орг.отдел
          </button>
        </div>
        <div class="ml-2">
          <button *ngIf="allInc.sprStatementsStatusEntity.id == 1" (click)="peresadkaWindow()"
                  class="w-64 rounded-lg h-8 bg-blue-300">
            Добавить тестируемого
          </button>
        </div>
      </div>
      <div class="flex">
        <div class="ml-2">
          <button [routerLink]="['/main/incident']" class="w-64 rounded-lg h-8 bg-blue-300">
            Вернуться к списку
          </button>
        </div>
        <div class="ml-2">
          <button *ngIf="allInc.sprStatementsStatusEntity.id == 1" (click)="deleteStatement()"
                  class="w-64 rounded-lg h-8 bg-red-300">
            Удалить черновик
          </button>
        </div>
        <div class="ml-2">
          <button *ngIf="allInc.sprStatementsStatusEntity.id == 1" (click)="operatorSendStatementFalse(false)"
                  class="w-64 rounded-lg h-8 bg-blue-300">
            Сохранить черновик
          </button>
        </div>
      </div>
    </div>
    <div class="px-3 py-4 scroll-table-body overflow-x-auto flex">
      <table class="table min-w-full">
        <thead>
        <tr>
          <th>
            <input [checked]="checkedAll == true" (click)="checkAllOptions()" class="w-4 h-4" type="checkbox">
          </th>
          <th scope="col" class="py-3 text-center text-xs font-medium text-gray-500 uppercase">
            {{ 'IIN' | translate }}
          </th>
          <th scope="col" class="py-3 text-center text-xs font-medium text-gray-500 uppercase">
            ФИО
          </th>
          <th scope="col" class="py-3 text-center text-xs font-medium text-gray-500 uppercase">
            Место
          </th>
          <th scope="col" class="py-3 text-center text-xs font-medium text-gray-500 uppercase">
            Аудитория
          </th>
          <th scope="col" class="py-3 text-center text-xs font-medium text-gray-500 uppercase">
            {{ 'StudentStatus' | translate }}
          </th>
          <th scope="col" class="py-3 text-center text-xs font-medium text-gray-500 uppercase">
            Внештатная ситуация
          </th>
        </tr>
        </thead>
        <tbody class="bg-white border-2.border-gray-200">
        <tr class="border-2 border-gray-200" *ngFor="let students of studentTest | filter: searchText">
          <td class="px-6 py-4 text-center">
            <div class="text-sm text-gray-900">
              <input (change)="checkStudent($event, students)" class="w-4 h-4" [checked]="students.isChecked"
                     type="checkbox">
            </div>
          </td>
          <td class="py-4 text-center">
            <div class="text-sm text-gray-900">
              {{ students.iin }}
            </div>
          </td>
          <td class="py-4 text-center">
            <div class="text-sm text-gray-900 ml-20 w-72">
              {{ students.student }}
            </div>
          </td>
          <td class="py-4 text-center">
            <div class="text-sm text-gray-900 ">
              {{ students.placeNumber }}
            </div>
          </td>
          <td class="py-4 text-center">
            <div class="text-sm text-gray-900">
              {{ students.audit }}
            </div>
          </td>
          <td class="py-4 text-center">
            <div *ngFor="let action of allInc.actionType" class="text-sm text-gray-900 w-60 ml-9">
                <span *ngIf="translate.currentLang == 'kz' && action.checked == true"> {{ action.childActionNameKaz }}
                </span>
              <span *ngIf="translate.currentLang == 'ru' && action.checked == true"> {{ action.childActionNameRus }}
                </span>
            </div>
          </td>
          <td class="py-4 text-center">
            <div class="text-sm text-gray-900 w-60 ml-10">
              <span *ngIf="translate.currentLang == 'kz'"> {{ allInc.resetSessionTypeId.nameKaz }} </span>
              <span *ngIf="translate.currentLang == 'ru'"> {{ allInc.resetSessionTypeId.nameRus }} </span>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</form>





<!-- <div *ngIf="this.form.get('parentActionId')!.value != 0">
                <div formArrayName="actionTypeIds" class="mt-2">
                  <div style="display: flex;" *ngFor="let act of parentList; index as i">
                      <div [formGroup]="actionsArray.controls[i]">
                        <input (click)="returnFunc(act)" formControlName="checked" class="mr-1 w-4 h-4" [value]="act.checked" type="checkbox">
                        <label>
                          <span *ngIf="translate.currentLang == 'kz'"> - {{ act.childActionNameKaz }} </span>
                          <span *ngIf="translate.currentLang == 'ru'"> - {{ act.childActionNameRus }} </span>
                        </label>
                      </div>
                    <div *ngIf="checkId == true && act.childActionId == 3">
                      <input [(ngModel)]="addTime" [ngModelOptions]="{standalone: true}" [mask]="timeForTestMask"
                        onkeyup="this.value = this.value.replace(/[^\d]/g,'');" [step]="5" [min]="5" [max]="160"
                        type="text" class="border-2 w-24 mx-2 px-2 rounded-lg bg-gray-300">
                      Кол-во добавочного времени в минутах
                    </div>
                    <div *ngIf="checkApel == true && act.childActionId == 8">
                      <input [(ngModel)]="addAppealTime" [ngModelOptions]="{standalone: true}" [mask]="timeForTestMask"
                        onkeyup="this.value = this.value.replace(/[^\d]/g,'');" [step]="5" [min]="5" [max]="160"
                        type="text" class="border-2 mx-2 w-24 px-2 rounded-lg bg-gray-300">
                      Кол-во добавочного времени апелляция
                    </div>
                  </div>
                </div>
              </div> -->
