<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<div class="mb-3 container">
  <form [formGroup]="form" class="mx-auto w-full" action="">

    <div class="flex my-7">
      <div class="w-1/5 h-1 bg-blue-300 mt-4"></div>
      <div class="w-3/5 text-center text-3xl font-hairline uppercase tracking-widest">
        {{ 'editingATested' | translate }}
      </div>
      <div class="w-1/5 h-1 bg-blue-300 mt-4"></div>
    </div>

    <div class="border-1 rounded-lx shadow-2xl">


      <div class="ml-10">
        <div class="flex mt-5">
          <p class="w-14"> {{ 'iin' | translate }} </p>
          <input readonly formControlName="iin" class="w-1/4 bg-gray-200 pl-3 rounded-full mx-16" type="number">
        </div>

        <div class="flex mt-7">
          <p class="w-14"> {{ 'ikt' | translate }} </p>
          <input readonly formControlName="studentTestId" class="w-1/4 bg-gray-200 pl-3 rounded-full mx-16" type="text">
        </div>

        <div class="flex mt-7">
          <p class="w-14"> {{ 'lastName' | translate }} </p>
          <input readonly formControlName="lastname" class="w-1/4 bg-gray-200 pl-3 rounded-full mx-16" type="text">
        </div>

        <div class="flex mt-7">
          <p class="w-14"> {{ 'firstName' | translate }} </p>
          <input readonly formControlName="firstname" class="w-1/4 bg-gray-200 pl-3 rounded-full mx-16" type="text">
        </div>

        <div class="flex mt-7">
          <p class="w-14"> {{ 'patronymic' | translate }} </p>
          <input readonly formControlName="patronymic" class="w-1/4 bg-gray-200 pl-3 rounded-full mx-16" type="text">
        </div>

        <div class="flex mt-7">
          <p class="w-14"> {{ 'phone' | translate }} </p>
          <input formControlName="phoneNumber" class="w-1/4 bg-gray-200 pl-3 rounded-full mx-16" type="text">
        </div>
      </div>

       <div class="ml-10 mt-7">
        <p class="w-full"> {{ 'SpecialtyQualificationIndicatedInTheDiplomaOfEducationOrRetrainingDocumentWithTheAssignmentOfTheAppropriateQualificationForThePositionHeld' | translate }} </p>
        <select formControlName="eduSpecialityId" class="w-full mt-2 bg-gray-200 rounded-full">
          <option disabled value="-1"> {{'currentQualificationChooseQualification' | translate }} </option>
          <option [value]="item.id" *ngFor="let item of collegeSpecList">
            <span *ngIf="translate.currentLang == 'kz'"> {{ item.code }} - {{ item.name.kk }} </span>
            <span *ngIf="translate.currentLang == 'ru'"> {{ item.code }} - {{ item.name.ru }} </span>
          </option>
        </select>
      </div>

          <div class="header-h1 my-7 font-bold text-center">
            <h1> {{'typeOfEmployment' | translate}} </h1>
          </div>

          <div class="ml-10 mt-7">
            <p class="w-full"> Область </p>
            <select (change)="instituteLists(0)" formControlName="eduInstituteOblId" class="w-full mt-2 bg-gray-200 rounded-full">
              <option disabled value="-1"> {{'currentQualificationChooseQualification' | translate }} </option>
              <option [value]="item.id" *ngFor="let item of oblList">
                <span *ngIf="translate.currentLang == 'kz'"> {{ item.name.kk }} </span>
                <span *ngIf="translate.currentLang == 'ru'"> {{ item.name.ru }} </span>
              </option>
            </select>
          </div>

          <div class="ml-10 mt-7">
            <p class="w-full"> Вуз обучения </p>
            <select formControlName="eduInstituteId" class="w-full mt-2 bg-gray-200 rounded-full">
              <option disabled value="-1"> Вуз </option>
              <option [value]="item.id" *ngFor="let item of collegeList">
                <span *ngIf="translate.currentLang == 'kz'"> {{ item.name.kk }} </span>
                <span *ngIf="translate.currentLang == 'ru'"> {{ item.name.ru }} </span>
              </option>
            </select>
          </div>

      <div class="header-h1 my-7 font-bold text-center">
        <h1> {{ 'TESTPARAMETERS' | translate }} </h1>
      </div>

      <div class="ml-10">
        <div class="mt-7">
          <div formArrayName="subjects" class="py-3" *ngFor="let item of subjects.controls; index as i">
            <div>
                <div>
                    <div>
                        {{ 'SubjectDisciplineInTheBlockContentOfTheAcademicSubjectInTheDirectionOfActivity' | translate }}
                    </div>
                    <div>
                        <select class="w-full mt-2 bg-gray-200 rounded-full" (change)="setSubjectValue(0, $event)">
                            <option selected value="-1">
                                --
                            </option>
                            <option [selected]="this.studentInfo.subjects[0].subjectId == sub.id" [value]="sub.id" *ngFor="let sub of subjectList[i]">
                                <span *ngIf="translate.currentLang == 'kz'">{{ sub.name.kk }}</span>
                                <span *ngIf="translate.currentLang == 'ru'">{{ sub.name.ru }}</span>
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        </div>

        <div class="mt-7">
          <p class="w-full"> Область </p>
          <select (change)="punktList(0)"formControlName="testOrgOblId" class="w-full bg-gray-200 rounded-full">
            <option disabled value="-1"> {{'currentQualificationChooseQualification' | translate }} </option>
            <option [value]="item.id" *ngFor="let item of oblList">
              <span *ngIf="translate.currentLang == 'kz'"> {{ item.name.kk }} </span>
              <span *ngIf="translate.currentLang == 'ru'"> {{ item.name.ru }} </span>
            </option>
          </select>
        </div>

        <div class="mt-7">
          <p class="w-1/3"> {{ 'TestingLocation' | translate }} </p>
          <select (change)="testPeriodList(0)" formControlName="testOrgId" class="w-full bg-gray-200 rounded-full">
            <option [value]="0" disabled selected> {{ 'ChooseATestingSite' | translate }}</option>
            <option [value]="item.id" *ngFor="let item of pstList">
              <span *ngIf="translate.currentLang == 'kz'"> {{ item.name.kk }} </span>
              <span *ngIf="translate.currentLang == 'ru'"> {{ item.name.ru }} </span>
            </option>
          </select>
        </div>

        <div class="mt-7">
          <p> {{ 'StreamNumberTestDate' | translate }} </p>
          <div>
            <select formControlName="testPeriodId" class="w-full bg-gray-200 rounded-full">
              <option [value]="0" disabled selected> {{ 'Stream' | translate }} </option>
              <option [value]="item.id" *ngFor="let item of periodList">
                {{ 'TestDate' | translate }} {{ item.testDate }}
                </option>
            </select>
          </div>
        </div>

        <div class="mt-7">
            <p> {{ 'TestLanguage' | translate }} </p>
            <select formControlName="testLangId" class="w-full bg-gray-200 rounded-full">
              <option [value]="0" selected> {{ 'ChooseYourLanguage' | translate }} </option>
              <option [value]="item.id" *ngFor="let item of langList">
                <span *ngIf="translate.currentLang == 'kz'"> {{ item.nameKaz}} </span>
                <span *ngIf="translate.currentLang == 'ru'"> {{ item.nameRus}} </span>
              </option>
            </select>
          </div>
      </div>

      <div class="pt-2 flex mt-4">
        <button (click)="editUser()" type="button" class="mb-2 w-full rounded-full py-1 px-24 bg-blue-300 rounded-full text-grey-darker">
          {{ 'Edit' | translate }}
        </button>
        <button [routerLink]="['/main/students']" class="font-serif mb-2 w-full rounded-full py-1 text-center px-24 bg-red-400 rounded-full text-grey-darker">
             {{ 'Cancellation' | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
