import { Component } from '@angular/core';

@Component({
  selector: 'app-incident-for-ib',
  templateUrl: './incident-for-ib.component.html',
  styleUrls: ['./incident-for-ib.component.css']
})
export class IncidentForIbComponent {

}
