<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>  
<div class="mb-3 container">
  <form [formGroup]="form" class="mx-auto w-full" action="">

    <div class="flex my-7">
      <div class="w-1/5 h-1 bg-blue-300 mt-4"></div>
      <div class="w-3/5 text-center text-3xl font-hairline uppercase tracking-widest">
        {{ 'editingATested' | translate }}
      </div>
      <div class="w-1/5 h-1 bg-blue-300 mt-4"></div>
    </div>

    <div class="border-1 rounded-lx shadow-2xl">


      <div class="ml-10">
        <div class="flex mt-5">
          <p class="w-14"> {{ 'iin' | translate }} </p>
          <input readonly formControlName="iin" class="w-1/4 bg-gray-200 pl-3 rounded-full mx-16" type="number">
        </div>

        <div class="flex mt-7">
          <p class="w-14"> {{ 'ikt' | translate }} </p>
          <input readonly formControlName="ikt" class="w-1/4 bg-gray-200 pl-3 rounded-full mx-16" type="text">
        </div>

        <div class="flex mt-7">
          <p class="w-14"> {{ 'lastName' | translate }} </p>
          <input readonly formControlName="lastName" class="w-1/4 bg-gray-200 pl-3 rounded-full mx-16" type="text">
        </div>

        <div class="flex mt-7">
          <p class="w-14"> {{ 'firstName' | translate }} </p>
          <input readonly formControlName="firstName" class="w-1/4 bg-gray-200 pl-3 rounded-full mx-16" type="text">
        </div>

        <div class="flex mt-7">
          <p class="w-14"> {{ 'patronymic' | translate }} </p>
          <input readonly formControlName="patronymic" class="w-1/4 bg-gray-200 pl-3 rounded-full mx-16" type="text">
        </div>

        <div class="flex mt-7">
          <p class="w-14"> {{ 'phone' | translate }} </p>
          <input formControlName="phoneNumber" class="w-1/4 bg-gray-200 pl-3 rounded-full mx-16" type="text">
        </div>
      </div>

      <div class="header-h1 font-bold text-center">
        <h1> {{'typeOfEmployment' | translate}} </h1>
      </div>

      <div *ngIf="form.get('jobTypeId')!.value != 8 && form.get('jobTypeId')!.value != 9" class="my-7 flex">
        <p class="ml-10 w-34"> {{ 'OrganizationType' | translate }} </p>
         <select (change)="getCategoryList(form.get('jobTypeId')!.value)" (change)="this.getTestLangList(0)" formControlName="jobTypeId" 
          class="w-auto ml-28 pl-3 pr-3 bg-gray-200 rounded-full">
          <option value="0" disabled selected> {{ 'ChooseTypeOfOrganization' | translate }} </option>
          <option [value]="item.id" *ngFor="let item of jobTypeList">  
            <span *ngIf="translate.currentLang == 'kz'">{{ item.nameKaz }} </span>
            <span *ngIf="translate.currentLang == 'ru'">{{ item.nameRus }} </span>
          </option>
        </select>
      </div>

      <div *ngIf="form.get('jobTypeId')?.value == 3 || form.get('jobTypeId')?.value == 5 || form.get('jobTypeId')?.value == 2 || form.get('jobTypeId')?.value == 6 || form.get('jobTypeId')?.value == 1" class="mt-7 ml-10">
       <p class="w-1/2"> {{ 'SpecialtyQualificationIndicatedInTheDiplomaOfEducationOrRetrainingDocumentWithTheAssignmentOfTheAppropriateQualificationForThePositionHeld' | translate }} </p>
        <input formControlName="diplomaSpecialityName" class="w-full rounded-lg bg-gray-200" type="text">
    </div>

      <div class="ml-10">
        <div *ngIf="form.get('jobTypeId')!.value != 8 && form.get('jobTypeId')!.value != 9" class="">
          <div class="flex">
            {{ 'EnterBin' | translate }}
            <input formControlName="bin" class="w-full border bg-gray-200 rounded-lg my-3 h-6 text-center"
              placeholder="БИН" type="text">
          </div>

          <div (click)="searchJob(form.get('bin')!.value)"
            class="w-full cursour-pointer font-serif mb-2 w-full rounded-full text-center py-1 px-24 bg-blue-300 rounded-full text-grey-darker">
            {{ 'SearchByBIN' | translate }}
          </div>
        </div>

        <div *ngIf="form.get('jobTypeId')!.value != 8 && form.get('jobTypeId')!.value != 9" class="flex py-3">
          <div class="w-1/3">
            {{ 'WorkPlace' | translate }}:
          </div>
          <div class="w-2/3">
            <select class="border bg-gray-200 rounded-lg" formControlName="firstLevelKato"
              (change)="getKATOsList(0, $event.target, true, 0)">
              <option selected disabled value="-1">
                {{ 'SelectObl' | translate }}
              </option>
              <option *ngFor="let obl of katoOblList" [value]="obl.id">
                <span *ngIf="translate.currentLang == 'kz'">{{ obl.nameKaz }} </span>
                <span *ngIf="translate.currentLang == 'ru'">{{ obl.nameRus }} </span>
              </option>
            </select>
            <div class="" *ngFor="let kat of KATOs; let i = index">
              <select class="border bg-gray-200 rounded-lg mt-2" (change)="getKATOsList(i, $event.target, false, 0)">
                <option [disabled] value="">
                  --
                </option>
                <option [selected]="pseudoId[i+1] == subject.id" *ngFor="let subject of kat; let j = index" [value]="j">
                  <span *ngIf="translate.currentLang == 'kz'">{{ subject.nameKaz }}</span>
                  <span *ngIf="translate.currentLang == 'ru'">{{ subject.nameRus }}</span>
                </option>
              </select>
            </div>

          </div>
        </div>
        <select *ngIf="form.get('jobTypeId')!.value != 8 && form.get('jobTypeId')!.value != 9" formControlName="jobId" class="w-full bg-gray-200 rounded-lg border my-1" name="">
          <option selected disabled [value]="0">
            {{ 'WorkPlace' | translate }}
          </option>
          <option [value]="item.jobId" *ngFor="let item of jobList">
            <span *ngIf="translate.currentLang == 'kz'"> {{ item.nameKaz }} </span>
            <span *ngIf="translate.currentLang == 'ru'"> {{ item.nameRus }} </span>
          </option>
        </select>

        <div *ngIf="form.get('jobTypeId')!.value == 9 && form.get('jobTypeId')!.value != 8" class="mt-7">
          <div>
            <p> {{ 'Obl' | translate }} </p>
            <select (change)="getAllRaionByOblId()" class="w-full rounded-lg border bg-gray-200 my-1" formControlName="eduInstituteOblId">
              <option selected disabled placeholder value="0">
                {{ 'EnterObl' | translate }}
              </option>
              <option *ngFor="let obl of oblList" [value]="obl.oblId">
                <span *ngIf="translate.currentLang == 'kz'"> {{ obl.nameKaz }} </span>
                <span *ngIf="translate.currentLang == 'ru'"> {{ obl.nameRus }} </span>
              </option>
            </select>
          </div>
        </div>

        <div *ngIf="form.get('jobTypeId')!.value == 8" class="mt-7">
          <div class="">
            <p> {{ 'EnterObl' | translate }} </p>
          <select class="w-full rounded-lg border bg-gray-200 my-1" formControlName="oblId">
            <option selected disabled placeholder value="0">
              {{ 'EnterObl' | translate }}
            </option>
            <option *ngFor="let obl of oblList" [value]="obl.oblId">
              <span *ngIf="translate.currentLang == 'kz'"> {{ obl.nameKaz }} </span>
              <span *ngIf="translate.currentLang == 'ru'"> {{ obl.nameRus }} </span>
            </option>
          </select>
        </div>
        </div>

        <div *ngIf="form.get('jobTypeId')!.value == 9" class="mt-7">
          <div class="">
            <p> {{ 'EnterRaion' | translate }} </p> 
          <select class="w-full rounded-lg border bg-gray-200 my-1" formControlName="raionId">
            <option selected disabled placeholder value="0">
              {{ 'EnterRaion' | translate }}
            </option>
            <option *ngFor="let raion of raionList" [value]="raion.raionId">
              <span *ngIf="translate.currentLang == 'kz'"> {{ raion.nameKaz }} </span>
              <span *ngIf="translate.currentLang == 'ru'"> {{ raion.nameRus }} </span>
            </option>
          </select>
        </div>
        </div>

        <div *ngIf="form.get('jobTypeId')!.value == 8" class="mt-7">
          <div class="">
            <p> {{ 'UniversityOfEducation' | translate }} </p> 
            <select formControlName="eduInstituteId" class="w-full rounded-lg border bg-gray-200 my-1">
              <option selected disabled value="0">
                {{ 'EnterVUZ' | translate }} 
              </option>
              <option *ngFor="let institute of instituteList" [value]="institute.id">
                <span *ngIf="translate.currentLang == 'kz'"> {{ institute.nameKaz }} </span>
                <span *ngIf="translate.currentLang == 'ru'"> {{ institute.nameRus }} </span>
              </option>
            </select>
          </div>
        </div>

        <div *ngIf="form.get('jobTypeId')!.value == 9 && form.get('jobTypeId')!.value != 8" class="mt-7">
          <div class="">
            <p> {{ 'ChooseACollege' | translate }} </p> 
            <select class="w-full rounded-lg border bg-gray-200 my-1" formControlName="eduSchoolId">
              <option selected disabled placeholder value="0">
                {{ 'ChooseACollege' | translate }}
              </option>
              <option *ngFor="let school of collegList" [value]="school.id">
                <span *ngIf="translate.currentLang == 'kz'"> {{ school.nameKaz }} </span>
                <span *ngIf="translate.currentLang == 'ru'"> {{ school.nameRus }} </span>
              </option>
            </select>
          </div>
        </div>

        <div *ngIf="form.get('jobTypeId')!.value != 8 && form.get('jobTypeId')!.value != 9" class="mt-7">
          <p class="w-1/3"> {{ 'currentQualifications' | translate }} </p>
          <select (change)="getPretendedCategoryList(1)" formControlName="currentCategoryId"
            class="w-full bg-gray-200 rounded-full">
            <option disabled value="-1"> {{'currentQualificationChooseQualification' | translate }} </option>
            <option [value]="item.id" *ngFor="let item of categoryList">
              <span *ngIf="translate.currentLang == 'kz'"> {{ item.nameKaz }} </span>
              <span *ngIf="translate.currentLang == 'ru'"> {{ item.nameRus }} </span>
            </option>
          </select>
        </div>

        <div class="mt-7">
          <!-- *ngIf="form.get('jobTypeId')!.value != 8 && form.get('jobTypeId')!.value != 9" -->
          <p class="w-1/3"> {{ 'ApplicableQualifications' | translate }} </p>
          <select formControlName="pretendedCategoryId" class="w-full bg-gray-200 rounded-full">
            <option disabled [value]="0"> {{ 'currentQualificationChooseQualification' | translate }} </option>
            <option [value]="item.id" *ngFor="let item of pretendedCategoryList">
              <span *ngIf="translate.currentLang == 'kz'"> {{ item.nameKaz }} </span>
              <span *ngIf="translate.currentLang == 'ru'"> {{ item.nameRus }} </span>
            </option>
          </select>
        </div>

        <div *ngIf="form.get('currentCategoryId')?.value != 0 && form.get('jobTypeId')!.value != 8 && form.get('jobTypeId')!.value != 9" class="mt-7">
          <p class="w-1/3"> {{ 'ValidityPeriodOfTheCurrentQualification' | translate }} </p>
          <input formControlName="currentCategoryValidTo" data-date-format="dd/mm/yyyy" type="date" min="1950-01-01"
            max="2021-12-31">
        </div>

        <div *ngIf="form.get('jobTypeId')?.value == 8 || form.get('jobTypeId')?.value == 9" class="mt-7">
          <div>
         <p> {{ 'SpecialtyQualificationIndicatedInTheDiplomaOfEducationOrRetrainingDocumentWithTheAssignmentOfTheAppropriateQualificationForThePositionHeld' | translate }} </p>
           <select formControlName="eduSpecialityId" class="w-full bg-gray-200 rounded-full">
            <option selected disabled value="0">
              {{ 'currentQualificationChooseQualification' | translate }}
            </option>
            <option *ngFor="let item of specialityTypeList" [value]="item.id">
              <span *ngIf="translate.currentLang == 'kz'"> {{ item.code }} - {{ item.nameKaz }} </span>
              <span *ngIf="translate.currentLang == 'ru'"> {{ item.code }} - {{ item.nameRus }} </span>
            </option>
          </select>
        </div>
      </div>
      </div>

      <div *ngIf="form.get('jobTypeId')!.value != 8 && form.get('jobTypeId')!.value != 9" class="ml-10 mt-7">
        <div>
          <p> {{ 'PeriodOfStudy' | translate }} </p>
          <input (change)="checkEduPeriod()" type="number" formControlName="eduPeriodStartYear"
            class="bg-gray-200 pl-2 rounded-lg" mask="0000" min="1960" max="2022">
          <input (change)="checkEduPeriod()" type="number" formControlName="eduPeriodFinishYear"
            class="ml-3 bg-gray-200 pl-2 rounded-lg" mask="0000" min="1960" max="2022">
        </div>
      </div>

      <div class="header-h1 font-bold text-center">
        <h1> {{ 'TESTPARAMETERS' | translate }} </h1>
      </div>
      <div class="ml-10">
        
        <div class="mt-7">
          <p> {{ 'TestLanguage' | translate }} </p>
          <select (change)="getChoiceSubject(0)" formControlName="testLangId" class="w-full bg-gray-200 rounded-full">  <!-- (change)="getChoiceSubject(0)"  -->
            <option [value]="0" selected> {{ 'ChooseYourLanguage' | translate }} </option>
            <option [value]="item.id" *ngFor="let item of testLangList"> 
              <span *ngIf="translate.currentLang == 'kz'"> {{ item.nameKaz }} </span>
              <span *ngIf="translate.currentLang == 'ru'"> {{ item.nameRus }} </span>
            </option>   
          </select>
        </div> 

        <div *ngIf="form.get('jobTypeId')?.value == 6" class="">
          <div>
               {{ 'EduTypeId' | translate }} 
          </div>
          <div class="my-4">
              <fieldset (change)="getCurrentEduTypeSpr()" form="applicationForm">
                  <div class="w-full speciality flex space-around">
                      <div class="mx-auto">
                          <input [checked]="form.get('eduTypeId')!.value == 1" formControlName="eduTypeId" [value]="1" id="GED" name="eduTypeId" type="radio"> <label class="ml-2" for="GED"> {{ 'GeneralEducationDisciplines' | translate }} </label>
                      </div>
                      <div class="mx-auto">
                          <input [checked]="form.get('eduTypeId')!.value == 2" formControlName="eduTypeId" [value]="2" id="SD" name="eduTypeId" type="radio"> <label class="ml-2" for="SD"> {{ 'SpecialDisciplines' | translate }} </label>
                      </div>
                  </div>
              </fieldset>
          </div>
        </div>

        <div *ngIf="form.get('jobTypeId')?.value == 6 && form.get('eduTypeId')?.value == 2" class="mt-7">
          <p> {{ 'Speciality' | translate }} </p>
          <div>
            <select  formControlName="specialityId" class="w-full bg-gray-200 rounded-full"> 
              <option selected disabled [ngValue]="0" selected> {{ 'ChooseASpecialty' | translate }} </option>
              <option [ngValue]="+item.id" *ngFor="let item of specialityList">
                <span *ngIf="translate.currentLang == 'kz'"> {{ item.nameKaz }} </span>
                <span *ngIf="translate.currentLang == 'ru'"> {{ item.nameRus }} </span>
              </option>
            </select>
          </div>
        </div> 

        <div *ngIf="form.get('jobTypeId')?.value == 7" class="mt-7">
          <p> Являюсь руководителем (директором): </p>
          <select formControlName="headerTypeId" class="w-full bg-gray-200 rounded-full">
            <option selected disabled [value]="0"> Являюсь руководителем (директором): </option>
            <option [ngValue]="+item.headerTypeId" *ngFor="let item of headerTypeList">
              <span *ngIf="translate.currentLang == 'kz'"> {{ item.nameKaz }} </span>
              <span *ngIf="translate.currentLang == 'ru'"> {{ item.nameRus }} </span>
            </option>
          </select>
        </div>

        <div class="mt-7 flex">
          <div>
            <label class="form-check-label">
              {{ 'Disability' | translate }}
            </label>
          </div>
          <input formControlName="isInvalid" class="ml-2 mt-1 w-4 h-4 invalid-checkbox" type="checkbox">
        </div>

        <div class="mt-7">
          <p class="w-1/3"> {{ 'TestingLocation' | translate }} </p>
          <select (change)="getTestPeriodList(1)" formControlName="testVptId" class="w-full bg-gray-200 rounded-full">
            <option [value]="0" disabled selected> {{ 'ChooseATestingSite' | translate }}</option>
            <option [value]="item.vptId" *ngFor="let item of vptList">
              <span *ngIf="translate.currentLang == 'kz'"> {{ item.nameKaz }} </span>
              <span *ngIf="translate.currentLang == 'ru'"> {{ item.nameRus }} </span>
            </option>
          </select>
        </div>

        <div class="mt-7">
          <p> {{ 'StreamNumberTestDate' | translate }} </p>
          <div  class="">
            <select formControlName="testPeriodId" class="w-full bg-gray-200 rounded-full">
              <option [value]="0" disabled selected> {{ 'Stream' | translate }} </option>
              <option [value]="item.id" *ngFor="let item of testPeriodList"> 
                {{ 'StreamNumber' | translate }} {{ item.id }}, {{ 'TestDate' | translate }} {{ item.startTestTime }} {{ item.testDate }}
                </option>
            </select>
          </div>
        </div>
      </div>

      <div class="pt-2 flex mt-4">
        <button (click)="editUser()" type="button"
          class="mb-2 w-full rounded-full py-1 px-24 bg-blue-300 rounded-full text-grey-darker">
          {{ 'Edit' | translate }}
        </button>
        <button [routerLink]="['/main/students']"
        class="font-serif mb-2 w-full rounded-full py-1 text-center px-24 bg-red-400 rounded-full text-grey-darker">
             {{ 'Cancellation' | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
