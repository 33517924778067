import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, ReactiveFormsModule, FormGroup, Validators, FormArrayName, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute, } from '@angular/router';
import { StudentTestControllerService } from '../services/student-test-controller.service';
import { AutheticationControllerService } from '../services/authetication-controller.service';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
// import { NgxMaskModule } from 'ngx-mask';


@Component({
  selector: 'app-new-incident',
  templateUrl: './new-incident.component.html',
  styleUrls: ['./new-incident.component.css']
})
export class NewIncidentComponent implements OnInit {

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public sanitizer: DomSanitizer,
    // public mask: NgxMaskModule,
    private main: StudentTestControllerService,
    public translate: TranslateService,
    private _notifications: NotificationsService) { }

  public loading = false;
  form!: FormGroup;
  formUnReset!: FormGroup;
  formChild!: FormGroup;
  dropdownSettings: IDropdownSettings = {};
  resetSessionTypeId!: number;
  studentTestId: any = [];
  studentId!: number;
  clicked = false;
  testDate: any;
  timeMask = "00:00";
  searchText = '';
  iin: any;
  lastname: any;
  id: any;
  placeNum: any;
  remoteStudentTestId: any;
  patronymic: any;
  studentTestStatusTypeId: any;
  testFinishedAt: any;
  studentTestStatusTypeName: any;
  testLangId: any;
  statementsTime: string = '00:00';
  notice!: string;
  testLangName: any;
  testOrgName = localStorage.getItem('testOrgName');
  testOrg = localStorage.getItem('testOrgId');
  testOrgId: any;
  testStartedAt: any;
  testTypeId: any;
  testTimeLeftInMinutes: any;
  orgIdsChekedsTrue: any = [];
  auditNum: any;
  createdAt: any;
  testPeriodList: any;
  firstname: any;
  testPeriodId: any;
  actionTypeIds: any = [];
  checked = false;
  resetSessionList: any;
  num!: number;
  namesIds: any;
  allUsersList: any;
  dropdownList: any;
  studentList: any;
  actionTypeId: any;
  selectedItems: any;
  zaprosInc = Array<any>();
  isAllChecked = false;
  checkedCount = 0;
  currentPageArray: any = [];
  p: number = 1;
  checkId = false;
  checkApel = false;
  role: any;
  userRole: any;
  addTime!: string;
  timeForTestMask: string = '000';
  sendStatement = false;
  addAppealTime!: string;
  ActionTreeList: any;
  parentList = Array<any>();
  parentActionId: any;
  childActionId: any;
  studentTestList: any = [];
  statementId: any;
  fileIdToDelete: any;


  ngOnInit(): void {

    if (localStorage.getItem('userRole') == 'ROLE_ADMIN') {
      this.role = 'admin';
    }

    if (localStorage.getItem('userRole') == 'ROLE_OPERATOR_NCT') {
      this.role = 'operatorNqt';
    }

    if (localStorage.getItem('userRole') == 'ROLE_OPERATOR') {
      this.role = 'operator';
    }

    this.form = new FormGroup({
      resetSessionTypeId: new FormControl(),
      testPeriodId: new FormControl(),
      parentActionId: new FormControl(),
      iin: new FormControl(),
      sendStatement: new FormControl(false),
      studentTestList: new FormControl([]),
      actionTypeIds: new FormArray([]),
      statementsTime: new FormControl(),
      notice: new FormControl(),
      addTime: new FormControl(),
      addAppealTime: new FormControl(),
      statementId: new FormControl(),
      checked: new FormControl(true),
      docFiles: new FormArray([]),
      additionalFiles: new FormArray([]),
      checkForResetTime: new FormControl(),

    });

    // this.formUnReset = new FormGroup({
    //   addTime: new FormControl(),
    //   addAppealTime: new FormControl(),
    //   checked: new FormControl(false),
    // });

    this.findAllResetSessionType();
    this.findAllTestPeriodByTestOrgId();
    this.findAllStatementsActionTree();

  }

  get actionsArray(): any { return this.form.get('actionTypeIds') as FormArray }

  actionSaveUser(checked: boolean = false, childActionId: any, childActionNameKaz: any, childActionNameRus: any, parentActionId: any, parentActionNameRus: any, parentActionNameKaz: any): FormGroup {
    return new FormGroup({
      checked: new FormControl(checked),
      childActionId: new FormControl(childActionId),
      childActionNameKaz: new FormControl(childActionNameKaz),
      childActionNameRus: new FormControl(childActionNameRus),
      parentActionId: new FormControl(parentActionId),
      parentActionNameRus: new FormControl(parentActionNameRus),
      parentActionNameKaz: new FormControl(parentActionNameKaz),

    })
  }

  findAllStatementsActionTree() {
    this.parentList = [];
    this.main.findAllStatementsActionTree()
      .subscribe((res: any) => {
        this.ActionTreeList = [];
        this.ActionTreeList = res;
      }, (err: any) => {

      });

  }

  findChildActionByParentId(action: any) {
    this.form.get('addTime')!.setValue("");
    this.form.get('addAppealTime')!.setValue("");
    this.actionsArray.controls = [];
    this.main.findChildActionByParentId(action.parentActionId)
      .subscribe((res: any) => {
        this.parentList = res;
        this.formsOfActions(action);
      }, (err: any) => {

      });
  }

  formsOfActions(anction: any) {
    if (this.parentList.length > 0) {
      this.parentList.forEach((element: any) => {
        this.actionsArray.push(this.actionSaveUser(
          true,
          element.childActionId,
          element.childActionNameKaz,
          element.childActionNameRus,
          element.parentActionId,
          element.parentActionNameKaz,
          element.parentActionNameRus,

        ));
      });
    } else {
      this.actionsArray.push(this.actionSaveUser(
        true,
        anction.childActionId,
        anction.childActionNameKaz,
        anction.childActionNameRus,
        anction.parentActionId,
        anction.parentActionNameKaz,
        anction.parentActionNameRus,

      ));
    }

  }

  findAllResetSessionType() {
    this.main.findAllResetSessionType()
      .subscribe((res: any) => {
        this.resetSessionList = res;
      }, (err: any) => {
      });
  }

  findAllTestPeriodByTestOrgId() {
    let orgId;
    orgId = this.testOrgName?.slice(0, 3)
    orgId = Number(orgId);
    this.main.findAllTestPeriodByTestOrgId(this.testOrg)
      .subscribe((res: any) => {
        this.testPeriodList = [];
        res[0].testPeriodForStatementsDtoList.forEach((element: any, i: any) => {
          this.testPeriodList[i] = {
            name: "" + element.testDate + ' - ' + element.startTestTime,
            id: element.id
          }
        });
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
          this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
      });
  }

  idOfStudent(studentId: number) {
    return new FormControl(studentId);
  }

  getStudentListFromOnlineTest() {
    this.main.getStudentListFromOnlineTest(this.form.get('testPeriodId')!.value)
      .subscribe((res: any) => {
        this.studentList = [];
        this.studentList = res.items;
      }, (err: any) => {
      });
  }

  operatorSendStatement(sendStatement: boolean) {
    this.form.get('studentTestList')!.setValue(this.studentTestId);
    this.form.get('statementId')!.setValue(this.statementId);

    if (this.form.get('parentActionId')!.value !== 2) {
      this.form.get('addTime')!.setValue("");
    }

    if (this.form.get('parentActionId')!.value !== 12) {
      this.form.get('addTime')!.setValue("");
    }

    if(this.form.get('parentActionId')!.value !== 9 || this.form.get('parentActionId')!.value == 13){
      this.form.get('addAppealTime')!.setValue("");
    }

    this.main.operatorSendStatement(sendStatement, this.form.value)
      .subscribe((res: any) => {
        this.router.navigate(['/main/incident']);
        this.translate.currentLang == 'ru' ? this._notifications.create('Успешно', 'заявка создана', NotificationType.Success, 5000) :
          this._notifications.create('Сәтті', 'пайдаланушы бұғатталған', NotificationType.Success);
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
          this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
      });
  }

  checkAllOptions() {
    if (!this.isAllChecked) {
      this.studentList.forEach((element: any) => {
        this.studentTestId.push({
          studentTestId: element.remoteStudentTestId,
          checked: true
        });
        element.isChecked = true;
        if (this.isAllChecked = true) {
          this.checkedCount = 0;
        }
      });
      this.isAllChecked = true;
    } else {
      this.studentList.forEach((element: any) => {
        element.isChecked = false;
        this.studentTestId = [];
      });
      this.isAllChecked = false;
    }
  }

  checkStudent(event: any, student: any) {
    this.checkedCount = 0;
    if (event.target.checked == true) {


      this.studentTestId.push({
        studentTestId: student.remoteStudentTestId,
        checked: student.isChecked = true
      });
    } else {
      for (let i = 0; i < this.studentList.length; i++) {
        if (this.studentList[i] === student) {
          this.studentList[i].isChecked = false;
        }
      }
      this.studentTestId = this.studentTestId.filter((s: any) => student.remoteStudentTestId !== s.studentTestId);
    }
    this.studentList.forEach((element: any) => {
      if (element.isChecked === false) {
        this.isAllChecked = false;
      } else {
        this.checkedCount++;
      }
    });
    // if(this.studentList.length == this.checkedCount){
    //   this.isAllChecked = true;
    // }
  }

  returnFunc(actions: any) {



    this.form.get('actionTypeIds')!.value.forEach((element: any) => {
      if (element.parentActionId === 2) {
        this.checkId = true;
        this.form.get('addTime')!.value;
      } else {
        if (actions.parentActionId !== 2) {
          this.checkId = false;
          this.form.get('addTime')!.setValue(0);
        }
      }
    });
    this.form.get('actionTypeIds')!.value.forEach((element: any) => {
      if (element.parentActionId === 12) {
        this.checkId = true;
        this.form.get('addTime')!.value;
      } else {
        if (actions.parentActionId !== 12) {
          this.checkId = false;
          this.form.get('addTime')!.setValue(0);
        }
      }
    });
    this.form.get('actionTypeIds')!.value.forEach((element: any) => {
      if (this.form.get('parentActionId')!.value === 9) {
        this.checkApel = true;
        this.addAppealTime = "";
      } else {
        if (this.form.get('parentActionId')!.value === 9) {
          this.checkApel = false;
          this.addAppealTime = "";
        }
      }
    });
    // this.form.get('actionTypeIds')!.value.forEach((element: any) => {
    //   if (actions.childActionId === 9) {
    //     if (element.checked === false && this.form.get('parentActionId')!.value === 9) {
    //       this.checkApel = true;
    //       this.addAppealTime = "";
    //     }else{
    //       if (element.checked === true && this.form.get('parentActionId')!.value === 9) {
    //       this.checkApel = false;
    //       this.addAppealTime = "";
    //       }
    //     }
    //   }
    // });
  }

  get filesExam(): any { return this.form.get('additionalFiles') };

  addFile() {
    let obj = null;

    obj = this.filesExam;

    obj.push(
      new FormGroup({
        filename: new FormControl('', [Validators.required]),
        id: new FormControl(0),
        data: new FormControl()
      })
    );
  }

  addFileExam() {
    let obj = null;

    obj = this.filesExam;

    obj.push(
      new FormGroup({
        filename: new FormControl('', [Validators.required]),
        id: new FormControl(0),
        data: new FormControl()
      })
    );
  }

  changeListenerExam($event: any, i: number): void {
    let obj: any = null;

    obj = this.filesExam;

    const file: File = $event.target.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      obj.controls[i].get('filename').setValue(file.name);
      let imgData: any = myReader.result;

      obj.controls[i].get('data').setValue(imgData.toString().substring(imgData.toString().indexOf(',') + 1));
    }
    myReader.readAsDataURL(file);
  }

  dwnldCheckExam(id: number): boolean {
    return this.filesExam.controls[id].get('filename').value !== '';
  }

  removeFileExam() {
    this.translate.currentLang == 'ru' ? this._notifications.create('Файл', 'успешно удален', NotificationType.Success, 5000) : this._notifications.create('Файл', 'сәтті жойылды', NotificationType.Success, 5000);
    let obj = null;

    obj = this.filesExam;

    obj.removeAt(this.fileIdToDelete);
  }

}
