import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, ReactiveFormsModule, FormGroup, Validators, FormArrayName } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { RefControllerService } from '../services/ref-controller.service';
import { AutheticationControllerService } from '../services/authetication-controller.service';
import { StudentTestControllerService } from '../services/student-test-controller.service';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { Subscriber } from 'rxjs';

@Component({
  selector: 'app-students',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.css']
})
export class StudentsComponent implements OnInit {
  public loading = false;
  form!: FormGroup;
  formStatus!: FormGroup;
  setStudentStatus: any;
  id: any;
  activeList: any;
  statusList: any;
  studentData: any;
  studentList!: any[];
  updateStatus: any;
  studentTestStatusTypeId: any;
  nameRus: any;
  studentId: any;
  iin: any;
  firstName: any;
  lastName: any;
  dateZayav: any;
  patronymic: any;
  jobTypeEntity: any;
  phoneNumber: any;
  login: any;
  password: any;
  annulmentTypeIdList: any = [];
  auditNum: any;
  testVptId: any;
  specialityEntity: any;
  pretendedCategory: any;
  testVptList: any;
  limit: any;
  job: any;
  page: any;
  studenId: any;
  studentTestId: any;
  statusStudenta: any;
  testDate: any;
  showModal = false;
  showConfirmation = false;
  langName: any;
  testStatus: any;
  isInvalid: any;
  subjects: any;
  arrayStud: any;
  jobTypeList: any;
  testVpt: any;
  statusId: any;
  isAllChecked = false;
  vptId: any;
  vptList: any;
  certifName: any;
  newStatusList: any;
  appTypeusId: any;
  testFinishedAt: any;
  testStartedAt: any;
  activeModal: any;
  showStatus = false;
  searchChecker = 0;
  jobType: any;
  jobTypeId: any;
  obl: any;
  testScoreSum: any;
  role: any;
  testPeriod: any = [];
  currentPageArray: any = [];
  tipoEduTypeEntity: any;
  year: any;
  testType: any;
  content: any = [];
  numberOfPages: any;
  hoverOnStudent = 0;
  showStatusReset = false;
  checkedCount = 0;
  employmentTypeEntity: any;
  roomNumber: any;
  roomPlaceNumber: any;
  informWindow = false;
  iinMask = '000000000000';
  formResetSession!: FormGroup;
  studentTestIdBuffer: any;
  isHaveEsse: any;
  langEntity: any;
  sabjectId: any;
  eduInstituteId : any;
  eduSchool: any;
  email: any;
  showPer = false;
  showPeresadka = false;
  withStatusNeYavilsya = false;
  roomId: any;
  testPeriodId: any;
  testVptLists: any;
  annulmentTypeId: any = [];
  ppentName: any;
  vptLists: any = [];
  testOrgId: any;
  transferList: any;
  roomFreeNumber: any;
  auditPlaceList: any = [];
  annulmentTypeIds: any = [];
  freePlaceList: any = [];
  list: any = [];
  placeNum: any;
  auditId: any;
  actualumber: any;
  studentsTestId: any;
  resetSessionList: any = [];


  p: number = 1;

  constructor(
    private autheticationControllerService: AutheticationControllerService,
    private refControllerService: RefControllerService,
    public route: ActivatedRoute,
    public router: Router,
    private studentTest: StudentTestControllerService,
    public translate: TranslateService,
    private _notifications: NotificationsService) { }

  ngOnInit(): void {
    this.studentList = [];

    if(localStorage.getItem('role') == 'ROLE_ADMIN'){
      this.role = 'admin';
    }

    if(localStorage.getItem('role') == 'ROLE_OPERATOR_NCT'){
      this.role = 'operatorNqt';
    }

    if(localStorage.getItem('role') == 'ROLE_OPERATOR'){
      this.role = 'operator';
    }



    this.formResetSession = new FormGroup({
      studentTestList: new FormArray([]),
      testPeriodId: new FormControl(0),
      testVptId: new FormControl(0),
      resetByTestVpt: new FormControl(false),
      studentsTestId: new FormControl(0),

    });

    this.form = new FormGroup({
      setStudentStatus: new FormControl(0),
      updateStudentForm: new FormArray([]),
      studentData: new FormControl(),
      arrayStud: new FormControl(),
      lastName: new FormControl(''),
      firstName: new FormControl(''),
      searchType: new FormControl(0),
      newStatusList: new FormControl(''),
      patronymic: new FormControl(''),
      list: new FormControl([]),
      iin: new FormControl(),
      login: new FormControl(),
      password: new FormControl(),
      vptList: new FormControl(),
      content: new FormControl([]),
      jobTypeList: new FormControl(),
      // roomId: new FormControl(),
      testVptList: new FormControl(),
      testVptId: new FormControl(),
      // sabjectId: new FormControl(),
      dateZayav: new FormControl(),
      testOrg: new FormControl(),
      isHaveEsse: new FormControl(),
      testOrgId: new FormControl(),
    });

    this.formStatus = new FormGroup({
      studentsTestId: new FormControl(0),
      studentTestStatusTypeId: new FormControl(0),
      annulmentTypeIds: new FormArray([]),
      notes: new FormControl(''),
      checked: new FormControl(false),
      reasonDesc: new FormControl(),
    });

    this.getAllVpt();
    this.form.get('testOrg')!.setValue(0);
    this.form.get('testVptId')!.setValue(0);
    this.form.get('searchType')!.setValue(1);
    this.formStatus.get('studentTestStatusTypeId')!.setValue(0);

  }

  getTestStatus(){
    this.studentTest.findSomeTestStatusById()
      .subscribe({
        next: (res: any) => {
          this.statusList = res;
          this.showStatus = true;
        },
        error: (err: any) => {
    }
  });
}

  findAllAppTypeByIdInForChangeAppType(){
    this.studentTest.findAllAppTypeByIdInForChangeAppType()
      .subscribe((res: any) => {
        this.activeList = res.items;
      }, (err: any) => {

      });
  }

  showModalPerehod(studentTestId: any){
    this.studentTestId = studentTestId;
    this.showPer = true;
    this.findAllAppTypeByIdInForChangeAppType();
  }

findAllResetSessionType(){
  this.studentTest.findAllAnnulmentType()
  .subscribe((res: any) => {
    this.resetSessionList = [];
    this.resetSessionList = res;
  }, (err: any) => {
  });
}

  getStudentData(studentTestId: any) {
    this.showConfirmation = false;
    this.studentTest.getLoginAndPasswordOfStudent(studentTestId)
      .subscribe({
        next: (res: any) => {
          this.studentData = res.studentTestId;
        },
         error :(err: any) => {
      }
    });
  }

  showModalWind(studentTestId: any) {
    let checkedCount: number = 0;
    let studentListStudentId: number = 0;
    this.studentList.forEach((element: any) => {
        checkedCount = checkedCount + 1;
        studentListStudentId = element.studentTestId;
        if(studentTestId == element.studentTestId){
          element.isChecked = true;
        }
    });
      this.showModal = true;
      this.studentTest.getLoginAndPasswordOfStudent(studentTestId)
      .subscribe({
        next: (res: any) => {
          this.login = res.login;
          this.password = res.password;
     },
        error: (err: any) => {
    }
   });
  }

  closeLoginModal(studentTestId: any){
    this.showModal = !this.showModal;
    this.studentList.forEach((element: any) => {
        if(studentTestId == element.studentTestId){
          element.isChecked = false;
        }
    });
  }

  poiskIin() {
    // this.loading = true;
    let iin = this.form.get('iin')!.value;
    this.studentTest.studentTestFindByIin(iin)
      .subscribe({
        next: (res: any) => {
          this.loading = false;
          this.studentList = res;
          this.studentList.forEach((element: any) => {
            element.isChecked = false;
          });
      },
        error: (err: any) => {
          this.loading = false;
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
          this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
        }
      })
  }

  poiskFio() {
    this.loading = true;
    if(!!this.form.get('lastName')!.value && !!this.form.get('firstName')!.value && !!this.form.get('patronymic')!.value){
      this.studentTest.findByFio(this.form.get('lastName')!.value, this.form.get('firstName')!.value, this.form.get('patronymic')!.value, this.p - 1, 50)
      .subscribe({
        next: (res: any) => {
          this.studentList = res.content;
          this.numberOfPages = res.totalElements;
          this.studentList.forEach((element: any) => {
            element.isChecked = false;
          });
      },
        error: (err: any) => {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
          this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
          this.loading = false;
        }
      });
    }
    if(!!this.form.get('lastName')!.value && !!this.form.get('firstName')!.value && this.form.get('patronymic')!.value == ''){
      this.studentTest.findByFi(this.form.get('lastName')!.value, this.form.get('firstName')!.value, this.p - 1, 50)
      .subscribe({
        next: (res: any) => {
          this.studentList = res.content;
          this.numberOfPages = res.totalElements;
          this.studentList.forEach((element: any) => {
            element.isChecked = false;
            this.loading = false;
          });
      },
        error :(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
        this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
        this.loading = false;
        }
      });
    }
    if(!!this.form.get('lastName')!.value && this.form.get('firstName')!.value == '' && !!this.form.get('patronymic')!.value){
      this.studentTest.findByFo(this.form.get('lastName')!.value, this.form.get('patronymic')!.value, this.p - 1, 50)
      .subscribe({
        next: (res: any) => {
          this.studentList = res.content;
          this.numberOfPages = res.totalElements;
          this.studentList.forEach((element: any) => {
            element.isChecked = false;
            this.loading = false;
          });
      },
        error: (err: any) => {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
          this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
          this.loading = false;
        }
      });
    }
    if(this.form.get('lastName')!.value == '' && !!this.form.get('firstName')!.value && !!this.form.get('patronymic')!.value){
      this.studentTest.findByIo(this.form.get('firstName')!.value, this.form.get('patronymic')!.value, this.p - 1, 50)
      .subscribe({
        next: (res: any) => {
          this.studentList = res.content;
          this.numberOfPages = res.totalElements;
          this.studentList.forEach((element: any) => {
            element.isChecked = false;
            this.loading = false;
          });
      },
        error: (err: any) => {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
          this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
          this.loading = false;
        }
      });
    }
    if(!!this.form.get('firstName')!.value && this.form.get('lastName')!.value == '' && this.form.get('patronymic')!.value == ''){
      this.studentTest.findByI(this.form.get('firstName')!.value, this.p - 1, 50)
      .subscribe({
        next:  (res: any) => {
        this.studentList = res.content;
        this.numberOfPages = res.totalElements;
        this.studentList.forEach((element: any) => {
          element.isChecked = false;
          this.loading = false;
        });
      },
        error:  (err: any) => {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
          this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
          this.loading = false;
        }
      });
    }
    if(this.form.get('lastName')!.value == '' && this.form.get('firstName')!.value == '' && !!this.form.get('patronymic')!.value ){
      this.studentTest.findByO(this.form.get('patronymic')!.value, this.p - 1, 50)
      .subscribe({
        next: (res: any) => {
          this.studentList = res.content;
          this.numberOfPages = res.totalElements;
          this.studentList.forEach((element: any) => {
            element.isChecked = false;
            this.loading = false;
          });
      },
        error: (err: any) => {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
          this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
          this.loading = false;
        }
      });
    }
    if(!!this.form.get('lastName')!.value && this.form.get('firstName')!.value == '' && this.form.get('patronymic')!.value == ''){
      this.studentTest.findByF(this.form.get('lastName')!.value, this.p - 1, 50)
      .subscribe({
        next: (res: any) => {
          this.studentList = res.content;
          this.numberOfPages = res.totalElements;
          this.studentList.forEach((element: any) => {
            element.isChecked = false;
            this.loading = false;
          });
      },
        error:(err: any) => {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
          this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
          this.loading = false;
        }
      });
    }
  }

  findStudent() {
    this.informWindow = true;
      if(this.searchChecker != 0){
        this.studentList.forEach((element: any) => {
          element.isChecked = false;
        });
        this.searchChecker = 1;
        if (this.form.get('searchType')!.value == 1) {
          this.poiskIin();
        }
        if (this.form.get('searchType')!.value == 2){
          this.poiskFio();
        }
        }else{
        if (this.form.get('searchType')!.value == 1) {
          this.poiskIin();
        }
        if (this.form.get('searchType')!.value == 2) {
          this.poiskFio();
        }
      }
      this.isAllChecked = false;
      this.currentPageArray = [];
  }

 allTestStatus(){
   this.studentTest.getAllTestStatus()
   .subscribe({
    next: (res: any) => {
      this.statusId = res;
     },
     error: (err: any) => {
    }
   });
 }

  oknoData(student: any){
    this.hoverOnStudent = student.studentTestId;
    this.dateZayav = student.dateZayav;
    this.iin = student.iin;
    this.jobType = student.jobType;
    this.jobTypeId = student.jobTypeId;
    this.lastName = student.lastName;
    this.firstName = student.firstName;
    this.patronymic = student.patronymic;
    this.studentTestId = student.studentTestId;
    this.langName = student.langName;
    this.subjects = student.subjects;
    this.langEntity = student.langEntity;
    this.eduSchool = student.eduSchool;
    this.eduInstituteId = student.eduInstituteId;
    this.testPeriod = student.testPeriod;
    this.testVpt = student.testVpt;
    this.phoneNumber = student.phoneNumber;
    this.isHaveEsse = student.isHaveEsse;
    this.email = student.email;
    this.job = student.job;
    this.employmentTypeEntity = student.employmentTypeEntity;
    this.testStatus = student.testStatus;
    this.jobTypeEntity = student.jobTypeEntity;
    this.specialityEntity = student.specialityEntity;
    this.tipoEduTypeEntity = student.tipoEduTypeEntity;
    this.pretendedCategory = student.pretendedCategory;
    this.vptId = student.vptId;
    this.obl = student.obl;
    this.testScoreSum = student.testScoreSum;
    this.roomNumber = student.roomNumber;
    this.roomPlaceNumber = student.roomPlaceNumber;
    this.testFinishedAt = student.testFinishedAt;
    this.testStartedAt = student.testStartedAt;
    this.isInvalid = student.isInvalid;
  }

  dowloadSertif(){
      this.studentTest.dowloadSertif(3, this.testPeriod.testDate.slice(-4), this.studentTestId, this.iin)
    .subscribe({
      next: (res: any) => {
        const blob = new Blob([res], {type: 'application/pdf'});
        // const data = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        let certifLink = res.data.certificateDownLoadUrl;
        window.open(certifLink, "_blank");
        this.loading = false;
        // link.href = data;
        // link.download = 'сертификат.pdf';
        link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
        setTimeout(function() {
          // window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
    },
    error: async (err: any) => {
      const message = JSON.parse(await err.error.text()).message;
      this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', message, NotificationType.Error, 5000) :
      this._notifications.create('Қате', message, NotificationType.Error, 5000);
    }
    });
  }

  checkAllOptions() {
    this.getCurrentPage();
    if(!this.isAllChecked){
      this.studentList.forEach((element: any) => {
        this.currentPageArray.forEach((copyElement: any) => {
          if(copyElement == element){
            element.isChecked = true;
          }
        });
      });
      this.isAllChecked = true;
    }else{
      this.studentList.forEach((element: any) => {
        this.currentPageArray.forEach((copyElement: any) => {
          if(copyElement == element){
            element.isChecked = false;
          }
        });
      });
      this.isAllChecked = false;
    }
  }

  getCurrentPage(){
    this.currentPageArray = [];
    let currentFirstElement: any = (this.p * 50) - 50;
    for(let i = 0; i < 50; i++){
      if(this.studentList[currentFirstElement + i] != undefined){
        this.currentPageArray.push(this.studentList[currentFirstElement + i]);
      }
    }
  }
  checkPage(event: any){
    if(this.p != event){
      this.studentList.forEach((element: any) => {
        if(element.isChecked == true){
          element.isChecked = false;
        }
      });
    }
    this.p = event;
    this.getCurrentPage();
    this.findStudent();
    let counter: number = 0;
    if(counter == 10){
      this.isAllChecked = true;
    }else{
      this.isAllChecked = false;
    }
  }

  checkStudent(event: any, student: any){
    this.checkedCount = 0;
    if (event.target.checked == true) {
      for( let i = 0; i < this.studentList.length; i++){
        if ( this.studentList[i] === student) {
          this.studentList[i].isChecked = true;
        }
      }
    } else{
      for( let i = 0; i < this.studentList.length; i++){
        if ( this.studentList[i] === student) {
          this.studentList[i].isChecked = false;
        }
      }
    }
    this.studentList.forEach((element: any) => {
      if(element.isChecked){
        this.checkedCount++;
      }
    });
  }

  get studentsId(): any { return this.formStatus.get('studentsTestId') as FormArray}

  get studentsIdList(): any { return this.formResetSession.get('studentTestList') as FormArray}

  idOfStudent(studentId: number) {
    return new FormControl(studentId);
  }

  showModalStatus(studentTestStatusId: any, studentsTestId: any){
    this.formStatus.get('studentTestStatusTypeId')?.setValue(0);
    this.formStatus.get('reasonDesc')?.setValue('');
    this.formStatus.get('studentsTestId')?.setValue(studentsTestId);
    this.formStatus.get('studentTestStatusTypeId')?.setValue(studentTestStatusId);
    this.formStatus.get('notes')?.setValue('');
    this.studentsTestId = studentsTestId;
    this.statusStudenta = studentTestStatusId;
    this.findAnnulmentType(studentsTestId);
    if(this.statusStudenta == 7){
      this.getFindSomeTestStatus();
    }else{
      this.getTestStatus();
    }
  }

  get allAnnuStatusArray(): any {return this.formStatus.get('annulmentTypeIds') as FormArray}

  findAnnulmentType(studentsTestId: any){
    this.allAnnuStatusArray.controls = [];
    this.studentTest.findAnnulmentType(studentsTestId)
    .subscribe((res: any) => {
      this.annulmentTypeIdList = [];
      this.annulmentTypeIdList = res.annulmentTypeIdList;
      this.annulmentTypeIdList.forEach((element: any) => {
        this.allAnnuStatusArray.push(this.anullObject(element.annulmentTypeId, element.checked))
      })
      this.loading = false;
      // this.formsOfStatus();
    }, (err: any) => {
    });
  }
  coasnd(){
  }

  anullObject(id: any, checked: boolean = false): FormGroup {
    return new FormGroup({
      id: new FormControl (id),
      checked: new FormControl(checked)
    });

    }

  updateStudentStatus(showStatus: false){
    if(this.formStatus.get('annulmentTypeIds')!.value == 0){
      this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', 'Не выбрана причина', NotificationType.Error, 5000) :
      this._notifications.create('Қате', 'Не выбрана причина', NotificationType.Error, 5000);
    }else{
    this.studentTest.setStatus(this.formStatus.value)
    .subscribe({
      next: (res: any) => {
        this.findStudent();
        this.translate.currentLang == 'ru' ? this._notifications.create('Успешно', 'Cтатус изменен', NotificationType.Success, 5000) :
        this._notifications.create('Сәтті', 'Техникалық хатшы қосылды', NotificationType.Success, 5000);
      },
    error: (err: any) => {
      this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
      this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
    }
    });
  }
  }

  getAllVpt(){
    this.studentTest.findAllVpt()
    .subscribe({
      next: (res: any) => {
        this.vptList = res;
      },
        error: (err: any) => {
        }
    });
  }

  getAllTestPeriodByTestVptId(){
    this.studentTest.findAllTestPeriodByTestVptId(this.form.get('testOrg')!.value)
    .subscribe({
      next: (res: any) => {
        this.testVptList = res;
      },
      error: (err: any) => {
      }
    });
  }

  resetSessionWindow(code: number){
    let checkedCount: number = 0;
    this.studentList.forEach((element: any) => {
      if(element.isChecked == true){
      checkedCount = checkedCount + 1;
      }
    });
    if(checkedCount == 0){
      this.showStatusReset = false;
    }else{
      this.showStatusReset = true;
    }
  }

  getResetSession(){
    this.studentsIdList.push(this.idOfStudent(this.studentTestIdBuffer));

    this.studentTest.resetSession(this.formResetSession.value)
    .subscribe({
      next:(res: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Успешно', res.response, NotificationType.Success, 5000) :
        this._notifications.create('', res.response, NotificationType.Success, 5000);
        this.showStatusReset = false;
        this.findStudent();
        this.studentTestIdBuffer = 0;
        this.studentsIdList.clear();
      },
      error:(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
        this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
      }
      });
  }

  getShowStatusReset(id: any){
    this.studentTestIdBuffer = id;
    this.showStatusReset = !this.showStatusReset;
  }

  getFindSomeTestStatus(){
    this.showStatus = true;
    this.studentTest.findSomeTestStatus()
    .subscribe({
      next:(res: any) => {
        this.statusList = res;
       },
       error: (err: any) => {
      }
    });
  }

  getNewStatus(){
    if(this.statusStudenta == 7){
      this.getFindSomeTestStatus()
    }
  }

    showModalPeresad(studentTestId: any, code: any){
      this.findPlaceInfoForTransfer(studentTestId, code);
      this.showPeresadka = true;
      }

      transferStudentTest(){
      this.showPeresadka = false;
      let list =  [{
        roomId: this.auditId,
        roomPlaceNumber: this.roomPlaceNumber,
        studentTestId: this.studentTestId,
        testOrgId: this.testVptId,
        testPeriodId: this.testPeriodId,
      }];
    this.studentTest.transferStudentTest(this.withStatusNeYavilsya, list)
      .subscribe({
        next:(res: any) => {
          // console.log(this.withStatusNeYavilsya);

          this.oknoData(this.studentTestId);
          this.findStudent()
          this.translate.currentLang == 'ru' ? this._notifications.create('Успешно', 'Пересадка прошла успешно', NotificationType.Success, 5000) :
          this._notifications.create('Сәтті', 'Техникалық хатшы қосылды', NotificationType.Success, 5000);
        },
        error: (err: any) => {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
          this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
        }
      });
  }

  findPlaceInfoForTransfer(studentTestId: any, code: any){
    this.studentTest.getStudentTestInfo(studentTestId)
    .subscribe({
      next:  (res: any) => {
        let actualumber: any
        this.transferList = res;
        this.actualumber = this.transferList.placeNum;
          this.getAllVpts(1);
        },
        error:(err: any) => {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
          this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
        }
    });
  }

  getAllVpts(code: any){
    this.vptLists = [];
    this.studentTest.findAllVpt()
    .subscribe({
      next: (res: any) => {
        this.vptLists = res;
        if(code == 0){
          this.testVptId = 0;
        }else{
          this.testVptId = this.transferList.testVpt.vptId;
          this.getAllTestPeriodByTestVptIds(1);
        }
      }, error: (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
        this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
      }
    })
  }

  getAllTestPeriodByTestVptIds(code: any){
    this.testVptLists = [];
    this.studentTest.findAllTestPeriodByTestVptId(this.testVptId)
    .subscribe({
      next: (res: any) => {
        this.testVptLists = res;
        if(code == 0){
          this.testPeriodId = 0;
        }else{
         this.testPeriodId = this.transferList.testPeriod.id;
         this.roomNumbers(1);
        }
        this.vptLists.forEach((element: any) => {
          if(element.vptId == this.testVptId){
            if(this.translate.currentLang == 'kz'){
              this.ppentName = element.vptId + '-' + element.nameKaz
            }else{
              this.ppentName = element.vptId + '-' + element.nameRus
            }
          }
        });
      },
      error: (err: any) => {
      }
    });
  }

  roomNumbers(code: any){
    this.auditPlaceList = [];
    this.studentTest.roomNumber(this.testPeriodId, this.testVptId)
  .subscribe({
    next: (res: any) => {
      this.auditPlaceList = res;
      if(code == 0){
        this.auditId = 0;
        }else{
        this.auditId = this.transferList.auditId;
        this.placeNumber(1);
      }
    },
    error: (err: any) => {
    this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
    this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
  }
  });
}

placeNumber(code: any){
  this.loading = true;
    this.freePlaceList = [];
    this.studentTest.roomPlaceNumber(this.testVptId, this.testPeriodId, this.auditId)
  .subscribe({
    next: (res: any) => {
      this.loading = false;
      this.freePlaceList = res;
      if(code == 0){
        this.roomPlaceNumber = 0;
      }else{
        this.roomPlaceNumber = this.transferList.roomPlaceNumber;
      }
    },
    error: (err: any) => {
      this.loading = false;
      this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
      this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
    }
  });
}

closePeresad(asd: any){
  this.showPeresadka = false
}

  imageAfterTest(studentTestId: any){
    this.studentTest.getImageAfterTest(studentTestId)
      .subscribe((res: any) => {

        const base64Data = res.items[0].content;
        const binaryString = atob(base64Data);
        const binaryLength = binaryString.length;
        const bytes = new Uint8Array(binaryLength);

        for (let i = 0; i < binaryLength; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        const blob = new Blob([bytes], { type: 'image/png' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = 'downloaded-image.png';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        URL.revokeObjectURL(url);
      })
  }

}
