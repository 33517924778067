import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, ReactiveFormsModule, FormGroup, Validators, FormArrayName, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute, } from '@angular/router';
import { StudentTestControllerService } from '../services/student-test-controller.service';
import { AutheticationControllerService } from '../services/authetication-controller.service';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { IDropdownSettings } from 'ng-multiselect-dropdown';


@Component({
  selector: 'app-incident-for-oper',
  templateUrl: './incident-for-oper.component.html',
  styleUrls: ['./incident-for-oper.component.css']
})
export class IncidentForOperComponent {

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public sanitizer: DomSanitizer,
    // public mask: NgxMaskModule,
    private main: StudentTestControllerService,
    public translate: TranslateService,
    private _notifications: NotificationsService) {

  }

  public loading = false;
  form!: FormGroup;
  dropdownSettings: IDropdownSettings = {};
  resetSessionTypeId: any;
  studentId!: number;
  selectedItems: any = [];
  addForm!: FormGroup;
  testOrgName = localStorage.getItem('testOrgName');

  clicked = false;
  testDate: any;
  timeMask = '00:00';
  iin: any;
  testOrgEntity: any;
  searchIIN: string = '';
  lastname: any;
  placeNum: any;
  remoteStudentTestId: any;
  patronymic: any;
  testPeriodForStatementsDtoList: any = [];
  statementId: any;
  studentTestStatusTypeId: any;
  testFinishedAt: any;
  studentTestStatusTypeName: any;
  testLangId: any;
  time: string = '00:00';
  notice!: string;
  testLangName: any;
  testStartedAt: any;
  testTypeId: any;
  testTimeLeftInMinutes: any;
  auditNum: any;
  createdAt: any;
  testPeriodList: any;
  firstname: any;
  resetSessionList: any;
  statId: any;
  num!: number;
  namesIds: any;
  allUsersList: any;
  dropdownList: any;
  actionTypeIds: any = [];
  studentTest: any;
  zaprosInc = Array<any>();
  studentTestId: any = [];
  checkedCount = 0;
  currentPageArray: any = [];
  checkeds: any = [];
  allInc: any;
  checked!: boolean;
  actionTypeId: any;
  student: any;
  id: any;
  actionType: any = [];
  statusForIBNCT: any;
  role: any;
  userRole: any;
  document: any;
  statusListForUsers: any;
  statementStatusId: any;
  testPeriodId: any;
  testPeriod: any = [];
  checkId = false;
  statementsTime: any;
  timeForTestMask: string = '000';
  addTime: any;
  addAppealTime: any;
  disabled = false;
  fileIdToDelete: any;
  checkApel: any;
  isChecked = false;
  childActionId: any;
  parentActionId: any
  parentList = Array<any>();
  ActionTreeList: any;
  checkedAll = true;
  studentInnList: any;
  searchTerm = '';
  showModal = false;
  sendStatement = false;
  searchText: string = '';
  iins: any;
  statFiles:any[] =  [];
  items: any;

  ngOnInit(): void {


    if (localStorage.getItem('userRole') == 'ROLE_ADMIN') {
      this.role = 'admin';
    }

    if (localStorage.getItem('userRole') == 'ROLE_OPERATOR_NCT') {
      this.role = 'operatorNct';

    }

    if (localStorage.getItem('userRole') == 'ROLE_OPERATOR') {
      this.role = 'operator';
    }

    if (localStorage.getItem('userRole') == 'ROLE_IB') {
      this.role = 'IB';
    }

    this.statId = this.route.snapshot.queryParamMap.get('statementId');

    this.form = new FormGroup({
      resetSessionTypeId: new FormControl(),
      studentTestId: new FormControl(),
      num: new FormControl(),
      childActionId: new FormControl(),
      parentActionId: new FormControl(),
      actionTypeIds: new FormArray([]),
      statementId: new FormControl(),
      addTime: new FormControl(),
      additionalFiles: new FormArray([]),
      testDate: new FormControl(),
      actionTypeId: new FormControl(),
      statId: new FormControl(),
      checked: new FormControl(),
      checkedAll: new FormControl(false),
      id: new FormControl(),
      sendStatement: new FormControl(false),
      statementStatusId: new FormControl(),
      notice: new FormControl(),
      testPeriodId: new FormControl(),
      statementsTime: new FormControl(),
      iins: new FormControl(),
      addAppealTime: new FormControl(),
    });

    this.addForm = new FormGroup({
      iin: new FormControl(),
      testPeriodId: new FormControl(),
    });

    this.changeStatusStatement();

  }

  get actionsArray(): any { return this.form.get('actionTypeIds') as FormArray }

  changeStatusStatement() {
    this.main.changeStatusStatement(this.statId)
      .subscribe((res: any) => {
          this.allInc = res;
          this.form.get('id')!.setValue(+this.statId);
          this.form.get('resetSessionTypeId')!.setValue(this.allInc.resetSessionTypeId);
          this.form.get('testPeriodId')?.setValue(this.allInc.testPeriod.id);
          this.form.get('statementId')!.setValue(this.allInc.statementId);
          this.form.get('actionTypeId')!.setValue(this.allInc.actionTypeId);
          this.form.get('notice')!.setValue(this.allInc.notice);
          this.form.get('statementsTime')!.setValue(this.allInc.statementsTime);
          this.form.get('addAppealTime')!.setValue(this.allInc.addAppealTime);
          this.form.get('addTime')!.setValue(this.allInc.addTime);
          this.form.get('childActionId')!.setValue(this.allInc.childActionId);
          this.form.get('parentActionId')!.setValue(this.allInc.parentActionId);
          this.form.get('resetSessionTypeId')!.setValue(this.allInc.resetSessionTypeId.id);
          this.addTime = this.allInc.addTime;
          this.addAppealTime = this.allInc.addAppealTime
          this.actionType = this.allInc.actionType;
          this.studentTest = this.allInc.studentTest;
          this.childActionId = this.allInc.childActionId;
          this.parentActionId = this.allInc.parentActionId;
          this.checked = this.actionType.checked;
          this.testPeriod = this.allInc.testPeriod;

          this.findAllResetSessionType();
          this.findAllStatementsActionTree();
          this.statementAttachedFiles();

          this.parentList = res.actionType;
          this.actionTypeIds = res.actionType;

          this.studentTest.forEach((element: any) => {
            element.isChecked = true;
          });

          res.actionType.forEach((element: any) => {
            this.actionsArray.push(this.actionSaveUser(
              element.checked,
              element.childActionId,
              element.childActionNameKaz,
              element.childActionNameRus,
              element.parentActionId,
              element.parentActionNameKaz,
              element.parentActionNameRus,
            ));
          });

          this.parentList.forEach((element: any) => {
            if (element.checked == true && element.childActionId == 3) {
              this.checkId = true;
            }
            if (element.checked == true && element.childActionId == 8) {
              this.checkApel = true;
            }
          });

          this.parentList.forEach((element: any) => {
            if (element.checked == true && element.childActionId == 3) {
              this.checkId = true;
            }
            if (element.checked == true && element.childActionId == 8) {
              this.checkApel = true;
            }
          });
        },
        (err: any) => {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
            this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
        });
  }

  findAllStatementsActionTree() {
    this.main.findAllStatementsActionTree()
      .subscribe((res: any) => {
        this.ActionTreeList = [];
        this.ActionTreeList = res;
      }, (err: any) => {
      });
  }

  findChildActionByParentId(parentActionId: any) {
    this.actionsArray.controls = [];
    this.main.findChildActionByParentId(parentActionId)
      .subscribe((res: any) => {
        this.parentList = [];
        this.parentList = res;
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
          this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
      });
  }

  actionSaveUser(checked = false, childActionId: any, childActionNameKaz: any, childActionNameRus: any, parentActionId: any, parentActionNameRus: any, parentActionNameKaz: any,): FormGroup {
    return new FormGroup({
      checked: new FormControl(checked),
      childActionId: new FormControl(childActionId),
      childActionNameKaz: new FormControl(childActionNameKaz),
      childActionNameRus: new FormControl(childActionNameRus),
      parentActionId: new FormControl(parentActionId),
      parentActionNameRus: new FormControl(parentActionNameRus),
      parentActionNameKaz: new FormControl(parentActionNameKaz),

    })
  }

  childcheckchange(event: any, act: any) {
    this.parentList.forEach(obj => {
      if (obj.childActionId == act.childActionId) {
        obj.checked = event.target.checked;
      }
    });
  }

  findAllResetSessionType() {
    this.main.findAllResetSessionType()
      .subscribe((res: any) => {
        this.resetSessionList = [];
        this.resetSessionList = res;
      }, (err: any) => {
      });
  }

  operatorSendStatement(sendStatement: boolean) {
    this.form.get('addTime')?.setValue('');
    this.form.get('addAppealTime')?.setValue('');
    let child = [];
    let studentTestList: any = [];

    if (this.parentList.length == 0) {
      child =[
        {
          parentActionId: 1,
          childActionId: 1,
          checked: true
        }
      ]
    }else{
      child = this.parentList;
    }
    this.studentTest.forEach((element: any) => {
      if(element.isChecked != false){
        studentTestList.push({
          studentTestId: element.studentTestId,
          checked: element.isChecked
        })
      }
    })

    let obj = {
      studentTestList: studentTestList,
      actionTypeIds: child,
      sendStatement: this.sendStatement = true,
      statementId: this.form.get('statementId')?.value,
      testPeriodId: this.form.get('testPeriodId')?.value,
      statementsTime: this.form.get('statementsTime')?.value,
      resetSessionTypeId: this.allInc.resetSessionTypeId.id,
      addTime: this.form.get('addTime')?.value,
      addAppealTime: this.form.get('addAppealTime')?.value,
      notice: this.form.get('notice')?.value,
    };

    this.form.get('actionTypeIds')?.patchValue(this.parentList);

    let checkedStudent: any = [];
    studentTestList.forEach((element: any) => {
      if (element.studentTestId != null && typeof element.studentTestId !== "undefined" && element.isChecked == true) {
        checkedStudent.push({
            studentTestId: element.studentTestId,
            checked: element.isChecked == true
          }
        )
      }
      if (element.isChecked == false) {
        studentTestList.splice(element.studentTestId)
      }
    });
    if (this.checkId == true) {
      this.form.get('addTime')!.setValue(this.addTime);
    } else {
      this.form.get('addTime')!.setValue("");
    }
    if (this.checkApel == true) {
      this.form.get('addAppealTime')!.setValue(this.addAppealTime);
    } else {
      this.form.get('addAppealTime')!.setValue("");
    }
    this.form.get('studentTestId')!.setValue(checkedStudent);
    this.main.operatorSendStatement(sendStatement, obj)
      .subscribe((res: any) => {
        this.router.navigate(['/main/incident']);
        this.translate.currentLang == 'ru' ? this._notifications.create('Успешно', 'заявка создана', NotificationType.Success, 5000) :
          this._notifications.create('Сәтті', 'пайдаланушы бұғатталған', NotificationType.Success);
      }, (err: any) => {

        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
          this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
      });
  }

  operatorSendStatementFalse(sendStatement: boolean) {
    // this.form.get('addTime')!.setValue(this.addTime);
    this.form.get('addAppealTime')!.setValue(this.addAppealTime);
    let child = [];
    let studentTestList: any = [];

    if (this.parentList.length == 0) {
      child =[
        {
          parentActionId: 1,
          childActionId: 1,
          checked: true
        }
      ]
    }else{
      child = this.parentList;
    }
    this.studentTest.forEach((element: any) => {
      if(element.isChecked != false){
        studentTestList.push({
          studentTestId: element.studentTestId,
          checked: element.isChecked
        })
      }
    })

    let obj = {
      studentTestList: studentTestList,
      actionTypeIds: child,
      sendStatement: this.sendStatement = false,
      statementId: this.form.get('statementId')?.value,
      testPeriodId: this.form.get('testPeriodId')?.value,
      statementsTime: this.form.get('statementsTime')?.value,
      resetSessionTypeId: this.allInc.resetSessionTypeId.id,
      addTime: this.form.get('addTime')?.value,
      addAppealTime: this.form.get('addAppealTime')?.value,
      notice: this.form.get('notice')?.value,
    };

    this.form.get('actionTypeIds')?.patchValue(this.parentList);

    let checkedStudent: any = [];
    studentTestList.forEach((element: any) => {
      if (element.studentTestId != null && typeof element.studentTestId !== "undefined" && element.isChecked == true) {
        checkedStudent.push({
            studentTestId: element.studentTestId,
            checked: element.isChecked == true
          }
        )
      }
      if (element.isChecked == false) {
        studentTestList.splice(element.studentTestId)
      }
    });

    // if(this.form.get('parentActionId')!.value == 4) {
    //   this.form.get('addTime')!.setValue(this.addTime);
    // }else{
    //   this.form.get('addTime')!.setValue("");
    // }

    // if(this.checkApel == true && this.form.get('parentActionId')!.value == 9) {
    //   this.form.get('addAppealTime')!.setValue(this.addAppealTime);
    // }else{
    //   this.form.get('addAppealTime')!.setValue("");
    // }

    this.form.get('studentTestId')!.setValue(checkedStudent);
    this.main.operatorSendStatement(sendStatement, obj)
      .subscribe((res: any) => {
        this.router.navigate(['/main/incident']);
        this.translate.currentLang == 'ru' ? this._notifications.create('Успешно', 'заявка создана', NotificationType.Success, 5000) :
          this._notifications.create('Сәтті', 'пайдаланушы бұғатталған', NotificationType.Success);
      }, (err: any) => {

        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
          this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
      });
  }

  checkAllOptions() {
    if (!this.checkedAll) {
      this.studentTest.forEach((element: any) => {
        element.isChecked = true;
        if (this.checkedAll = true) {
          this.checkedCount = 0;
        }
      });
      this.checkedAll = true;
    } else {
      this.studentTest.forEach((element: any) => {
        element.isChecked = false;
        this.studentTestId = [];
      });
      this.checkedAll = false;
    }
  }

  checkStudent(event: any, student: any) {
    this.checkedCount = 0;
    if (event.target.checked == true) {
      for (let i = 0; i < this.studentTest.length; i++) {
        if (this.studentTest[i] === student) {
          this.studentTest[i].isChecked = true;
          this.studentTestId.push({
            studentTestId: this.studentTest[i],
            checked: this.studentTest[i].isChecked = true
          });
        }
      }
    } else {
      for (let i = 0; i < this.studentTest.length; i++) {
        if (this.studentTest[i] === student) {
          this.studentTest[i].isChecked = false;
          this.studentTestId.splice(i, this.studentTest[i])
        }
      }
    }
    this.studentTest.forEach((element: any) => {
      if (element.isChecked) {
        this.checkedCount++;
      }
    });
    this.studentTest.forEach((element: any) => {
      if (element.isChecked == false) {
        this.checkedAll = false;
      }
    });
  }

  poiskIin() {
    this.loading = true;
    if(!!this.testPeriodId && !!this.addForm.get('iin')!.value){
      let iin = this.addForm.get('iin')!.value;
      this.main.getStudentListFromOnlineTestIIN(this.testPeriodId, iin)
        .subscribe((res: any) => {
          this.loading = false;
          this.studentInnList = [];
          this.studentInnList = res.items;
        }, (err: any) => {
          this.loading = false;
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
            this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
        });
    }
    if(!!this.testPeriodId && this.addForm.get('iin')!.value == ''){
      this.main.getStudentListOnlyNum(this.testPeriodId)
        .subscribe((res: any) => {
          this.loading = false;
          this.studentInnList = [];
          this.studentInnList = res.items;
        }, (err: any) => {
          this.loading = false;
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
            this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
        });
    }

  }

  peresadkaWindow() {
    this.testPeriodId;
    this.findAllTestPeriodByTestOrgId()
    this.showModal = true;
    this.addForm.get('iin')!.setValue("");
    this.testPeriodList = [];
    this.studentInnList = [];
  }

  closePeresad(asd: any){
    this.showModal = false
    this.addForm.get('iin')!.setValue("");
    this.testPeriodList = [];
    this.studentInnList = [];
  }

  findAllTestPeriodByTestOrgId() {
    let orgId;
    orgId = this.testOrgName?.slice(0, 3)
    orgId = Number(orgId);
    this.main.findAllTestPeriodByTestOrgId(orgId)
      .subscribe((res: any) => {
        this.testPeriodList = [];
        this.testPeriodId = this.allInc.testPeriod.id;
        res[0].testPeriodForStatementsDtoList.forEach((element: any, i: any) => {
          this.testPeriodList[i] = {
            name: "" + element.testOrgId + ' - ' + element.testDate + ' - ' + element.startTestTime,
            id: element.id
          }
        });
        this.poiskIin();

      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
          this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
      });
  }

  addTest(students: any) {
    let fio = students.lastname + '  ' + students.firstname + '  ' + students.patronymic;
    let obj = {
      studentTestId:students.remoteStudentTestId,
      iin: students.iin,
      student: fio,
      isChecked: true,
      audit: students.auditNum,
      placeNumber: students.placeNum,
    }
    this.studentTest.push(obj);
    this.studentTest.filter((elem: any)=> {

    })
    this.translate.currentLang == 'ru' ? this._notifications.create('Успешно', 'Тестируемый добавлен', NotificationType.Success, 5000) :
      this._notifications.create('Сәтті', 'пайдаланушы бұғатталған', NotificationType.Success);
  }

  returnFunc(act: any) {
    this.parentList.forEach((element: any) => {
      if (this.form.get('parentActionId')!.value === 4) {
        if (this.form.get('parentActionId')!.value === 4) {
          this.addTime = "";
        }
        if (this.form.get('parentActionId')!.value === 4) {
          this.checkId = true
        }
      }
      if (act.childActionId == 8 && this.form.get('parentActionId')!.value === 9) {
        if (element.checked == true && this.form.get('parentActionId')!.value === 9) {
          this.checkApel = false;
        }
        if (element.checked == false && this.form.get('parentActionId')!.value === 9) {
          this.checkApel = true
        }
      }
    });
  }

  deleteStatement() {
    this.main.deleteStatement(this.allInc.statementId)
      .subscribe((res: any) => {
        this.router.navigate(['/main/incident']);
        this.translate.currentLang == 'ru' ? this._notifications.create('Успешно', res.message, NotificationType.Success, 5000) :
          this._notifications.create('Сәтті', res.message, NotificationType.Success, 5000);
      }, (err: any) => {
      });
  }

  statementAttachedFiles(){
    this.main.statementAttachedFiles(this.allInc.statementId)
      .subscribe((res: any) => {
        this.statFiles = res;
        this.addFiles();
      })
  }

  get files() : any { return this.form.get('additionalFiles')};

  addFiles(){
    let obj = null;

    this.statFiles.forEach((element: any) => {
      obj = this.files;
      obj.push(
        new FormGroup({
          filename: new FormControl(element.filename, [Validators.required]),
          id: new FormControl(element.id),
          ord: new FormControl(element.ord),
          data: new FormControl(element.data)
        })
      );
    });
  }

  downloadFile(fileId: any, name: any, index:number){
    this.main.statementAttachedFiles(fileId)
      .subscribe((res: any) => {
        const byteArray = new Uint8Array(
          atob(res[index].data).split('').map((char:any)=>char.charCodeAt(0))
        );
        const blob = new Blob([byteArray], {type: 'application/octet-stream'});
        const data = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = data;
        link.download = name;
        link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
        setTimeout(function() {
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      }, (err: any) => {
      });
  }

  addFile() {
    let obj = null;
    obj = this.files;
    obj.push(
      new FormGroup({
        filename: new FormControl('', [Validators.required]),
        id: new FormControl(0),
        ord: new FormControl(0),
        data: new FormControl()
      })
    );
  }

  changeListener($event: any, i: number) : void {
    let obj: any = null;

    obj = this.files;

    const file: File = $event.target.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      obj.controls[i].get('filename').setValue(file.name);
      let imgData: any = myReader.result;

      obj.controls[i].get('data').setValue(imgData.toString().substring(imgData.toString().indexOf(',') + 1));
    }
    myReader.readAsDataURL(file);
  }

  dwnldCheck(id: number): boolean {
    return this.files.controls[id].get('filename').value !== '';
  }

  removeFile(){
    this.translate.currentLang == 'ru' ? this._notifications.create('Файл', 'успешно удален', NotificationType.Success, 5000) : this._notifications.create('Файл', 'сәтті жойылды', NotificationType.Success, 5000);
    let obj = null;

    obj = this.files;

    obj.removeAt(this.fileIdToDelete);
  }

}

// dwnldCheckExam(id: number): boolean {
//   return this.filesExam.controls[id].get('filename').value !== '';
// }

// removeFileExam(){
//   this.translate.currentLang == 'ru' ? this._notifications.create('Файл', 'успешно удален', NotificationType.Success, 5000) : this._notifications.create('Файл', 'сәтті жойылды', NotificationType.Success, 5000);
//   let obj = null;

//   obj = this.filesExam;

//   obj.removeAt(this.fileIdToDelete);
// }
// changeListenerExam($event: any, i: number) : void {
//   let obj: any = null;

//   obj = this.filesExam;

//   const file: File = $event.target.filesExam[0];
//   const myReader: FileReader = new FileReader();

//   myReader.onloadend = (e) => {
//     obj.controls[i].get('filename').setValue(file.name);
//     let imgData: any = myReader.result;

//     obj.controls[i].get('data').setValue(imgData.toString().substring(imgData.toString().indexOf(',') + 1));
//   }
//   myReader.readAsDataURL(file);
// }

// addFile() {
//   let obj = null;

//   obj = this.filesExam;

//   obj.push(
//     new FormGroup({
//       filename: new FormControl('', [Validators.required]),
//       id: new FormControl(0),
//       data: new FormControl()
//     })
//   );
// }

//  anotherExamSertificate(fileId: any, name: any){
//   this.main.statementAttachedFiles(fileId)
//   .subscribe((res: any) => {
//     const blob = new Blob([res], {type: 'application/octet-stream'});
//       const data = window.URL.createObjectURL(blob);
//       const link = document.createElement('a');
//       link.href = data;
//       link.download = name;
//       link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
//       setTimeout(function() {
//         window.URL.revokeObjectURL(data);
//         link.remove();
//       }, 100);
//   }, (err: any) => {
// });
// }
