<!-- <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading> -->
<div class="flex my-5">
    <div class="w-1/2 h-1 bg-blue-300 mt-4"></div>
    <div class="w-3/5 text-center text-3xl font-hairline uppercase tracking-widest">
       Инциденты
    </div>
    <div class="w-1/2 h-1 bg-blue-300 mt-4"></div>
    </div>
     <form [formGroup]="form"> 
      <div style="width: 1600px; height: 700px;" class="text-gray-900 bg-gray-200">
         <div class="">
        <div *ngIf="userRole == 'operator'" class="ml-3"> <b> {{testOrgName}} </b> </div>
        <div *ngIf="userRole == 'operator'" class="flex mt-5" style="width: 500px;">
         <p class="ml-3" style="width: 195px;">
           Дата тестирования
         </p>
         <select class="rounded-lg ml-2" [value]="0" formControlName="num">
           <option [value]="0" disabled selected>
             Дата тестирования
           </option>
           <option [value]="item.id" *ngFor="let item of testPeriodList">
             {{ item.name }}
           </option>

         </select>
       </div>
         </div>
         <div class="">

         </div>
      </div>
    </form> 