<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<form [formGroup]="formResetSession">
  <div (click)="showStatusReset = !showStatusReset" *ngIf="showStatusReset" class="w-full h-screen bg-black top-0 left-0 z-20 bg-opacity-25 fixed"></div>
  <div *ngIf="showStatusReset" class="text-center border-2 p-4 top-0 h-50 left-0 z-20 realy-delete fixed z-30 w-1/3 bg-white">
        <div class="text-3xl"> {{ 'ChangingTheStatus' | translate }}</div>
        <div class="my-2">
         {{ 'AreYouSureYouWantToResetTheSession' | translate }}
        </div>

      <div class="flex">
        <div class="w-1/2">
          <button (click)="getResetSession()" class="w-56 my-10 rounded-lg btn text-center btn-outline-primary text-white btn-lg btn-block bg-red-400 p-1">
            {{ 'Confirm' | translate }}
          </button>
        </div>

        <div class="w-1/2">
            <button (click)="showStatusReset = !showStatusReset" class="w-56 my-10 rounded-lg btn bg-blue-400 text-center text-white btn-outline-primary btn-lg btn-block p-1">
              {{ 'Cancellation' | translate }}
            </button>
        </div>
      </div>
  </div>
</form>

<form [formGroup]="formStatus" action="">
  <div (click)="showStatus = !showStatus" *ngIf="showStatus"
    class="w-full h-screen bg-black top-0 left-0 z-20 bg-opacity-25 fixed"></div>
  <div *ngIf="showStatus" class="border-2 p-4 top-0 h-auto left-0 z-20 classA fixed z-30 w-1/2 bg-white">
    <div class="text-3xl"> {{ 'ChangingTheStatusOfTheTestTaker' | translate }} </div>

    <div class="my-2 flex text-left">
      <label> {{ 'Status' | translate }} </label>
      <select (change)="findAnnulmentType(studentsTestId)" formControlName="studentTestStatusTypeId"
        class="rounded-lg bg-gray-300 mr-20 flex-grow w-full px-3 lg:text-xs lg:text-base ml-3 text-white-500 font-thin">
        <option [value]="0" disabled selected> {{ 'SelectTestTakerStatus' | translate }} </option>
        <option *ngFor="let item of statusList" [disabled]="formStatus.get('studentTestStatusTypeId')!.value == 7 && item.id == 7" [value]="item.id">
          <span *ngIf="translate.currentLang == 'kz'"> {{ item.nameKaz }} </span>
          <span *ngIf="translate.currentLang == 'ru'"> {{ item.nameRus }} </span>
        </option>
      </select>
    </div>

    <div *ngIf="this.formStatus.get('studentTestStatusTypeId')!.value == 14 || this.formStatus.get('studentTestStatusTypeId')!.value == 13 || this.formStatus.get('studentTestStatusTypeId')!.value == 2 || this.formStatus.get('studentTestStatusTypeId')!.value == 1" class="classAA scroll-table-body">
      <div class="flex">
        <div class="">

          <div class="my-10 w-full">
            <label class="checkLableClass"> {{ 'SelectReasonCancellation' | translate }} </label>
              <div class="divClass">
                  <div>
                     <div formArrayName="annulmentTypeIds" *ngFor="let annul of annulmentTypeIdList; index as i" class="">
                       <div [formGroup]="allAnnuStatusArray.controls[i]">
                        <input (change)="coasnd()" type="checkbox" formControlName="checked" [value]="annul.checked" class="mt-1 w-4 boxxxClass h-4 bg-gray-300 rounded-lg border-2 ml-4">
                        <span class="" *ngIf="translate.currentLang == 'kz'"> - {{ annul.nameKaz }} </span>
                        <span class="" *ngIf="translate.currentLang == 'ru'"> - {{ annul.nameRus }} </span>
                       </div>
                  </div>
                </div>
            </div>
          </div>
          <div class="flex">
            <label> {{ 'CauseDescription' | translate }} </label>
            <textarea formControlName="notes" class="bg-gray-200 ml-3 w-96 h-48"></textarea>
          </div>
          <div class="flex">
          <div class="w-1/2">
            <button (click)="updateStudentStatus(showStatus=false)"
              class="w-56 my-10 rounded-lg btn text-center btn-outline-primary text-white btn-lg btn-block bg-red-400 p-1">
              {{ 'Change' | translate }}
            </button>
          </div>

          <div class="w-1/2">
            <button (click)="showStatus = !showStatus"
              class="w-56 my-10 rounded-lg btn bg-blue-400 text-center text-white btn-outline-primary btn-lg btn-block p-1">
              {{ 'Cancellation' | translate }}
            </button>
          </div>
        </div>
        </div>
      </div>
    </div>

    <div *ngIf="this.formStatus.get('studentTestStatusTypeId')!.value != 13 || this.formStatus.get('studentTestStatusTypeId')!.value != 2 || this.formStatus.get('studentTestStatusTypeId')!.value != 1" class="mt-32 flex">

      <div class="w-1/2">
        <button (click)="updateStudentStatus(showStatus=false)"
          class="w-56 my-10 rounded-lg btn text-center btn-outline-primary text-white btn-lg btn-block bg-red-400 p-1">
          {{ 'Change' | translate }}
        </button>
      </div>

      <div class="w-1/2">
        <button (click)="showStatus = !showStatus"
          class="w-56 my-10 rounded-lg btn bg-blue-400 text-center text-white btn-outline-primary btn-lg btn-block p-1">
          {{ 'Cancellation' | translate }}
        </button>
      </div>
    </div>
  </div>
</form>

<div class="flex head-of-table my-5">
  <div class="w-1/2 noneDisplay h-1 bg-blue-300 mt-4"></div>
  <div class="w-3/5 text-of-head text-center text-3xl font-hairline uppercase tracking-widest">
    {{ 'AccompanyingTestTakers' | translate }}
  </div>
  <div class="w-1/2 noneDisplay h-1 bg-blue-300 mt-4"></div>
  </div>
<div (click)="showModal = !showModal" *ngIf="showModal" class="w-full h-screen bg-black top-0 left-0 z-20 bg-opacity-25 fixed"></div>
<div *ngIf="showModal" class="text-center border-2 p-4 top-0 h-80 left-0 classA z-20 fixed z-30 w-1/3 bg-white">
  <div class="" *ngFor="let student of studentList">
    <div *ngIf="student.isChecked == true" class="">
      <div class="mb-2 text-3xl"> {{ 'TestSubjectsData' | translate }} </div>
      <div class="text-xl text-gray-900">
        <label class="mx-5">
         {{ student.lastName }}  {{ student.firstName }}  {{ student.patronymic }}
        </label>
    </div>

        <div class="text-xl text-gray-900">
            <label class="mx-5"  > {{ 'Login' | translate }}
               {{ login }}
           </label>
        </div>

        <div class="text-xl text-gray-900">
          <label> {{ 'Password' | translate }}
             {{ password }}
          </label>
        </div>

        <div class="text-xl text-gray-900">
          <label> {{ 'Phone' | translate }}
             {{ student.phoneNumber }}
          </label>
        </div>

      <div>
          <button (click)="closeLoginModal(student.studentTestId)" class= "w-56 my-10 rounded-lg btn text-center text-white btn-outline-primary btn-lg btn-block bg-blue-300 p-1">
              {{ 'Close' | translate }}
          </button>
      </div>
    </div>
  </div>
</div>

<div class="flex">
  <form [formGroup]="form">


    <div (click)="showPer = !showPer" *ngIf="showPer"
         class="w-full h-screen bg-black top-0 left-0 z-20 bg-opacity-25 fixed"></div>
    <div *ngIf="showPer" class="text-center border-2 p-4 top-0 h-80 left-0 classA z-20 fixed z-30 w-1/3 bg-white">
      <div class="">
        <div class="">
          <div class="mb-2 text-3xl"> {{ 'TransferToAnother' | translate }} </div>
          <div class="text-xl text-gray-900">
            <div class="text-indigo-600 mx-3 rounded-full cursor-pointer hover:text-indigo-900">
              <select [ngModelOptions]="{standalone: true}" [(ngModel)]="appTypeusId" class="mt-2 w-40">
                <option disabled selected [value]="0">
                  {{ 'ChooseTheTypeTesting' | translate }}
                </option>
                <option [value]="items.id" *ngFor="let items of activeList">
                  <span *ngIf="translate.currentLang == 'kz'">{{ items.name.kk }} </span>
                  <span *ngIf="translate.currentLang == 'ru'">{{ items.name.ru }} </span>
                </option>
              </select>
              <div>
                <button [routerLink]="['/main/' + appTypeusId]" [queryParams]="{studentTestId: studentTestId}"> {{
                    'TransferToAnother' | translate }} </button>
              </div>
            </div>
          </div>

          <div class="">
            <button (click)="showPer = !showPer"
                    class="w-56 my-10 rounded-lg btn text-center text-white btn-outline-primary btn-lg btn-block bg-blue-300 p-1">
              {{ 'Close' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>


      <div class="text-gray-900 bg-gray-200 main-table-form">
       <div class="flex">
        <div>
          <fieldset form="form">
            <div class="flex p-3">
                <input id="iins" value="1" formControlName="searchType" [value]="1" class="mt-1 w-5" type="radio">
                <label for="iins" class="ml-2 cursor-pointer w-auto"> {{ 'SearchByIIN' | translate }} </label>
                <input *ngIf="this.form.get('searchType')?.value == 1" formControlName="iin" class="rounded-lg bg-white ml-10" type="text" placeholder="ИИН">
                <!-- [mask]="iinMask" -->
                <div class="ml-10"></div>
                <div class="ml-10"></div>
            </div>
            <div class="flex p-3">
                <input id="fios" value="2" formControlName="searchType" class="mt-1 w-5" type="radio">
                <label for="fios" class="ml-2 cursor-pointer w-auto"> {{ 'SearchByFIO' | translate }} </label>
                <div *ngIf="this.form.get('searchType')?.value == 2">
                <input formControlName="lastName" class="rounded-lg bg-white ml-10" placeholder=" {{ 'lastName' | translate }} " type="text">
                <input formControlName="firstName" class="rounded-lg bg-white ml-10" placeholder=" {{ 'firstName' | translate }} " type="text">
                <input formControlName="patronymic" class="rounded-lg bg-white mt ml-10" placeholder=" {{ 'patronymic' | translate }} " type="text">
              </div>
            </div>
          </fieldset>
           <div class="flex">
              <button (click)="findStudent()" type="button" *ngIf="form.get('searchType')!.value != 0"
              class="cursour-pointer w-40 bg-gray-300 hover:bg-gray-300 rounded-lg lg:px-3 py-1 ml-2 text-grey-dark text-sm">
                {{ 'Search' | translate }}
              </button>
           </div>
        </div>
       </div>

          <pagination-controls *ngIf="studentList.length> 0" previousLabel="Назад" nextLabel="Вперед" (autoHide)="true" class="text-center mt-4"
            (pageChange)="checkPage($event)"></pagination-controls>
          <table *ngIf="informWindow == true" class="min-w-full">
            <thead>
                        <tr>

                             <th class="thClass">
                             </th>

                             <th scope="col" class="px-4 py-3 iinClass text-xs font-medium text-gray-500 uppercase">
                                {{ 'IIN' | translate }}
                             </th>

                             <th scope="col" class="px-4 py-3 fioClass text-xs font-medium text-gray-500 uppercase">
                                {{ 'FIO' | translate }}
                             </th>

                             <th scope="col" class="px-4 py-3 statusClass text-xs font-medium text-gray-500 uppercase">
                                {{ 'StudentStatus' | translate }}
                             </th>

                             <th scope="col" class="px-4 py-3 text-xs text-left font-medium text-gray-500 uppercase">
                                {{ 'Actions' | translate }}
                             </th>

                        </tr>
            </thead>
          </table>

      <div class="px-3 py-4 tableClass scroll-table-body flex">
          <table class="min-w-full">
              <tbody class="bg-white border-2.border-gray-200">
                <tr *ngFor="let student of studentList | paginate: {itemsPerPage: 50, currentPage: p, totalItems: this.numberOfPages}; index as i"
                class="border-2 border-gray-200 cursor-pointer hover:bg-gray-200"
                [ngClass]="{'bg-gray-300': hoverOnStudent == student.studentTestId}" (click)="oknoData(student)">

                    <td class="px-2 py-2 text-center align-middle">
                      <div class="text-sm text-gray-900">
                    </div>
                    </td>

                    <td class="px-4 py-4 text-center align-middle">
                      <div class="text-sm text-gray-900">
                        {{ student.iin }}
                    </div>
                    </td>

                    <td class="px-4 py-4 fioTdClass text-left w-96 align-middle">
                        <div class="text-sm text-gray-900">
                         {{ student.lastName }}  {{ student.firstName }}   {{ student.patronymic }}
                        </div>
                    </td>

                    <td class="px-4 py-4 statusTdClass text-left align-middle">
                         <div class="text-sm text-gray-900 w-52">
                            <span *ngIf="translate.currentLang == 'kz'"> {{ student.testStatus.nameKaz }} </span>
                            <span *ngIf="translate.currentLang == 'ru'"> {{ student.testStatus.nameRus }} </span>
                         </div>
                    </td>
                    <td class="py-4 text-center text-sm font-medium">
                      <div (click)="showModalPeresad(student.studentTestId, 1)" class="text-indigo-600 mx-3 rounded-full cursor-pointer hover:text-indigo-900">
                        Пересадка
                    </div>
                      <div (click)="showModalPerehod(student.studentTestId)"
                           class="text-indigo-600 mx-3 rounded-full  cursor-pointer hover:text-indigo-900">
                        {{ 'TransferToAnother' | translate }}
                      </div>
                      <div *ngIf="student.jobTypeEntity != null" [routerLink]="['/main/' + student.jobTypeEntity.id]" [queryParams]="{ studentTestId: student.studentTestId }"
                      class="text-indigo-600 mx-3 rounded-full cursor-pointer hover:text-indigo-900">
                          {{ 'Edit' | translate }}
                      </div>
                      <div (click)="showModalWind(student.studentTestId)"
                      class="text-indigo-600 mx-3 rounded-full cursor-pointer hover:text-indigo-900">
                          {{ 'UsernamePasswordOfTheTestedPerson' | translate }}
                      </div>
                       <div (click)="showModalStatus(student.testStatus.id, student.studentTestId)"
                       class="text-indigo-600 mx-3 rounded-full cursor-pointer hover:text-indigo-900">
                          {{ 'ChangeStatus' | translate }}
                      </div>
                      <div (click)="getShowStatusReset(student.studentTestId)" class="text-indigo-600 mx-3 rounded-full cursor-pointer hover:text-indigo-900">
                          {{ 'ResetTheSession' | translate }}
                      </div>

                    </td>

                </tr>
              </tbody>
            </table>
         </div>
      </div>

      <div class="">
        <div (click)="showPeresadka = !showPeresadka" *ngIf="showPeresadka" class="w-full h-screen bg-black top-0 left-0 z-20 bg-opacity-25 fixed"></div>
        <div *ngIf="showPeresadka" class="text-center border-2 p-4 top-0 h-80 left-0 z-20 classA fixed z-30 w-1/2 bg-white">
          <div class="text-3xl"> {{ 'Transfer' | translate }} </div>
          <div class="text-left">

            <div class="my-2 flex">
              <label class="lableClass"> Пункт </label>
              <select (change)="getAllTestPeriodByTestVptIds(0)" [(ngModel)]="testVptId" [ngModelOptions]="{standalone: true}"
                class="rounded-lg border-2 mr-20 flex-grow w-full px-3 lg:text-xs lg:text-base text-gray-500 font-thin">
                <option [value]="0" disabled selected> {{ 'PPent:' | translate }} </option>
                <option *ngFor="let item of vptLists" [value]="item.vptId">
                  <span *ngIf="translate.currentLang == 'kz'"> {{ item.codeId }} - {{ item.nameKaz }} </span>
                  <span *ngIf="translate.currentLang == 'ru'"> {{ item.codeId }} - {{ item.nameRus }} </span>
                </option>
              </select>
            </div>

            <div class="my-2 flex">
              <label class="lableClass"> {{ 'Stream' | translate }} </label>
              <select (change)="roomNumbers(0)" [(ngModel)]="testPeriodId" [ngModelOptions]="{standalone: true}"
                class="rounded-lg border-2 mr-20 flex-grow w-full px-3 lg:text-xs lg:text-base text-gray-500 font-thin">
                <option [value]="0" disabled selected> {{ 'Stream' | translate }} </option>
                <option *ngFor="let item of testVptLists" [value]="item.id">
                  {{ item.testDate }} - {{ item.startTestTime }} - {{ item.enterTestTime }} - свободные места на потоке {{ item.freePlaceCount }}
                </option>
              </select>
            </div>

            <div class="my-2 flex">
              <label class="lableClass"> Аудитория </label>
                 <select (change)="placeNumber(0)" [(ngModel)]="auditId" [ngModelOptions]="{standalone: true}"
                 class="rounded-lg border-2 mr-20 flex-grow w-full px-3 lg:text-xs lg:text-base text-gray-500 font-thin">
                <option [value]="0" disabled selected> Аудитория </option>
                <option *ngFor="let item of auditPlaceList" [value]="item.id">
                  Номер аудитории {{ item.roomNumber }} - Свободные места {{ item.roomFreePlace }}
                </option>
              </select>
            </div>

            <div class="my-2 flex">
              <label class="lableClass"> Место </label>
              <select [(ngModel)]="roomPlaceNumber" [ngModelOptions]="{standalone: true}"
                class="rounded-lg border-2 mr-20 flex-grow w-full px-3 lg:text-xs lg:text-base text-gray-500 font-thin">
                <option [value]="0" disabled selected> Место </option>
                <option [value]="actualumber" disabled selected> Место на котором сейчас сидит тестируемый - {{ transferList.placeNum }}
                </option>
                <option *ngFor="let item of freePlaceList" [value]="item.roomPlaceNumber">
                  Место {{ item.roomPlaceNumber }} - "Места для инвалидов {{ item.placePriorityId }}"
                </option>
              </select>
            </div>
          </div>

          <div class="flex">
            <div class="w-1/2">
              <button (click)="transferStudentTest()"
                class="w-56 my-10 rounded-lg btn text-center btn-outline-primary text-white btn-lg btn-block bg-red-400 p-1">
                {{ 'Change' | translate }}
              </button>
            </div>

            <div class="w-1/2">
              <button *ngIf="showPeresadka" (click)="closePeresad($event)"
                class="w-56 my-10 rounded-lg btn bg-blue-400 text-center text-white btn-outline-primary btn-lg btn-block p-1">
                {{ 'Cancellation' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
  </form>

  <div *ngIf="informWindow == true" class="mt-3 ml-3 bg-white border-2 realy">
    <div *ngIf="hoverOnStudent" class="pl-2">

      <p> <b>Текущая занятость:</b>
        <span *ngIf="translate.currentLang == 'kz'"> {{ employmentTypeEntity.nameKaz }} </span>
        <span *ngIf="translate.currentLang == 'ru'"> {{ employmentTypeEntity.nameRus }} </span>
      </p>
      <p> <b>{{ 'FIO' | translate }}:</b> {{ lastName }}  {{ firstName }}  {{ patronymic }} </p>
      <p> <b>ИИН:</b> {{ iin }} </p>
      <p> <b>{{ 'IKT' | translate }}:</b> {{ studentTestId }} </p>
      <p> <b>{{ 'ApplicationDate' | translate }}:</b> {{ dateZayav }} </p>
      <p> <b>{{ 'phone' | translate }}:</b> {{ phoneNumber }} </p>
      <p> <b>{{ 'DeliveryLanguage' | translate }}: </b>
        <span *ngIf="translate.currentLang == 'kz'"> {{ langEntity.nameKaz }} </span>
        <span *ngIf="translate.currentLang == 'ru'"> {{ langEntity.nameRus }} </span> </p>
        <p> <b>email:</b>
          {{ email }}
        </p>
        <p *ngIf="specialityEntity != null"> <b>Специальность:</b>
          <span *ngIf="translate.currentLang == 'kz'"> {{ specialityEntity.nameKaz }} </span>
          <span *ngIf="translate.currentLang == 'ru'"> {{ specialityEntity.nameRus }} </span>
        </p>
        <p *ngIf="obl != null"> <b>Область места работы/обучения:</b>
          <span *ngIf="translate.currentLang == 'kz'"> {{ obl.nameKaz }} </span>
          <span *ngIf="translate.currentLang == 'ru'"> {{ obl.nameRus }} </span>
        </p>
        <p *ngIf="job != null"> <b>Место работы/обучения:</b>
          <span *ngIf="translate.currentLang == 'kz'"> {{ job.nameKaz }} </span>
          <span *ngIf="translate.currentLang == 'ru'"> {{ job.nameRus }} </span>
        </p>

        <p *ngIf="pretendedCategory != null"> <b>Претендуемая квалификация:</b>
          <span *ngIf="translate.currentLang == 'kz'"> {{ pretendedCategory.nameKaz }} </span>
          <span *ngIf="translate.currentLang == 'ru'"> {{ pretendedCategory.nameRus }} </span>
        </p>
        <div *ngIf="subjects.length != 0">
      <p  *ngFor="let subject of subjects;index as i">
      <span>
      <b>{{ 'Item' | translate }}:</b> {{ i+1 }}
        <span *ngIf="translate.currentLang == 'kz'"> {{ subject?.nameKaz }} - Балл {{ subject?.testScore }} </span>
        <span *ngIf="translate.currentLang == 'ru'"> {{ subject?.nameRus }} - Балл {{ subject?.testScore }} </span>
      </span>
    </p>
  </div>
      <p *ngIf="isHaveEsse != null"> <b>Эссе сохранено в базе:</b>
      <span *ngIf="isHaveEsse == true"> Да </span>
      <span *ngIf="isHaveEsse == false"> Нет </span>
      </p>
      <p> <b>Сумма баллов:</b>
        <span *ngIf="translate.currentLang == 'kz'"> {{ testScoreSum }} </span>
        <span *ngIf="translate.currentLang == 'ru'"> {{ testScoreSum }} </span>
      </p>

      <p *ngIf="jobTypeEntity.id == 8 || eduInstituteId != null"> <b>Место обучения:</b>
        <span *ngIf="translate.currentLang == 'kz'"> {{ eduInstituteId.nameKaz }} </span>
        <span *ngIf="translate.currentLang == 'ru'"> {{ eduInstituteId.nameRus }} </span>
      </p>

      <p *ngIf="jobTypeEntity.id == 9 || eduSchool != null"> <b>Место обучения:</b>
        <span *ngIf="translate.currentLang == 'kz'"> {{ eduSchool.nameKaz }} </span>
        <span *ngIf="translate.currentLang == 'ru'"> {{ eduSchool.nameRus }} </span>
      </p>
      <p> <b>Аудитория:</b>
        <span *ngIf="translate.currentLang == 'kz'"> {{ roomNumber }} </span>
        <span *ngIf="translate.currentLang == 'ru'"> {{ roomNumber }} </span>
      </p>
      <p> <b>Место:</b>
        <span *ngIf="translate.currentLang == 'kz'"> {{ roomPlaceNumber }} </span>
        <span *ngIf="translate.currentLang == 'ru'"> {{ roomPlaceNumber }} </span>
      </p>
      <p *ngIf="testPeriod != null"> <b>{{ 'TestDate' | translate }}:</b> {{ testPeriod.testDate }} - {{ testPeriod.startTestTime }}</p>
      <p *ngIf="jobTypeEntity != null || testStartedAt != null"> <b>Начало тестирования:</b> {{ testStartedAt }} </p>
      <p *ngIf="jobTypeEntity != null || testFinishedAt != null"> <b>Конец тестирования:</b>  {{ testFinishedAt }} </p>
      <p> <b>Инвалидность:</b> {{ isInvalid }} </p>
      <p *ngIf="jobTypeEntity != null"> <b>Тип тестируемого:</b>
        <span *ngIf="translate.currentLang == 'kz'"> {{ jobTypeEntity.nameKaz }} </span>
        <span *ngIf="translate.currentLang == 'ru'"> {{ jobTypeEntity.nameRus }} </span>
      </p>
      <p> <b> {{ 'TestingLocation' | translate }}: </b>
        <span *ngIf="translate.currentLang == 'kz'"> {{ testVpt.codeId }} - {{ testVpt.nameKaz }} </span>
        <span *ngIf="translate.currentLang == 'ru'"> {{ testVpt.codeId }} -  {{ testVpt.nameRus }} </span>
      </p>
        <p *ngIf="tipoEduTypeEntity != null"> <b>Вид предмета обучения:</b>
          <span *ngIf="translate.currentLang == 'kz'"> {{ tipoEduTypeEntity.nameKaz }} </span>
          <span *ngIf="translate.currentLang == 'ru'"> {{ tipoEduTypeEntity.nameRus }} </span>
        </p>
        <div class="flex">
        <p> <b>Статус:</b>
          <span *ngIf="translate.currentLang == 'kz'"> {{ testStatus.nameKaz }} </span>
          <span *ngIf="translate.currentLang == 'ru'"> {{ testStatus.nameRus }} </span>
        </p>
        <button *ngIf="testStatus.id == 7" (click)="dowloadSertif()" class="h-13 w-40 b-2 bg-blue-300 rounded-lg">Cертификат</button>
      </div>
    </div>
  </div>
</div>
