import { isDevMode } from "@angular/core";

export class apiRoutes {
  [x: string]: any;
    backend: any;
    refControllerRoutes: any;
    studentTestControllerRoutes: any;
    autheticationControllerRoutes: any;
    routes: any;
    backendStat: any;
    certificate: any;
    backendApp: any;

    constructor(){
        if (isDevMode()) {
            this.backend = 'https://nqt-operator.debug.testcenter.kz/nqt/operator/api'; // путь для LocalHost
            this.backendApp = 'https://app.debug.testcenter.kz/nqt/student/api/v1'; // путь для LocalHost
            this.certificate = 'https://certificate.testcenter.kz/api/v1';
            this.backendStat = 'https://nqt-operator.debug.testcenter.kz/statback/operator/api'
        }
        if (!isDevMode()) {
            this.backend = './nqt/operator/api'; // боевой путь
            this.backendApp = './nqt/student/api/v1'; // боевой путь
            this.certificate = './certificate/api/v1';
            this.backendStat = './statback/operator/api'


        }

        this.refControllerRoutes = { // ссылки для запросов

        }

        this.studentTestControllerRoutes = {
            studentTestFindByIin: this.backend + '/studentTest/studentTestFindByIin'
        }

        this.autheticationControllerRoutes = {
            getToken: this.backend + '/getToken'
        }

        this.routes = {

            // kato-controller
            findAllObl: this.backend + '/findAllObl', // Получение списка области


            // ref-controller
          // findAllObl: this.backend + '/findAllObl',
          findPlaceInfoForTransfer: this.backend + '/findPlaceInfoForTransfer',
            findSpecialityByName: this.backend + '/findSpecialityByName', // Получение списка специальности (поиск по названию) {nameRusKaz}
            findCategoryByJobType: this.backend + '/ref/findCategoryByJobType', // Получение списка текущей квалификационной категории {jobTypeId}
            findJobByName: this.backend + '/ref/findJobByName', // Получение списка место работы (поиск по названию) {name}
            findSomeTestStatusById: this.backend + '/ref/findSomeTestStatusById', // Получение списка статусов
            findAllJobByOblIdAndRaionId: this.backend + '/ref/findAllJobByOblIdAndRaionId', // Получение списка место работы, по области и району {oblId}/{raionId}
            choiceSubjectFind: this.backend + '/choiceSubjectFind', // Получение списка предмет(дисциплина) по блоку "Содержание учебного предмета"/По направлению деятельности
            findPretendedCategoryByJobType: this.backend + '/ref/findPretendedCategoryByJobType', // Получение мписка претендуемой квалификационной категории {currentCategoryId}/{jobTypeId}
            findAllSpecialityByJobTypeIdAndEduTypeId: this.backend + '/findAllSpecialityByJobTypeIdAndEduTypeId', // Получение списка специальности {jobTypeId}/{eduTypeId}
            getAllJobType: this.backend + '/ref/getAllJobType', // Получение списка тип организаций в котором работает тестируемый
            getAllTestStatus: this.backend + '/ref/getAllTestStatus', // Получение списка статусов (при редактирований)
            findAllPermissions: this.backend + '/ref/findAllPermissions', // Получение списка привилегий
            findAllSpeciality: this.backend + '/findAllSpeciality', // Получение списка специальности
            findAllLangEntity: this.backend + '/ref/findAllLangEntity', // Получение списка языков {jobTypeId}
            findAllEduType: this.backend + '/findAllEduType', // Получение списка вид предмета обучения
            findAllUserRole: this.backend + '/ref/findAllUserRole', // Получение списка ролей
            findAllInstitute: this.backend + '/findAllInstitute',
            getLandingLeafListExcel: this.backend + '/getLandingLeafListExcel', //{testVptId}/{testPeriodId}
           findAllSubjectByJobTypeIdAndSpecialityIdAndEduTypeIdAndLangId: this.backend + '/findAllSubjectByJobTypeIdAndSpecialityIdAndEduTypeIdAndLangId', //{jobTypeId}/{specialityId}​/{eduTypeId}​/{langId}


            // student-test-controller
            setStatus: this.backend + '/studentTest/setStatus', //Изменение статуса тестируемого PUT запрос
            findStudentTestByStudentTestId: this.backend + '/studentTest/findStudentTestByStudentTestId', // Получение данных тестируемого {studentTestId}
            getProfileInfoForAddUser: this.backend + '/getProfileInfoForAddUser', // Получить данные о пользователе из auth server {iin}
            getLoginAndPasswordOfStudent: this.backend + '/studentTest/getLoginAndPasswordOfStudent', // Показать логин/пароль тестируемого {studentTestId}
            studentTestFindByIin: this.backend + '/studentTest/studentTestFindByIin', // Получение данных тестируемого (поиск по ИИН)
            updateStudent: this.backend + '/studentTest/updateStudent', // Редактирование данных тестируемого POST запрос
            findByFio: this.backend + '/studentTest/findByFio', // Получение списка тестируемых (поиск по ФИО)
            findStudentTestByTestVptIdAndTestPeriod: this.backend + '/studentTest/findStudentTestByTestVptIdAndTestPeriod',
            findAllBlockedUsers: this.backend + '/findAllBlockedUsers',
            findUserByIin: this.backend + '/findUserByIin',
            getLandingLeafList: this.backend + '/getLandingLeafList', //{testVptId}/{testPeriodId}
            resetSession: this.backend + '/studentTest/resetSession',
            findSpecialityByJobTypeId: this.backend + '/findSpecialityByJobTypeId', //{jobTypeId}
            findSchoolById: this.backend + '/findSchoolById', // {schoolId}
            findAllRaionByOblId: this.backend + '/findAllRaionByOblId', //{oblId}
            findAllSchoolByOblId: this.backend + '/findAllSchoolByOblId', //{oblId}
            finAllSchoolByOblIdAndRaionId: this.backend + '/finAllSchoolByOblIdAndRaionId', // {oblId}/{raionId}



            // public-controller
            findAllVpt: this.backend + '/findAllVpt', // Получение списка место тестирования
            findAllTestPeriodByVptIdAndTestDateAndJobType: this.backend + '/findAllTestPeriodByVptIdAndTestDateAndJobType', // Получение списка потока {vptId}/{jobType}/{studentTestId}
            getPermissionsByUserRole: this.backend + '/getPermissionsByUserRole', // Получение списка привилегий по ролям {userRoleId}
            addUser: this.backend + '/addUser', // Добавление новых пользователей {addPermissions}/{userRoleId} POST запрос
            blockUser: this.backend + '/blockUser', // Блокирование пользователя {userId} POST запрос
            unblockUser: this.backend + '/unblockUser', // Разблокирование пользователя {userId} POST запрос
            findAllUsers: this.backend + '/findAllUsers', // Получение списка пользователей
            findUserPermissions: this.backend + '/public/findUserPermissions', // Получение списка привилегий пользователя
            findAllTestPeriodByTestVptId: this.backend + '/findAllTestPeriodByTestVptId', //{testVptId}
            updateUser: this.backend + '/updateUser',
            getUpdatesUserInfo: this.backend + '/getUpdatesUserInfo',
            exit: this.backend + '/exit',
            findSomeTestStatus: this.backend + '/ref/findSomeTestStatus',
            getLandingLeafListArchive : this.backend + '/getLandingLeafList',
            findTestTime: this.backend + '/findTestTime',

            findAllHeaderType: this.backend + '/ref/findAllHeaderType',
            auth: this.backend + '/getToken',
            getShort: this.backendApp + '/app/items/short',
            getCategory: this.backendApp + '/app/job-type/items/category/from/items', //{jobTypeId}
            toCategory: this.backendApp + '/app/job-type/items/category/to/items', //{jobTypeId}
            katoList: this.backendApp + '/app/job-type/items/kato/items/job/items', //{jobTypeId} {id}
            katoCodeList: this.backendApp + '/app/job-type/items/kato/items/children', //{jobTypeId} {id}
            jobType: this.backendApp + '/app/job-type/items/kato/items/children', //{jobTypeId} {id}
            getRaionList: this.backendApp + '/app/job-type/items/obl/items/raion/items', //{jobTypeId} {oblId}
            getOblList: this.backendApp + '/app/job-type/items/obl/items', //{jobTypeId}
            punktList: this.backendApp + '/app/job-type/items/test-org/items', //{jobTypeId}
            testPeriodList: this.backendApp + '/app/job-type/items/test-org/items/test-period/items', //{jobTypeId} {testOrgId}
            testLangList: this.backendApp + '/app/job-type/items/subject/items/lang/items', //{jobTypeId} {subjectId}
            subjList: this.backendApp + '/app/job-type/items/subject/ord/items/subject/items', //{jobTypeId} {subjectOrd}
            subjCount: this.backendApp + '/app/job-type/items/subject/ord/count', //{jobTypeId}
            specialityList: this.backendApp + '/app/job-type/items/edu-type/items/speciality/items', //{jobTypeId} {eduTypeId}
            langList: this.backendApp + '/app/job-type/items/edu-type/items/speciality/items/subject/items', //lang/items //{jobTypeId} {eduTypeId} {specialityId} {subjectId}
            headerType: this.backendApp + '/app/job-type/items/header-type/items', //{jobTypeId}
            collegeSpec: this.backendApp + '/app/job-type/items/college-speciality-for-graduates/items', //{jobTypeId}
            getCollege: this.backendApp + '/app/job-type/items/obl/items/raion/items/college/items' //{jobTypeId} {oblId} {raionId}
          }
    }

}

