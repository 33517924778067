import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, ReactiveFormsModule, FormGroup, Validators, FormArrayName, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute,  } from '@angular/router';
import { RefControllerService } from '../services/ref-controller.service';
import { AutheticationControllerService } from '../services/authetication-controller.service';
import { StudentTestControllerService } from '../services/student-test-controller.service';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-incident',
  templateUrl: './incident.component.html',
  styleUrls: ['./incident.component.css']
})
export class IncidentComponent {

  public loading = false;
  testOrgName = localStorage.getItem('testOrgName');
  orgId = localStorage.getItem('testOrgId');
  form!: FormGroup;

  userRole: any;
  testPeriodList: any;


  constructor(
    private autheticationControllerService: AutheticationControllerService, 
    private refControllerService: RefControllerService,
    public route: ActivatedRoute, 
    public router: Router, 
    private studentTest: StudentTestControllerService, 
    public translate: TranslateService,
    private _notifications: NotificationsService) {}

    

  ngOnInit(): void {

    if(localStorage.getItem('userRole') == 'ROLE_OPERATOR_NCT'){
      this.userRole = 'operatorNct';
    }

    if(localStorage.getItem('userRole') == 'ROLE_OPERATOR'){
      this.userRole = 'operator';
    }

    if(localStorage.getItem('userRole') == 'ROLE_IB'){
      this.userRole = 'IB';
    }
    
  }



}


