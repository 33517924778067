import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, FormArrayName } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { StudentTestControllerService } from '../services/student-test-controller.service'; 
import { apiRoutes } from '../config/api.routes';
import { NotificationsService, NotificationType } from 'angular2-notifications';

import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-edit-users',
  templateUrl: './edit-users.component.html',
  styleUrls: ['./edit-users.component.css']
})
export class EditUsersComponent implements OnInit {
  public loading = false;
  iin!: number;
  auth: any;
  reg: any;
  lastname!: string;
  firstname!: string;
  patronymic!: string;
  email!: string;
  role: any; 
  userRole: any;
  userRoleId: any;
  orgTypeId!: number;
  code: any;
  descr: any;
  roleList: any;
  addPermissions: any;
  addUser: any;
  operData: any;
  authUserId: any;
  checked!: boolean;
  permissions: any = [];
  permissionsList: any;
  permissionsGroupId: any;
  userId: any;
  spisokUserPermision = Array<any>();
  vptList: any;
  testVptId: any;
  vptId: any;

  formAdd!: FormGroup;
  constructor(
    private studentTest: StudentTestControllerService, 
    private router: Router,  
    private _notifications: NotificationsService,
    private route: ActivatedRoute, 
    private apiRoutes: apiRoutes, 
    public translate: TranslateService) { }

  ngOnInit(): void {

    if(localStorage.getItem('role') == 'ROLE_ADMIN'){
      this.role = 'admin';
    }
    
    if(localStorage.getItem('role') == 'ROLE_OPERATOR_NCT'){
      this.role = 'operatorNqt';
    }


    this.userId = this.route.snapshot.queryParamMap.get('userId');
    this.formAdd = new FormGroup({
      iin: new FormControl(),
      lastname: new FormControl(),
      firstname: new FormControl(),
      patronymic: new FormControl(),
      email: new FormControl(),
      userRoleId: new FormControl(),
      userId: new FormControl(),
      testVptId: new FormControl(),
      permissions: new FormArray ([]),
    });
    this.allUserRole(); 
    this.poiskIin();
  }

  get permissionsArray(): any {return this.formAdd.get('permissions') as FormArray}


  permissionSaveUser(id: any, descr: any, checked: boolean = false): FormGroup { 
    return new FormGroup({
      id: new FormControl(id),
      descr: new FormControl(descr),
      checked: new FormControl(checked),
    })
  }

  allUserRole(){
    this.studentTest.findAllUserRole()
    .subscribe((res: any) => {
      this.roleList = [];
      this.roleList = res;
      this.findAllVpt(1);

    }, (err: any) => {
   });
  }
 
  updateOperators(){
    this.loading = true;
    this.studentTest.updateUser(this.formAdd.value) 
    .subscribe((res: any) => {
      this.loading = false;
      this.translate.currentLang == 'ru' ? this._notifications.create('Успешно', 'Оператор редактирован', NotificationType.Success, 5000) : 
      this._notifications.create('Сәтті', '', NotificationType.Success, 5000);
      this.operData = res;
      this.permissions = [];
      this.permissions = res;
      this.router.navigate(['/main/operators']);

    }, (err: any) => {
      this.loading = false;
      this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) : 
      this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
    });
  }

  findAllUsers(){
    this.studentTest.findAllUsers()
    .subscribe((res: any) => {
      this.operData = [];
      this.operData = res;
    }, (err: any) => {
   });
  } 

  poiskIin(){
    this.loading = true; 
    let iin = this.formAdd.get('iin')!.value;
    this.studentTest.getUpdatesUserInfo(this.userId)
      .subscribe((res: any) => {
        this.loading = false;
        this.formAdd.get('iin')!.setValue(res.iin);
        this.formAdd.get('lastname')!.setValue(res.lastname);
        this.formAdd.get('firstname')!.setValue(res.firstname);
        this.formAdd.get('patronymic')!.setValue(res.patronymic);
        this.formAdd.get('email')!.setValue(res.email);
        this.formAdd.get('userRoleId')!.setValue(res.userRole.id);
        this.formAdd.get('userId')!.setValue(+this.userId);
        this.formAdd.get('testVptId')!.setValue(res.testVptId);
        this.studentTest.getPermissionsByUserRole(res.userRole.id) 

        .subscribe((resp: any) => {
          this.spisokUserPermision = resp;
        }, (err: any) => {
      });

        res.permissionCheckeds.forEach((element: any) => {
          this.permissionsArray.push(this.permissionSaveUser(element.id,
            element.userPermissionType,
            element.checked));
        });
      }, (err: any) => {
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) : 
        this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
      });
  }

  formsOfUserRole(){
    this.spisokUserPermision.forEach((element: any)  => {
      this.permissionsArray.push(this.permissionSaveUser(
        element.id,
        element.userPermissionType,
        element.checked
        ));
    });
  }

  getPermission(){
    this.permissionsArray.controls = [];
    this.studentTest.getPermissionsByUserRole(this.formAdd.get('userRoleId')!.value) 
    .subscribe((res: any) => {
      this.spisokUserPermision = res;
      this.formsOfUserRole();
    }, (err: any) => {
   });
  }

  findAllVpt(code: any){
    this.studentTest.findAllVpt()
      .subscribe((res: any) => {
        this.vptList = [];
        this.vptList = res;
        if(code == 0){
          this.formAdd.get('testVptId')!.setValue(0);
        }else{
        //   this.formAdd.get('testVptId')!.setValue(this.testVptId);
        }

      }, (err: any) => {

      });
  }
  
  showAuth(){
    this.updateOperators();
    this.allUserRole();
    this.reg = false;
    this.auth = true;
  }

}
