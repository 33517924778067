<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>  
<div class="mb-3 container">
  <form [formGroup]="form" class="mx-auto w-full" action="">

    <div class="flex my-7">
      <div class="w-1/5 h-1 bg-blue-300 mt-4"></div>
      <div class="w-3/5 text-center text-3xl font-hairline uppercase tracking-widest">
        Статистика НКТ
      </div>
      <div class="w-1/5 h-1 bg-blue-300 mt-4"></div>
    </div>

    <div class="border-1 rounded-lx shadow-2xl">

        <!-- <div class="flex mt-7">
          <p class="w-1/6"> Выберете год </p>
          <select formControlName="year" class="w-auto bg-gray-200 rounded-full">
            <option disabled [value]="0"> Выберете год </option>
            <option [value]="item.id" *ngFor="let item of testYearList">
             {{ item.year }} 
            </option>
          </select>
        </div> -->

        <div class="flex">
          <div class="flex">
            <p>Выбор сезона</p>
            <select class="bg-gray-200" (change)="changSeason($event)" formControlName="seasonId">
              <option [value]="0" selected>Выбор сезона</option>
              <option [value]="item.seasonId" *ngFor="let item of seasons">
                <span *ngIf="translate.currentLang == 'kz'"> {{ item.nameKaz }} </span>
                <span *ngIf="translate.currentLang == 'ru'"> {{ item.nameRus }} </span>
              </option>
            </select>
          </div>
            <p>Период тестирования</p>
            <div class="ml-5"> От
                <input formControlName="fromdate" class="ml-5" type="date">
            </div>
            <div class="ml-5"> До
                <input formControlName="todate" class="ml-5" type="date">
            </div>
        </div>

      <div class="pt-2 flex mt-4">
        <button (click)="downloadExcelENT()" type="button" class="mb-2 w-full rounded-full py-1 px-24 bg-blue-300 rounded-full text-grey-darker">
          Скачать
        </button>
      </div>
    </div>
  </form>
</div>
