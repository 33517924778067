<div class="w-full text-center mt-80 h-full">
    <label class="text-3xl">
        {{ 'errAuth' | translate }} 
        <p> {{ 'textErr' | translate }} </p>
    </label>
      <a class="w-full" [routerLink]="'https://app.testcenter.kz/auth'">
         <button (click)="back()" class="mt-20 rounded-lg bg-blue-300 p-2">
              {{ 'buttText' | translate }}
         </button>
      </a>
</div>