import { Component } from '@angular/core';

@Component({
  selector: 'app-incident-for-nct',
  templateUrl: './incident-for-nct.component.html',
  styleUrls: ['./incident-for-nct.component.css']
})
export class IncidentForNctComponent {

}
