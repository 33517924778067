import { Component, KeyValueDiffers, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder, FormArrayName } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { StudentTestControllerService } from '../services/student-test-controller.service';
import { apiRoutes } from '../config/api.routes';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';

@Component({
  selector: 'app-nqt9',
  templateUrl: './nqt9.component.html',
  styleUrls: ['./nqt9.component.css']
})
export class Nqt9Component implements OnInit {

  iin!: number;
  id!: number;
  firstname!: string;
  patronymic!: string;
  phoneNumber!: string;
  oblList: any;
  oblId: any;
  jobTypeId: any;
  studentInfo: any;
  categoryList: any;
  pretendedCategoryList: any;
  studentId: any;
  raionList: any;
  jobTypeList: any;
  jobList: any;
  date: string = '00.00.0000';
  eduPeriod: string = '0000';
  langList: any;
  eduTypeList: any;
  specialityList: any;
  isInvalid = false;
  studentTestId: any;
  testVptList: any;
  periodList: any;
  specialityId: any;
  vptList: any;
  eduSpecialityId: any;
  currentCategoryList: any;
  update: any;
  subjectList: any = [];
  dopustimPeremennya: any;
  parentId: any;
  hasChildren: any;
  code: any;
  schoolId: any;
  instituteList: any;
  katoOblList: any;
  KATOOblList: any = [];
  KATOCityList = [];
  KATOSeloList = [];
  KATORaionList = [];
  schoolTypeId: any;
  testOrg = [];
  testPeriod = [];
  specialityTypeList: any;
  KATOs: any = [];
  fromCategoryId: any;
  isFirstSelect: any
  schoolList: any;
  KATOsubject = [];
  collegList: any;
  pseudoId: any = [];
  showNasPunkt = true;
  lastChild = false;
  pageLangId: any;
  role: any;
  jobId: any;
  public loading = false;
  objDiffer: any;
  katoIndex: any;
  jobType: any;
  fromCategoryValidTo: string = '00.00.0000';
  toCategoryList: any;
  toCategoryId: any;
  kato: any;
  pstList: any;
  testOrgId: any;
  testPeriodId: any;
  testLangId: any;
  collegeSpecList: any;
  eduSchoolOblId: any;
  testOrgOblId: any;
  isCitizen: any;
  eduSchoolRaionId: any;
  collegeList: any;
  subjectCount: any;
  subjectCountArray: any = [];
  ord: any;
  diplomaSpecialityName: any;
  employmentTypeId: any;
  employmentList: any;
  form!: FormGroup;

  constructor( private studentTest: StudentTestControllerService,
    private router: Router,
    private route: ActivatedRoute,
    private apiRoutes: apiRoutes,
    private fb: FormBuilder,
     public translate: TranslateService,
     private _notifications: NotificationsService,
     private differs:  KeyValueDiffers) { }

  ngOnInit(): void {
    this.objDiffer = {};


    if(localStorage.getItem('role') == 'ROLE_ADMIN'){
      this.role = 'admin';
    }

    if(localStorage.getItem('role') == 'ROLE_OPERATOR_NCT'){
      this.role = 'operatorNqt';
    }

    if(localStorage.getItem('role') == 'ROLE_OPERATOR'){
      this.role = 'operator';
    }


    if(localStorage.getItem('Lang') == 'ru'){
      this.pageLangId = 2;
    }else{
      this.pageLangId = 1;
    }

    this.form = new FormGroup({
      studentTestId: new FormControl(),
      iin: new FormControl(),
      firstname: new FormControl(''),
      jobTypeId: new FormControl(),
      ord: new FormControl(0),
      eduPeriodStartYear: new FormControl(0),
      headerTypeId: new FormControl(0),
      eduPeriodFinishYear: new FormControl(0),
      diplomaSpecialityName: new FormControl(''),
      lastname: new FormControl(''),
      toCategoryId: new FormControl(0),
      patronymic: new FormControl(''),
      phoneNumber: new FormControl(''),
      specialityId: new FormControl(0),
      isInvalid: new FormControl(false),
      isCitizen: new FormControl(false),
      eduTypeId: new FormControl(0),
      testLangId: new FormControl(0),
      schoolTypeId: new FormControl(),
      testOrgId: new FormControl(),
      subjectId: new FormControl(),
      jobId: new FormControl(0),
      schoolId: new FormControl(),
      specialityTypeId: new FormControl(0),
      eduSpecialityId: new FormControl(0),
      subjects: new FormArray([]),
      subjectCountArray: new FormArray([]),
      eduInstituteId: new FormControl(0),
      eduInstituteOblId: new FormControl(),
      testStatusId: new FormControl(),
      testPeriodId: new FormControl(),
      fromCategoryValidTo: new FormControl(''),
      eduSchoolId: new FormControl(0),
      testOrgOblId: new FormControl(),
      eduSchoolOblId: new FormControl(),
      eduSchoolRaionId: new FormControl(),
      collegList: new FormControl(0),
      employmentTypeId: new FormControl(),
    });
    this.studentTestId = this.route.snapshot.queryParamMap.get('studentTestId');
    this.getShort();

  }

  getShort(){
    this.studentTest.getShort(this.studentTestId)
    .subscribe((res: any) => {
      this.studentInfo = res.data;
      this.form.get('studentTestId')!.setValue(+this.studentTestId);
      this.form.get('iin')!.setValue(this.studentInfo.iin);
      this.form.get('firstname')!.setValue(this.studentInfo.firstname);
      this.form.get('lastname')!.setValue(this.studentInfo.lastname);
      this.form.get('patronymic')!.setValue(this.studentInfo.patronymic);
      this.form.get('phoneNumber')!.setValue(this.studentInfo.phoneNumber);
      this.form.get('jobTypeId')!.setValue(9);
      this.form.get('jobId')!.setValue(this.studentInfo.jobId);
      this.form.get('testStatusId')!.setValue(this.studentInfo.testStatusId);
      this.form.get('testPeriodId')!.setValue(this.studentInfo.testPeriodId);
      this.form.get('eduInstituteId')!.setValue(this.studentInfo.eduInstituteId);
      this.form.get('eduSpecialityId')!.setValue(this.studentInfo.eduSpecialityId);
      this.form.get('eduTypeId')!.patchValue(this.studentInfo.eduTypeId);
      this.form.get('eduInstituteOblId')!.setValue(this.studentInfo.eduInstituteOblId);
      this.form.get('subjectId')!.setValue(this.studentInfo.subjectId);
      this.form.get('schoolId')!.setValue(this.studentInfo.schoolId);
      this.form.get('eduSchoolId')!.setValue(this.studentInfo.eduSchoolId);
      this.form.get('schoolTypeId')!.setValue(this.studentInfo.schoolTypeId);
      this.form.get('eduSchoolOblId')!.setValue(this.studentInfo.eduSchoolOblId);
      this.form.get('testOrgOblId')!.setValue(this.studentInfo.testOrgOblId);
      this.form.get('eduSchoolRaionId')!.setValue(this.studentInfo.eduSchoolRaionId);
      this.form.get('testOrgId')!.setValue(this.studentInfo.testOrgId);
      this.form.get('ord')!.setValue(this.studentInfo.ord);
      this.form.get('testLangId')!.setValue(this.studentInfo.testLangId);
      this.form.get('employmentTypeId')!.setValue(this.studentInfo.employmentTypeId);

      this.studentTest.testLangList(this.form.get('jobTypeId')!.value, this.form.get('employmentTypeId')!.value, this.studentInfo.subjects[0]?.subjectId)
          .subscribe((res: any) => {
              this.langList = res.items;
              this.form.get('testLangId')!.patchValue(this.studentInfo.testLangId);
              this.subjects.controls[0].get('subjectId')!.setValue(this.studentInfo.subjects[0]?.subjectId);
          });

      this.collegeSpec(1);

      this.getOblList(1);

      this.getRaionList(1);

      this.getCollege(1);

      this.getsubjectCount();

      this.punktList(1);

      this.testPeriodList(1);


    }, (err: any) => {
   });
  }

  collegeSpec(code: any){
    this.studentTest.collegeSpec(this.form.get('jobTypeId')!.value)
      .subscribe((res: any) => {
        this.collegeSpecList = [];
        this.collegeSpecList = res.items;
        if(code == 0){
          this.form.get('eduSpecialityId')!.setValue(0);
        }else{
          this.form.get('eduSpecialityId')!.setValue(this.studentInfo.eduSpecialityId);
        }
      }, (err: any) => {
    });
  }

  getOblList(code: any){
    this.studentTest.getOblList(this.form.get('jobTypeId')!.value, this.form.get('employmentTypeId')!.value)
    .subscribe((res: any) => {
      this.oblList = [];
      this.oblList = res.items;
      if(code == 0){
        this.form.get('eduSchoolOblId')!.setValue(0);
        this.form.get('testOrgOblId')!.setValue(0);
      }else{
        this.form.get('testOrgOblId')!.setValue(this.studentInfo.testOrgOblId);
        this.form.get('eduSchoolOblId')!.setValue(this.studentInfo.eduSchoolOblId);
      }
    }, (err: any) => {
   });
  }

  getRaionList(code: any){
    this.studentTest.getRaionList(this.form.get('jobTypeId')!.value, this.form.get('employmentTypeId')!.value, this.form.get('eduSchoolOblId')!.value)
    .subscribe((res: any) => {
      this.raionList = [];
      this.raionList = res.items;
      if(code == 0){
        this.form.get('eduSchoolRaionId')!.setValue(0);
      }else{
        this.form.get('eduSchoolRaionId')!.setValue(this.studentInfo.eduSchoolRaionId);
      }
    }, (err: any) => {
   });
  }

  getCollege(code: any){
      this.collegeList = [];
      this.studentTest.getCollege(this.form.get('jobTypeId')!.value, this.form.get('employmentTypeId')!.value, this.form.get('eduSchoolOblId')!.value, this.form.get('eduSchoolRaionId')!.value)
    .subscribe((res: any) => {
      this.collegeList = res.items;
      if(code == 0){
        this.form.get('eduSchoolId')!.setValue(0);
      }else{
        this.form.get('eduSchoolId')!.setValue(this.studentInfo.eduSchoolId);
      }
    }, (err: any) => {
   });
  }

  get subjects() : FormArray { return this.form.get("subjects") as FormArray;}

  setSubjectValue(i: any, value: any){
    this.subjects.controls[i].get('subjectId')!.setValue(value.target.value);
    this.getSubjectsLang(i);
  }

  getsubjectCount(){
    this.studentTest.subjCount(this.form.get('jobTypeId')!.value, this.form.get('employmentTypeId')!.value,)
      .subscribe((res: any) => {
        this.subjectCount = res.data.subjectCount;
        this.subjectCountLoop();
      }, (err: any) => {
    });
  }

  subjectCountLoop(){
    this.subjectCountArray = [];
    let subj = this.form.controls["subjects"] as FormArray;
    for(var i = ++this.subjectCountArray.length; i <= this.subjectCount; i++){
      this.studentTest.subjList(this.form.get('jobTypeId')!.value, this.form.get('employmentTypeId')!.value, i)
          .subscribe((res: any) => {
            this.subjectList.push(res.items);
          }, (error: any) =>{
        });
      this.addSubject(i);
    }
    // this.langList.reverse();
  }

  addSubject(i: any) {
    this.subjects.push(this.newSubject(i));
    this.subjectCountArray.push(this.subjects);
  }

  newSubject(i: any): FormGroup {
    return new FormGroup({
      ord: new FormControl(i),
      subjectId: new FormControl(this.studentInfo.subjects[0].subjectId)
    });
  }

  punktList(code: any){
    this.studentTest.findAllTestOrgForInc(this.form.get('testOrgOblId')!.value)
      .subscribe((res: any) => {
        this.pstList = res;
        if(code == 0){
          this.form.get('testOrgId')!.setValue(0)
        }else{
          this.form.get('testOrgId')!.setValue(this.studentInfo.testOrgId)
        }

      }, (err: any) => {

      });
  }


  testPeriodList(code: any){
    this.studentTest.findAllTestPeriodByVptIdAndTestDateAndJobType(this.form.get('testOrgId')!.value, this.form.get('jobTypeId')!.value, this.form.get('studentTestId')!.value)
      .subscribe((res: any) => {
        this.periodList = res;
        if(code == 0){
          this.form.get('testPeriodId')!.setValue(0)
        }else{
          this.form.get('testPeriodId')!.setValue(this.studentInfo.testPeriodId)
        }
      }, (err: any) => {
      });
  }


  // testLangList(){
  //   this.studentTest.findAllLangEntity(this.form.get('jobTypeId')!.value)
  //     .subscribe((res: any) => {
  //         this.langList = res;
  //     }, (err: any) =>{
  //
  //     });
  // }

  getSubjectsLang(i: any){
    this.studentTest.testLangList(this.form.get('jobTypeId')!.value, this.form.get('employmentTypeId')!.value, this.subjects.controls[i].get('subjectId')!.value)
      .subscribe((res: any) => {
          this.langList = res.items;
      }, (error: any) =>{
    });
  }

  editUser(){
    this.studentTest.updateStudent(this.form.get('studentTestId')!.value, this.form.value)
      .subscribe((res: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Успешно', 'пользователь редактирован', NotificationType.Success, 5000) :
        this._notifications.create('Сәтті', 'пайдаланушы бұғатталған', NotificationType.Success);
        this.update = res.studentTestId;
        this.router.navigate(['/main/students']);
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
        this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
    });
  }
}
