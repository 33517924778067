import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { StudentTestControllerService } from '../services/student-test-controller.service';
import { NotificationsService, NotificationType } from 'angular2-notifications';

interface Testers {
  ikt: number,
  iin: string,
  fullName: string,
  studentTestStatusTypeId: number,
  studentTestStatusTypeNameKaz: string,
  studentTestStatusTypeNameRus: string,
  testTypeId: number,
  testTypeNameKaz: string,
  testTypeNameRus: string,
  testTypeGroupId: number,
  createdAt: string
}

interface DataByIkt {
  studentTest: {
    ikt: number, 
    iin: string,
    fullName: string,
    testStartedAt?: Date,
    testFinishedAt?: Date,
    studentTestStatusTypeId: number,
    studentTestStatusTypeNameKaz: string,
    studentTestStatusTypeNameRus: string
  },
  studentTestSubject: [
    {
      ord: number,
      subjectNameKaz: string,
      subjectNameRus: string,
      testScore: number
    }
  ]
}
@Component({
  selector: 'app-probtest',
  templateUrl: './probtest.component.html',
  styleUrls: ['./probtest.component.css']
})
export class ProbtestComponent implements OnInit {
  form!: FormGroup;
  testers: Testers[] = [];
  pagedTesters!: any[]; // Отфильтрованный массив для отображения на странице
  currentPage: number = 1; // Текущая страница
  itemsPerPage: number = 10; // Количество элементов на странице
  p: number = 1;
  dataByIkt!: DataByIkt;
  totalScore!: number;
  dateForm!: FormGroup;
  fromdate?: string;
  todate?: string;
  role: any;
  formStatus!: FormGroup;
  showStatus = false;
  statusList: any; 
  selectedIkt: any;
  changeStatusData: any;

  constructor(public translate: TranslateService, private studentTest: StudentTestControllerService, private fb: FormBuilder, private _notifications: NotificationsService) {}

  ngOnInit(): void 
  {
    
    this.form = new FormGroup({
      searchType: new FormControl(), 
      iin: new FormControl(),
      surname: new FormControl(),
      name: new FormControl(),
      patronymic: new FormControl(),
      email: new FormControl(),
      numberzakaz: new FormControl(),
    }); 

    this.formStatus = new FormGroup({
      ikt: new FormControl(0),
      testStatusId: new FormControl(0),
    });

   
  }
  onSearch()
  {
    if(this.form.get('searchType')?.value === 1)
    {
      this.p = 1;
      this.studentTest.getTesterByIIN(this.form.get('iin')?.value, this.fromdate, this.todate).subscribe(
        (res: any) => {
         this.testers = res;
        },
        (err: any) =>{
          console.log(err)
        });
    }

    else if(this.form.get('searchType')?.value === 2) 
    {
      this.p = 1;
      this.studentTest.getTesterByFIO(this.form.get('surname')?.value, this.form.get('name')?.value, this.form.get('patronymic')?.value, this.fromdate, this.todate).subscribe(
        (res: any) => {
          this.testers = res;
        },
        (err: any) => {
          console.log(err)
        });
    }

    else if(this.form.get('searchType')?.value === 3)
    {
      this.studentTest.getTesterByEmail(this.form.get('email')?.value, this.fromdate, this.todate).subscribe(
        (res: any) => {
          this.testers = res;
        },
        (err: any) => {
          console.log(err)
        }
      )
    }
  
  }
  getDataByIkt(ikt: number)
  {
    this.studentTest.getDataByIkt(ikt).subscribe(
      (res: any) => 
      {
        this.dataByIkt = res;
        this.totalScore = this.dataByIkt.studentTestSubject.reduce((sum, subject) => sum + subject.testScore, 0)
      },
      (err: any) => 
      {
        console.log(err)
      }
    )
  }

  showModalStatus(ikt: any)
  {
    this.showStatus = true;
    this.formStatus.get('testStatusId')?.setValue(0);
    this.findSomeTestStatusByIdProbTest()
    this.selectedIkt = ikt;
  }

  closeModalStatus()
  {
    this.showStatus = false;
    this.selectedIkt = 0;
  }

  findSomeTestStatusByIdProbTest()
  {
    this.studentTest.findSomeTestStatusByIdProbTest().subscribe(
      (res: any) => {
        this.statusList = res;
      },
      (err: any) => {
        console.error(err);
      }
    )
  }

  updateStudentStatus(showStatus: false) {

    this.formStatus.patchValue({
      ikt: this.selectedIkt
    })

    this.studentTest.setStatusProbTest(localStorage.getItem('token'), this.formStatus.value).subscribe(
      (res: any) => {
        
        this.onSearch();
        this.translate.currentLang == 'ru' ? this._notifications.create('Успешно', 'Cтатус изменен', NotificationType.Success, 5000) :
        this._notifications.create('Сәтті', 'Статус өзгертілді', NotificationType.Success, 5000);
        
      },
      (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
        this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);

      });



  }
  
}
