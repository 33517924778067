<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<form class="formClass" [formGroup]="form">
  <div id="whoobe-1yakq"
       class="bg-white text-base w-full rounded-lg shadow m-auto border border-gray-300 mt-10 flex flex-col">
    <div id="whoobe-pa28x" class="flex">

      <div class="text-gray-900 rounded-lg bg-gray-200" style="width: 1548px;">
        <div class="w-full my-2 text-center text-3xl font-hairline uppercase tracking-widest">
          Внештатные ситуации во время тестирования
        </div>

        <div *ngIf="userRole == 'operator'" class="ml-3"> <b> {{testOrgName}} </b> </div>

        <div class="mt-10">

          <div *ngIf="userRole == 'IB'" class="flex mt-5" style="width: 500px;">
            <p class="ml-3" style="width: 195px;">
              Область
            </p>
            <select (change)="findAllTestOrg()" class="rounded-lg ml-2 w-72" [value]="0" formControlName="oblId">
              <option [value]="0" disabled selected> Область </option>
              <option [value]="item.id" *ngFor="let item of oblList">
                <span *ngIf="translate.currentLang == 'kz'"> {{ item.nameKaz }} </span>
                <span *ngIf="translate.currentLang == 'ru'"> {{ item.nameRus }} </span>
              </option>
            </select>
          </div>

          <div *ngIf="userRole == 'IB'" class="flex mt-5 my-2">
            <p class="ml-3" style="width: 195px;">
              {{ 'PPent' | translate }}
            </p>
            <select (change)="findAllTestPeriodByTestOrg(0)" formControlName="testOrgId" class="w-96 ml-2 rounded-lg">
              <option disabled selected [value]="0">
                {{ 'PPent' | translate }}
              </option>
              <option *ngFor="let item of testOrgList" [value]="item.id">
                <span *ngIf="translate.currentLang == 'kz'"> {{ item.code }} - {{ item.nameKaz }} </span>
                <span *ngIf="translate.currentLang == 'ru'"> {{ item.code }} - {{ item.nameRus }} </span>
              </option>
            </select>
          </div>

          <div *ngIf="userRole == 'IB'" class="flex mt-5 my-2">
            <p style="width: 195px;" class="ml-3"> Дата тестирования</p>
            <select formControlName="testPeriodId" class="w-1/5 ml-2 rounded-lg bg-width">
              <option disabled selected [value]="0">
                дата тестирования
              </option>
              <option *ngFor="let item of testPeriodOrgList" [value]="item.id">
                <span> {{ item.testDate }} - {{ item.startTestTime }} - {{ item.enterTestTime}} </span>
              </option>
            </select>
          </div>

          <div *ngIf="userRole == 'operatorNct'" class="flex mt-5" style="width: 500px;">
            <p class="ml-3" style="width: 195px;">
              Пункт
            </p>
            <select (change)="findAllTestPeriodByTestOrgs()" class="rounded-lg ml-2 w-72" [value]="0"
                    formControlName="testOrgId">
              <option [value]="0" disabled selected> Пункт </option>
              <option [value]="item.id" *ngFor="let item of testOrgListsNct">
                <span *ngIf="translate.currentLang == 'kz'"> {{ item.code }} - {{ item.nameKaz }} </span>
                <span *ngIf="translate.currentLang == 'ru'"> {{ item.code }} - {{ item.nameRus }} </span>
              </option>
            </select>
          </div>

          <div *ngIf="userRole == 'operatorNct'" class="flex mt-5 my-2">
            <p style="width: 195px;" class="ml-3"> Дата тестирования</p>
            <select formControlName="num" class="w-1/5 ml-2 rounded-lg bg-width">
              <option disabled selected [value]="0">
                дата тестирования
              </option>
              <option *ngFor="let item of testPeriodOrgListNct" [value]="item.id">
                {{ item.name }}
              </option>
            </select>
          </div>

          <div *ngIf="userRole == 'operatorNct' || userRole == 'IB'" class="flex mt-5" style="width: 600px;">
            <p class="ml-3" style="width: 200px;">
              Статус внештатной ситуации
            </p>
            <select class="rounded-lg w-96 ml-2" [value]="0" formControlName="statementsStatus">
              <option [value]="0" disabled selected>
                Статус внештатной ситуации
              </option>
              <option [value]="item.id" *ngFor="let item of searchStatusList">
                <span *ngIf="translate.currentLang == 'kz'"> {{ item.nameKaz }} </span>
                <span *ngIf="translate.currentLang == 'ru'"> {{ item.nameRus }} </span>
              </option>
            </select>
          </div>

          <div *ngIf="userRole == 'operator'" class="flex mt-5" style="width: 500px;">
            <p class="ml-3" style="width: 195px;">
              Дата тестирования
            </p>
            <select class="rounded-lg ml-2" [value]="0" formControlName="num">
              <option [value]="0" disabled selected>
                Дата тестирования
              </option>
              <option [value]="item.id" *ngFor="let item of testPeriodList">
                {{ item.name }}
              </option>

            </select>
          </div>


          <button (click)="findAllStatementsForOperIb()" *ngIf="userRole == 'IB'" type="button"
                  class="cursour-pointer w-40 my-2 bg-gray-300 hover:bg-gray-300 rounded-lg lg:px-3 py-1 ml-2 text-grey-dark text-sm">
            Фильтр
          </button>
          <button (click)="findAllStatementsForOper()" *ngIf="userRole == 'operatorNct'" type="button"
                  class="cursour-pointer w-40 my-2 bg-gray-300 hover:bg-gray-300 rounded-lg lg:px-3 py-1 ml-2 text-grey-dark text-sm">
            Фильтр
          </button>
          <button (click)="sbros()" *ngIf="userRole != 'operator'" type="button"
                  class="cursour-pointer w-40 my-2 bg-gray-300 hover:bg-gray-300 rounded-lg lg:px-3 py-1 ml-2 text-grey-dark text-sm">
            Сброс
          </button>
          <button (click)="findAllStatementsForUsers()" *ngIf="userRole != 'operator'" type="button"
                  class="cursour-pointer w-40 my-2 bg-gray-300 hover:bg-gray-300 rounded-lg lg:px-3 py-1 ml-2 text-grey-dark text-sm">
            Обновить список
          </button>

          <div *ngIf="userRole == 'operator'" class="flex mt-5" style="width: 600px;">
            <p class="ml-3" style="width: 200px;">
              Статус внештатной ситуации
            </p>
            <select class="rounded-lg w-96 ml-2" [value]="0" formControlName="statementsStatus">
              <option [value]="0" disabled selected>
                Статус внештатной ситуации
              </option>
              <option [value]="item.id" *ngFor="let item of searchStatusList">
                <span *ngIf="translate.currentLang == 'kz'"> {{ item.nameKaz }} </span>
                <span *ngIf="translate.currentLang == 'ru'"> {{ item.nameRus }} </span>
              </option>
            </select>
          </div>

        </div>

        <button *ngIf="userRole == 'operator'" [routerLink]="['/main/new-incident']" type="button"
                class="cursour-pointer w-40 my-2 bg-blue-300 hover:bg-gray-300 rounded-lg lg:px-3 py-1 ml-2 text-grey-dark text-sm">
          Создать инцидент
        </button>
        <button (click)="findAllStatementsForOper()" *ngIf="userRole == 'operator'" type="button"
                class="cursour-pointer w-40 my-2 bg-blue-300 hover:bg-gray-300 rounded-lg lg:px-3 py-1 ml-2 text-grey-dark text-sm">
          Фильтр
        </button>
        <button (click)="sbros()" *ngIf="userRole == 'operator'" type="button"
                class="cursour-pointer w-40 my-2 bg-blue-300 hover:bg-gray-300 rounded-lg lg:px-3 py-1 ml-2 text-grey-dark text-sm">
          Сброс
        </button>

        <div class="px-3 py-4 overflow-y-auto" style="height: 600px;">
          <div class="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 pr-10 lg:px-8">
            <table class="min-w-full">
              <thead>
              <tr>
                <th
                  class="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-500 tracking-wider">
                  Код</th>
                <th
                  class="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-500 tracking-wider">
                  Действие</th>
                <th
                  class="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-500 tracking-wider">
                  Статус запроса</th>
                <th
                  class="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-500 tracking-wider">
                  Время инцидента</th>
                <th *ngIf="userRole != 'operator'"
                    class="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-500 tracking-wider">
                  Место тестирования</th>
                <th
                  class="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-500 tracking-wider">
                  Дата тестирования</th>
                <th
                  class="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-500 tracking-wider">
                  Основание</th>
              </tr>
              </thead>
              <tbody class="bg-white">
              <tr *ngFor="let inc of allInc">
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                  <div class="flex items-center">
                    <div>
                      <div class="text-sm leading-5 text-gray-800">{{ inc.statementId }}</div>
                    </div>
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap border-b text-blue-900 border-gray-800 text-sm leading-5">
                    <span *ngIf="inc.sprStatementsStatusEntity.id == 1 && userRole == 'operator'"
                          [routerLink]="['/main/incident-for-oper']" [queryParams]="{ statementId: inc.statementId}"
                          class="text-indigo-600 rounded-full cursor-pointer hover:text-indigo-900">Открыть</span>
                  <span *ngIf="inc.sprStatementsStatusEntity.id != 1 && userRole == 'operator'"
                        [routerLink]="['/main/incident-for-oper']" [queryParams]="{ statementId: inc.statementId}"
                        class="text-indigo-600 rounded-full cursor-pointer hover:text-indigo-900">Просмотр</span>

                  <span
                    *ngIf="(inc.sprStatementsStatusEntity.id == 2 || inc.sprStatementsStatusEntity.id == 3) && userRole == 'operatorNct'"
                    [routerLink]="['/main/incident-for-nct']" [queryParams]="{ statementId: inc.statementId}"
                    class="text-indigo-600 rounded-full cursor-pointer hover:text-indigo-900">Открыть</span>
                  <span
                    *ngIf="inc.sprStatementsStatusEntity.id != 2 && inc.sprStatementsStatusEntity.id != 3 && userRole == 'operatorNct'"
                    [routerLink]="['/main/incident-for-nct']" [queryParams]="{ statementId: inc.statementId}"
                    class="text-indigo-600 rounded-full cursor-pointer hover:text-indigo-900">Просмотр</span>

                  <span
                    *ngIf="(inc.sprStatementsStatusEntity.id == 4 || inc.sprStatementsStatusEntity.id == 6 || inc.sprStatementsStatusEntity.id == 10) && userRole == 'IB'"
                    [routerLink]="['/main/incident-for-ib']" [queryParams]="{ statementId: inc.statementId}"
                    class="text-indigo-600 rounded-full cursor-pointer hover:text-indigo-900">Открыть</span>
                  <span
                    *ngIf="inc.sprStatementsStatusEntity.id != 1 && inc.sprStatementsStatusEntity.id != 2 && inc.sprStatementsStatusEntity.id != 4 && inc.sprStatementsStatusEntity.id != 6 && inc.sprStatementsStatusEntity.id != 10 && userRole == 'IB'"
                    [routerLink]="['/main/incident-for-ib']" [queryParams]="{ statementId: inc.statementId}"
                    class="text-indigo-600 rounded-full cursor-pointer hover:text-indigo-900">Просмотр</span>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap border-b text-blue-900 border-gray-800 text-sm leading-5">
                  <span *ngIf="translate.currentLang == 'kz'"> {{ inc.sprStatementsStatusEntity.nameKaz }} </span>
                  <span *ngIf="translate.currentLang == 'ru'"> {{ inc.sprStatementsStatusEntity.nameRus }} </span>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap border-b text-blue-900 border-gray-800 text-sm leading-5">{{
                    inc.createdAt }}</td>
                <td *ngIf="userRole != 'operator'"
                    class="px-6 py-4 whitespace-no-wrap border-b text-blue-900 border-gray-800 text-sm leading-5">
                  <span *ngIf="translate.currentLang == 'kz'"> {{ inc.testVptEntity.codeId }} - {{ inc.testVptEntity.nameKaz }} </span>
                  <span *ngIf="translate.currentLang == 'ru'"> {{ inc.testVptEntity.codeId }} - {{ inc.testVptEntity.nameRus }} </span>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap border-b text-blue-900 border-gray-800 text-sm leading-5">{{
                    inc.testPeriod.testDate }}</td>
                <td class="px-6 py-4 whitespace-no-wrap border-b text-blue-900 border-gray-800 text-sm leading-5">
                  <span *ngIf="translate.currentLang == 'kz'"> {{ inc.resetSessionTypeId.nameKaz }} </span>
                  <span *ngIf="translate.currentLang == 'ru'"> {{ inc.resetSessionTypeId.nameRus }} </span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
