import { Component, OnInit } from '@angular/core';
import { StudentTestControllerService } from '../services/student-test-controller.service';
import { Router, ActivatedRoute, Routes } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-appnct',
  templateUrl: './appnct.component.html',
  styleUrls: ['./appnct.component.css']
})
export class AppnctComponent implements OnInit {

  constructor(
    private route: ActivatedRoute, 
    public router: Router, 
    private studentTest: StudentTestControllerService, 
    private _notifications: NotificationsService, 
    public translate: TranslateService) { }

    ref = `https://auth.testcenter.kz/auth?link=` + window.location.href;

  ngOnInit(): void {
  }

  CheckConnection(){
    this.studentTest.getLocalHost()
    .subscribe((res: any) => {
      localStorage.setItem('userInfoToken', res.token);
      this.router.navigate(['/main']);
    }, (err: any) => {
      this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка, не удалось соедениться', err.error.message, NotificationType.Error, 5000) : 
      this._notifications.create('Қате, қосылу мүмкін болмады', err.error.message, NotificationType.Error, 5000);
    });
  }

  back(){
    localStorage.clear();
    window.location.href = './';
  }


}
