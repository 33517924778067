import { Injectable, isDevMode } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RefControllerService {
  backend: any;
  routes: any;
  static findFieldOfEducation: string;

  constructor() { 
    if(isDevMode()){
      this.backend = 'http://192.168.200.13:28080/nqt/operator/api/'; // путь для LocalHost
    }
    
    if (!isDevMode()) {
      this.backend = 'nqt/operator/api/'; // боевой путь
    }

//     this.routes = {

//       // ref-controller
//       findSpecialityByName: this.backend + '/ref/findSpecialityByName', // Получение списка специальности (поиск по названию) {nameRusKaz} 
//       findCategoryByJobType: this.backend + '/ref/findCategoryByJobType', // Получение списка текущей квалификационной категории {jobTypeId}
//       findJobByName: this.backend + '/ref/findJobByName', // Получение списка место работы (поиск по названию) {name}
// ​      findAllRaionByOblId​: this.backend + '/ref​/findAllRaionByOblId​', // Получение списка районов по области {oblId}
//       findSomeTestStatusById: this.backend + '/ref/findSomeTestStatusById', // Получение списка статусов
//       findAllJobByOblIdAndRaionId: this.backend + '/ref/findAllJobByOblIdAndRaionId', // Получение списка место работы, по области и району {oblId}/{raionId}
//       choiceSubjectFind: this.backend + '/ref/choiceSubjectFind', // Получение списка предмет(дисциплина) по блоку "Содержание учебного предмета"/По направлению деятельности
//       findPretendedCategoryByJobType: this.backend + '/ref/findPretendedCategoryByJobType', // Получение мписка претендуемой квалификационной категории {currentCategoryId}/{jobTypeId}
//       findAllSpecialityByJobTypeIdAndEduTypeId: this.backend + '/ref/findAllSpecialityByJobTypeIdAndEduTypeId', // Получение списка специальности {jobTypeId}/{eduTypeId}
//       getAllJobType: this.backend + '/ref​/getAllJobType', // Получение списка тип организаций в котором работает тестируемый
//       getAllTestStatus: this.backend + '/ref/getAllTestStatus', // Получение списка статусов (при редактирований)
//       findAllPermissions: this.backend + '/ref/findAllPermissions', // Получение списка привилегий
//       findAllSpeciality: this.backend + '/ref/findAllSpeciality', // Получение списка специальности
//       findAllLangEntity: this.backend + '/ref/findAllLangEntity', // Получение списка языков {jobTypeId}
//       findAllEduType: this.backend + '/ref/findAllEduType', // Получение списка вид предмета обучения
//       findAllObl: this.backend + '/ref/findAllObl', // Получение списка области
//       findAllUserRole: this.backend + '/ref/findAllUserRole', // Получение списка ролей


//       // student-test-controller
//       setStatus: ​this.backend + '/studentTest​/setStatus', //Изменение статуса тестируемого PUT запрос
//       findStudentTestByStudentTestId: this.backend + '/studentTest/findStudentTestByStudentTestId', // Получение данных тестируемого {studentTestId} 
//       getPersonalData: this.backend + '/studentTest/getPersonalData', // Получить данные о пользователе из auth server {iin}
//       getLoginAndPasswordOfStudent: this.backend + '/studentTest/getLoginAndPasswordOfStudent', // Показать логин/пароль тестируемого {studentTestId}
//       studentTestFindByIin: this.backend + '/studentTest/studentTestFindByIin', // Получение данных тестируемого (поиск по ИИН)
//       updateStudent: this.backend + '/studentTest/updateStudent', // Редактирование данных тестируемого POST запрос
//       findByFio: this.backend + '/studentTest/findByFio', // Получение списка тестируемых (поиск по ФИО)


//       // public-controller
//       findAllVpt: this.backend + '/public/findAllVpt', // Получение списка место тестирования
//       updateUserPermissions: this.backend + '/public/updateUserPermissions', // Назначение прав доступа (роли, привилегий) пользователям (нужно передовать в Permissions вместе с новыми значениями и старые, которые стояли) {userId}/{userRoleId}/{updatePermissions} PUT запрос
//       findAllTestPeriodByVptIdAndTestDateAndJobType: this.backend + '/public/findAllTestPeriodByVptIdAndTestDateAndJobType', // Получение списка потока {vptId}/{jobType}/{studentTestId}
//       getPermissionsByUserRole: this.backend + '/public/getPermissionsByUserRole', // Получение списка привилегий по ролям {userRoleId}
//       addUser: this.backend + '/public/addUser', // Добавление новых пользователей {addPermissions}/{userRoleId} POST запрос
//       blockUser: this.backend + '/public/blockUser', // Блокирование пользователя {userId} POST запрос
//       unblockUser: this.backend + '/public/unblockUser', // Разблокирование пользователя {userId} POST запрос 
//       findAllUsers: this.backend + '/public/findAllUsers', // Получение списка пользователей
//       findUserPermissions: this.​backend  + '/public​/findUserPermissions', // Получение списка привилегий пользователя
//     }

  }
}

