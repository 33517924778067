import { Component, KeyValueDiffers, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { StudentTestControllerService } from '../services/student-test-controller.service'; 
import { apiRoutes } from '../config/api.routes';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';

@Component({
  selector: 'app-student-test',
  templateUrl: './student-test.component.html',
  styleUrls: ['./student-test.component.css']
})
export class StudentTestComponent implements OnInit {

    iin!: number;
    ikt!: number;
    lastName!: string;
    firstName!: string;
    patronymic!: string;
    phoneNumber!: string;
    oblList: any;
    oblId: any;
    studentInfo: any;
    categoryList: any;
    pretendedCategoryList: any;
    raionList: any;
    jobTypeList: any;
    jobList: any;
    currentCategoryValidTo: any;
    date: string = '00.00.0000';
    eduPeriod: string = '0000';
    testLangList: any;
    eduTypeList: any;
    specialityList: any;
    isInvalid = false;
    studentTestId: any;
    testVptList: any;
    testPeriodList: any;
    specialityId: any;
    vptList: any;
    eduSpecialityId: any;
    currentCategoryList: any;
    update: any;
    subjectList: any;
    dopustimPeremennya: any;
    parentId: any;
    hasChildren: any;
    code: any;
    schoolId: any;
    instituteList: any;
    id: any;
    katoOblList: any;
    KATOOblList: any = [];
    KATOCityList = [];
    KATOSeloList = [];
    KATORaionList = [];
    schoolTypeId: any;
    langList = [];
    testOrg = [];
    testPeriod = [];
    specialityTypeList: any;
    KATOs: any = [];
    schoolList: any;
    KATOsubject = [];
    collegList: any;
    pseudoId: any = [];
    showNasPunkt = true;
    lastChild = false;
    pageLangId: any;
    raionId: any;
    role: any;
    jobId: any;
    public loading = false;
    objDiffer: any;
    headerTypeList: any;
    headerTypeId: any;
    jobType: any;
    katoIndex: any;

    form!: FormGroup;
    
  constructor(
    private studentTest: StudentTestControllerService,
    private router: Router, 
    private route: ActivatedRoute, 
    private apiRoutes: apiRoutes,
     public translate: TranslateService, 
     private _notifications: NotificationsService,
     private differs:  KeyValueDiffers) { }

  ngOnInit(): void {

    this.objDiffer = {};


    if(localStorage.getItem('role') == 'ROLE_ADMIN'){
      this.role = 'admin';
    }
    
    if(localStorage.getItem('role') == 'ROLE_OPERATOR_NCT'){
      this.role = 'operatorNqt'; 
    }

    if(localStorage.getItem('role') == 'ROLE_OPERATOR'){
      this.role = 'operator';
    }


    if(localStorage.getItem('Lang') == 'ru'){
      this.pageLangId = 2;
    }else{
      this.pageLangId = 1;
    }
    
    this.form = new FormGroup({                        
      studentTestId: new FormControl(0),        
      studentId: new FormControl(0),
      iin: new FormControl(0),
      ikt: new FormControl(0),
      firstName: new FormControl(0),
      specialityList: new FormControl(0),
      jobType: new FormControl(),
      lastName: new FormControl(0),
      patronymic: new FormControl(0),
      phoneNumber: new FormControl(0),
      jobTypeId: new FormControl(0),
      specialityId: new FormControl(0),
      raionId: new FormControl(0),
      schoolList: new FormControl(0),
      eduTypeId: new FormControl(0),
      raionList: new FormControl(0),
      testLangId: new FormControl(0),
      oblId: new FormControl(0),
      schoolTypeId: new FormControl(0),
      subjectId: new FormControl(0),
      bin: new FormControl(0),
      jobId: new FormControl(0),
      firstLevelKato: new FormControl(0),
      schoolId: new FormControl(0),
      currentCategoryId: new FormControl(0),
      pretendedCategoryId: new FormControl(0),
      currentCategoryValidTo: new FormControl(''),
      diplomaSpecialityName: new FormControl(''),
      eduPeriodStartYear: new FormControl(0),
      eduPeriodFinishYear: new FormControl(0),
      isInvalid: new FormControl(0),
      specialityTypeId: new FormControl(0),
      eduSpecialityId: new FormControl(0),
      auditId: new FormControl(0),
      eduInstituteId: new FormControl(0),
      eduInstituteOblId: new FormControl(0),
      placeNum: new FormControl(0),
      kato: new FormArray ([]),
      headerTypeId: new FormControl(),
      testStatusId: new FormControl(0),
      testVptId: new FormControl(0),
      vptId: new FormControl(0),
      testPeriodId: new FormControl(0), 
      eduSchoolId: new FormControl(0), 
      collegList: new FormControl(0), 
      optionalSubjectCount: new FormControl(0),
    }); 
    this.studentTestId = this.route.snapshot.queryParamMap.get('studentTestId');
    this.getStudentInfo();  
    this.getJobTypeList();
    
  }
  

  getStudentInfo(){
    this.studentTest.findStudentTestByStudentTestId(this.studentTestId)
    .subscribe((res: any) => {
      this.studentInfo = res;
      this.form.get('studentTestId')!.setValue(+this.studentTestId);
      this.form.get('iin')!.setValue(this.studentInfo.iin);
      this.form.get('ikt')!.setValue(+this.studentInfo.ikt);
      this.form.get('firstName')!.setValue(this.studentInfo.firstName);
      this.form.get('lastName')!.setValue(this.studentInfo.lastName);
      this.form.get('patronymic')!.setValue(this.studentInfo.patronymic);
      this.form.get('phoneNumber')!.setValue(this.studentInfo.phoneNumber);
      this.form.get('diplomaSpecialityName')!.setValue(this.studentInfo.diplomaSpecialityName);
      this.form.get('eduPeriodFinishYear')!.setValue(this.studentInfo.eduPeriodFinishYear);
      this.form.get('eduPeriodStartYear')!.setValue(this.studentInfo.eduPeriodStartYear);
      this.form.get('isInvalid')!.setValue(this.studentInfo.isInvalid);
      this.form.get('oblId')!.setValue(this.studentInfo.oblId);
      this.form.get('jobTypeId')!.setValue(this.studentInfo.jobTypeId);
      this.form.get('jobId')!.setValue(this.studentInfo.jobId);
      this.form.get('testStatusId')!.setValue(this.studentInfo.testStatusId);
      this.form.get('studentId')!.setValue(this.studentInfo.studentId);  
      this.form.get('eduInstituteId')!.setValue(this.studentInfo.eduInstituteId);
      this.form.get('eduSpecialityId')!.setValue(this.studentInfo.eduSpecialityId);
      this.form.get('eduTypeId')!.patchValue(this.studentInfo.eduTypeId);  
      this.form.get('eduInstituteOblId')!.setValue(this.studentInfo.eduInstituteOblId);
      this.form.get('subjectId')!.setValue(this.studentInfo.subjectId);
      this.form.get('schoolId')!.setValue(this.studentInfo.schoolId);
      this.form.get('testVptId')!.setValue(this.studentInfo.testVptId);
      this.form.get('vptId')!.setValue(this.studentInfo.vptId);
      this.form.get('eduSchoolId')!.setValue(this.studentInfo.eduSchoolId);
      this.form.get('jobType')!.setValue(this.studentInfo.jobType);
      this.form.get('raionId')!.setValue(this.studentInfo.raionId);
      this.form.get('schoolTypeId')!.setValue(this.studentInfo.schoolTypeId);
      this.form.get('headerTypeId')!.setValue(this.studentInfo.headerTypeId);
      
      

      // if(this.studentInfo.jobTypeId != 9){
        this.getCategoryList(this.studentInfo.jobTypeId, this.studentInfo.eduTypeId);
      // }

      this.getTestLangList(0);

      this.getAllSpeciality();

      this.getAllInstitute();

      this.getSpecialityByJobTypeId()

      this.getInstituteObl();

      this.getVpt();

      if(this.studentInfo.jobTypeId == 7){
      this.findAllHeaderType(1);
    }

      if(this.studentInfo.jobTypeId != 8 && this.studentInfo.jobTypeId != 9){
        this.getKATOOblList(this.studentInfo.oblId);

        this.setJob(this.studentInfo.jobId, this.studentInfo.katoId);
      }else{
        this.getObl();
      }
      
      this.getAllSchoolByOblIdAndRaionId();

      this.getJobTypeList();
    }, (err: any) => {
   });
  }

  setJob(jobId: any, kato: any){
    this.loading = true;
        this.KATOs = [];
        this.jobId = jobId;
        this.katoIndex = 0;
        
        this.form.get('firstLevelKato')!.setValue(kato[0]);

        this.rekursiya(kato, this.katoIndex);
       
        this.katoGetter.controls = [];
        this.pseudoId = [];
        kato.forEach((elem: any) => {
          this.pseudoId.push(elem);
        });
        this.getJobItems(kato[kato.length-1], true);
        
  }

  rekursiya(kato: any, index: any){
    const element = kato[index];
      this.studentTest.findAllRaionByObl​(element)   
        .subscribe((resp: any) => {
          if(resp.length > 0){
            this.KATOs.push(resp);
            this.katoIndex = this.katoIndex + 1;
            this.rekursiya(kato, this.katoIndex);
          }
        }, (err: any) => {
    });  
    if(this.katoIndex == kato.length - 1){
      this.loading = false;
    }
  }

  checkEduPeriod(){
    if(this.form.get('eduPeriodStartYear')!.value < 1960){
      this.form.get('eduPeriodStartYear')!.setValue(1960);
    }
    if(this.form.get('eduPeriodFinishYear')!.value > 2022){
      this.form.get('eduPeriodFinishYear')!.setValue(2022);
    }
  }

  searchJob(bin: any){
    this.studentTest.searchJobByBin(bin)
      .subscribe((res: any) => {
        this.KATOs = [];
        this.jobId = res.jobId;
        this.form.get('firstLevelKato')!.setValue(res.katoId[0]);
        res.katoId.forEach((element: any) => {
          this.studentTest.findAllRaionByObl​(element)   
          .subscribe((resp: any) => {
            if(resp.length > 0){
              this.KATOs.push(resp);
              this.KATOs.sort();
              this.KATOs.reverse();
            }
          }, (err: any) => {
            this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) : 
            this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
          });
        });

        this.katoGetter.controls = [];
        this.pseudoId = [];
        res.katoId.forEach((elem: any) => {
          this.pseudoId.push(elem);
        });
        this.getJobItems(res.katoId[res.katoId.length-1], true);
      }, (err: any) => {
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) :
        this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
      });
  }

  get katoGetter() : FormArray {
    return this.form.get("kato") as FormArray;
  }

  getKATOsList(i: any, j: any, firstSelect: any, code: number){
    let id: number = 0;
    if(code == 1){
      this.form.get('jobId')!.setValue(this.studentInfo.jobId);  
    }else{
      this.form.get('jobId')!.setValue(0);
    
    }
    if(firstSelect){
      id = this.form.get('firstLevelKato')!.value;
      this.lastChild = false;
    }else{
      id = this.KATOs[i][j.value].id;
      this.lastChild = !this.KATOs[i][j.value].hasChildren;
    }
    if(this.lastChild == false || firstSelect == true){ 
      this.studentTest.findAllRaionByObl​(id)   
      .subscribe((res: any) => {
        if(firstSelect){
          this.KATOs = [];
          this.pseudoId = [];
          this.KATOs.push(res);
        };
        if(firstSelect == false && this.lastChild == true){
          this.KATOs.splice(i+1, 1);
          this.KATOs.push(res);
        };
        if(firstSelect == false && this.KATOs[i][j.value].hasChildren == true){
          this.KATOs.splice(i+1, 2);
          this.KATOs.push(res);
        };
      }, (err: any) => {
     });
    }
      if(firstSelect == false && this.KATOs[i][j.value].hasChildren == false){
        this.KATOs.splice(i+1, this.KATOs.length);
        this.getJobItems(id, false);
      }else{
        this.lastChild = false;
      };
  }

  getJobItems(nasPunkId: any, binSearch: any){
    if(this.showNasPunkt == true){
      this.studentTest.getJobList(nasPunkId)
      .subscribe((res: any) => {
        this.jobList = [];
        this.jobList = res;
      }, (err: any) => {
     });
    }
    if(binSearch == true){
      setTimeout(() => {
        this.form.get('jobId')!.setValue(this.jobId);
      }, 1000);
    }
  }

  getObl(){
    this.studentTest.findAllObl()
    .subscribe((res: any) => {
      this.oblList = [];
      this.oblList = res;
      this.getAllRaionByOblId​();
    })
  }

getChoiceSubject(code: number){ 
    this.subjectList = [];
    this.studentTest.choiceSubjectFind(this.form.get('jobTypeId')!.value, this.form.get('testLangId')!.value, this.form.get('specialityId')!.value, this.form.get('eduTypeId')!.value)
  .subscribe((res: any) => {
    this.subjectList = res;
    if(code == 0){
      this.form.get('subjectId')!.setValue(0);
    }else{
      this.form.get('subjectId')!.setValue(this.studentInfo.subjectId);  
    }
  }, (err: any) => {
 });
}

  getKATOOblList(firstLevelKato: number){
    this.studentTest.findAllObl()
      .subscribe((res: any) => {
        this.katoOblList = [];
        this.katoOblList = res;
        this.oblList = [];
        this.oblList = res;
        this.form.get('firstLevelKato')!.setValue(this.studentInfo.katoId[0]);
        this.form.get('oblId')!.setValue(0);
      }, (err: any) => {
    });
  }

  getVpt(){
    this.studentTest.findAllVpt()
    .subscribe((res: any) => {
      this.vptList = [];
      this.vptList = res;
      this.getTestPeriodList(0);
    }, (err: any) => {
   });
  }

  getJobList(){
    this.studentTest.findAllJobByOblIdAndRaionId(this.form.get('oblId')!.value, this.form.get('raionId')!.value)
    .subscribe((res: any) => {
      this.jobList = [];
      this.jobList = res;
      this.form.get('jobId')!.setValue(this.studentInfo.jobId);
    }, (err: any) => {
   });
  }

  getJobTypeList(){
    this.studentTest.getAllJobType()
    .subscribe((res: any) => {
      this.jobTypeList = [];
      this.jobTypeList = res;
      this.form.get('jobTypeId')!.setValue(this.studentInfo.jobTypeId); 
    }, (err: any) => {
   });
  }

  getCategoryList(jobTypeId: number, eduTypeId = 0){
    this.form.get('jobTypeId')!.setValue(jobTypeId);
    this.studentTest.findCategoryByJobType(jobTypeId)
    .subscribe((res: any) =>  {
      this.categoryList = res;
      this.form.get('currentCategoryId')!.setValue(this.studentInfo.currentCategoryId);
      this.form.get('optionalSubjectCount')!.setValue(0);
      if(this.form.get('jobTypeId')!.value != 0){
        this.form.get('currentCategoryId')!.setValue(this.studentInfo.currentCategoryId);
        if(this.form.get('jobTypeId')!.value == 6){
        }
        this.form.get('currentCategoryId')!.setValue(this.studentInfo.currentCategoryId);
        this.form.get('pretendedCategoryId')!.setValue(this.studentInfo.pretendedCategoryId);
        this.form.get('currentCategoryValidTo')!.setValue(this.studentInfo.currentCategoryValidTo);
        this.getPretendedCategoryList(0);
      }else{
        this.form.get('currentCategoryId')!.setValue(0);
        this.form.get('pretendedCategoryId')!.setValue(0);
        this.form.get('currentCategoryValidTo')!.setValue(0);
        this.form.get('currentCategoryValidTo')!.setValue(0); 
        this.form.get('testLangId')!.setValue(0);
        this.form.get('specialityId')!.setValue(0);
        this.form.get('subjectId')!.setValue(0);
        this.getPretendedCategoryList(0);
      }
    }, (err: any) =>{
      this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) : 
      this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
    });
  }

  getPretendedCategoryList(code: number){
    if(this.form.get('jobTypeId')!.value != 8){
      this.studentTest.findPretendedCategoryByJobType(this.form.get('currentCategoryId')!.value, this.form.get('jobTypeId')!.value) 
      .subscribe((res: any) => {
        this.pretendedCategoryList = [];
        if(code == 1){
          this.form.get('pretendedCategoryId')!.setValue(0);
        }
        this.pretendedCategoryList = res;
      }, (err: any) => {
     });
    }
  
  }

  findAllHeaderType(code: any){
        this.headerTypeList = [];
        if(this.form.get('jobTypeId')!.value == 7){
      this.studentTest.findAllHeaderType() 
      .subscribe((res: any) => {
        this.headerTypeList = res;
        if(code == 0){
          this.form.get('headerTypeId')!.setValue(0);
        }else{
          this.form.get('headerTypeId')!.setValue(this.studentInfo.headerTypeId);
        }
      }, (err: any) => {

      });

    }
   }

  getTestLangList(code: number){
    this.studentTest.findAllLangEntity(this.form.get('jobTypeId')!.value)
    .subscribe((res: any) => {
      this.testLangList = [];
      this.testLangList = res;
      this.form.get('testLangId')!.setValue(this.studentInfo.testLangId); 
      if(this.form.get('jobTypeId')!.value == 6){
        if(code == 0){
          this.form.get('testLangId')!.setValue(this.studentInfo.testLangId);
        }else{
          this.form.get('testLangId')!.setValue(0);
        }
        this.getEduTypeList();
        this.getChoiceSubject(1);
      }else{
        this.getSubjectList();
        this.getChoiceSubject(1);
      }
    });
  }

  getEduTypeList(){
    this.studentTest.findAllEduType()
    .subscribe((res: any) => {
      this.eduTypeList = [];
      this.eduTypeList = res;
      if(this.form.get('eduTypeId')!.value != 1){
        this.getSpecialityList();
      }
      if(this.form.get('eduTypeId')!.value == 1){
        this.getSubjectList();
      }
    }, (err: any) => {
   });
  }

  getCurrentEduTypeSpr(){
    this.form.get('subjectId')!.setValue(0);
    this.form.get('specialityId')!.setValue(0);
    if(this.form.get('eduTypeId')!.value != 1){
      this.getSpecialityList();
    }
    if(this.form.get('eduTypeId')!.value == 1){
      this.getSubjectList();
    }
  }

  getTestPeriodList(code: number){
    this.studentTest.findAllTestPeriodByVptIdAndTestDateAndJobType(this.form.get('testVptId')!.value, this.form.get('jobTypeId')!.value, this.form.get('studentTestId')!.value) //this.studentInfo.studentTestId
      .subscribe((res: any) => {
        this.testPeriodList = [];
        this.testPeriodList = res;
        if(code == 0){
          this.form.get('testPeriodId')!.setValue(this.studentInfo.testPeriodId);
        }else{
          this.form.get('testPeriodId')!.setValue(0);
        }
       }, (err: any) => {
    });
  }

  getSubjectList(){
    if(this.form.get('jobTypeId')!.value == 6 && this.form.get('eduTypeId')!.value == 1){
      this.studentTest.choiceSubjectFind(this.form.get('jobTypeId')!.value, this.form.get('testLangId')!.value, 0, this.form.get('eduTypeId')!.value)
     .subscribe((res: any) => {
       this.subjectList = [];
       this.subjectList = res;
      }, (err: any) => {
     });
    }
    if(this.form.get('jobTypeId')!.value == 6 && this.form.get('eduTypeId')!.value != 1){
      if(this.form.get('jobTypeId')!.value == 6 && this.form.get('eduTypeId')!.value == 0){
      }
      if(this.form.get('specialityId')!.value == 0 && this.form.get('eduTypeId')!.value == 0){
        this.getEduTypeList();
      }else{
        this.studentTest.choiceSubjectFind(this.form.get('jobTypeId')!.value, this.form.get('testLangId')!.value, 0, this.form.get('eduTypeId')!.value)
        .subscribe((res: any) => {
          this.subjectList = [];
          this.subjectList = res;
          }, (err: any) => {
        });
      }
    }
    if(this.form.get('jobTypeId')!.value != 6 && this.form.get('eduTypeId')!.value != 1){
      this.studentTest.choiceSubjectFind(this.form.get('jobTypeId')!.value, this.form.get('testLangId')!.value, 0, this.form.get('eduTypeId')!.value)
     .subscribe((res: any) => {
      this.form.get('subjectId')!.setValue(this.studentInfo.subjectId);
      this.form.get('subjectId')!.setValue(0);
      this.subjectList = [];
      this.subjectList = res;
      }, (err: any) => {
     });
    }
  }

  getSpecialityList(){
    if(this.form.get('eduTypeId')!.value != 1){
      this.studentTest.findAllSpecialityByJobTypeIdAndEduTypeId(this.form.get('jobTypeId')!.value, this.form.get('eduTypeId')!.value)
    .subscribe((res: any) => {
      this.specialityList = [];
      this.specialityList = res;
        this.form.get('specialityId')!.setValue(this.studentInfo.specialityId);
      this.getSubjectList();
    }, (err: any) => {
    });
    }else{
      this.getSubjectList();
    } 
  }

  editUser(){
    this.studentTest.updateStudent(this.form.get('studentTestId')!.value, this.form.value)
      .subscribe((res: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Успешно', 'пользователь редактирован', NotificationType.Success, 5000) :
        this._notifications.create('Сәтті', 'пайдаланушы бұғатталған', NotificationType.Success);
        this.update = res.studentTestId;
        this.router.navigate(['/main/students']);
      },(err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) : 
        this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
      });
  }

  getAllInstitute(){
    this.studentTest.getFindAllInstitute()
    .subscribe((res: any) => {
      this.instituteList = [];
      this.instituteList = res;
    }, (err: any) => {
   });
  }

  getInstituteObl(){
    this.studentTest.findAllObl()
    .subscribe((res: any) => {
      this.oblList = [];
      this.oblList = res.oblId;
    }, (err: any) => {
   });
  }

  getAllSpeciality(){
    this.studentTest.findAllSpeciality()
    .subscribe((res: any) => {
      this.specialityList = [];
      this.specialityList = res;
    }, (err: any) =>{
   });
  }

  getSpecialityByJobTypeId(){  
    this.studentTest.findSpecialityByJobTypeId(this.form.get('jobTypeId')?.value)
    .subscribe((res: any) => {
      this.specialityTypeList = [];
      this.specialityTypeList = res;
    }, (err: any) => {
   });
  }​

  getSchoolById(){
    this.studentTest.findSchoolById(this.form.get('schoolId')?.value)
    .subscribe((res: any) => {
      this.schoolList = [];
      this.schoolList = res;
    }, (err: any) => {
   });
  }

  getAllRaionByOblId​(){
    if(this.form.get('jobType')!.value != 8){
      this.studentTest.findAllRaionByOblId​(this.form.get('eduInstituteOblId')?.value)
      .subscribe((res: any) => {
        this.raionList = [];
        this.raionList = res;
      }, (err: any) => {
     });
    }
  }
  
  getAllSchoolByOblIdAndRaionId(){
    if(this.form.get('jobType')!.value != 8){
      this.studentTest.finAllSchoolByOblIdAndRaionId(this.form.get('eduInstituteOblId')?.value, this.form.get('raionId')?.value)
      .subscribe((res: any) => {
        this.collegList = [];
        this.collegList = res;
      }, (err: any) => {
     });
    }
    }
}
