<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<div *ngIf="showBlockModal" class="w-full h-screen bg-black top-0 left-0 z-20 bg-opacity-25 fixed"></div>
<div *ngIf="showBlockModal" class="text-center border-2 p-4 top-0 left-0 z-20 realy-delete fixed z-30 w-1/3 bg-white">
    <div class="mb-2 text-xl">
         {{ 'BLOCK' | translate }}
    </div>

    <div class="flex">
        <button (click)="blockUserOper()" class="w-56 mx-10 mb-4 rounded-lg btn btn-outline-primary btn-lg btn-block bg-red-500 p-1 text-white"> {{ 'block' | translate }} </button>
        <button (click)="showBlockModal = !showBlockModal" class="w-56 mx-10 mb-4 rounded-lg btn btn-outline-primary btn-lg btn-block bg-blue-300 p-1"> {{'Cancellation' | translate }} </button>
    </div>
</div>

<form [formGroup]="form" action=""> 
    <div class="text-gray-900 bg-gray-200">
        <div class="w-auto my-2 text-center text-3xl font-hairline uppercase tracking-widest">
            {{ 'ListOfOperators' | translate }} 
         </div>

         <div class="flex"> 
          <div class="flex p-3">
            <label for="iins" class="cursor-pointer w-auto"> {{ 'SearchByIIN' | translate }} </label>
            <input formControlName="iin" id="iin" class="rounded-lg bg-white ml-3" type="text" placeholder="ИИН">
        </div>
         </div>

         <div class="flex">
           <p class="ml-3">
              {{ 'UserStatus' | translate }}
           </p>
           <select class="rounded-lg ml-2" value="0" (change)="changeFormList()" formControlName="users"> 
             <option value="0" disabled selected>
                {{ 'userList' | translate }}
             </option>
             <option value="1">
              {{ 'showActiveUsers' | translate }}
             </option>
             <option value="2">
              {{ 'showBlockUsers' | translate }}
            </option>
           </select>
         </div>
         <button (click)="poiskIin()" type="button" class="cursour-pointer w-40 my-2 bg-gray-300 hover:bg-gray-300 rounded-lg lg:px-3 py-1 ml-2 text-grey-dark text-sm">
          {{ 'Search' | translate }}
     </button>
        
    <div class="px-3 py-4 flex"> 
        <table class="min-w-full divide-y divide-gray-200">
            <thead class="">
              <tr> 
                
                <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
                   {{ 'firstName' | translate }} 
                </th>
                <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
                   {{ 'lastName' | translate }} 
                </th>
                <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
                   {{ 'patronymic' | translate }} 
                </th>
                <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
                  <!-- {{ 'ExamResult1' | translate }} --> Email
                </th>
                <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
                   {{ 'IIN' | translate }} 
                </th>
                <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
                   {{ 'Status' | translate }} 
                </th>
                <th scope="col" class="w-40 py-3 text-center text-xs font-medium text-gray-500 uppercase">
                   {{ 'Actions' | translate }} 
                </th>
                
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              
              <tr *ngFor="let users of allUsersList"> 
                <td  class="px-6 text-center py-4">
                  <div class="text-sm text-gray-500"></div>
                  {{ users.firstname }}
                </td>

                <td class="px-6 text-center py-4">
                  <div class="text-sm text-gray-500"></div>
                  {{ users.lastname }}
                </td>

                <td class="px-6 text-center py-4">
                    <div class="text-sm text-gray-500"></div>
                    {{ users.patronymic }}
                </td>

                <td class="px-6 text-center py-4">
                    <div class="text-sm text-gray-500"></div>
                    {{ users.email }}
                </td>

                <td class="px-6 text-center py-4">
                    <div class="text-sm text-gray-500"></div>
                    {{ users.iin }}
                </td>

                <td class="px-6 text-center py-4">
                  <div class="text-sm text-gray-500"></div>
                  {{ users.userStatusType.descr }}
                </td>

                <td class="py-4 text-center text-center text-sm font-medium">
                  <span [routerLink]="['/main/edit-users']" [queryParams]="{ userId: users.id}" 
                      class="text-indigo-600 mx-3 rounded-full w-14 cursor-pointer hover:text-indigo-900">Редактировать
                  </span>
                  <span class="text-indigo-600 mx-3 rounded-full w-14 cursor-pointer hover:text-indigo-900" 
                      [ngClass]="{'text-gray-500': users.userStatusType.id != 1, 'text-indigo-600': users.userStatusType.id != 2}">
                      <button [disabled]="users.userStatusType.id != 1" (click)="showModal(users.id)"> {{ 'blocked' | translate }} </button>
                  </span>
                  <span class="text-indigo-600 mx-3 rounded-full w-14 cursor-pointer hover:text-indigo-900" 
                      [ngClass]="{'text-gray-500': users.userStatusType.id != 2, 'text-indigo-600': users.userStatusType.id != 1}">
                      <button [disabled]="users.userStatusType.id != 2" (click)="unblockUserOper(users.id)"> {{ 'unBlocked' | translate }} </button>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
       </div>
    </div>
</form>