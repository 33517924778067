import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormArray, FormControl, ReactiveFormsModule, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Routes } from '@angular/router';
import { RefControllerService } from '../services/ref-controller.service';
import { AutheticationControllerService } from '../services/authetication-controller.service';
import { StudentTestControllerService } from '../services/student-test-controller.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit, OnDestroy {

  auth: any;
  token: any;
  infoUser: any;
  authorizeToken: any;
  authUserId!: number;
  firstname!: string;
  username: any;
  lastname!: string;
  patronymic!: string;
  email!: string;
  iin!: string;
  role: any;
  public loading = false;
  form!: FormGroup;
  testOrgName: any;
  testOrgId: any;
  permissions: any;

  constructor(
    private autheticationControllerService: AutheticationControllerService,
    private refControllerService: RefControllerService,
    private route: ActivatedRoute,
    public router: Router,
    private studentTest: StudentTestControllerService) { }

  ngOnDestroy(): void {
    this.windowClose();
  }

  ngOnInit(): void {

    this.username = localStorage.getItem('username');

    this.getLocal();
    this.operatorToken();
    localStorage.setItem('Lang', 'ru');

    if (localStorage.getItem('role') == 'ROLE_ADMIN') {
      this.role = 'admin';
    }

    if (localStorage.getItem('role') == 'ROLE_OPERATOR_NCT') {
      this.role = 'operatorNqt';
    }

    if (localStorage.getItem('role') == 'ROLE_OPERATOR') {
      this.role = 'operator';
    }

    if (localStorage.getItem('token') == undefined || localStorage.getItem('token') == 'null') {
      if (this.route.snapshot.queryParamMap.get('token') != null) {
        this.token = this.route.snapshot.queryParamMap.get('token');
      } else {
        // window.location.href = `https://auth.testcenter.kz/auth?link=` + window.location.href;
        window.location.href = environment.url + window.location.href;
      }
    }

    this.getLocal();
    this.operatorToken();

    let tok: any = localStorage.getItem('token');
  }

  getLocal() {
    this.studentTest.getLocalHost()
      .subscribe((res: any) => {
        localStorage.setItem('userInfoToken', res.token);
      }, (err: any) => {
        this.router.navigate(['/appnct']);
      });
  }

  operatorToken() {
    if (this.route.snapshot.queryParamMap.get('token') != null) {
      if (localStorage.getItem('token') == null) {
        this.studentTest.auth(this.route.snapshot.queryParamMap.get('token')!)
          .subscribe((res: any) => {
            localStorage.setItem('token', res.token);
            localStorage.setItem('role', res.userRole);
            localStorage.setItem('authToken', this.route.snapshot.queryParamMap.get('token')!);
            localStorage.setItem('username', res.username);
            localStorage.setItem('testOrgName', res.testOrgName);
            localStorage.setItem('testOrgId', res.testOrgId);
            localStorage.setItem('permissions', res.permissions);

            this.permissions = localStorage.getItem('permissions');
            this.testOrgId = localStorage.getItem('testOrgId');
            this.testOrgName = localStorage.getItem('testOrgName');
            this.username = localStorage.getItem('username');
            window.location.reload();
          }, (err: any) => {
            if (err.status == 403) {
              localStorage.clear();
              this.router.navigate(['/unauthorized']);
            }

          });
      }
    } else {
      if (localStorage.getItem('token') == null) {
      }
    }
  }

  logOut() {
    this.studentTest.exit()
      .subscribe((res: any) => {
        localStorage.clear();
        window.location.href = './';
      }, (err: any) => {
      });
  }

  windowClose() {
    window.localStorage.removeItem('userInfoToken');
    window.localStorage.removeItem('token');
    window.localStorage.clear();
  }
}
