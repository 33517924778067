import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, FormArrayName } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { StudentTestControllerService } from '../services/student-test-controller.service'; 
import { apiRoutes } from '../config/api.routes';
import { NotificationsService, NotificationType } from 'angular2-notifications';

import { TranslateService } from '@ngx-translate/core';
import { AnimationQueryMetadata } from '@angular/animations';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {
  public loading = false;
  iin!: number;
  auth: any;
  reg: any;
  lastname!: string;
  firstname!: string;
  patronymic!: string;
  email!: string;
  role: any; 
  userRole: any;
  userRoleId: any;
  orgTypeId!: number;
  code: any;
  descr: any;
  roleList: any;
  addPermissions: any;
  addUser: any;
  operData: any;
  authUserId: any;
  checked!: boolean;
  permissions = [];
  permissionsList: any;
  permissionsGroupId: any;
  spisokUserPermision = Array<any>();
  oblList: any;
  vptList: any;
  testVptId: any;
  testVptList: any;

  formAdd!: FormGroup;

  constructor(
    private studentTest: StudentTestControllerService, 
    private router: Router,  
    private _notifications: NotificationsService,
    private route: ActivatedRoute, 
    private apiRoutes: apiRoutes, 
    public translate: TranslateService) { }

  ngOnInit(): void {

    if(localStorage.getItem('role') == 'ROLE_ADMIN'){
      this.role = 'admin';
    }
    
    if(localStorage.getItem('role') == 'ROLE_OPERATOR_NCT'){
      this.role = 'operatorNqt';
    }


    this.formAdd = new FormGroup({
      iin: new FormControl(),
      lastname: new FormControl(),
      firstname: new FormControl(), 
      patronymic: new FormControl(),
      email: new FormControl(),
      // oblId: new FormControl(),
      testVptId: new FormControl(),
      userRoleId: new FormControl(0),
      permissions: new FormArray ([]), 
  });

  this.allUserRole(); 
  }

  get permissionsArray(): any {return this.formAdd.get('permissions') as FormArray}


  permissionSaveUser(id: any, descr: any, checked: boolean = false,): FormGroup { 
    return new FormGroup({
      id: new FormControl(id),
      descr: new FormControl(descr),
      checked: new FormControl(checked),
    })
  }

  allUserRole(){
    this.studentTest.findAllUserRole()
    .subscribe((res: any) => {
      this.roleList = res;
    }, (err: any) => {
   });
  }

  addOperators(){
    this.loading = true;
    this.studentTest.addUser(this.formAdd.value)
    .subscribe((res: any) => {
      this.loading = false;
      this.translate.currentLang == 'ru' ? this._notifications.create('Успешно', 'Оператор добавлен', NotificationType.Success, 5000) : 
      this._notifications.create('Сәтті', '', NotificationType.Success, 5000);
      this.findAllUsers();
      this.operData = res;
      this.permissions = [];
      this.permissions = res;
      this.router.navigate(['/main/operators']);
    }, (err: any) => {
      this.loading = false;
      this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) : 
      this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
    });
  }

  findAllUsers(){
    this.studentTest.findAllUsers()
    .subscribe((res: any) => {
      this.operData = [];
      this.operData = res;
    }, (err: any) => {
   });
  } 

  poiskIin(){
    this.loading = true;
    let iin = this.formAdd.get('iin')!.value;
    this.studentTest.getProfileInfoForAddUser(iin)
      .subscribe((res: any) => {
        this.loading = false;
        this.formAdd.get('lastname')!.setValue(res.lastname);
        this.formAdd.get('firstname')!.setValue(res.firstname);
        this.formAdd.get('patronymic')!.setValue(res.patronymic);
        this.formAdd.get('email')!.setValue(res.email);
      }, (err: any) => {
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) : 
        this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
     });
  }

  getAllTestPeriodByTestVptId(){
    this.studentTest.findAllTestPeriodByTestVptId(this.formAdd.get('testVptId')!.value)
    .subscribe((res: any) => {
      this.testVptList = [];
      this.testVptList = res; 
      
      
    }, (err: any) => {
   });
  }

  findAllVpt(code: any){
    this.studentTest.findAllVpt()
      .subscribe((res: any) => {
        this.vptList = [];
        this.vptList = res;
        if(code == 0){
          this.formAdd.get('testVptId')!.setValue(0);
        }else{
          this.formAdd.get('testVptId')!.setValue(this.testVptId);
        }
      }, (err: any) => {

      });
  }

  formsOfUserRole(){
    this.spisokUserPermision.forEach((element: any)  => {
      this.permissionsArray.push(this.permissionSaveUser(
        element.id,
        element.userPermissionType,
        element.checked
        ));
    });
  }

  getPermission(){
    this.permissionsArray.controls = [];
    this.studentTest.getPermissionsByUserRole(this.formAdd.get('userRoleId')!.value) 
    .subscribe((res: any) => {
      this.spisokUserPermision = res;
      this.formsOfUserRole();
    }, (err: any) => {
   });
  }

  showAuth(){
    this.addOperators();
    this.allUserRole();
    this.reg = false;
    this.auth = true;
  }

}