<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<div>
    <form [formGroup]="formAdd">
        <div class="container w-3/5 mx-auto ">
            <div class="mx-auto bg-white rounded shadow">
                <div class="mx-16 py-4 px-8 text-black text-center text-xl font-bold border-b border-grey-500">
                    {{ 'OperatorData' | translate }}
                </div>

                <div class="py-4 px-8">
                    <div class="mb-4 relative">
                        <label class="block text-grey-darker text-sm font-bold mb-2"> {{ 'IIN' | translate }} </label>
                        <input formControlName="iin" class=" border rounded w-full py-2 px-3 text-grey-darker" type="text" placeholder="Введите ИИН">
                    </div>

                    <div class="mb-4">
                        <label class="block text-grey-darker text-sm font-bold mb-2"> {{ 'lastname' | translate }} </label>
                        <input readonly formControlName="lastname" class="border rounded w-full py-2 px-3 text-grey-darker" type="text">
                    </div>

                    <div class="mb-4">
                        <label class="block text-grey-darker text-sm font-bold mb-2"> {{ 'firstname' | translate }} </label>
                        <input readonly formControlName="firstname" class="border rounded w-full py-2 px-3 text-grey-darker" type="text">
                    </div>

                    <div class="mb-4">
                        <label class="block text-grey-darker text-sm font-bold mb-2"> {{ 'patronymic' | translate }} </label>
                        <input readonly formControlName="patronymic" class="border rounded w-full py-2 px-3 text-grey-darker" type="text">
                    </div>

                    <div class="mb-4">
                        <label class="block text-grey-darker text-sm font-bold mb-2"> {{ 'email' | translate }} </label>
                        <input readonly formControlName="email" class="border rounded w-full py-2 px-3 text-grey-darker" type="text" value="">
                    </div>

                    <div class="mb-4">
                        <label class="block text-grey-darker text-sm font-bold mb-2"> {{ 'role' | translate }} </label>
                        <select (change)="getPermission()" (change)="findAllVpt(0)" formControlName="userRoleId" class="border rounded w-full py-2 px-3 text-grey-darker">
                            <option value="0" disabled> {{ 'role' | translate }} </option>
                            <option [value]="user.id" *ngFor="let user of roleList">
                                {{user.descr}}
                            </option>
                        </select>
                    </div>
                    <div *ngIf="this.formAdd.get('userRoleId')!.value == 4" class="mb-4">
                        <label class="block text-grey-darker text-sm font-bold mb-2">ППЕНТ</label>
                        <select formControlName="testVptId" class="border rounded w-full py-2 px-3 text-grey-darker">
                            <option [value]="0" disabled selected> ППЕНТ </option>
                            <option [value]="user.vptId" *ngFor="let user of vptList"> 
                                <span *ngIf="translate.currentLang == 'kz'"> {{ user.codeId }} - {{ user.nameKaz }} </span>
                                <span *ngIf="translate.currentLang == 'ru'"> {{ user.codeId }} - {{ user.nameRus }} </span>
                            </option>
                        </select>
                    </div> 
                </div>
            </div>
        </div>

        <div *ngIf="this.formAdd.get('userRoleId')!.value != 0" class="p-10 w-full">
           
            <div class="flex mb-4">
            <div class="w-1/5 h-1 bg-blue-300 mt-4">
            </div>
            <div class="w-full text-center text-3xl font-hairline uppercase tracking-widest">
                {{ 'AccessRightsForAccompanyingTestTakers' | translate }}
             </div>
             <div class="w-1/5 h-1 bg-blue-300 mt-4">
             </div>
            </div>

            <div class="mt-12 p-6 box">
                <div formArrayName="permissions" *ngFor="let permission of spisokUserPermision; index as i">
                    <div *ngIf="permission.permissionsGroupId == 1">
                        <div [formGroup]="permissionsArray.controls[i]">
                            <input formControlName="checked" class="mr-1 w-4 h-4" [value]="permission.checked" type="checkbox">
                            <label>
                                <span *ngIf="translate.currentLang == 'kz'"> - {{ permission.nameKaz }} </span>
                                <span *ngIf="translate.currentLang == 'ru'"> - {{ permission.nameRus }} </span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="formAdd.get('userRoleId')!.value != 4" class="flex mb-4"> 
                <div class="w-1/5 h-1 bg-blue-300 mt-4">
                </div>
                <div class="w-full text-center text-3xl font-hairline uppercase tracking-widest">
                    {{ 'AccessRightsForMaintenanceOfSystemUsers' | translate }}
                </div>
                <div class="w-1/5 h-1 bg-blue-300 mt-4">
                </div>
            </div>

            <div class="mt-12 p-6 box">
                <div formArrayName="permissions" *ngFor="let permission of spisokUserPermision; index as i">
                    <div *ngIf="permission.permissionsGroupId == 2">
                        <div [formGroup]="permissionsArray.controls[i]">
                            <input formControlName="checked" class="mr-1 w-4 h-4" [value]="permission.checked" type="checkbox">
                            <label>
                                <span *ngIf="translate.currentLang == 'kz'"> - {{ permission.nameKaz }} </span>
                                <span *ngIf="translate.currentLang == 'ru'"> - {{ permission.nameRus }} </span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div *ngIf="formAdd.get('userRoleId')!.value != 0" class="flex mb-4"> 
            <button  (click)="updateOperators()"
                class="font-serif mb-2 w-full rounded-full py-1 text-center px-24 bg-blue-300 rounded-full text-grey-darker">
                {{ 'save' | translate }}
                <!-- [routerLink]="['/main/operators']" -->
            </button> 
            <button [routerLink]="['/main/operators']" class="font-serif mb-2 w-full rounded-full py-1 text-center px-24 bg-red-400 rounded-full text-grey-darker">
                 {{ 'Cancellation' | translate }}
            </button>
        </div>
</form>