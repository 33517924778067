import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, ReactiveFormsModule, FormGroup, Validators, FormArrayName, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute,  } from '@angular/router';
import { RefControllerService } from '../services/ref-controller.service';
import { AutheticationControllerService } from '../services/authetication-controller.service';
import { StudentTestControllerService } from '../services/student-test-controller.service';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-stream-search',
  templateUrl: './stream-search.component.html',
  styleUrls: ['./stream-search.component.css']
})
export class StreamSearchComponent implements OnInit {

  constructor(
    private autheticationControllerService: AutheticationControllerService,
    private refControllerService: RefControllerService,
    public route: ActivatedRoute,
    public router: Router,
    private studentTest: StudentTestControllerService,
    public translate: TranslateService,
    private _notifications: NotificationsService) {}

    public loading = false;
    form!: FormGroup;
    formPeresad!: FormGroup;
    formDate!: FormGroup;
  formStatus!: FormGroup;
  setStudentStatus: any;
  statusList: any;
  studentData: any;
  studentList: any;
  studentInfo: any
  updateStatus: any;
  userInfoToken = localStorage.getItem('userInfoToken');
  linkToken = localStorage.getItem('token');
  nameRus: any;
  sort: any;
  showPeresadka = false;
  studentId: any;
  iin: any;
  firstName: any;
  withStatusNeYavilsya = false;
  lastName: any;
  isChecked = false;
  patronymic: any;
  date: any;
  testOrgId: any;
  time: any;
  phoneNumber: any;
  login: any;
  password: any;
  testPeriodId: any;
  testVptList: any;
  studenId: any;
  studentTestId: any;
  resetSessionTypeId: any;
  showModal = false;
  id: any;
  showConfirmation = false;
  langName: any;
  testStatus: any;
  subjects: any;
  arrayStud: any;
  jobTypeList: any;
  statusId: any;
  pstList: any;
  isAllChecked = false;
  vptId: any;
  resetByTestVpt = false;
  vptList: any;
  role: any;
  activeModal: any;
  studentTestList: any = [];
  showStatus = false;
  testVptId: any;
  sessionList: any;
  timel: any;
  searchChecker = 0;
  currentPageArray: any = [];
  checkedCount = 0;
  iinMask = '000000000000';
  today = new Date();
  currentDay: any;
  showPass = true;
  ppentName: any;
  vptLists: any;
  roomId: any;
  roomPlaceNumber: any;
  p: number = 1;
  testVptLists: any;
  size: any;
  allsize: any;
  onePageSize: any;

  ngOnInit(): void {


    this.currentDay =  this.today.getFullYear() + '-' + (this.today.getMonth() + 1) + '-' + this.today.getDate();

    if(localStorage.getItem('role') == 'ROLE_ADMIN'){
      this.role = 'admin';
    }

    if(localStorage.getItem('role') == 'ROLE_OPERATOR_NCT'){
      this.role = 'operatorNqt';
    }

    if(localStorage.getItem('role') == 'ROLE_OPERATOR'){
      this.role = 'operator';
    }

    this.form = new FormGroup({
      studentTestList: new FormArray([]),
      iin: new FormControl(0),
      testPeriodId: new FormControl(),
      sort: new FormControl(0),
      roomId: new FormControl(),
      resetSessionTypeId: new FormControl(7),
      showPass: new FormControl(false),
      roomPlaceNumber: new FormControl(),
      testVptId: new FormControl(),
      resetByTestVpt: new FormControl(false),
      size: new FormControl(),
      onePageSize: new FormControl(),
      allsize: new FormControl(),

    });

    this.formPeresad = new FormGroup({
      testVptId: new FormControl(),
      testPeriodId: new FormControl(),
    });

    this.formDate = new FormGroup({
      date: new FormControl(),
      time: new FormControl(),
    });

     this.getAllVpt();
     this.getTime();
     this.form.get('testVptId')!.setValue(0);
     this.form.get('testPeriodId')!.setValue(0);
  }



  getTestStatus(){
    this.studentTest.findSomeTestStatusById()
      .subscribe((res: any) => {
        this.statusList = res;
        this.showStatus = true;
      }, (err: any) => {
    });
  }

  getStudentData(studentTestId: any) {
    this.showConfirmation = false;
    this.studentTest.getLoginAndPasswordOfStudent(studentTestId)
      .subscribe((res: any) => {
        this.studentData = res.studentTestId;
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
        this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
     });
  }

  showModalWind() {
    let checkedCount: number = 0;
    let studentListStudentId: number = 0;
    this.studentList.forEach((element: any) => {
      if(element.isChecked == true){
        checkedCount = checkedCount + 1;
        studentListStudentId = element.studentTestId;
      }
    });

    if(checkedCount != 1){
      this.showModal = false;
    }else{
      this.showModal = true;
      this.studentTest.getLoginAndPasswordOfStudent(studentListStudentId)
      .subscribe((res: any) => {
        this.login = res.login;
        this.password = res.password;
      }, (err: any) => {
      });
    }
  }

  findStudent() {
      this.poiskPotok();
      this.isAllChecked = false;
      this.currentPageArray = [];
      this.studentTestId = 0;
      this.testVptId = 0;
      this.testPeriodId = 0;
      this.checkedCount = 0;
      this.size;
  }

 allTestStatus(){
   this.studentTest.getAllTestStatus()
   .subscribe((res: any) => {
    this.statusId = res;
   }, (err: any) => {
  });
 }

  updateStudents(studentTestId: any) {
    this.router.navigate(['/main/student-test'], { queryParams: { id: studentTestId, studentId: studentTestId} })
  }

  getCurrentPage(){
    this.currentPageArray = [];
    let currentFirstElement: any = (this.p * 20) - 20;
    for(let i = 0; i < 20; i++){
      if(this.studentList[currentFirstElement + i] != undefined){
        this.currentPageArray.push(this.studentList[currentFirstElement + i]);
      }
    }
  }

  checkPage(event: any){
    this.p = event;
    this.getCurrentPage();
    let counter: number = 0;
    // this.studentList.forEach((element: any) => {
    //   if(element.isChecked == true){
    //     element.isChecked = false;
    //   }
    // });
    if(counter == 10){
      this.isAllChecked = true;
    }else{
      this.isAllChecked = false;
    }
  }

  checkAllOptions() {
    this.getCurrentPage();
    if(!this.isAllChecked){
      this.studentList.forEach((element: any) => {
            element.isChecked = true;
            element.disabled = true;
          if(this.isAllChecked = true){
            this.checkedCount = 0;
          }
        });
      this.isAllChecked = true;
    }else{
      this.studentList.forEach((element: any) => {
            element.isChecked = false;
            element.disabled = false;
    });
      this.isAllChecked = false;
    }
  }

  checkStudent(event: any, student: any){
    this.checkedCount = 0;
    if (event.target.checked == true) {
      for( let i = 0; i < this.studentList.length; i++){
        if ( this.studentList[i] === student) {
          this.studentList[i].isChecked = true;
        }
      }
    }else{
      for( let i = 0; i < this.studentList.length; i++){
        if ( this.studentList[i] === student) {
          this.studentList[i].isChecked = false;

        }
      }
    }
    this.studentList.forEach((element: any) => {
      if(element.isChecked){
        this.checkedCount++;
      }
    });
    this.studentList.forEach((element: any) => {
      if(element.isChecked == false){
    this.isAllChecked = false;

      }
  });
  }

  get studentsId(): any { return this.form.get('studentTestList') as FormArray}

  idOfStudent(studentId: number) {
    return new FormControl(studentId);
  }

  showModalStatus(){
    this.studentList.forEach((element: any) => {
      if(element.isChecked == true){
        this.studentsId.push(this.idOfStudent(element.studentTestId));
      }
    });
    this.getTestStatus();
  }

  updateStudentStatus(){
    this.studentTest.setStatus(this.formStatus.value)
    .subscribe((res: any) => {
    },(err: any) => {
   });
  }

  getAllVpt(){
    this.studentTest.findAllVpt()
    .subscribe((res: any) => {
      this.vptList = [];
      this.vptList = res;
      console.log(this.vptList = res);

    }, (err: any) => {
   });
  }

  getAllTestPeriodByTestVptId(code: any){
    this.studentTest.findAllTestPeriodByTestVptId(this.form.get('testVptId')!.value)
    .subscribe((res: any) => {
      this.testVptList = res;
      // this.studentList = [];
      if(code == 0){
        this.form.get('testPeriodId')!.setValue(0);
      }
      this.vptList.forEach((element: any) => {
        if(element.vptId == this.form.get('testVptId')!.value){
          if(this.translate.currentLang == 'kz'){
            this.ppentName = element.codeId + '-' + element.nameKaz
          }else{
            this.ppentName = element.codeId + '-' + element.nameRus
          }
        }
      });
    }, (err: any) => {
   });
  }

  getAllVpts(code: any){
      this.vptLists = [];
      this.studentTest.findAllVpts()
    .subscribe((res: any) => {
      this.vptLists = res;
      if(code == 0){
        this.testVptId = 0;
      }else{
        this.testVptId = this.form.get('testVptId')!.value;
      }

    }, (err: any) => {
   });
  }

  getAllTestPeriodByTestVptIds(code: any){
    this.testOrgId = this.testVptId
    this.testVptLists = [];
    this.studentTest.findAllTestPeriodByTestVptIds(this.testOrgId)
    .subscribe((res: any) => {
      this.testVptLists = res;
      if(code == 0){
        this.testOrgId = 0;
      }else{
        this.testOrgId = this.form.get('testPeriodId')!.value;
      }
      // if(code == 0){
      //   this.form.get('')!.setValue(0);
      // }
      // this.vptLists.forEach((element: any) => {
      //   if(element.vptId == this.form.get('testVptId')!.value){
      //     if(this.translate.currentLang == 'kz'){
      //       this.ppentName = element.vptId + '-' + element.nameKaz
      //     }else{
      //       this.ppentName = element.vptId + '-' + element.nameRus
      //     }
      //   }
      // });
    }, (err: any) => {
   });
  }

  refresh(){
    this.studentList = [];
    this.allsize = 0;
  }

 poiskPotok(){
  this.loading = true;
  if(this.form.get('iin')!.value != 0){
    this.studentTest.findStudentTestByTestVptIdAndTestPeriodWithIin(this.form.get('testVptId')!.value, this.form.get('testPeriodId')!.value, this.form.get('iin')!.value)
      .subscribe((res: any) => {
        this.loading = false;
        this.studentList = [];
        this.studentList = res.studentDtoList;
        this.studentList.forEach((element: any) => {
          element.isChecked = false;
        });
        this.lastName = res.lastName;
        this.firstName = res.firstName;
        this.patronymic = res.patronymic;
        this.phoneNumber = res.phoneNumber;
        this.langName = res.langName;
        this.subjects = res.subjects;
        this.testStatus = res.testStatus;
        this.size = res.size;
        this.allsize = res.size;
        this.onePageSize = res.size;
      }, (err: any) => {
        this.loading = false;
        this.studentList = [];
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
        this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
      });
  }else{
    this.studentTest.findStudentTestByTestVptIdAndTestPeriod(this.form.get('testVptId')!.value, this.form.get('testPeriodId')!.value)
      .subscribe((res: any) => {
        this.studentList = [];
        this.studentList = res.studentDtoList;
        this.studentList.forEach((element: any) => {
          element.isChecked = false;
        });
        this.lastName = res.lastName;
        this.firstName = res.firstName;
        this.patronymic = res.patronymic;
        this.phoneNumber = res.phoneNumber;
        this.langName = res.langName;
        this.subjects = res.subjects;
        this.testStatus = res.testStatus;
        this.size = res.size;
        this.allsize = res.size;
        this.onePageSize = res.size;
      }, (err: any) => {
        this.studentList = [];
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
        this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
      });
    }
  }

  getTime(){
    this.studentTest.findTestTime()
    .subscribe((res: any) => {
      this.timel = res;
    }, (err: any) => {

    });
  }

  downloadPdf(){
    this.studentTest.getLandingLeafList(this.form.get('testVptId')?.value,
    this.form.get('testPeriodId')?.value,
    this.linkToken,
    this.userInfoToken,
    this.form.get('sort')?.value,
    this.showPass)
    .subscribe((res: any) => {
      const blob = new Blob([res], {type: 'application/*'});
        const data = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = data;
        this.studentTest.findAllVpt()
        .subscribe((res: any) => {
          this.vptList = [];
          this.vptList = res;
        }, (err: any) => {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
          this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
        });
        link.download = this.ppentName + '.pdf';
        link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
        setTimeout(function() {
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
    }, async (err: any) => {
      const message = JSON.parse(await err.error.text()).message;
      this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', message, NotificationType.Error, 5000) :
      this._notifications.create('Қате', message, NotificationType.Error, 5000);
      this.loading = false;
    });
  }

  getLandingLeafListArchive(){
    this.studentTest.getLandingLeafListArchive(this.linkToken, this.userInfoToken, this.form.get('sort')!.value, this.formDate?.value)
    .subscribe((res: any) => {
      const blob = new Blob([res], {type: 'application/*'});
        const data = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = data;
        link.download = 'posadlist.zip';
        link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
        setTimeout(function() {
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
    }, async (err: any) => {
      const message = JSON.parse(await err.error.text()).message;
      this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', message, NotificationType.Error, 5000) :
      this._notifications.create('Қате', message, NotificationType.Error, 5000);
      this.loading = false;
    });
  }

  downloadExcel(){
    this.studentTest.getLandingLeafListExcel(this.form.get('testVptId')?.value,
    this.form.get('testPeriodId')?.value,
    this.linkToken,
    this.userInfoToken,
    this.form.get('sort')?.value,
    this.showPass)
    .subscribe((res: any) => {
      const blob = new Blob([res], {type: 'application/*'});
        const data = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = data;
        link.download = 'posadlist.xls'; //xlsx
        link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
        setTimeout(function() {
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
    }, async (err: any) => {
      const message = JSON.parse(await err.error.text()).message;
      this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', message, NotificationType.Error, 5000) :
      this._notifications.create('Қате', message, NotificationType.Error, 5000);
      this.loading = false;
    });
  }

  resetSessionWindow(code: number){
    let checkedCount: number = 0;
    this.studentList.forEach((element: any) => {
      if(element.isChecked == true){
      checkedCount = checkedCount + 1;
      }
    });
    if(checkedCount == 0){
      this.showStatus = false;
    }else{
      this.showStatus = true;
    }
  }

  getResetSession(){
    this.studentList.forEach((element: any) => {
      if(element.isChecked == true){
        this.studentsId.push(this.idOfStudent(element.studentTestId));
      }
    });
    this.studentTest.resetSession(this.form.value)
    .subscribe((res: any) => {
      this.translate.currentLang == 'ru' ? this._notifications.create('Успешно', res.response, NotificationType.Success, 5000) :
      this._notifications.create('', res.response, NotificationType.Success, 5000);
      this.showStatus = false;
      this.findStudent();
    }, (err: any) => {
      this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
      this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
    });
  }

  transferStudentTest(){
    if(
      this.testVptId == 0 ||
      this.testPeriodId == 0
      ){
      this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', 'Не все данные заполнены', NotificationType.Error, 5000) :
      this._notifications.create('Қате', 'Не все данные заполнены', NotificationType.Error, 5000);
    }else{
      let list =  [{
          studentTestId: this.studentTestId,
          testOrgId: this.testVptId,
          testPeriodId: this.testPeriodId,
          roomId: this.roomId,
          roomPlaceNumber: this.roomPlaceNumber
        }]
    this.studentTest.transferStudentTest(this.withStatusNeYavilsya, list)
    .subscribe((res: any) => {
      this.translate.currentLang == 'ru' ? this._notifications.create('Успешно', 'Пересадка прошла успешно', NotificationType.Success, 5000) :
      this._notifications.create('Сәтті', 'Техникалық хатшы қосылды', NotificationType.Success, 5000);
    }, (err: any) => {
      this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
      this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
    });
  }
  }

  peresadka(){
    this.showPeresadka = false;
    let data: any = [];
  this.studentList.forEach((element: any) => {
    if(element.isChecked == true ){
      data.push(
        {
          studentTestId: element.studentTestId,
          testOrgId: this.testVptId,
          testPeriodId: this.testPeriodId
        }
      )
    }
  });
  this.studentTest.transferStudentTest(this.withStatusNeYavilsya, data)
    .subscribe((res: any) => {
      this.findStudent();
this.translate.currentLang == 'ru' ? this._notifications.create('Успешно', 'Пересадка прошла успешно', NotificationType.Success, 5000) :
      this._notifications.create('Сәтті', 'Техникалық хатшы қосылды', NotificationType.Success, 5000);
      this.studentList = [];

    }, (err: any) => {
      this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
      this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
    });
}

  // peresadkaWindow(code: number){
  //   this.loading = true;
  //   let checkedCount: number = 0;
  //   this.studentList.forEach((element: any) => {
  //     if(element.isChecked == true, element.isAllChecked == true){
  //     checkedCount = checkedCount + 1;
  //     }
  //   });
  //   if(checkedCount == 0){
  //     this.showPeresadka = false;
  //   }else{
  //     this.showPeresadka = true;
  //   }
  // }

// punktList(code: any){
//   this.studentTest.punktList(this.form.get('jobTypeId')!.value)
// .subscribe((res: any) => {
//   this.pstList = [];
//   this.pstList = res.items;
//   if(code == 0){
//     this.form.get('testOrgId')!.setValue(0);
//   }else{
//     this.form.get('testOrgId')!.setValue(this.studentInfo.testOrgId);
//   }
// }, (err: any) => {
// });
// }

showModalPeresad(){
  this.getAllVpts(1);
  this.getAllTestPeriodByTestVptIds(1);
    this.testVptId = this.form.get('testVptId')!.value;
    this.testPeriodId = this.form.get('testPeriodId')!.value;
    let checkedCount: number = 0;
    let studentListStudentId: number = 0;
    this.studentList.forEach((element: any) => {
        checkedCount = checkedCount + 1;
        studentListStudentId = element.studentTestId;
        if(this.studentTestId == element.studentTestId){
          element.isAllChecked = true;
          element.isChecked = true;
        }
    });
      this.showPeresadka = true;

  }

  closePeresad(asd: any){
    this.showPeresadka = false
    // this.formPlace.reset();
  }

  studentTestListToExcel(){
    this.studentTest.studentTestListToExcel(this.form.get('testVptId')?.value,
    this.form.get('testPeriodId')?.value)
    .subscribe((res: any) => {
      const blob = new Blob([res], {type: 'application/*'});
        const data = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = data;
        link.download = 'Vygruzka spiska testiruemyh.xls'; //xlsx
        link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
        setTimeout(function() {
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
    }, async (err: any) => {
      const message = JSON.parse(await err.error.text()).message;
      this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', message, NotificationType.Error, 5000) :
      this._notifications.create('Қате', message, NotificationType.Error, 5000);
      this.loading = false;
    });
  }

}

