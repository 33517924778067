<form [formGroup]="formStatus" action="">
    <div (click)="showStatus = !showStatus" *ngIf="showStatus"
        class="w-full h-screen bg-black top-0 left-0 z-20 bg-opacity-25 fixed"></div>
    <div *ngIf="showStatus" class="border-2 p-4 top-100 h-auto left-100 z-20 classA fixed z-30 w-1/2 bg-white">
        <div class="text-3xl"> {{ 'ChangingTheStatusOfTheTestTaker' | translate }} </div>
        <div class="my-2 flex text-left">
            <label> {{ 'Status' | translate }} </label>
            <select formControlName="testStatusId"
                class="rounded-lg bg-gray-300 mr-20 flex-grow w-full px-3 lg:text-xs lg:text-base ml-3 text-white-500 font-thin">
                <option [value]="0" disabled selected> {{ 'SelectTestTakerStatus' | translate }} </option>
                <option *ngFor="let item of statusList"
                    [disabled]="formStatus.get('testStatusId')!.value == 7 && item.id == 7"
                    [value]="item.id">
                    <span *ngIf="translate.currentLang == 'kz'"> {{ item.nameKaz }} </span>
                    <span *ngIf="translate.currentLang == 'ru'"> {{ item.nameRus }} </span>
                </option>
            </select>
        </div>
        <div *ngIf="this.formStatus.get('testStatusId')!.value != 13 || this.formStatus.get('testStatusId')!.value != 2 || this.formStatus.get('testStatusId')!.value != 1"
            class="mt-32 flex">

            <div class="w-1/2">
                <button (click)="updateStudentStatus(showStatus=false)"
                    class="w-56 my-10 rounded-lg btn text-center btn-outline-primary text-white btn-lg btn-block bg-red-400 p-1">
                    {{ 'Change' | translate }}
                </button>
            </div>

            <div class="w-1/2">
                <button (click)="closeModalStatus()"
                    class="w-56 my-10 rounded-lg btn bg-blue-400 text-center text-white btn-outline-primary btn-lg btn-block p-1">
                    {{ 'Cancellation' | translate }}
                </button>
            </div>
        </div>
    </div>
</form>
<div class="flex">
    <div class="w-4/6 h-full shadow-md bg-white rounded-lg">
        <div class="flex mb-6">
            <div class="flex items-center w-3/5 h-20 border-2 px-14 bg-gray-50 rounded-md">
                <div>
                    <p>Выберите пероид</p>
                </div>
                <div class="flex items-center ml-4">
                    <p class="ml-2"> от </p>
                    <input [(ngModel)]="fromdate" type="date" class="bg-gray-200 rounded-lg border-2 ml-4 h-7 px-2">
                    <p class="ml-2"> до </p>  
                    <input [(ngModel)]="todate" type="date" class="bg-gray-200 rounded-lg border-2 ml-4 h-7 px-2">
                </div>
            </div>
            <div class="flex flex-col justify-center w-2/5 h-20 border-2 gap-2 px-4 bg-gray-50 rounded-md">
                <div class="flex items-center">
                    <p>Всего кол-во купленных попыток: </p><span class="bg-gray-200 ml-2 px-4 rounded-md">{{testers.length}}</span>
                </div>   
                <div class="flex items-center">
                    <p>Из них кол-во использованных попыток: </p><span class="bg-gray-200 ml-2 px-4 rounded-md">30</span>
                </div>
            </div>
        </div>
        <div class="text-gray-900 bg-gray-200 p-4 rounded-lg shadow-md">
                <form [formGroup]="form" class="p-4">
                    <div class="flex items-center mb-4">
                        <input type="radio" [value]="1"  class="mt-1 w-4" formControlName="searchType">
                        <label for="iins" class="ml-2 cursor-pointer mr-1 w-auto font-medium"> Поиск по ИИН </label>
                        <div *ngIf="form.get('searchType')?.value === 1">
                            <input type="text" class="rounded-lg bg-white ml-28 px-52 text-center" placeholder="ИИН" formControlName="iin">
                        </div>
                    </div>
                    <div class="flex items-center mt-4">
                        <input type="radio" [value]="2"  class="mt-1 w-4" formControlName="searchType">
                        <label for="fios" class="ml-2 cursor-pointer mr-1 w-auto font-medium"> Поиск по ФИО </label>
                        <div *ngIf="form.get('searchType')?.value === 2">
                            <input type="text" class="rounded-lg bg-white ml-28 text-center" placeholder="Фамилия" formControlName="surname">
                            <input type="text" class="rounded-lg bg-white ml-5 text-center" placeholder="Имя" formControlName="name">
                            <input type="text" class="rounded-lg bg-white ml-5 text-center" placeholder="Отчество" formControlName="patronymic">
                        </div>
                    </div>
                    <div class="flex items-center mt-4">
                        <input type="radio" [value]="3"  class="mt-1 w-4" formControlName="searchType">
                        <label for="email" class="ml-2 cursor-pointer w-auto font-medium"> Поиск по email </label>
                        <div *ngIf="form.get('searchType')?.value === 3">
                            <input type="text" class="rounded-lg bg-white ml-28 px-52 text-center" placeholder="Почта" formControlName="email">
                        </div>
                    </div>
                    <!--
                    <div class="flex items-center mt-4">
                        <input type="radio" [value]="4"  class="mt-1 w-4" formControlName="searchType">
                        <label for="zakaz" class="ml-2 cursor-pointer w-auto font-medium"> Поиск по номеру заказу </label>
                        <div *ngIf="form.get('searchType')?.value === 4">
                            <input type="text" class="rounded-lg bg-white ml-11 px-52 text-center" placeholder="Номер заказа" formControlName="numberzakaz">
                        </div>
                    </div>
                    -->
                    <div class="mt-3 ml-6">
                        <button type="button" (click)="onSearch()" class="bg-blue-400 hover:bg-blue-500 transition rounded-lg text-white px-6 py-1">Поиск</button>
                    </div>
                </form>
            
                <pagination-controls (pageChange)="p=$event" previousLabel="Назад" nextLabel="Вперед" (autoHide)="true" class="text-center"></pagination-controls>
                <div class="mt-6 bg-white w-auto m-auto p-4 shadow-md rounded-lg">
                    <table *ngIf="testers" class="min-w-full text-center border-collapse table-fixed">
                        <thead>
                            <tr>
                                <th class="px-14 py-3 border border-slate-600 text-sm leading-4 text-gray-500">ИКТ</th>
                                <th class="px-14 py-3 border border-slate-600 text-sm leading-4 text-gray-500">ИИН</th>
                                <th class="px-14 py-3 border border-slate-600 text-sm leading-4 text-gray-500">ФИО</th>
                                <th class="px-14 py-3 border border-slate-600 text-sm leading-4 text-gray-500">Статус тестируемого</th>
                                <th class="px-14 py-3 border border-slate-600 text-sm leading-4 text-gray-500">Вид</th>
                                <th class="px-14 py-3 border border-slate-600 text-sm leading-4 text-gray-500">Действия</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr (click)="getDataByIkt(tester.ikt)" *ngFor="let tester of testers | paginate: { itemsPerPage: 10, currentPage: p, totalItems: testers.length }; index as i" class="hover:bg-gray-100 transition">
                                <td class="border border-slate-700 px-4 py-2">{{tester.ikt}}</td>
                                <td class="border border-slate-700 px-4 py-2">{{tester.iin}}</td>
                                <td class="border border-slate-700 px-4 py-2">{{tester.fullName}}</td>
                                <td class="border border-slate-700 px-4 py-2">{{tester.studentTestStatusTypeNameRus}}</td>
                                <td class="border border-slate-700 px-4 py-2">{{tester.testTypeNameRus}}</td>
                                <td class="border border-slate-700 px-4 py-2">
                                    <div class="text-indigo-600 mx-3 rounded-full  cursor-pointer hover:text-indigo-900" (click)="showModalStatus(tester.ikt)">
                                        {{ 'ChangeStatus' | translate }}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <hr class="mt-6">
                    <!--
                    <div class="text-center mt-4">
                        <button type="button" class="bg-green-500 hover:bg-green-600 transition text-white rounded-lg px-6 py-2">
                            Отказ от тестирования. Возврат оплаченной суммы
                        </button>
                    </div>
                -->
                </div>
            
        </div>
    </div>
    <div *ngIf="dataByIkt" class="w-2/6 h-full border-2 p-6 bg-white rounded-lg shadow-lg ml-2">
        <p class="font-bold text-lg mb-4 border-b pb-2">Результаты тестирования</p>
        <div class="mb-4">
            <p class="text-gray-700 font-semibold">ФИО:</p>
            <p class="text-gray-900">{{dataByIkt.studentTest.fullName}}</p>
        </div>
        <div class="mb-4">
            <p class="text-gray-700 font-semibold">ИИН:</p>
            <p class="text-gray-900">{{dataByIkt.studentTest.iin}}</p>
        </div>
        <div class="mb-4">
            <p class="text-gray-700 font-semibold">ИКТ:</p>
            <p class="text-gray-900">{{dataByIkt.studentTest.ikt}}</p>
        </div>
        <div class="mb-4">
            <p class="text-gray-700 font-semibold">Предметы и баллы:</p>
            <div class="space-y-2">
                <div *ngFor="let lesson of dataByIkt.studentTestSubject; let i = index" class="bg-gray-100 p-3 rounded-lg">
                    <p class="text-gray-900 font-medium">Предмет {{i + 1}}: <span class="text-gray-800">{{lesson.subjectNameRus}}</span></p>
                    <p class="text-blue-600 font-semibold">Баллов: {{lesson.testScore}}</p>
                </div>
            </div>
        </div>
        <div class="mb-4">
            <p class="text-gray-700 font-semibold">Сумма баллов:</p>
            <p class="text-blue-600 font-bold">{{totalScore}}</p>
        </div>
        <div class="mb-4">
            <p class="text-gray-700 font-semibold">Начало тестирования:</p>
            <p class="text-gray-900">{{dataByIkt.studentTest.testStartedAt}}</p>
        </div>
        <div class="mb-4">
            <p class="text-gray-700 font-semibold">Конец тестирования:</p>
            <p class="text-gray-900">{{dataByIkt.studentTest.testFinishedAt}}</p>
        </div>
        <div>
            <p class="text-gray-700 font-semibold">Статус:</p>
            <p class="text-green-600 font-bold">{{dataByIkt.studentTest.studentTestStatusTypeNameRus}}</p>
        </div>
    </div>
    
</div>