import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, ReactiveFormsModule, FormGroup, Validators, FormArrayName } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { RefControllerService } from '../services/ref-controller.service';
import { AutheticationControllerService } from '../services/authetication-controller.service';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { StudentTestControllerService } from '../services/student-test-controller.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-operators',
  templateUrl: './operators.component.html',
  styleUrls: ['./operators.component.css']
})
export class OperatorsComponent implements OnInit {
  public loading = false; 
  allUsersList: any;
  id: any;
  firstname: any;
  userStatusType: any;
  showBlockModal = false;
  allUsersData: any;
  userBlocked = false;
  lastname: any;
  role: any;
  patronymic: any;
  blockUsers: any;
  email: any;
  iin: any; 
  userId: any;
  form!: FormGroup;

 

  constructor(
    private autheticationControllerService: AutheticationControllerService, 
    private refControllerService: RefControllerService,
    public route: ActivatedRoute, 
    public router: Router, 
    private studentTest: StudentTestControllerService, 
    private _notifications: NotificationsService, 
    public translate: TranslateService) {}

  ngOnInit(): void {

    if(localStorage.getItem('role') == 'ROLE_ADMIN'){
      this.role = 'admin';
    }
    
    if(localStorage.getItem('role') == 'ROLE_OPERATOR_NCT'){
      this.role = 'operatorNqt';
    }

    this.form = new FormGroup({
      allUsersList: new FormControl(),
      firstname: new FormControl(),
      lastname: new FormControl(), 
      patronymic: new FormControl(),
      email: new FormControl(),
      userStatusType: new FormControl(),
      iin: new FormControl(),
      users: new FormControl()
    });

    this.form.get('users')?.setValue(0);

    this.getAllUsers();
  }

  getAllUsers(){ 
    this.studentTest.findAllUsers() 
    .subscribe((res: any) => {
    this.allUsersList = [];  
    this.allUsersList = res;
    }, (err: any) => {
      this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) : 
      this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
    });
  }

blockUserOper(){
  this.loading = true;
  this.studentTest.blockUser(this.userId)
  .subscribe((res: any) => {
    this.loading = false;
    this.showBlockModal = false;
    this.translate.currentLang == 'ru' ? this._notifications.create('Успешно', 'пользователь заблокирован', NotificationType.Success, 5000) :
    this._notifications.create('Сәтті', 'пайдаланушы бұғатталған', NotificationType.Success);
    this.getAllUsers(); 
    this.userBlocked = false;
  }, (err: any) => {
    this.loading = false;
    this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) : 
    this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
  });
}

unblockUserOper(userId: any){
  this.loading = true;
  this.studentTest.unblockUser(userId)
  .subscribe((res: any) => {
    this.loading = false;
    this.showBlockModal = false;
    this.translate.currentLang == 'ru' ? this._notifications.create('Успешно', 'пользователь разблокирован', NotificationType.Success, 5000) :
    this._notifications.create('Сәтті', 'пайдаланушы бұғатталған', NotificationType.Success);
    this.getAllBlockedUsers(); 
    this.userBlocked = false;
  }, (err: any) =>{
    this.loading = false;
    this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) : 
    this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
  });
}

showModal(userId: number){
  this.showBlockModal = true;
  this.userId = userId;
}

finedUserTech(){
  this.loading = true;
  this.studentTest.findAllUsers()
  .subscribe((res: any) => { 
    this.loading = false;
    this.allUsersData = [];
    this.allUsersData = res;
  },(err: any) => {
    this.loading = true;
    this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) : 
    this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
  });
}

changeFormList(){
  if(this.form.get('users')?.value == 1){ 
    this.getAllUsers();
  }else{
    this.getAllBlockedUsers();
  }
}

getAllBlockedUsers(){
this.studentTest.findAllBlockedUsers()
  .subscribe((res: any ) => {
    this.allUsersList = [];
    this.allUsersList = res;
  }, (err: any ) => {
 });
} 

poiskIin(){
  this.loading = true;
  let iin = this.form.get('iin')!.value;
  this.studentTest.findUserByIin(iin)
    .subscribe((res: any) => {
      this.loading = false;
      this.allUsersList = [];
      this.allUsersList.push(res);
    }, (err: any) => {
      this.loading = false;
      this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) : 
      this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
   });
 }

}
