import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StudentTestControllerService } from '../services/student-test-controller.service';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-back-pay',
  templateUrl: './back-pay.component.html',
  styleUrls: ['./back-pay.component.css']
})
export class BackPayComponent implements OnInit {

  public loading = false;
  appTypeId: any;
  seasonId: any;
  setStudentStatus: any;
  statusList: any;
  studentData: any;
  studentListTransfer: any;
  studentList!: any[];
  updateStatus: any;
  studentTestStatusTypeId: any;
  nameRus: any;
  studentId: any;
  iin: any;
  firstName: any;
  showModalTransfer = false;
  lastName: any;
  dateZayav: any;
  patronymic: any;
  phoneNumber: any;
  login: any;
  password: any;
  testVptId: any;
  testVptList: any;
  limit: any;
  page: any;
  studenId: any;
  studentTestId: any;
  statusStudenta: any;
  findAnnulList: any;
  showModal = false;
  showConfirmation = false;
  allAnnulStatusList: any;
  stodentsFor: any;
  paymentId: any;
  langName: any;
  testStatus: any;
  subjects: any;
  arrayStud: any;
  studentTestIdList: any;
  jobTypeList: any;
  testVpt: any;
  statusId: any;
  isAllChecked = false;
  vptId: any;
  vptList: any;
  newStatusList: any;
  resetSessionList: any;
  activeModal: any;
  showStatus = false;
  searchChecker = 0;
  annulStatusChecks: any;
  appType: any;
  role: any;
  testPeriod: any;
  currentPageArray: any = [];
  content: any = [];
  numberOfPages: any;
  hoverOnStudent = 0;
  showStatusReset = false;
  showPeresadka = false;
  checkedCount = 0;
  informWindow = false;
  iinMask = '000000000000';
  studentTestIdBuffer: any;
  roomId: any;
  freePlaceList: any;
  testOrgId: any;
  testPeriodId: any;
  placePriorityId: any;
  length: any;
  auditPlaceList: any;
  posadOblList: any;
  testOrgList: any;
  oblId: any;
  testPeriodOrgList: any;
  roomPlaceNumber: any;
  transferList: any;
  roomFreeNumber: any;
  code: any;
  codeObl: any;
  testScoreSum: any;
  testFinishedAt: any;
  institute: any;
  testStartedAt: any;
  testScore: any;
  testOrgEntity: any = [];
  annulmentTypeIdList: any = [];
  school: any;
  annuStatusList: any;
  season: any;
  studentsTestId: any;
  annulmentTypeId: any = [];
  annulmentTypeIds: any = [];
  student: any;
  notes: any;
  resetSessionTypeId: any;
  sprDisabilityEntity: any;
  p: number = 1;
  paymentStatusEntity: any;
  paymentProviderType: any;
  testDate: any;
  id: any;
  orderId: any;
  payList: any;
  dateOrder: any;
  amountOfMoney: any;
  searchTerm: string = '';

  form!: FormGroup;
  formPay!: FormGroup;
  formMOdal!: FormGroup;

  constructor(
    private main: StudentTestControllerService,
    private route: ActivatedRoute,
    public router: Router,
    public translate: TranslateService,
    private _notifications: NotificationsService
    ) { }

  ngOnInit(): void {

    this.formMOdal = new FormGroup({
      iin: new FormControl(),
      orderId: new FormControl(),
      studentTestId: new FormControl(),
      searchTypeModal: new FormControl(0),
      paymentId: new FormControl()

    });

    this.formPay = new FormGroup({
      paymentId: new FormControl(),
      studentTestId: new FormControl()
    });

    this.form = new FormGroup({
      updateStudentForm: new FormArray([]),
      studentData: new FormControl(),
      paymentId: new FormControl(),
      arrayStud: new FormControl(),
      lastName: new FormControl(''),
      firstName: new FormControl(''),
      searchType: new FormControl(0),
      appType: new FormControl(0),
      seasonId: new FormControl(),
      appTypeId: new FormControl(),
      patronymic: new FormControl(''),
      iin: new FormControl(),
      vptList: new FormControl(),
      content: new FormControl([]),
      jobTypeList: new FormControl(),
      testVptList: new FormControl(),
      dateZayav: new FormControl(),
      testOrg: new FormControl(),
      testPeriodId: new FormControl(),
      placePriorityId: new FormControl(0),
      roomId: new FormControl(),
      testOrgId: new FormControl(0),
      testPeriod: new FormControl(),
      oblId: new FormControl(),
      studentsTestId: new FormControl(0),
      studentTestStatusTypeId: new FormControl(0),
      reasonDesc: new FormControl(),
      annulmentTypeIds: new FormArray([]),
      notes: new FormControl(''),
      checked: new FormControl(false),
      studentTestId: new FormControl(0),
      paymentStatusEntity: new FormControl(),
      paymentProviderType: new FormControl(),
      orderId: new FormControl(),
      payList: new FormControl(),
      dateOrder: new FormControl(),
      amountOfMoney: new FormControl(),
    });

    this.form.get('testOrg')!.setValue(0);
    this.form.get('searchType')!.setValue(1);
    this.formMOdal.get('searchTypeModal')!.setValue(1);

  }

  poiskIin() {
    this.loading = true;
    let iin = this.form.get('iin')!.value;
    this.main.findByIinPayments(iin)
      .subscribe((res: any) => {
        this.loading = false;
        this.studentList = [];
        this.studentList = res;
        this.studentList.forEach((element: any) => {
          element.isChecked = false;
        });
      }, (err: any) => {
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
        this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
      });
  }

  // 889424

  findByNumPayments(){
    this.loading = true;
    this.main.findByNumPayments(this.form.get('orderId')!.value)
    .subscribe((res: any) => {
      this.loading = false;
      this.studentList = [];
      this.studentList = res;
      this.studentList.forEach((element: any) => {
        element.isChecked = false;
      });
    }, (err: any) => {
      this.loading = false;
      this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
      this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
    });
  }

  returnMoney(student: any) {
    this.main.returnMoney(student)
    .subscribe((res: any) => {
      this.translate.currentLang == 'ru' ? this._notifications.create('Успешно', res.message, NotificationType.Success, 5000) :
      this._notifications.create('Сәтті', res.message, NotificationType.Success, 5000);
      this.poiskFio();
      this.poiskIin();
      this.findByNumPayments();
      this.informWindow = false;
    }, (err: any) => {
      this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
      this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
    });
  }

  checkSearchType(searchType: any){
    if(searchType == 1){
      this.form.get('lastName')!.setValue('');
      this.form.get('firstName')!.setValue('');
      this.form.get('patronymic')!.setValue('');
    }else{
      this.form.get('iin')!.setValue(0);
    }
  }

  checkSearchTypeModal(searchTypeModal: any){
    if(searchTypeModal == 1){
      this.formMOdal.get('studentTestId')!.setValue(0);
    }else{
      this.formMOdal.get('iin')!.setValue(0);
    }
  }

  poiskFio() {
    this.loading = true;
    if(!!this.form.get('lastName')!.value && !!this.form.get('firstName')!.value && !!this.form.get('patronymic')!.value){
      this.loading = true;
      this.main.findByFioPayments(this.form.get('lastName')!.value, this.form.get('firstName')!.value, this.form.get('patronymic')!.value, this.p - 1, 50)
      .subscribe((res: any) => {
        this.loading = false;
        this.studentList = res.content;
        this.numberOfPages = res.totalElements;
        this.studentList.forEach((element: any) => {
          element.isChecked = false;
        });
      }, (err: any) => {
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
        this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
      });
    }
    if(!!this.form.get('lastName')!.value && !!this.form.get('firstName')!.value && this.form.get('patronymic')!.value == ''){
      this.loading = true;
      this.main.findByFiPayments(this.form.get('lastName')!.value, this.form.get('firstName')!.value, this.p - 1, 50)
      .subscribe((res: any) => {
        this.loading = false;
        this.studentList = res.content;
        this.numberOfPages = res.totalElements;
        this.studentList.forEach((element: any) => {
          element.isChecked = false;
        });
      }, (err: any) => {
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
        this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
      });
    }
    if(!!this.form.get('lastName')!.value && this.form.get('firstName')!.value == '' && !!this.form.get('patronymic')!.value){
      this.loading = true;
      this.main.findByFoPayments(this.form.get('lastName')!.value, this.form.get('patronymic')!.value, this.p - 1, 50)
      .subscribe((res: any) => {
        this.loading = false;
        this.studentList = res.content;
        this.numberOfPages = res.totalElements;
        this.studentList.forEach((element: any) => {
          element.isChecked = false;
        });
      }, (err: any) => {
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
        this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
      });
    }
    if(this.form.get('lastName')!.value == '' && !!this.form.get('firstName')!.value && !!this.form.get('patronymic')!.value){
      this.loading = true;
      this.main.findByIoPayments(this.form.get('firstName')!.value, this.form.get('patronymic')!.value, this.p - 1, 50)
      .subscribe((res: any) => {
        this.loading = false;
        this.studentList = res.content;
        this.numberOfPages = res.totalElements;
        this.studentList.forEach((element: any) => {
          element.isChecked = false;
        });
      }, (err: any) => {
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
        this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
      });
    }
    if(!!this.form.get('firstName')!.value && this.form.get('lastName')!.value == '' && this.form.get('patronymic')!.value == ''){
      this.loading = true;
      this.main.findByIPayments(this.form.get('firstName')!.value, this.p - 1, 50)
      .subscribe((res: any) => {
        this.loading = false;
        this.studentList = res.content;
        this.numberOfPages = res.totalElements;
        this.studentList.forEach((element: any) => {
          element.isChecked = false;
        });
      }, (err: any) => {
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
        this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
      });
    }
    if(this.form.get('lastName')!.value == '' && this.form.get('firstName')!.value == '' && !!this.form.get('patronymic')!.value ){
      this.loading = true;
      this.main.findByOPayments(this.form.get('patronymic')!.value, this.p - 1, 50)
      .subscribe((res: any) => {
        this.loading = false;
        this.studentList = res.content;
        this.numberOfPages = res.totalElements;
        this.studentList.forEach((element: any) => {
          element.isChecked = false;
        });
      }, (err: any) => {
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
        this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
      });
    }
    if(!!this.form.get('lastName')!.value && this.form.get('firstName')!.value == '' && this.form.get('patronymic')!.value == ''){
      this.loading = true;
      this.main.findByFPayments(this.form.get('lastName')!.value, this.p - 1, 50)
      .subscribe((res: any) => {
        this.loading = false;
        this.studentList = res.content;
        this.numberOfPages = res.totalElements;
        this.studentList.forEach((element: any) => {
          element.isChecked = false;
        });
      }, (err: any) => {
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
        this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
      });
    }
  }

  getStudentData(studentTestId: any) {
    this.loading = true;
    this.showConfirmation = false;
    this.main.getLoginAndPasswordOfStudent(studentTestId)
      .subscribe((res: any) => {
        this.loading = false;
        this.main = res.studentTestId;
      }, (err: any) => {
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
        this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
    });
  }



  findStudent() {
    this.loading = true;
    this.informWindow = true;
      if(this.searchChecker != 0){
        this.studentList.forEach((element: any) => {
          element.isChecked = false;
        });
        this.searchChecker = 1;
        if (this.form.get('searchType')!.value == 1) {
          this.loading = true;
          this.poiskIin();
        }
        if (this.form.get('searchType')!.value == 2){
          this.loading = true;
          this.poiskFio();
        }
        if (this.form.get('searchType')!.value == 3) {
          this.loading = true;
          this.findByNumPayments();
        }
        }else{
        if (this.form.get('searchType')!.value == 1) {
          this.loading = true;
          this.poiskIin();
        }
        if (this.form.get('searchType')!.value == 2) {
          this.loading = true;
          this.poiskFio();
        }
        if (this.form.get('searchType')!.value == 3) {
          this.loading = true;
          this.findByNumPayments();
        }
        if (this.form.get('searchType')!.value == 1) {
          this.loading = true;
          this.poiskIin();
        }
        if (this.form.get('searchType')!.value == 2) {
          this.loading = true;
          this.poiskFio();
        }
        if (this.form.get('searchType')!.value == 3) {
          this.loading = true;
          this.findByNumPayments();
        }
      }
      this.isAllChecked = false;
      this.currentPageArray = [];
  }

  oknoData(student: any){
    this.informWindow = true;
    this.hoverOnStudent = student;
    this.id = student.id;
    this.testScoreSum = student.testScoreSum;
    this.paymentId = student.paymentId;
    this.dateZayav = student.dateZayav;
    this.institute = student.institute;
    this.season = student.season;
    this.iin = student.iin;
    this.school = student.school;
    this.lastName = student.lastName;
    this.testFinishedAt = student.testFinishedAt;
    this.testStartedAt = student.testStartedAt;
    this.testScore = student.testScore;
    this.firstName = student.firstName;
    this.patronymic = student.patronymic;
    this.studentTestId = student.studentTestId;
    this.subjects = [];
    this.subjects = student.subjects;
    this.testPeriod = student.testPeriod;
    this.appTypeId = student.appTypeId;
    this.seasonId = student.seasonId;
    this.appType = student.appType;
    this.testStatus = student.testStatus;
    this.phoneNumber = student.phoneNumber;
    this.paymentStatusEntity = student.paymentStatusEntity;
    this.paymentProviderType = student.paymentProviderType;
    this.testDate = student.testDate;
    this.orderId = student.orderId;
    this.dateOrder = student.dateOrder;
    this.amountOfMoney = student.amountOfMoney;

  }

  checkAllOptions() {
    this.getCurrentPage();
    if(!this.isAllChecked){
      this.studentList.forEach((element: any) => {
        this.currentPageArray.forEach((copyElement: any) => {
          if(copyElement == element){
            element.isChecked = true;
          }
        });
      });
      this.isAllChecked = true;
    }else{
      this.studentList.forEach((element: any) => {
        this.currentPageArray.forEach((copyElement: any) => {
          if(copyElement == element){
            element.isChecked = false;
          }
        });
      });
      this.isAllChecked = false;
    }
  }

  getCurrentPage(){
    this.currentPageArray = [];
    let currentFirstElement: any = (this.p * 50) - 50;
    for(let i = 0; i < 50; i++){
      if(this.studentList[currentFirstElement + i] != undefined){
        this.currentPageArray.push(this.studentList[currentFirstElement + i]);
      }
    }
  }

  checkPage(event: any){
    if(this.p != event){
      this.studentList.forEach((element: any) => {
        if(element.isChecked == true){
          element.isChecked = false;
        }
      });
    }
    this.p = event;
    this.getCurrentPage();
    this.findStudent();
    let counter: number = 0;
    if(counter == 10){
      this.isAllChecked = true;
    }else{
      this.isAllChecked = false;
    }
  }

  checkStudent(event: any, student: any){
    this.checkedCount = 0;
    if (event.target.checked == true) {
      for( let i = 0; i < this.studentList.length; i++){
        if ( this.studentList[i] === student) {
          this.studentList[i].isChecked = true;
        }
      }
    } else{
      for( let i = 0; i < this.studentList.length; i++){

        if ( this.studentList[i] === student) {
          this.studentList[i].isChecked = false;
        }
      }
    }

    this.studentList.forEach((element: any) => {
      if(element.isChecked){
        this.checkedCount++;
      }
    });
  }

  idOfStudent(studentId: number) {
    return new FormControl(studentId);
  }

  setTransferPayment(){
    let paymet = {
      paymentId: this.form.get('paymentId')!.value,
      studentTestId: this.formMOdal.get('studentTestId')!.value
    }
    this.main.setTransferPaymentOfStudent(paymet)
      .subscribe((res: any) => {
        this.studentListTransfer = [];
        this.formMOdal.get('studentTestId')!.setValue(0)
        this.form.get('paymentId')!.setValue(0)
        this.formMOdal.get('orderId')!.setValue(0)
        this.showModalTransfer = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Успешно', 'оплата перенесена', NotificationType.Success, 5000) :
          this._notifications.create('Сәтті', 'оплата перенесена', NotificationType.Success);

      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
          this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
      });
  }

  closeModal(){
    this.showModalTransfer = !this.showModalTransfer
    this.studentListTransfer = [];
    this.formMOdal.get('studentTestId')!.setValue(0)
    this.form.get('paymentId')!.setValue(0)
    this.formMOdal.get('orderId')!.setValue(0)
    this.formMOdal.get('searchTypeModal')!.setValue(1);

  }

  openModalTransfer(student: any){
    this.form.get('paymentId')!.setValue(student.paymentId)
    this.showModalTransfer = true;
  }

  transferPaymentStudent(){
    if(this.searchChecker != 0){

      this.searchChecker = 1;
      if (this.formMOdal.get('searchTypeModal')!.value == 1) {
        this.poiskIinTransfer();
      }
      if (this.formMOdal.get('searchTypeModal')!.value == 4) {
        this.findByNumPaymentsTransfer();
      }
    }else{
      if (this.formMOdal.get('searchTypeModal')!.value == 1) {
        this.poiskIinTransfer();
      }
      if (this.formMOdal.get('searchTypeModal')!.value == 4) {
        this.findByNumPaymentsTransfer();
      }
      if (this.formMOdal.get('searchTypeModal')!.value == 1) {
        this.poiskIinTransfer();
      }
      if (this.formMOdal.get('searchTypeModal')!.value == 4) {
        this.findByNumPaymentsTransfer();
      }
    }
    this.isAllChecked = false;
    this.currentPageArray = [];
  }
  findByNumPaymentsTransfer(){
    this.main.findByNumPaymentsTrans(this.formMOdal.get('studentTestId')!.value)
      .subscribe((res: any) => {
        this.studentListTransfer = res;

      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
          this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
      });
  }

  poiskIinTransfer() {
    let orderId = this.formMOdal.get('orderId')!.value;
    this.main.findByIinPaymentsTrans(orderId)
      .subscribe((res: any) => {
        this.studentListTransfer = res;
        this.formMOdal.get('studentTestId')!.setValue(res[0].studentTestId)
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
          this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
      });
  }

}
