import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, ReactiveFormsModule, FormGroup, Validators, FormArrayName, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute,  } from '@angular/router';
import { RefControllerService } from '../services/ref-controller.service';
import { AutheticationControllerService } from '../services/authetication-controller.service';
import { StudentTestControllerService } from '../services/student-test-controller.service';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-incident',
  templateUrl: './incident.component.html',
  styleUrls: ['./incident.component.css']
})
export class IncidentComponent {

  public loading = false;
  testOrgName: any
  orgId: any

  form!: FormGroup;
  allInc: any[] = [];
  num: any;
  id: any;
  testPeriodId: any;
  oblId: any;
  resetSessionList: any;
  statementId: any;
  statement: any;
  testOrgList: any;
  testOrgLists: any;
  statusList: any;
  testOrgId: any;
  operatorNctAndIbId: any;
  testDate: any;
  enterTestTime: any;
  testPeriodForStatementsDtoList: any;
  // sprStatementsStatusEntity: any;
  oblList: any;
  testPeriodOrgList: any;
  searchList: any;
  searchStatusList: any;
  statementsStatus: any;
  testOrgEntity: any;
  testOrgListsNct: any;
  testPeriodOrgListNct: any;

  userRole: any;
  testPeriodList: any;


  constructor(
    private autheticationControllerService: AutheticationControllerService,
    private refControllerService: RefControllerService,
    public route: ActivatedRoute,
    public router: Router,
    private studentTest: StudentTestControllerService,
    public translate: TranslateService,
    private _notifications: NotificationsService) {
  }


  ngOnInit(): void {

    if (localStorage.getItem('role') == 'ROLE_OPERATOR_NCT') {
      this.userRole = 'operatorNct';
    }

    if (localStorage.getItem('role') == 'ROLE_OPERATOR') {
      this.userRole = 'operator';
    }

    if (localStorage.getItem('role') == 'ROLE_IB') {
      this.userRole = 'IB';
    }

    this.testOrgName = localStorage.getItem('testOrgName');
    this.orgId = localStorage.getItem('testOrgId');

    this.form = new FormGroup({
      testPeriodId: new FormControl(),
      resetSessionTypeId: new FormControl(),
      num: new FormControl(),
      id: new FormControl(),
      oblId: new FormControl(),
      statementId: new FormControl(),
      testOrgId: new FormControl(),
      statement: new FormControl(),
      operatorNctAndIbId: new FormControl(),
      testDate: new FormControl(),
      statementsStatus: new FormControl(),
    });

    this.form.get('resetSessionTypeId')!.setValue(0);
    this.form.get('testPeriodId')!.setValue(0);
    this.form.get('testOrgId')!.setValue(0);
    this.form.get('operatorNctAndIbId')!.setValue(0);
    this.form.get('oblId')!.setValue(0);
    this.form.get('num')!.setValue(0);
    this.form.get('statementsStatus')!.setValue(0);

    if (this.userRole == 'operator') {
      this.findAllTestPeriodByTestOrgOperator();
      this.findAllStatementsForUsers();

    }

    if (this.userRole == 'operatorNct') {
      this.getPpentOfOperatorNct();
      this.findAllStatementsForUsers();
      this.findAllObl()
    }

    this.findAllStatementsStatus();

    if (this.userRole == 'IB') {
      this.findAllObl()
      this.findAllStatementsForUsers();
    }


  }

  sbros() {
    // this.findAllStatementsForUsers();
    // this.getPpentOfOperatorNct();
    // this.findAllStatementsForOper();
    // this.findAllTestPeriodByTestOrgId();
    this.form.get('num')!.setValue(0);
    this.form.get('resetSessionTypeId')!.setValue(0);
    this.form.get('testPeriodId')!.setValue(0);
    this.form.get('testOrgId')!.setValue(0);
    this.form.get('operatorNctAndIbId')!.setValue(0);
    this.form.get('statementsStatus')!.setValue(0);
    this.form.get('oblId')!.setValue(0);
  }

  // searchByTestOrgAndTestPeriod(){
  //   this.studentTest.searchByTestOrgAndTestPeriod(this.form.get('testOrgId')!.value, this.form.get('testPeriodId')!.value)
  //     .subscribe((res: any) => {
  //       this.allInc = [];
  //       this.allInc = res;
  //     }, (err: any) => {
  //       this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
  //         this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
  //     });
  // }

  findAllObl() {
    this.loading = true;
    this.studentTest.findAllObl()
      .subscribe((res: any) => {
        this.loading = false;
        this.oblList = [];
        this.oblList = res;
      }, (err: any) => {
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
          this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
      });
  }

  findAllTestPeriodByTestOrg(code: any) {
    this.loading = true;
    this.studentTest.findAllTestPeriodByTestVptId(this.form.get('testOrgId')!.value)
      .subscribe((res: any) => {
        this.loading = false;
        this.testPeriodOrgList = [];
        this.testPeriodOrgList = res;
        this.testOrgList.forEach((element: any) => {
          if(element.id == this.form.get('testOrgId')!.value){
            // if(this.translate.currentLang == 'kz'){
            //   // this.ppentName = element.id + '-' + element.nameKaz
            // }else{
            //   // this.ppentName = element.id + '-' + element.nameRus
            // }
          }
        });
      }, (err: any) => {
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
          this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
      });
  }

  findAllTestPeriodByTestOrgs() {
    // this.loading = true;
    // this.studentTest.findAllTestPeriodByTestOrgId(this.form.get('testOrgId')!.value)
    //   .subscribe((res: any) => {
    //     this.loading = false;
    //     this.testPeriodOrgListNct = [];
    //     res.forEach((element: any) => {
    //       element.testPeriodForStatementsDtoList.forEach((elem: any, i: any) => {
    //         if(elem.testOrgId == this.form.get('testOrgId')!.value){
    //           this.testPeriodOrgListNct[i] = {
    //             name:"" + element.testOrgId + ' - ' + elem.testDate + ' - ' + elem.startTestTime,
    //             id: elem.id
    //           }
    //         }
    //       })
    //     });
    //   }, (err: any) => {
    //     this.loading = false;
    //     this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
    //       this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
    //   });
  }

  findAllTestOrg() {
    // this.loading = true;
    // this.studentTest.findAllTestOrg(this.form.get('oblId')!.value)
    //   .subscribe((res: any) => {
    //     this.loading = false;
    //     this.testOrgList = [];
    //     this.testOrgList = res;
    //   }, (err: any) => {
    //     this.loading = false;
    //     this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
    //       this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
    //   });
  }

  findAllResetSessionType() {
    // this.studentTest.findAllResetSessionType()
    //   .subscribe((res: any) => {
    //     this.resetSessionList = [];
    //     this.resetSessionList = res;
    //
    //   }, (err: any) => {
    //     this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
    //       this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
    //   });
  }

  findAllTestPeriodByTestOrgId() {
    // this.studentTest.findAllTestPeriodByTestOrgId(this.form.get('testOrgId')!.value)
    //   .subscribe((res: any) => {
    //     this.testPeriodList = [];
    //     res.forEach((element: any) => {
    //       element.testPeriodForStatementsDtoList.forEach((elem: any, i: any) => {
    //         if(elem.id == this.form.get('testOrgId')!.value){
    //           this.testPeriodList[i] = {
    //             name:"" + element.testOrgId + ' - ' + elem.testDate + ' - ' + elem.startTestTime,
    //             id: elem.id
    //           }
    //         }
    //       })
    //     });
    //   }, (err: any) => {
    //     this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
    //       this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
    //   });
  }

  findAllTestPeriodByTestOrgOperator() {
    // this.studentTest.findAllTestPeriodByTestOrgId(this.orgId)
    //   .subscribe((res: any) => {
    //     this.testPeriodList = [];
    //     res[0].testPeriodForStatementsDtoList.forEach((element: any, i: any) => {
    //       this.testPeriodList[i] = {
    //         name:"" + element.testDate + ' - ' + element.startTestTime,
    //         id: element.id
    //       }
    //     });
    //   }, (err: any) => {
    //     this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
    //       this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
    //   });
  }

  findAllStatementsForUsers() {
    this.studentTest.findAllStatementsForUsers()
      .subscribe((res: any) => {
        this.allInc = [];
        this.allInc = res;
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
          this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
      });
  }

  findAllStatementsForOperIb() {
    // if(this.form.get('testPeriodId')!.value != 0 && this.form.get('statementsStatus')!.value != 0 && this.form.get('testOrgId')!.value != 0){
    //   this.studentTest.findAllStatementsForOperFull(this.form.get('testPeriodId')!.value, this.form.get('statementsStatus')!.value, this.form.get('testOrgId')!.value)
    //     .subscribe((res: any) => {
    //       this.allInc = [];
    //       this.allInc = res;
    //     }, (err: any) => {
    //       this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
    //         this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
    //     });
    // }
    // if(this.form.get('testPeriodId')!.value == 0 && this.form.get('statementsStatus')!.value == 0 && this.form.get('testOrgId')!.value != 0){
    //   this.studentTest.findOnlyStatusTestOrg(this.form.get('testOrgId')!.value)
    //     .subscribe((res: any) => {
    //       this.allInc = [];
    //       this.allInc = res;
    //     }, (err: any) => {
    //       this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
    //         this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
    //     });
    // }
    // if(this.form.get('testPeriodId')!.value == 0 && this.form.get('statementsStatus')!.value != 0 && this.form.get('testOrgId')!.value != 0){
    //   this.studentTest.findAllStatementsForOper(this.form.get('statementsStatus')!.value, this.form.get('testOrgId')!.value)
    //     .subscribe((res: any) => {
    //       this.allInc = [];
    //       this.allInc = res;
    //     }, (err: any) => {
    //       this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
    //         this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
    //     });
    // }
    // if(this.form.get('testPeriodId')!.value != 0 && this.form.get('statementsStatus')!.value == 0 && this.form.get('testOrgId')!.value != 0){
    //   this.studentTest.findOnlyTestPeriod(this.form.get('testPeriodId')!.value, this.form.get('testOrgId')!.value)
    //     .subscribe((res: any) => {
    //       this.allInc = [];
    //       this.allInc = res;
    //     }, (err: any) => {
    //       this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
    //         this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
    //     });
    // }
    // if(this.form.get('testPeriodId')!.value == 0 && this.form.get('statementsStatus')!.value != 0 && this.form.get('testOrgId')!.value == 0){
    //   this.studentTest.findOnlyStatus(this.form.get('statementsStatus')!.value)
    //     .subscribe((res: any) => {
    //       this.allInc = [];
    //       this.allInc = res;
    //     }, (err: any) => {
    //       this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
    //         this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
    //     });
    // }
  }

  findAllStatementsForOper() {
    // if(this.form.get('num')!.value != 0 && this.form.get('statementsStatus')!.value != 0 && this.form.get('testOrgId')!.value != 0){
    //   this.studentTest.findAllStatementsForOperFull(this.form.get('num')!.value, this.form.get('statementsStatus')!.value, this.form.get('testOrgId')!.value)
    //     .subscribe((res: any) => {
    //       this.allInc = [];
    //       this.allInc = res;
    //       if(this.userRole != 'admin'){
    //         // this.findAllStatementsForUsers();
    //       }
    //     }, (err: any) => {
    //       this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
    //         this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
    //     });
    // }
    // if(this.form.get('num')!.value == 0 && this.form.get('statementsStatus')!.value == 0 && this.form.get('testOrgId')!.value != 0){
    //   this.studentTest.findOnlyStatusTestOrg(this.form.get('testOrgId')!.value)
    //     .subscribe((res: any) => {
    //       this.allInc = [];
    //       this.allInc = res;
    //     }, (err: any) => {
    //       this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
    //         this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
    //     });
    // }
    // if(this.form.get('num')!.value == 0 && this.form.get('statementsStatus')!.value != 0 && this.form.get('testOrgId')!.value != 0){
    //   this.studentTest.findAllStatementsForOper(this.form.get('statementsStatus')!.value, this.form.get('testOrgId')!.value)
    //     .subscribe((res: any) => {
    //       this.allInc = [];
    //       this.allInc = res;
    //     }, (err: any) => {
    //       this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
    //         this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
    //     });
    // }
    // if(this.form.get('num')!.value != 0 && this.form.get('statementsStatus')!.value == 0 && this.form.get('testOrgId')!.value != 0){
    //   this.studentTest.findOnlyTestPeriod(this.form.get('num')!.value, this.form.get('testOrgId')!.value)
    //     .subscribe((res: any) => {
    //       this.allInc = [];
    //       this.allInc = res;
    //     }, (err: any) => {
    //       this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
    //         this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
    //     });
    // }
    // if(this.form.get('num')!.value != 0 && this.form.get('statementsStatus')!.value == 0 && this.form.get('testOrgId')!.value == 0){
    //   this.studentTest.findOnlyTestPeriodNum(this.form.get('num')!.value)
    //     .subscribe((res: any) => {
    //       this.allInc = [];
    //       this.allInc = res;
    //     }, (err: any) => {
    //       this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
    //         this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
    //     });
    // }
    // if(this.form.get('num')!.value != 0 && this.form.get('statementsStatus')!.value != 0 && this.form.get('testOrgId')!.value == 0){
    //   this.studentTest.findOnlyNumStatus(this.form.get('num')!.value, this.form.get('statementsStatus')!.value)
    //     .subscribe((res: any) => {
    //       this.allInc = [];
    //       this.allInc = res;
    //     }, (err: any) => {
    //       this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
    //         this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
    //     });
    // }
    // if(this.form.get('num')!.value == 0 && this.form.get('statementsStatus')!.value != 0 && this.form.get('testOrgId')!.value == 0){
    //   this.studentTest.findOnlyStatus(this.form.get('statementsStatus')!.value)
    //     .subscribe((res: any) => {
    //       this.allInc = [];
    //       this.allInc = res;
    //     }, (err: any) => {
    //       this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
    //         this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
    //     });
    // }
  }

  getPpentOfOperatorNct() {
    // this.studentTest.getPpentOfOperatorNct()
    //   .subscribe((res: any) => {
    //     this.testOrgListsNct = [];
    //     this.testOrgListsNct = res;
    //   }, (err: any) => {
    //     this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
    //       this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
    //   });
  }

  findAllStatementsStatus() {
    //   this.studentTest.findAllStatementsStatus()
    //     .subscribe((res: any) => {
    //       this.searchStatusList = [];
    //       this.searchStatusList = res;
    //     }, (err: any) => {
    //     });
    // }


  }
}

