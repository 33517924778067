import { Injectable } from '@angular/core';
import { apiRoutes } from '../config/api.routes';
import { HttpClient } from '@angular/common/http';



@Injectable({
  providedIn: 'root'
})
export class AutheticationControllerService {   

  constructor(private http: HttpClient, private api: apiRoutes) { }

  auth(token: any) {
    // return this.http.post(this.api.backend + '/getToken/' + token, {}); 
    // return this.http.post(this.api.routes.auth, value, {}); 
  }

}
