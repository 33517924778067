import { Component, KeyValueDiffers, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder, FormArrayName } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { StudentTestControllerService } from '../services/student-test-controller.service'; 
import { apiRoutes } from '../config/api.routes';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-stat-nqt',
  templateUrl: './stat-nqt.component.html',
  styleUrls: ['./stat-nqt.component.css']
})
export class StatNqtComponent implements OnInit {

  public loading = false;

  fromdate = new Date () ;
  todate = new Date () ;
  tok: any;
  year: any;
  token = {token: localStorage.getItem('authToken')};
  testYearList: any;

  form!: FormGroup;
  seasons: any;

  constructor( 
    private studentTest: StudentTestControllerService,
    private router: Router, 
    private route: ActivatedRoute, 
    private api: apiRoutes,
    public translate: TranslateService, 
    private http: HttpClient,
    private _notifications: NotificationsService,
    ) { }

  ngOnInit(): void {

    this.statToken(this.token)
    .subscribe({
      next:(res: any) => {
      this.tok = res.token;
    },
    complete:()=>{
      // this.testYearStats();
    },
    error:(err)=>{

    }
  })

  this.getSeasonsNqt()
    .subscribe({
      next:( res: any) => {
        this.seasons = res
      }
    })
  
    
    this.form = new FormGroup({
     fromdate: new FormControl(),                   
     seasonId: new FormControl(),                        
     todate: new FormControl()
    }); 

    let date = new Date();

    let day: any = date.getDate();
    let month: any = date.getMonth() + 1;
    let year = date.getFullYear();

if (month < 10) month = "0" + month;
if (day < 10) day = "0" + day;

let today = year + "-" + month + "-" + day;
this.form.get('todate')!.setValue(today)

    // this.testYearStats();
    
  }

  getHeaderAuth(): any {
    if (this.tok != undefined || this.tok != null) {
      let userTok: any = localStorage.getItem('userInfoToken');
      let headers = {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.tok,
          'userInfoToken': '' + localStorage.getItem('userInfoToken')
        })
      }
      return headers;
    }
  }

  statToken(token: any){
    return this.http.post(this.api.backendStat + '/getToken/nqt', token, this.getHeaderAuth());
  }

  // testYearStat(){
  //   return this.http.get(this.api.backendStat + '/getYears', this.getHeaderAuth());
  //  }

  // testYearStats(){
  //   this.testYearStat()
  //     .subscribe((res: any) => {
  //       // this.testYearList = [];
  //       this.testYearList = res;
  //     }, (err: any) => {

  //     });
  // }

  nqt(value: any){
    return this.http.post(this.api.backendStat + '/nqt', value, {responseType: 'blob', headers: this.getTok()});
  }

  getSeasonsNqt(){
    return this.http.get(this.api.backendStat + '/getSeasonsNqt', this.getTok());
  }

  getTok(): any {
    let headers = {
      'Authorization': 'Bearer ' + this.tok,
      'userInfoToken': '' + localStorage.getItem('userInfoToken')
   }
   return headers;
}

  downloadExcelENT(){
    this.loading = true;
    this.nqt(this.form.value)
    .subscribe((res: any) => {
      const blob = new Blob([res], {type: 'application/*'});
        const data = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = data;
        link.download = 'Nqt_' + this.form.get('fromdate')!.value + '_' + this.form.get('todate')!.value + '.zip'; //xlsx
        link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
        setTimeout(function() {
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
    this.loading = false;

    }, async (err: any) => {
    this.loading = false;

      // const message = JSON.parse(await err.error.text()).message;
      // this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', message, NotificationType.Error, 5000) : 
      // this._notifications.create('Қате', message, NotificationType.Error, 5000);
    });
  }


  changSeason(e: any){
    
    this.seasons.forEach((elem:any) => {
      if(elem.seasonId === +e.target.value && elem.fromDate){
        this.form.get('fromdate')!.setValue(elem.fromDate)
      }
    });
    
  }


}