import { Component, OnInit } from '@angular/core';
import { AutheticationControllerService } from '../app/services/authetication-controller.service' ;
import { TranslateService } from '@ngx-translate/core';
import { NgModule } from '@angular/core';

import { FormArray, FormControl, ReactiveFormsModule, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { StudentTestControllerService } from '../app/services/student-test-controller.service';
import { LocationStrategy } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'nqt-operator';

  constructor(
    private authConstroller: AutheticationControllerService, 
    public translate: TranslateService, 
    private router: Router, 
    public location: LocationStrategy, 
    private studentTest: StudentTestControllerService){

    translate.addLangs(['ru', 'kz']);
    translate.setDefaultLang('ru');

    const browserLang: any = translate.getBrowserLang();
    translate.use(browserLang.match(/ru|kz/) ? browserLang : 'ru');
    this.changeLang(localStorage.getItem('Lang'));
  }

  public loading = false;

  ngOnInit(): void {
  }
    
  changeLang(lang: any){
    this.translate.use(lang);
    localStorage.setItem('Lang', lang);
  }
}
