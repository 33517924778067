<!-- <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading> -->
<div class="flex my-5">
<div class="w-1/2 h-1 bg-blue-300 mt-4"></div>
<div class="w-3/5 text-center text-3xl font-hairline uppercase tracking-widest">
  {{ 'StreamSearch' | translate }}
</div>
<div class="w-1/2 h-1 bg-blue-300 mt-4"></div>
</div>
 <form action=""> 
  <div (click)="showStatus = !showStatus" *ngIf="showStatus" class="w-full h-screen bg-black top-0 left-0 z-20 bg-opacity-25 fixed"></div>
  <div *ngIf="showStatus" class="text-center border-2 p-4 top-0 h-50 left-0 z-20 realy-delete fixed z-30 w-1/3 bg-white">
        <div class="text-3xl"> {{ 'ChangingTheStatus' | translate }}</div>
      
        <div class="my-2 ">
         {{ 'AreYouSureYouWantToResetTheSession' | translate }}
        </div>

      <div class="flex">
        <div class="w-1/2">
          <button (click)="getResetSession()" class="w-56 my-10 rounded-lg btn text-center btn-outline-primary text-white btn-lg btn-block bg-red-400 p-1">
            {{ 'Confirm' | translate }}
          </button>
        </div>

        <div class="w-1/2">
            <button (click)="showStatus = !showStatus" class="w-56 my-10 rounded-lg btn bg-blue-400 text-center text-white btn-outline-primary btn-lg btn-block p-1">
              {{ 'Cancellation' | translate }}
            </button>
        </div> 
      </div>
  </div>
</form>

<form [formGroup]="form" action=""> 


  <form [formGroup]="formPeresad">
  <div class="">
    <div (click)="showPeresadka = !showPeresadka" *ngIf="showPeresadka" class="w-full h-screen bg-black top-0 left-0 z-20 bg-opacity-25 fixed"></div>
    <div *ngIf="showPeresadka" class="text-center border-2 p-4 top-0 h-80 left-0 z-20 classA fixed z-30 w-1/2 bg-white">

      <div class="text-3xl"> Пересадка </div>

      <div class="text-left">
        <div class="my-2 flex"> 
          <label class="lableClass"> ППНКТ </label>
          <select (change)="getAllTestPeriodByTestVptIds(0)" [(ngModel)]="testVptId" [ngModelOptions]="{standalone: true}" class="rounded-lg border-2 mr-20 flex-grow w-full px-3 lg:text-xs lg:text-base text-gray-500 font-thin">
            <option [value]="0" disabled selected> ППНКТ </option>
            <option *ngFor="let item of vptLists" [value]="item.vptId">
              <span *ngIf="translate.currentLang == 'kz'"> {{ item.codeId }} - {{ item.nameKaz }} </span>
              <span *ngIf="translate.currentLang == 'ru'"> {{ item.codeId }} - {{ item.nameRus }} </span>
            </option>
          </select>
        </div>

        <div class="my-2 flex">
          <label class="lableClass"> {{ 'Stream' | translate }} </label>
          <select  [(ngModel)]="testPeriodId" [ngModelOptions]="{standalone: true}" class="rounded-lg border-2 mr-20 flex-grow w-full px-3 lg:text-xs lg:text-base text-gray-500 font-thin">
            <option [value]="0" disabled selected> {{ 'Stream' | translate }} </option>
            <option *ngFor="let item of testVptLists" [value]="item.id">
              {{ item.testDate }} - {{ item.startTestTime }} - {{ item.enterTestTime }} - свободные места на потоке {{ item.freePlaceCount }}
            </option>
          </select>
        </div>
      </div>
      <div class="flex my-2">
        <label class="lableClass"> При статусе "Не явился" </label> 
            <input [(ngModel)]="withStatusNeYavilsya" [ngModelOptions]="{standalone: true}" class="w-5 h-5 ml-5 mt-3" type="checkBox">
      </div>

      <div class="flex">
        <div class="w-1/2">
          <button (click)="peresadka()"
            class="w-56 my-10 rounded-lg btn text-center btn-outline-primary text-white btn-lg btn-block bg-red-400 p-1">
            {{ 'Change' | translate }}
          </button>
        </div>

        <div class="w-1/2">
          <button *ngIf="showPeresadka" (click)="closePeresad($event)"
            class="w-56 my-10 rounded-lg btn bg-blue-400 text-center text-white btn-outline-primary btn-lg btn-block p-1">
            {{ 'Cancellation' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</form>

    <div class="text-gray-900 bg-gray-200">
        
     <fieldset form="form">

        <div class="flex items-center w-full mb-12">
                <div class="ml-2 w-48 text-grey-dark">
                   {{ 'TestingLocation' | translate }}
                </div>
            
          <div class="w-full pt-1">
          <select (change)="getAllTestPeriodByTestVptId(0)" formControlName="testVptId" class="mx-auto ml-5 mr-2 rounded-lg bg-width">
            <option disabled value="0" >
              {{ 'ChooseAPlaceForTesting' | translate }}
            </option>
            <option *ngFor="let item of vptList" [value]="item.vptId" value="">
              <span *ngIf="translate.currentLang == 'kz'"> {{item.codeId}} - {{ item.nameKaz }} </span>
              <span *ngIf="translate.currentLang == 'ru'"> {{item.codeId}} - {{ item.nameRus }} </span>
            </option>
          </select> {{ 'TestingTime' | translate }}
          <select formControlName="testPeriodId" class="w-1/5 ml-5 rounded-lg bg-width">
            <option disabled value="0">
              {{ 'SelectStream' | translate }}
            </option>
            <option *ngFor="let item of testVptList" [value]="item.id" value="">
              {{ item.testDate }} - {{ item.startTestTime }}
            </option>
          </select>
        </div>
      </div> 
      <div class="flex my-2">
        <label class="lableClass"> Сортировка посадочного </label> 
          <select formControlName="sort" class="w-1/5 ml-5 rounded-lg bg-width">
              <option selected [value]="0">
                <span> По фамилии </span>
              </option>
              <option [value]="1">
                  <span> По месту </span>
              </option>
            </select>
      </div>
      <div *ngIf="role != 'operator'" class="flex my-2">
        <label class="lableClass"> Выгрузка посадочного листа с логином/паролем </label> 
            <input [(ngModel)]="showPass" [ngModelOptions]="{standalone: true}" class="w-5 h-5 ml-5 mt-3" type="checkBox">
      </div>
    </fieldset>
       <div class="flex"> 
        <button (click)="findStudent()" type="button" class="cursour-pointer w-40 bg-gray-300 hover:bg-gray-300 rounded-lg lg:px-3 py-1 ml-2 text-grey-dark text-sm">
           {{ 'Search' | translate }}
        </button>
        <button *ngIf="!isAllChecked && role != 'operator'" (click)="resetSessionWindow(0)" type="button" class="cursour-pointer bg-gray-300 ml-2 hover:bg-gray-300 rounded-lg lg:px-3 py-1 text-grey-dark text-sm">
           {{ 'ResetTheSession' | translate }}
        </button>
        <button *ngIf="isAllChecked && role != 'operator'" (click)="resetSessionWindow(1)" type="button" class="cursour-pointer bg-gray-300 ml-2 hover:bg-gray-300 rounded-lg lg:px-3 py-1 text-grey-dark text-sm">
           {{ 'ResetSessionToAllStudents' | translate }}
      </button>
        <button (click)="downloadPdf()" type="button" class="cursour-pointer bg-gray-300 ml-2 hover:bg-gray-300 rounded-lg lg:px-3 py-1 text-grey-dark text-sm">
           {{ 'PDF' | translate }}
        </button>
        <button (click)="downloadExcel()" type="button" class="cursour-pointer bg-gray-300 ml-2 hover:bg-gray-300 rounded-lg lg:px-3 py-1 text-grey-dark text-sm">
           {{ 'Excel' | translate }}
       </button>
       <button (click)="studentTestListToExcel()" *ngIf="role != 'operator'" type="button" class="cursour-pointer bg-gray-300 ml-2 hover:bg-gray-300 rounded-lg lg:px-3 py-1 text-grey-dark text-sm">
        Выгрузка тестируемых в Excel
       </button>
       <button *ngIf="( checkedCount != 0) || isAllChecked == true" (click)="showModalPeresad()" type="button" class="cursour-pointer bg-gray-300 ml-2 hover:bg-gray-300 rounded-lg lg:px-3 py-1 text-grey-dark text-sm">
            Пересадка
       </button>
       <form *ngIf="role != 'operator'" [formGroup]="formDate" action="">
       <div class="flex border-2 ml-2 rounded-lg border-gray-400">
       <button (click)="getLandingLeafListArchive()" type="button" class="cursour-pointer bg-gray-300 hover:bg-gray-300 rounded-lg lg:px-3 py-1 text-grey-dark text-sm">
        {{ 'Archive' | translate }}
       </button>
       <input formControlName="date" [min]="currentDay" type="date" class="w-32 ml-5">
       <select formControlName="time" class="w-36 ml-5 rounded-lg">Время тестирования
         <option placeholder="Выберете время" selected disabled value="0">Выберете время</option>
         <option *ngFor="let item of timel" [value]="item">
           {{item}}
         </option>
       </select>
      </div>
    </form>
   </div>

   <div>
    <p> Всего на потоке {{size}} </p>
    <p *ngIf="isAllChecked != false"> Всего выбрано {{allsize}} </p>
    <p *ngIf="isAllChecked != true"> Всего выбрано {{checkedCount}} </p> 
  </div>
       
        <pagination-controls (autoHide)="true" class="text-center mt-10" (pageChange)="checkPage($event)"></pagination-controls>
    <div class="px-3 py-4 scroll-table-body flex">
        <table class="min-w-full">
            <thead class="">
              <tr> 

                <th>
                    <input formControlName="resetByTestVpt" [checked]="isAllChecked" class="w-4 h-4" (click)="checkAllOptions()" type="checkbox" >  
                </th>
                
                <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
                   {{ 'IIN' | translate }} 
                </th>
                <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
                   {{ 'firstName' | translate }} 
                </th>
                <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
                   {{ 'lastName' | translate }} 
                </th>
                <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
                   {{ 'patronymic' | translate }} 
                </th>
                <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
                   {{ 'LangTest' | translate }} 
                </th>
                <th scope="col" class="px-6 py-3 w-52 text-center text-xs font-medium text-gray-500 uppercase">
                   {{ 'StudentStatus' | translate }} 
                </th>
                
              </tr>
            </thead>

            <tbody class="bg-white border-2.border-gray-200">
              <tr [ngClass]="{'bg-red-400': student.isChecked == true}" class="border-2 border-gray-200" *ngFor="let student of studentList | paginate: {itemsPerPage: 20, currentPage: p}; index as i">
                <td class="px-6 py-4 text-center">
                  <div class="text-sm text-gray-900">
                    <input class="w-4 h-4" [checked]="student.isChecked" (change)="checkStudent($event, student)" type="checkbox">
                </div>
                </td>

                <td class="px-6 py-4 text-center">
                  <div class="text-sm text-gray-900">
                    <span *ngIf="translate.currentLang == 'kz'"> {{ student.iin }} </span>
                    <span *ngIf="translate.currentLang == 'ru'"> {{ student.iin }} </span>
                </div> 
                </td>

                <td class="px-6 py-4 text-center">
                  <div class="text-sm text-gray-900">
                    <span *ngIf="translate.currentLang == 'kz'"> {{ student.firstName }} </span>
                    <span *ngIf="translate.currentLang == 'ru'"> {{ student.firstName }} </span>
                  </div>
                </td>

                <td class="px-6 py-4 text-center">
                    <div class="text-sm text-gray-900">
                      <span *ngIf="translate.currentLang == 'kz'"> {{ student.lastName }} </span>
                      <span *ngIf="translate.currentLang == 'ru'"> {{ student.lastName }} </span>
                    </div>
                </td>

                <td class="px-6 py-4 text-center">
                    <div class="text-sm text-gray-900">
                      <span *ngIf="translate.currentLang == 'kz'"> {{ student.patronymic }} </span>
                      <span *ngIf="translate.currentLang == 'ru'"> {{ student.patronymic }} </span>
                    </div>
                </td>

                <td class="px-6 py-4 text-center">
                    <div *ngIf="student.langEntity.nameKaz != null || student.langEntity.nameRus != null" class="text-sm text-gray-900">
                      <span *ngIf="translate.currentLang == 'kz'"> {{ student.langEntity.nameKaz }} </span>
                      <span *ngIf="translate.currentLang == 'ru'"> {{ student.langEntity.nameRus }} </span>
                    </div>
                </td>
 
                <td class="px-6 py-4 text-center">
                     <div class="text-sm text-gray-900 w-52">
                      <span *ngIf="translate.currentLang == 'kz'"> {{ student.testStatus.nameKaz }} </span>
                      <span *ngIf="translate.currentLang == 'ru'"> {{ student.testStatus.nameRus }} </span>
                     </div> 
                </td>

              </tr>
            </tbody>
          </table>
       </div>
    </div>
</form> 