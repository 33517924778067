<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>  
<div class="mb-3 container">
  <form [formGroup]="form" class="mx-auto w-full" action="">

    <div class="flex my-7">
      <div class="w-1/5 h-1 bg-blue-300 mt-4"></div>
      <div class="w-3/5 text-center text-3xl font-hairline uppercase tracking-widest">
        {{ 'editingATested' | translate }}
      </div>
      <div class="w-1/5 h-1 bg-blue-300 mt-4"></div>
    </div>

    <div class="border-1 rounded-lx shadow-2xl">


      <div class="ml-10">
        <div class="flex mt-5">
          <p class="w-14"> {{ 'iin' | translate }} </p>
          <input readonly formControlName="iin" class="w-1/4 bg-gray-200 pl-3 rounded-full mx-16" type="number">
        </div>

        <div class="flex mt-7">
          <p class="w-14"> {{ 'ikt' | translate }} </p>
          <input readonly formControlName="studentTestId" class="w-1/4 bg-gray-200 pl-3 rounded-full mx-16" type="text">
        </div>

        <div class="flex mt-7">
          <p class="w-14"> {{ 'lastName' | translate }} </p>
          <input readonly formControlName="lastname" class="w-1/4 bg-gray-200 pl-3 rounded-full mx-16" type="text">
        </div>

        <div class="flex mt-7">
          <p class="w-14"> {{ 'firstName' | translate }} </p>
          <input readonly formControlName="firstname" class="w-1/4 bg-gray-200 pl-3 rounded-full mx-16" type="text">
        </div>

        <div class="flex mt-7">
          <p class="w-14"> {{ 'patronymic' | translate }} </p>
          <input readonly formControlName="patronymic" class="w-1/4 bg-gray-200 pl-3 rounded-full mx-16" type="text">
        </div>

        <div class="flex mt-7">
          <p class="w-14"> {{ 'phone' | translate }} </p>
          <input formControlName="phoneNumber" class="w-1/4 bg-gray-200 pl-3 rounded-full mx-16" type="text">
        </div>
      </div>

     

      <!-- Действующий сотрудник -->
      <div *ngIf="this.form.get('employmentTypeId')!.value == 1">
        <div class="flex mt-7">
          <p class="ml-10"> Текущая занятость: </p>
          <select (change)="forEmploymentType()" formControlName="employmentTypeId" class="w-auto ml-5 bg-gray-200 rounded-full">
            <option disabled [value]="0"> Текущая занятость: </option>
            <option [value]="item.id" *ngFor="let item of employmentList">
              <span *ngIf="translate.currentLang == 'kz'"> {{ item.name.kk }} </span>
              <span *ngIf="translate.currentLang == 'ru'"> {{ item.name.ru }} </span>
            </option>
          </select>
        </div>

        <div class="mt-7 ml-10">
          <p class="w-1/2"> {{ 'SpecialtyQualificationIndicatedInTheDiplomaOfEducationOrRetrainingDocumentWithTheAssignmentOfTheAppropriateQualificationForThePositionHeld' | translate }} </p>
           <input formControlName="diplomaSpecialityName" class="w-full rounded-lg bg-gray-200" type="text">
         </div>
  
  
          <div class="flex mt-7 ml-10">
            <p class="w-1/6"> {{ 'currentQualifications' | translate }} </p>
            <select (change)="toCategory(0)" formControlName="fromCategoryId" class="w-auto bg-gray-200 rounded-full">
              <option disabled value="-1"> {{'currentQualificationChooseQualification' | translate }} </option>
              <option [value]="item.id" *ngFor="let item of categoryList">
                <span *ngIf="translate.currentLang == 'kz'"> {{ item.name.kk }} </span>
                <span *ngIf="translate.currentLang == 'ru'"> {{ item.name.ru }} </span>
              </option>
            </select>
          </div>
  
          <div class="flex ml-10 mt-7">
            <p class="w-1/2"> Срок обучения в высшем учебном заведении по указанной специальности (годы обучения): </p>
           С <input formControlName="eduPeriodStartYear" class="ml-2" type="text">
           До <input formControlName="eduPeriodFinishYear" class="ml-2" type="text">
          </div>
  
          <div class="flex ml-10 mt-7">
              <p class="w-1/5"> {{ 'ValidityPeriodOfTheCurrentQualification' | translate }} </p>
              <input formControlName="fromCategoryValidTo" mask="00.00.0000" type="text">
            </div>
  
          <div class="flex ml-10 mt-7">
            <p class="w-1/6"> {{ 'ApplicableQualifications' | translate }} </p>
            <select formControlName="toCategoryId" class="w-auto bg-gray-200 rounded-full">
              <option disabled [value]="0"> {{ 'currentQualificationChooseQualification' | translate }} </option>
              <option [value]="item.id" *ngFor="let item of toCategoryList">
                <span *ngIf="translate.currentLang == 'kz'"> {{ item.name.kk }} </span>
                <span *ngIf="translate.currentLang == 'ru'"> {{ item.name.ru }} </span>
              </option>
            </select>
          </div>
  
          <div class="mt-7 ml-10 flex">
              <div>
                <label class="form-check-label">
                  {{ 'Disability' | translate }}
                </label>
              </div>
              <input formControlName="isInvalid" class="ml-2 mt-1 w-4 h-4 invalid-checkbox" type="checkbox">
            </div>
  
            <div class="header-h1 my-7 font-bold text-center">
              <h1> {{'typeOfEmployment' | translate}} </h1>
            </div>
      
            <div class="ml-10"> 
              <div>
                <div class="flex">
                  {{ 'EnterBin' | translate }}
                  <input formControlName="bin" class="w-full border bg-gray-200 rounded-lg my-3 h-6 text-center"
                    placeholder="БИН" type="text">
                </div>
      
                <div (click)="searchJob(form.get('bin')!.value)" class="w-full cursour-pointer font-serif mb-2 w-full rounded-full text-center py-1 px-24 bg-blue-300 rounded-full text-grey-darker">
                
                  {{ 'SearchByBIN' | translate }}
                </div>
              </div>
      
              <div class="flex py-3">
                <div class="mr-3 w-1/3">
                  {{ 'WorkPlace' | translate }}:
                </div>
                <div class="w-2/3">
                  <select (change)="getKATOsList(0, $event.target, true, 0)" class="border bg-gray-200 rounded-lg" formControlName="firstLevelKato">
                    <option selected disabled value="0">
                      {{ 'SelectObl' | translate }}
                    </option>
                    <option *ngFor="let obl of KATOOblList" [value]="obl.id">
                      <span *ngIf="translate.currentLang == 'kz'">{{ obl.name.kk }} </span>
                      <span *ngIf="translate.currentLang == 'ru'">{{ obl.name.ru }} </span>
                    </option>
                  </select>
                  <div *ngFor="let kat of KATOs; let i = index">
                    <select (change)="getKATOsList(i, $event.target, false, 0)" class="border bg-gray-200 rounded-lg mt-2">
                      <option [disabled] value="0">
                        --
                      </option>
                      <option [selected]="pseudoId[i+1] == subject.id" *ngFor="let subject of kat; let j = index" [value]="j">
                        <span *ngIf="translate.currentLang == 'kz'">{{ subject.name.kk }}</span>
                        <span *ngIf="translate.currentLang == 'ru'">{{ subject.name.ru }}</span>
                      </option>
                    </select>
                  </div>
      
                </div>
              </div>
              <select formControlName="jobId" class="w-full bg-gray-200 rounded-lg border my-1">
                <option selected disabled [value]="0"> {{ 'WorkPlace' | translate }} </option>
                <option [value]="item.id" *ngFor="let item of jobList">
                  <span *ngIf="translate.currentLang == 'kz'"> {{ item.name.kk }} </span>
                  <span *ngIf="translate.currentLang == 'ru'"> {{ item.name.ru }} </span>
                </option>
              </select>
        </div>
      </div>
      <!-- Работник без стажа - выпускник высшего послевузовского образования -->
      <div *ngIf="this.form.get('employmentTypeId')!.value == 2">

        <div class="flex mt-7">
          <p class="ml-10"> Текущая занятость: </p>
          <select (change)="forEmploymentType()" formControlName="employmentTypeId" class="w-auto ml-5 bg-gray-200 rounded-full">
            <option disabled [value]="0"> Текущая занятость: </option>
            <option [value]="item.id" *ngFor="let item of employmentList">
              <span *ngIf="translate.currentLang == 'kz'"> {{ item.name.kk }} </span>
              <span *ngIf="translate.currentLang == 'ru'"> {{ item.name.ru }} </span>
            </option>
          </select>
        </div>

        <div class="flex ml-10 mt-7">
          <p class="w-44"> Облатсь: </p>
          <select (change)="institutes(0)" formControlName="eduInstituteOblId" class="w-auto ml-5 bg-gray-200 rounded-full">
            <option [value]="0" disabled selected> Облатсь места обучения </option>
            <option [value]="item.id" *ngFor="let item of oblLists">
              <span *ngIf="translate.currentLang == 'kz'"> {{ item.name.kk }} </span>
              <span *ngIf="translate.currentLang == 'ru'"> {{ item.name.ru }} </span>
            </option>
          </select>
        </div>

        <div class="flex ml-10 mt-7">
          <p class="w-44"> Место обучения: </p>
          <select (change)="instituteSpec(0)" formControlName="eduInstituteId" class="w-auto ml-5 bg-gray-200 rounded-full">
            <option [value]="0" disabled selected> Место обучения </option>
            <option [value]="item.id" *ngFor="let item of instituteLists">
              <span *ngIf="translate.currentLang == 'kz'"> {{ item.name.kk }} </span>
              <span *ngIf="translate.currentLang == 'ru'"> {{ item.name.ru }} </span>
            </option>
          </select>
        </div>

        <div class="flex ml-10 mt-7">
          <p class="w-44"> ГОП обучения: </p>
          <select formControlName="eduSpecialityId" class="w-auto ml-5 bg-gray-200 rounded-full">
            <option [value]="0" disabled selected> ГОП обучения </option>
            <option [value]="item.id" *ngFor="let item of instituteList">
              <span *ngIf="translate.currentLang == 'kz'"> {{ item.code }} - {{ item.name.kk }} </span>
              <span *ngIf="translate.currentLang == 'ru'"> {{ item.code }} - {{ item.name.ru }} </span>
            </option>
          </select>
        </div>
        
        <div class="mt-7 ml-10 flex">
          <div>
            <label class="form-check-label">
              {{ 'Disability' | translate }}
            </label>
          </div>
          <input formControlName="isInvalid" class="ml-2 mt-1 w-4 h-4 invalid-checkbox" type="checkbox">
        </div>

      </div>
<!-- Работник без стажа - выпускник организации технического или профессионалного образования -->
<div *ngIf="this.form.get('employmentTypeId')!.value == 3">

  <div class="flex mt-7">
    <p class="ml-10"> Текущая занятость: </p>
    <select (change)="forEmploymentType()" formControlName="employmentTypeId" class="w-auto ml-5 bg-gray-200 rounded-full">
      <option disabled [value]="0"> Текущая занятость: </option>
      <option [value]="item.id" *ngFor="let item of employmentList">
        <span *ngIf="translate.currentLang == 'kz'"> {{ item.name.kk }} </span>
        <span *ngIf="translate.currentLang == 'ru'"> {{ item.name.ru }} </span>
      </option>
    </select>
  </div>

  <div class="flex ml-10 mt-7">
    <p class="w-44"> Облатсь: </p>
    <select (change)="getRaionList(0)" formControlName="eduSchoolOblId" class="w-auto ml-5 bg-gray-200 rounded-full">
      <option [value]="0" disabled selected> Облатсь места обучения </option>
      <option [value]="item.id" *ngFor="let item of oblSchoolList">
        <span *ngIf="translate.currentLang == 'kz'"> {{ item.name.kk }} </span>
        <span *ngIf="translate.currentLang == 'ru'"> {{ item.name.ru }} </span>
      </option>
    </select>
  </div>

  <div class="flex ml-10 mt-7">
    <p class="w-44"> Район обучения: </p>
    <select (change)="getCollege(0)" formControlName="eduSchoolRaionId" class="w-auto ml-5 bg-gray-200 rounded-full">
      <option [value]="0" disabled selected> Район обучения </option>
      <option [value]="item.id" *ngFor="let item of raionList">
        <span *ngIf="translate.currentLang == 'kz'"> {{ item.name.kk }} </span>
        <span *ngIf="translate.currentLang == 'ru'"> {{ item.name.ru }} </span>
      </option>
    </select>
  </div>

  <div class="flex ml-10 mt-7">
    <p class="w-44"> Место обучения: </p>
    <select (change)="collegeSpec(0)" formControlName="eduSchoolId" class="w-auto ml-5 bg-gray-200 rounded-full">
      <option [value]="0" disabled selected> Место обучения </option>
      <option [value]="item.id" *ngFor="let item of collegList">
        <span *ngIf="translate.currentLang == 'kz'"> {{ item.name.kk }} </span>
        <span *ngIf="translate.currentLang == 'ru'"> {{ item.name.ru }} </span>
      </option>
    </select>
  </div>

  <div class="flex ml-10 mt-7">
    <p class="w-44"> Специальность: </p>
    <select formControlName="eduSpecialityId" class="w-auto ml-5 bg-gray-200 rounded-full">
      <option [value]="0" disabled selected> Специальность </option>
      <option [value]="item.id" *ngFor="let item of collegeSpecList">
        <span *ngIf="translate.currentLang == 'kz'"> {{ item.name.kk }} </span>
        <span *ngIf="translate.currentLang == 'ru'"> {{ item.name.ru }} </span>
      </option>
    </select>
  </div>
  
  <div class="mt-7 ml-10 flex">
    <div>
      <label class="form-check-label">
        {{ 'Disability' | translate }}
      </label>
    </div>
    <input formControlName="isInvalid" class="ml-2 mt-1 w-4 h-4 invalid-checkbox" type="checkbox">
  </div>

</div>
<!-- Лицо желающее возобновить преподавательскую деятельность -->
<div *ngIf="this.form.get('employmentTypeId')!.value == 4">
  <div class="flex mt-7">
    <p class="ml-10"> Текущая занятость: </p>
    <select (change)="forEmploymentType()" formControlName="employmentTypeId" class="w-auto ml-5 bg-gray-200 rounded-full">
      <option disabled [value]="0"> Текущая занятость: </option>
      <option [value]="item.id" *ngFor="let item of employmentList">
        <span *ngIf="translate.currentLang == 'kz'"> {{ item.name.kk }} </span>
        <span *ngIf="translate.currentLang == 'ru'"> {{ item.name.ru }} </span>
      </option>
    </select>
  </div>

  <div class="mt-7 ml-10">
    <p class="w-1/2"> {{ 'SpecialtyQualificationIndicatedInTheDiplomaOfEducationOrRetrainingDocumentWithTheAssignmentOfTheAppropriateQualificationForThePositionHeld' | translate }} </p>
     <input formControlName="diplomaSpecialityName" class="w-full rounded-lg bg-gray-200" type="text">
   </div>

   <div class="flex ml-10 mt-7">
    <p class="w-1/2"> Срок обучения в высшем учебном заведении по указанной специальности (годы обучения): </p>
   С <input formControlName="eduPeriodStartYear" class="ml-2 border-2" type="text">
   До <input formControlName="eduPeriodFinishYear" class="ml-2 border-2" type="text">
  </div>
  
  <div class="mt-7 ml-10 flex">
    <div>
      <label class="form-check-label">
        {{ 'Disability' | translate }}
      </label>
    </div>
    <input formControlName="isInvalid" class="ml-2 mt-1 w-4 h-4 invalid-checkbox" type="checkbox">
  </div>

</div>

      <div class="header-h1 my-7 font-bold text-center">
        <h1> {{ 'TESTPARAMETERS' | translate }} </h1>
      </div>

      <div class="ml-10">

        <div class="mt-7">
          <p class="w-1/3"> Область </p>
          <select (change)="punktList(0)" formControlName="testOrgOblId" class="w-full bg-gray-200 rounded-full">
            <option [value]="0" disabled selected> {{ 'ChooseATestingSite' | translate }}</option>
            <option [value]="item.id" *ngFor="let item of oblList">
              <span *ngIf="translate.currentLang == 'kz'"> {{ item.name.kk }} </span>
              <span *ngIf="translate.currentLang == 'ru'"> {{ item.name.ru }} </span>
            </option>
          </select>
        </div>
        
        <div class="mt-7">
          <p class="w-1/3"> {{ 'TestingLocation' | translate }} </p>
          <select formControlName="testOrgId" class="w-full bg-gray-200 rounded-full">
            <option [value]="0" disabled selected> {{ 'ChooseATestingSite' | translate }}</option>
            <option [value]="item.id" *ngFor="let item of pstList">
              <span *ngIf="translate.currentLang == 'kz'"> {{ item.name.kk }} </span>
              <span *ngIf="translate.currentLang == 'ru'"> {{ item.name.ru }} </span>
            </option>
          </select>
        </div>

        <div class="mt-7">
          <p> {{ 'StreamNumberTestDate' | translate }} </p>
          <div>
            <select formControlName="testPeriodId" class="w-full bg-gray-200 rounded-full">
              <option [value]="0" disabled selected> {{ 'Stream' | translate }} </option>
              <option [value]="item.id" *ngFor="let item of periodList"> 
                {{ 'TestDate' | translate }} {{ item.testDate }}
                </option>
            </select>
          </div>
        </div>

        <div class="mt-7">
            <p> {{ 'TestLanguage' | translate }} </p>
            <select formControlName="testLangId" class="w-full bg-gray-200 rounded-full"> 
              <option [value]="0" selected> {{ 'ChooseYourLanguage' | translate }} </option>
              <option [value]="item.id" *ngFor="let item of langList"> 
                <span *ngIf="translate.currentLang == 'kz'"> {{ item.name.kk }} </span>
                <span *ngIf="translate.currentLang == 'ru'"> {{ item.name.ru }} </span>
              </option>   
            </select>
          </div> 
      </div>

      <div class="pt-2 flex mt-4">
        <button (click)="editUser()" type="button" class="mb-2 w-full rounded-full py-1 px-24 bg-blue-300 rounded-full text-grey-darker">
          {{ 'Edit' | translate }}
        </button>
        <button [routerLink]="['/main/students']" class="font-serif mb-2 w-full rounded-full py-1 text-center px-24 bg-red-400 rounded-full text-grey-darker">
             {{ 'Cancellation' | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
