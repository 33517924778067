import { Component, OnInit } from '@angular/core';
import { Location } from "@angular/common";
import { Router, ActivatedRoute, Params } from "@angular/router";

import { AutheticationControllerService } from '../services/authetication-controller.service';


@Component({
  selector: 'app-authorization',
  templateUrl: './authorization.component.html',
  styleUrls: ['./authorization.component.css']
})
export class AuthorizationComponent implements OnInit {

  

  constructor(
    private autheticationControllerService: AutheticationControllerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location
  ) { 
    
    
  }

  ngOnInit(): void {
    
  }

  
  

  





}
