import { Injectable } from '@angular/core';
import { apiRoutes } from '../config/api.routes';
import { HttpClient, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { TokenServiceService } from './token-service.service';


@Injectable({
  providedIn: 'root'
})


export class StudentTestControllerService {

  constructor(private http: HttpClient, private api: apiRoutes, private tokenService: TokenServiceService) {
   }

 // авторизация
 auth(token: string) {
  return this.http.post(this.api.routes.auth + '/' + token, {},  {headers: {User: '' + localStorage.getItem('userInfoToken')}});
}
 // ref-controller

 // Получение списка специальности (поиск по названию) {nameRusKaz}
 findSpecialityByName(nameRusKaz: any){
   return this.http.get(this.api.routes.findSpecialityByName + '/' + nameRusKaz, this.tokenService.getHeaderAuth());
 }
 // Получение списка текущей квалификационной категории {jobTypeId}
 findCategoryByJobType(jobTypeId: any){
   return this.http.get(this.api.routes.findCategoryByJobType + '/' + jobTypeId, this.tokenService.getHeaderAuth());
 }
 // Получение списка место работы (поиск по названию) {name}
 findJobByName(name: any){
   return this.http.get(this.api.routes.findJobByName + '/' + name, this.tokenService.getHeaderAuth());
 }
 // Получение списка районов по области {oblId}
 findAllRaionByObl(katoId: any){
   return this.http.get(this.api.backend + '/findAllRaionByKatoId/' + katoId, this.tokenService.getHeaderAuth());
 }
 // Получение списка статусов
 findSomeTestStatusById(){
   return this.http.get(this.api.routes.findSomeTestStatusById, this.tokenService.getHeaderAuth());
 }

  findAllAppTypeByIdInForChangeAppType(){
    return this.http.get(this.api.backendApp + '/app/job-type/items', this.tokenService.getHeaderAuth());

  }
 // Получение списка место работы, по области и району {oblId}/{raionId}
 findAllJobByOblIdAndRaionId(oblId: any, raionId: any){
   return this.http.get(this.api.routes.findAllJobByOblIdAndRaionId + '/' + oblId + '/' + raionId, this.tokenService.getHeaderAuth());
 }
 // Получение списка предмет(дисциплина) по блоку "Содержание учебного предмета"/По направлению деятельности
 choiceSubjectFind(jobTypeId: any, langId: any, specialityId: any = 0, eduTypeId: any = 0){
   return this.http.get(this.api.routes.choiceSubjectFind + '?jobTypeId=' + jobTypeId + '&langId=' + langId + '&specialityId=' + specialityId + '&eduTypeId=' + eduTypeId, this.tokenService.getHeaderAuth());
 }
 // Получение мписка претендуемой квалификационной категории {currentCategoryId}/{jobTypeId}
 findPretendedCategoryByJobType(currentCategoryId: any, jobTypeId: any){
   return this.http.get(this.api.routes.findPretendedCategoryByJobType + '/' + currentCategoryId + '/' + jobTypeId, this.tokenService.getHeaderAuth());
 }
 // Получение списка специальности {jobTypeId}/{eduTypeId}
 findAllSpecialityByJobTypeIdAndEduTypeId(jobTypeId: any, eduTypeId: any){
   return this.http.get(this.api.routes.findAllSpecialityByJobTypeIdAndEduTypeId + '/' + jobTypeId + '/' + eduTypeId, this.tokenService.getHeaderAuth());
 }
 // Получение списка тип организаций в котором работает тестируемый
 getAllJobType(){
   return this.http.get(this.api.backend + '/ref/getAllJobType', this.tokenService.getHeaderAuth());
 }
 // Получение списка статусов (при редактирований)
 getAllTestStatus(){
   return this.http.get(this.api.routes.getAllTestStatus, this.tokenService.getHeaderAuth());
 }
 // Получение списка привилегий
 findAllPermissions(){
   return this.http.get(this.api.routes.findAllPermissions, this.tokenService.getHeaderAuth());
 }
 // Получение списка специальности
 findAllSpeciality(){
   return this.http.get(this.api.routes.findAllSpeciality, this.tokenService.getHeaderAuth());
 }
 // Получение списка языков {jobTypeId}
 findAllLangEntity(jobTypeId: any){
   return this.http.get(this.api.routes.findAllLangEntity + '/' + jobTypeId, this.tokenService.getHeaderAuth());
 }
 // Получение списка вид предмета обучения
 findAllEduType(){
   return this.http.get(this.api.routes.findAllEduType, this.tokenService.getHeaderAuth());
 }
 // Получение списка области
 findAllObl(): any{
   return this.http.get(this.api.backend + '/findAllObl', this.tokenService.getHeaderAuth());
 }
 // Получение списка ролей
 findAllUserRole(){
   return this.http.get(this.api.routes.findAllUserRole, this.tokenService.getHeaderAuth());
 }

 getProfileInfoForAddUser(iin: any){
  return this.http.get(this.api.routes.getProfileInfoForAddUser + '/' + iin, this.tokenService.getHeaderAuth());
 }

 // student-test-controller

 //Изменение статуса тестируемого PUT запрос
 setStatus(updateStatus: any){
   return this.http.put(this.api.backend + '/studentTest/setStatus', updateStatus, this.tokenService.getHeaderAuth());
 }
 // Получение данных тестируемого {studentTestId}
 findStudentTestByStudentTestId(studentTestId: any){
   return this.http.get(this.api.routes.findStudentTestByStudentTestId + '/' + studentTestId, this.tokenService.getHeaderAuth());
 }
 // Получить данные о пользователе из auth server {iin}
 getPersonalData(iin: any){
   return this.http.get(this.api.routes.getPersonalData + '/' + iin, this.tokenService.getHeaderAuth());
 }
 // Показать логин/пароль тестируемого {studentTestId}
 getLoginAndPasswordOfStudent(studentTestId: any){
   return this.http.get(this.api.routes.getLoginAndPasswordOfStudent + '/' + studentTestId, this.tokenService.getHeaderAuth());
 }
 // Получение данных тестируемого (поиск по ИИН)
 studentTestFindByIin(iin: any){
   return this.http.get(this.api.routes.studentTestFindByIin + '?iin=' + iin, this.tokenService.getHeaderAuth());
 }
 // Редактирование данных тестируемого POST запрос
 updateStudent(studentTestId: any, update: any){
   return this.http.post(this.api.routes.updateStudent + '/' + studentTestId, update, this.tokenService.getHeaderAuth());
 }
 // Получение списка тестируемых (поиск по ФИО)
findByFio(lastName: any, firstName: any, patronymic: any, page: any, limit: any){
   return this.http.get(this.api.backend + '/studentTest/findByFio' + '?lastname=' + lastName + '&firstname=' + firstName + '&patronymic=' + patronymic + '&page=' + page + '&limit=' + limit, this.tokenService.getHeaderAuth());
 }

findByFi(lastName: any, firstName: any, page: any, limit: any){
  return this.http.get(this.api.backend + '/studentTest/findByFio' + '?lastname=' + lastName + '&firstname=' + firstName + '&page=' + page + '&limit=' + limit, this.tokenService.getHeaderAuth());
}

findByFo(lastName: any, patronymic: any, page: any, limit: any){
  return this.http.get(this.api.backend + '/studentTest/findByFio' + '?lastname=' + lastName + '&patronymic=' + patronymic + '&page=' + page + '&limit=' + limit, this.tokenService.getHeaderAuth());
}

findByIo(firstName: any, patronymic: any, page: any, limit: any){
  return this.http.get(this.api.backend + '/studentTest/findByFio' + '?firstname=' + firstName + '&patronymic=' + patronymic + '&page=' + page + '&limit=' + limit, this.tokenService.getHeaderAuth());
}

findByF(lastName: any, page: any, limit: any){
  return this.http.get(this.api.backend + '/studentTest/findByFio' + '?lastname=' + lastName + '&page=' + page + '&limit=' + limit, this.tokenService.getHeaderAuth());
}

findByI(firstName: any, page: any, limit: any){
  return this.http.get(this.api.backend + '/studentTest/findByFio' + '?firstname=' + firstName + '&page=' + page + '&limit=' + limit, this.tokenService.getHeaderAuth());
}

findByO(patronymic: any, page: any, limit: any){
  return this.http.get(this.api.backend + '/studentTest/findByFio' + '?patronymic=' + patronymic + '&page=' + page + '&limit=' + limit, this.tokenService.getHeaderAuth());
}


 // public-controller

 // Получение списка место тестирования
 findAllVpt(){
   return this.http.get(this.api.routes.findAllVpt, this.tokenService.getHeaderAuth());
 }
 findAllVpts(){
  return this.http.get(this.api.routes.findAllVpt, this.tokenService.getHeaderAuth());
}
 // Назначение прав доступа (роли, привилегий) пользователям (нужно передовать в Permissions вместе с новыми значениями и старые, которые стояли) {userId}/{userRoleId}/{updatePermissions} PUT запрос
 updateUserPermissions(userId: any, userRoleId: any, updatePermissions: any){
   return this.http.put(this.api.routes.updateUserPermissions + '/' + userId + '/' + userRoleId + '/' + updatePermissions, this.tokenService.getHeaderAuth());
 }
 // Получение списка потока {vptId}/{jobType}/{studentTestId}
 findAllTestPeriodByVptIdAndTestDateAndJobType(vptId: any, jobType: any, studentTestId: any){
   return this.http.get(this.api.routes.findAllTestPeriodByVptIdAndTestDateAndJobType + '/' + vptId + '/' + jobType + '/' + studentTestId, this.tokenService.getHeaderAuth());
 }

 // Получение списка привилегий по ролям {userRoleId}
 getPermissionsByUserRole(userRoleId: any){
   return this.http.get(this.api.routes.getPermissionsByUserRole + '/' + userRoleId, this.tokenService.getHeaderAuth());
 }
 // Добавление новых пользователей {addPermissions}/{userRoleId} POST запрос
 addUser(update: any){
   return this.http.post(this.api.routes.addUser, update, this.tokenService.getHeaderAuth());
 }
 // Блокирование пользователя {userId} POST запрос
 blockUser(userId: any){
   return this.http.post(this.api.routes.blockUser + '/' + userId, {}, this.tokenService.getHeaderAuth());
 }
 // Разблокирование пользователя {userId} POST запрос
 unblockUser(userId: any){
   return this.http.post(this.api.routes.unblockUser + '/' + userId, {}, this.tokenService.getHeaderAuth());
 }
 // Получение списка пользователей
 findAllUsers(){
   return this.http.get(this.api.routes.findAllUsers, this.tokenService.getHeaderAuth());
 }
 // Получение списка привилегий пользователя
 findUserPermissions(){
   return this.http.get(this.api.routes.findUserPermissions, this.tokenService.getHeaderAuth());
 }

 searchJobByBin(bin: number){
  return this.http.get(this.api.backend + '/findByBin/' + bin, this.tokenService.getHeaderAuth());
 }

 getJobList(katoId: number){
  return this.http.get(this.api.backend + '/findAllJobByKatoId/' + katoId, this.tokenService.getHeaderAuth());
 }
 findAllTestPeriodByTestVptId(testVptId: any){
  return this.http.get(this.api.backend + '/findAllTestPeriodByTestVptId/' + testVptId, this.tokenService.getHeaderAuth());
 }


 findAllTestPeriodByTestVptIds(testVptId: any){
  return this.http.get(this.api.backend + '/findAllTestPeriodByTestVptId/' + testVptId, this.tokenService.getHeaderAuth());
 }

 findStudentTestByTestVptIdAndTestPeriodWithIin(testVptId: any, testPeriodId: any, iin: any){
  return this.http.get(this.api.backend + '/studentTest/findStudentTestByTestVptIdAndTestPeriod' + '?testVptId=' + testVptId + '&testPeriodId=' + testPeriodId + '&iin=' + iin, this.tokenService.getHeaderAuth());
 }

 findStudentTestByTestVptIdAndTestPeriod(testVptId: any, testPeriodId: any){
  return this.http.get(this.api.backend + '/studentTest/findStudentTestByTestVptIdAndTestPeriod/' + testVptId + '/' + testPeriodId, this.tokenService.getHeaderAuth());
 }

 updateUser(updateUsers: any){
  return this.http.put(this.api.routes.updateUser, updateUsers, this.tokenService.getHeaderAuth());
 }

 getUpdatesUserInfo(userId: any){
  return this.http.get(this.api.routes.getUpdatesUserInfo + '/' + userId, this.tokenService.getHeaderAuth());
 }

 findAllBlockedUsers(){
  return this.http.get(this.api.routes.findAllBlockedUsers, this.tokenService.getHeaderAuth());
 }

 findUserByIin(iin: any){
  return this.http.get(this.api.routes.findUserByIin + '/' + iin, this.tokenService.getHeaderAuth());
 }

 getLandingLeafList(testVptId: any, testPeriodId: any, token: any, userInfoToken: any, sort: any, showPass: any){
  return this.http.get(this.api.backend + '/getLandingLeafList' + '?testVptId=' + testVptId + '&testPeriodId=' + testPeriodId + '&token=' + token + '&userInfoToken=' + userInfoToken + '&sort=' + sort + '&showPass=' + showPass, {responseType: 'blob', headers: this.tokenService.getTok()});
 }

 getLandingLeafListExcel(testVptId: any, testPeriodId: any, token: any, userInfoToken: any, sort: any, showPass: any){
  return this.http.get(this.api.backend + '/getLandingLeafListExcel' + '?testVptId=' + testVptId + '&testPeriodId=' + testPeriodId + '&token=' + token + '&userInfoToken=' + userInfoToken + '&sort=' + sort + '&showPass=' + showPass, {responseType: 'blob', headers: this.tokenService.getTok()});
 }

 resetSession(value: any){
  return this.http.put(this.api.backend + '/studentTest/resetSession', value, this.tokenService.getHeaderAuth());
 }

 getFindAllInstitute(){
  return this.http.get(this.api.backend + '/findAllInstitute', this.tokenService.getHeaderAuth());
 }

 findAllSubjectByJobTypeIdAndSpecialityIdAndEduTypeIdAndLangId​(jobTypeId: any, specialityId: any, eduTypeId: any, langId: any){
  return this.http.get(this.api.backend + '/findAllSubjectByJobTypeIdAndSpecialityIdAndEduTypeIdAndLangId​' + '/' + jobTypeId + '/' + specialityId + '/' + eduTypeId + '/' + langId, this.tokenService.getHeaderAuth());
 }

 getLandingLeafListArchive(token: any, userInfoToken: any, sort: any, value: any){
  return this.http.post(this.api.backend + '/createLandingLeafZIP' + '?token=' + token + '&userInfoToken=' + userInfoToken + '&sort=' + sort, value, {responseType: 'blob', headers: this.tokenService.getTok()});
 }

 findSpecialityByJobTypeId(jobTypeId: any){
  return this.http.get(this.api.backend + '/findSpecialityByJobTypeId' + '/' + jobTypeId, this.tokenService.getHeaderAuth());
 }

 findSchoolById(schoolId: any){
  return this.http.get(this.api.backend + '/findSchoolById' + '/' + schoolId, this.tokenService.getHeaderAuth());
 }

 findAllRaionByOblId​(oblId: any){
  return this.http.get(this.api.routes.findAllRaionByOblId​ + '/' + oblId, this.tokenService.getHeaderAuth());
 }

 findAllSchoolByOblId(oblId: any){
  return this.http.get(this.api.routes.findAllSchoolByOblId + '/' + oblId, this.tokenService.getHeaderAuth());
 }

 finAllSchoolByOblIdAndRaionId(oblId: any, raionId: any){
  return this.http.get(this.api.routes.finAllSchoolByOblIdAndRaionId + '/' + oblId + '/' + raionId, this.tokenService.getHeaderAuth());
 }

 exit(){
  return this.http.post(this.api.backend + '/exit', {}, this.tokenService.getHeaderAuth());
 }

 getLocalHost(){
  return this.http.get('http://localhost:8080');
 }

 findSomeTestStatus(){
 return this.http.get(this.api.backend + '/ref/findSomeTestStatus', this.tokenService.getHeaderAuth());
 }

 findTestTime(){
  return this.http.get(this.api.backend + '/findTestTime', this.tokenService.getHeaderAuth());
 }

 findAllHeaderType(){
  return this.http.get(this.api.backend + '/ref/findAllHeaderType', this.tokenService.getHeaderAuth());
 }

 editForTestType(studentTestId: any, value: any){
   return this.http.put(this.api.backendApp + '/app/items/' + studentTestId, value, this.tokenService.getHeaderAuth());
 }

 getShort(id: any){
  return this.http.get(this.api.backendApp + '/app/items' + '/' + id + '/short', this.tokenService.getHeaderAuth());
}

getCategory(jobTypeId: any, employmentTypeId: any){
  return this.http.get(this.api.backendApp + '/app/job-type/items' + '/' + jobTypeId + '/employment-type/items' + '/' + employmentTypeId + '/category/from/items', this.tokenService.getHeaderAuth());
}

  findAllTestPeriodByVptIdAndTestDateAndJobTypeNew(jobTypeId: any, employmentTypeId: any, testOrgId : any){
    return this.http.get(this.api.backendApp + '/app/job-type/items/' + jobTypeId  + '/employment-type/items/' + employmentTypeId + '/test-org/items/' + testOrgId + '/test-period/items', this.tokenService.getHeaderAuth());
  }

toCategory(jobTypeId: any, employmentTypeId: any){
  return this.http.get(this.api.backendApp + '/app/job-type/items' + '/' + jobTypeId + '/employment-type/items' + '/' + employmentTypeId + '/category/to/items', this.tokenService.getHeaderAuth());
}

katoList(jobTypeId: any, id: any){
  return this.http.get(this.api.backendApp + '/app/job-type/items' + '/' + jobTypeId + '/kato/items' + '/' + id + '/children', this.tokenService.getHeaderAuth());
}

katoCodeList(jobTypeId: any, id: any){
  return this.http.get(this.api.backendApp + '/app/job-type/items' + '/' + jobTypeId + '/kato/items' + '/' + id + '/job/items', this.tokenService.getHeaderAuth());
}

jobType(){
  return this.http.get(this.api.backendApp + '/app/job-type/items', this.tokenService.getHeaderAuth());
}

getRaionList(jobTypeId: any, employmentTypeId: any, oblId: any){
  return this.http.get(this.api.backendApp + '/app/job-type/items' + '/' + jobTypeId + '/employment-type/items' + '/' + employmentTypeId + '/obl/items' + '/' + oblId + '/raion/items', this.tokenService.getHeaderAuth());
}

getOblList(jobTypeId: any, employmentTypeId: any){
return this.http.get(this.api.backendApp + '/app/job-type/items' + '/' + jobTypeId + '/employment-type/items' + '/' + employmentTypeId + '/obl/items', this.tokenService.getHeaderAuth());
}

getOblStud(jobTypeId: any, employmentTypeId: any){
  return this.http.get(this.api.backendApp + '/app/job-type/items' + '/' + jobTypeId + '/employment-type/items' + '/' + employmentTypeId + '/obl/items', this.tokenService.getHeaderAuth());
  }

getOblSchool(jobTypeId: any, employmentTypeId: any){
  return this.http.get(this.api.backendApp + '/app/job-type/items' + '/' + jobTypeId + '/employment-type/items' + '/' + employmentTypeId + '/obl/items', this.tokenService.getHeaderAuth());
 }

punktList(jobTypeId: any){
return this.http.get(this.api.backendApp + '/app/job-type/items' + '/' + jobTypeId + '/test-org/items', this.tokenService.getHeaderAuth());
}

  punktListNew(jobTypeId: any, employmentTypeId: any, oblId: any){
    return this.http.get(this.api.backendApp + '/app/job-type/items' + '/' + jobTypeId + '/employment-type/items' + '/' + employmentTypeId + '/obl/items' + '/' + oblId + '/test-org/items', this.tokenService.getHeaderAuth());
  }

headerTypeLang(jobTypeId: any, employmentTypeId: any, headerTypeId: any){
  return this.http.get(this.api.backendApp + '/app/job-type/items' + '/' + jobTypeId + '/employment-type/items' + '/' + employmentTypeId + '/header-type/items' + '/' + headerTypeId + '/lang/items', this.tokenService.getHeaderAuth());
}

testPeriodList(jobTypeId: any, employmentTypeId: any, testOrgId: any){
  return this.http.get(this.api.backendApp + '/app/job-type/items' + '/' + jobTypeId + '/employment-type/items' + '/' + employmentTypeId + '/test-org/items' + '/' + testOrgId + '/test-period/items', this.tokenService.getHeaderAuth());
}

testLangList(jobTypeId: any, employmentTypeId: any = 0, subjectId: any){
  return this.http.get(this.api.backendApp + '/app/job-type/items' + '/' + jobTypeId + '/employment-type/items' + '/' + employmentTypeId + '/subject/items' + '/' + subjectId + '/lang/items', this.tokenService.getHeaderAuth());
}

subjList(jobTypeId: any, employmentTypeId: any, subjectOrd: any){
  return this.http.get(this.api.backendApp + '/app/job-type/items' + '/' + jobTypeId + '/employment-type/items' + '/' + employmentTypeId + '/subject/ord/items' + '/' + subjectOrd + '/subject/items', this.tokenService.getHeaderAuth());
}

subjCount(jobTypeId: any, employmentTypeId: any){
return this.http.get(this.api.backendApp + '/app/job-type/items' + '/' + jobTypeId + '/employment-type/items' + '/' + employmentTypeId + '/subject/ord/count', this.tokenService.getHeaderAuth());
}

testOrgList(jobTypeId: number, employmentTypeId: any, oblId: any) {
  return this.http.get(this.api.backendApp + '/app/job-type/items' + '/' + jobTypeId + '/employment-type/items' + '/' + employmentTypeId + '/obl/items' + '/' + oblId + '/test-org/items', this.tokenService.getHeaderAuth());
}

specialityList(jobTypeId: any, employmentTypeId: any, eduTypeId: any){
  return this.http.get(this.api.backendApp + '/app/job-type/items' + '/' + jobTypeId + '/employment-type/items' + '/' + employmentTypeId + '/edu-type/items' + '/' + eduTypeId + '/speciality/items', this.tokenService.getHeaderAuth());
}

getTipoSoSubjectsItems(jobTypeId: any, employmentTypeId: any, eduTypeId: any, specialityId: any, subjectOrd: any, sort: any): any {
  return this.http.get(this.api.backendApp + '/app/job-type/items' + '/' + jobTypeId + '/employment-type/items' + '/' + employmentTypeId + '/edu-type/items' + '/' + eduTypeId + '/speciality/items' + '/' + specialityId + '/subject/ord/items' + '/' + subjectOrd + '/subject/items?sort=' + sort, this.tokenService.getHeaderAuth());
}

getSubjectLang(jobTypeId: number, employmentTypeId: any, subjectId : number): any {
  return this.http.get(this.api.backendApp + '/app/job-type/items' + '/' + jobTypeId + '/employment-type/items' + '/' + employmentTypeId + '/subject/items' + '/' + subjectId + '/lang/items', this.tokenService.getHeaderAuth());
}

getTipoSubjectLang(jobTypeId: number, employmentTypeId: any, eduTypeId: number, specialityId: number, subjectOrd : number): any {
  return this.http.get(this.api.backendApp + '/app/job-type/items' + '/' + jobTypeId + '/employment-type/items' + '/' + employmentTypeId + '/edu-type/items' + '/' + eduTypeId + '/speciality/items' + '/' + specialityId + '/subject/items' + '/' + subjectOrd  + '/lang/items', this.tokenService.getHeaderAuth());
}

langList(jobTypeId: any, employmentTypeId: any, eduTypeId: any, specialityId: any, subjectId: any){
return this.http.get(this.api.backendApp + '/app/job-type/items' + '/' + jobTypeId + '/employment-type/items' + '/' + employmentTypeId + '/edu-type/items' + '/' + eduTypeId + '/speciality/items' + '/' + specialityId + '/subject/items' + '/' + subjectId + '/lang/items', this.tokenService.getHeaderAuth());
}

findAllTestPeriodByTestVptIdApp(jobTypeId: any, employmentTypeId: any, testOrgId: any){
    return this.http.get(this.api.backendApp + '/app/job-type/items' + '/' + jobTypeId + '/employment-type/items' + '/' + employmentTypeId + '/test-org/items' + '/' + testOrgId + '/test-period/items', this.tokenService.getHeaderAuth());
  }
headerType(jobTypeId: any, employmentTypeId: any){
return this.http.get(this.api.backendApp + '/app/job-type/items' + '/' +  jobTypeId + '/employment-type/items' + '/' + employmentTypeId + '/header-type/items', this.tokenService.getHeaderAuth());
}

  headerTypeNew(jobTypeId: any, employmentTypeId: any, headerTypeId: any){
    return this.http.get(this.api.backendApp + '/app/job-type/items' + '/' + jobTypeId + '/employment-type/items' + '/' + employmentTypeId + '/header-type/items' + '/' + headerTypeId + '/lang/items', this.tokenService.getHeaderAuth());
  }
collegeSpec(jobTypeId: any){
  return this.http.get(this.api.backendApp + '/app/job-type/items' + '/' + jobTypeId + '/college-speciality-for-graduates/items', this.tokenService.getHeaderAuth());
}

getCollege(jobTypeId: any, employmentTypeId: any, oblId: any, raionId: any){
  return this.http.get(this.api.backendApp + '/app/job-type/items' + '/' + jobTypeId + '/employment-type/items' + '/' + employmentTypeId + '/obl/items' + '/' + oblId + '/raion/items' + '/' + raionId + '/college/items', this.tokenService.getHeaderAuth());
}

instituteSpec(jobTypeId: any){
return this.http.get(this.api.backendApp + '/app/job-type/items' + '/' + jobTypeId + '/institute-speciality/items', this.tokenService.getHeaderAuth());
}

instituteList(jobTypeId: any, employmentTypeId: any, oblId: any){
  return this.http.get(this.api.backendApp + '/app/job-type/items' + '/' + jobTypeId + '/employment-type/items' + '/' + employmentTypeId + '/obl/items' + '/' + oblId + '/institute/items', this.tokenService.getHeaderAuth());
}

getSpecialityItems(jobTypeId: number, employmentTypeId: any, eduTypeId: number): any {
  return this.http.get(this.api.backendApp + '/app/job-type/items' + '/' + jobTypeId + '/employment-type/items' + '/' + employmentTypeId + '/edu-type/items' + '/' + eduTypeId + '/speciality/items', this.tokenService.getHeaderAuth());
}

dowloadSertif(testType: any, year: any, id: any, iin: any){
  return this.http.get('https://certificate.testcenter.kz/api/v1/certificate/test/download-link/test-type/' + testType + '/year/' + year + '/student/' + id + '/iin/' + iin)
}

transferStudentTest(withStatusNeYavilsya: any, update: any){
  return this.http.post(this.api.backend + '/studentTest/transferStudentTest' + '/' + withStatusNeYavilsya, update, this.tokenService.getHeaderAuth());
}

nqt(value: any){
  return this.http.post(this.api.backendStat + '/nqt', value, {responseType: 'blob', headers: this.tokenService.getTok()});
}

testYearStats(){
  return this.http.get(this.api.backendStat + '/getYears', this.tokenService.getHeaderAuth());
 }

 employmentType(jobTypeId: any){
  return this.http.get(this.api.backendApp + '/app/job-type/items' + '/' + jobTypeId + '/employment-type/items', this.tokenService.getHeaderAuth());
 }

 roomPlaceNumber(testOrgId: any, testPeriodId: any, roomId: any){
  return this.http.get(this.api.backend + '/studentTest/roomPlaceNumber/' + testOrgId + '/' + testPeriodId + '/' + roomId, this.tokenService.getHeaderAuth());
 }

 roomNumber(testPeriodId: any, testOrgId: any){
  return this.http.get(this.api.backend + '/studentTest/roomNumber/' + testPeriodId + '/' + testOrgId, this.tokenService.getHeaderAuth());
 }

 getStudentTestInfo(studentTestId: any){
  return this.http.get(this.api.backend + '/studentTest/getStudentTestInfo/' + studentTestId, this.tokenService.getHeaderAuth());
 }

 newHeaderType(jobTypeId: any, employmentTypeId: any){
  return this.http.get(this.api.backendApp + '/app/job-type/items' + '/' + jobTypeId + '/employment-type/items' + '/' + employmentTypeId + '/header-type/items', this.tokenService.getHeaderAuth());
 }

 newLang(jobTypeId: any, employmentTypeId: any, headerTypeId: any){
  return this.http.get(this.api.backendApp + '/app/job-type/items' + '/' + jobTypeId + '/employment-type/items' + '/' + employmentTypeId + '/header-type/items' + '/' + headerTypeId + '/lang/items', this.tokenService.getHeaderAuth());
 }

 studentTestListToExcel(testVptId: any, testPeriodId: any){
  return this.http.get(this.api.backend + '/studentTestListToExcel' + '?testVptId=' + testVptId + '&testPeriodId=' + testPeriodId, {responseType: 'blob', headers: this.tokenService.getTok()});
 }

 findAnnulmentType(studentTestId: any){
  return this.http.get(this.api.backend + '/studentTest/findAnnulmentType/' + studentTestId, this.tokenService.getHeaderAuth());
 }

 findAllAnnulmentType(){
  return this.http.get(this.api.backend + '/studentTest/findAllAnnulmentType/', this.tokenService.getHeaderAuth());
 }

}
