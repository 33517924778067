<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<div class="flex">
  <form [formGroup]="formMOdal" action="">
    <div (click)="showModalTransfer = !showModalTransfer" *ngIf="showModalTransfer"
         class="w-full h-screen bg-black top-0 left-0 z-20 bg-opacity-25 fixed"></div>
    <div *ngIf="showModalTransfer"
         class="text-center border-2 p-4 top-0 left-0 z-20 transferPay fixed bg-white">

      <div class="text-3xl"> {{ 'Перевод оплаты на тестируемого' | translate }} </div>

      <fieldset>
        <div class="flex p-3">
          <input (keydown.enter)="transferPaymentStudent()" value="1" (click)="checkSearchTypeModal(1)" formControlName="searchTypeModal" [value]="1" class="mt-1 w-5" type="radio">
          <label class="ml-2 cursor-pointer w-auto"> {{ 'Поиск по номеру заказа' | translate }} </label>
          <input (keydown.enter)="transferPaymentStudent()" *ngIf="this.formMOdal.get('searchTypeModal')?.value != 4 && this.formMOdal.get('searchTypeModal')?.value == 1" formControlName="orderId" class="rounded-lg bg-white ml-10 border-2" type="text">
          <div class="ml-10"></div>
          <div class="ml-10"></div>
        </div>

        <div class="flex p-3">
          <input (keydown.enter)="transferPaymentStudent()" value="1" (click)="checkSearchTypeModal(1)" formControlName="searchTypeModal" [value]="4" class="mt-1 w-5" type="radio">
          <label class="ml-2 cursor-pointer w-auto"> {{ 'ИКТ' | translate }} </label>
          <input (keydown.enter)="transferPaymentStudent()" *ngIf="this.formMOdal.get('searchTypeModal')?.value == 4 && this.formMOdal.get('searchTypeModal')?.value != 1" formControlName="studentTestId"  class="rounded-lg bg-white ml-10 border-2" type="text">
          <div class="ml-10"></div>
          <div class="ml-10"></div>
        </div>
      </fieldset>

      <button (click)="transferPaymentStudent()" type="button" *ngIf="formMOdal.get('searchTypeModal')!.value != 0"
              class="cursour-pointer w-40 bg-gray-300 hover:bg-gray-300 rounded-lg lg:px-3 py-1 ml-2 text-grey-dark text-sm">
        {{ 'Search' | translate }}
      </button>

      <div *ngIf="this.formMOdal.get('studentTestId')!.value != 0 || this.formMOdal.get('orderId')!.value != 0" class="">
        <div> Перевод оплаты между тестированиями</div>
      </div>


      <div *ngFor="let items of studentListTransfer" class="flex p-3">
        <p>Перевод на:</p>
        <label class="ml-2 cursor-pointer w-auto"> {{ items.lastname }} {{ items.firstname }} {{ items.patronymic }} </label>
      </div>
      <div *ngFor="let items of studentListTransfer" class="flex p-3">
        <p>ИИН:</p>
        <label class="ml-2 cursor-pointer w-auto"> {{ items.iin }} </label>
      </div>
      <div *ngFor="let items of studentListTransfer" class="flex p-3">
        <p>Номер заказа:</p>
        <label class="ml-2 cursor-pointer w-auto"> {{ items.orderId }} </label>
      </div>

      <div class="flex">
        <div class="w-1/2">
          <button (click)="setTransferPayment()"
                  class="w-56 my-10 rounded-lg btn text-center btn-outline-primary text-white btn-lg btn-block bg-red-400 p-1">
            {{ 'Confirm' | translate }}
          </button>
        </div>

        <div class="w-1/2">
          <button (click)="closeModal()"
                  class="w-56 my-10 rounded-lg btn bg-blue-400 text-center text-white btn-outline-primary btn-lg btn-block p-1">
            {{ 'Cancellation' | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>

  <form [formGroup]="form">
    <div class="text-gray-900 bg-gray-200 main-table-form">
      <div class="flex">
        <div>
          <fieldset form="form">
            <div class="flex p-3">
              <input (keydown.enter)="findStudent()" id="iins" value="1" (click)="checkSearchType(1)" formControlName="searchType" [value]="1" class="mt-1 w-5" type="radio">
              <label for="iins" class="ml-2 cursor-pointer w-auto"> {{ 'SearchByIIN' | translate }} </label>
              <input (keydown.enter)="findStudent()" *ngIf="this.form.get('searchType')?.value != 2 && this.form.get('searchType')?.value != 3" formControlName="iin" class="rounded-lg bg-white ml-10" type="text" placeholder="ИИН">
              <div class="ml-10"></div>
              <div class="ml-10"></div>
            </div>

            <div class="flex p-3">
              <input (keydown.enter)="findStudent()" id="order" value="1" (click)="checkSearchType(1)" formControlName="searchType" [value]="3" class="mt-1 w-5" type="radio">
              <label for="order" class="ml-2 cursor-pointer w-auto"> {{ 'Номер заказа' | translate }} </label>
              <input (keydown.enter)="findStudent()" *ngIf="this.form.get('searchType')?.value == 3" formControlName="orderId" class="rounded-lg bg-white ml-10" type="text" placeholder="Номер заказа">
              <div class="ml-10"></div>
              <div class="ml-10"></div>
            </div>

            <div class="flex p-3">
              <input id="fios" value="2" (click)="checkSearchType(2)" formControlName="searchType" class="mt-1 w-5" type="radio">
              <label for="fios" class="ml-2 cursor-pointer w-auto"> {{ 'SearchByFIO' | translate }} </label>
              <div *ngIf="this.form.get('searchType')?.value == 2" class="">
                <input (keydown.enter)="findStudent()" formControlName="lastName" class="rounded-lg bg-white ml-10"
                  placeholder=" {{ 'lastName' | translate }} " type="text">
                <input (keydown.enter)="findStudent()" formControlName="firstName" class="rounded-lg bg-white ml-10"
                  placeholder=" {{ 'firstName' | translate }} " type="text">
                <input (keydown.enter)="findStudent()" formControlName="patronymic" class="rounded-lg bg-white mt ml-10"
                  placeholder=" {{ 'patronymic' | translate }} " type="text">
              </div>
            </div>
          </fieldset>

          <button (click)="findStudent()" type="button" *ngIf="form.get('searchType')!.value != 0"
            class="cursour-pointer w-40 bg-gray-300 hover:bg-gray-300 rounded-lg lg:px-3 py-1 ml-2 text-grey-dark text-sm">
            {{ 'Search' | translate }}
          </button>
        </div>
      </div>
      <pagination-controls previousLabel="Назад" nextLabel="Вперед" (autoHide)="true" class="text-center mt-4"
        (pageChange)="checkPage($event)"></pagination-controls>

      <table *ngIf="informWindow == true" class="min-w-full">
        <thead>
          <tr>

            <th class="thClass">
            </th>

            <th scope="col" class="px-4 py-3 iinClass text-xs font-medium text-gray-500 uppercase">
              {{ 'IIN' | translate }}
            </th>

            <th scope="col" class="px-4 py-3 fioClass text-xs font-medium text-gray-500 uppercase">
              {{ 'FIO' | translate }}
            </th>

            <th scope="col" class="px-4 py-3 statusClass text-xs font-medium text-gray-500 uppercase">
              {{ 'StudentStatus' | translate }}
            </th>

            <th scope="col" class="px-4 py-3 text-xs text-left font-medium text-gray-500 uppercase">
              {{ 'Actions' | translate }}
            </th>

          </tr>
        </thead>
      </table>

      <div class="px-3 py-4 tableClass scroll-table-body flex">
        <table class="min-w-full">
          <tbody class="bg-white border-2.border-gray-200">
            <tr *ngFor="let student of studentList | paginate: { itemsPerPage: 50, currentPage: p, totalItems: this.numberOfPages }; index as i"
              class="border-2 border-gray-200 cursor-pointer hover:bg-gray-200"
              [ngClass]="{'bg-gray-300': hoverOnStudent == student.studentTestId}" (click)="oknoData(student)">

              <td class="px-2 py-2 text-center align-middle">
                <div class="text-sm text-gray-900"></div>
              </td>

              <td class="px-4 py-4 text-center align-middle">
                <div class="text-sm text-gray-900">
                  {{ student.iin }}
                </div>
              </td>

              <td class="px-4 py-4 fioTdClass text-left w-96 align-middle">
                <div class="text-sm text-gray-900">
                  {{ student.lastName }} {{ student.firstName }} {{ student.patronymic }}
                </div>
              </td>

              <td class="px-4 py-4 statusTdClass text-left align-middle">
                <div class="text-sm text-gray-900 w-52">
                  <span *ngIf="translate.currentLang == 'kz'"> {{ student.testStatus.nameKaz }} </span>
                  <span *ngIf="translate.currentLang == 'ru'"> {{ student.testStatus.nameRus }} </span>
                </div>
              </td>

              <td class="py-4 text-center actClass text-sm font-medium">
                <div (click)="returnMoney(student.orderId)" class="text-indigo-600 mx-3 rounded-lg border-2 bg-blue-300 p-2 cursor-pointer hover:text-indigo-900">
                  {{ 'Refund' | translate }}
                </div>
<!--                <div (click)="openModalTransfer(student)" class="text-indigo-600 mx-3 rounded-lg border-2 bg-blue-300 p-2 cursor-pointer hover:text-indigo-900">-->
<!--                  {{ 'Перевести оплату' | translate }}-->
<!--                </div>-->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </form>

  <div *ngIf="informWindow == true" class="mt-3 ml-3 bg-white border-2 realy">
    <div *ngIf="hoverOnStudent" class="pl-2">
      <p *ngIf="testStatus.id != 0"> <b> {{ 'TestingSeason:' | translate }} </b>
        <span *ngIf="translate.currentLang == 'kz'"> {{ season.nameKaz }} </span>
        <span *ngIf="translate.currentLang == 'ru'"> {{ season.nameRus }} </span>
      </p>
      <p> <b> {{ 'FIO' | translate }}: </b> {{ lastName }} {{ firstName }} {{ patronymic }} </p>
      <p> <b> {{ 'IIN' | translate }}: </b> {{ iin }} </p>
      <p> <b> {{ 'IKT' | translate }}: </b> {{ studentTestId }} </p>
      <p> <b> {{ 'ApplicationDate' | translate }}: </b> {{ dateZayav }} </p>
      <p> <b> {{ 'phone' | translate }}: </b> {{ phoneNumber }} </p>
      <p *ngFor="let subject of subjects;index as i">
        <span>
          <b> {{ 'Item' | translate }}: </b> {{ i+1 }}
          <span *ngIf="translate.currentLang == 'kz'"> {{ subject?.nameKaz }} - балл {{ subject.testScore }} </span>
          <span *ngIf="translate.currentLang == 'ru'"> {{ subject?.nameRus }} - балл {{ subject.testScore }} </span>
        </span>
      </p>
      <p *ngIf="testStatus.id != 0"> <b> {{ 'TestDate' | translate }}: </b> {{ testPeriod.testDate }} - {{ testPeriod.startTestTime }}</p>
      <p> <b> {{ 'StartTesting:' | translate }} </b> {{ testPeriod.startTestTime }} </p>
<!--      <p> <b> {{ 'EndTesting:' | translate }} </b> {{ testFinishedAt }} </p>-->
      <p *ngIf="institute != null"> <b> Вуз: </b>
        <span *ngIf="translate.currentLang == 'kz'"> {{ institute.nameKaz }}, {{ institute.shortNameKaz }} </span>
        <span *ngIf="translate.currentLang == 'ru'"> {{ institute.nameRus }}, {{ institute.shortNameRus }} </span>
      </p>
      <p> <b> {{ 'Status' | translate }}: </b>
        <span *ngIf="translate.currentLang == 'kz'"> {{ testStatus.nameKaz }} </span>
        <span *ngIf="translate.currentLang == 'ru'"> {{ testStatus.nameRus }} </span>
      </p>
      <p> <b> {{ 'StatusOrder:' | translate }} </b>
        <span *ngIf="translate.currentLang == 'kz'"> {{ paymentStatusEntity.nameKaz }} </span>
        <span *ngIf="translate.currentLang == 'ru'"> {{ paymentStatusEntity.nameRus }} </span>
      </p>
      <p> <b> Дата и время оплаты </b>
       {{ dateOrder }}
      </p>
      <p> <b> {{ 'NumOrder:' | translate }} </b>
         {{ orderId }}
      </p>
      <p> <b> Сумма оплаты: </b>
        {{ amountOfMoney }}
     </p>
      <p> <b> {{ 'PaymentMethod:' | translate }} </b>
        <span *ngIf="translate.currentLang == 'kz'"> {{ paymentProviderType.nameKaz }} </span>
        <span *ngIf="translate.currentLang == 'ru'"> {{ paymentProviderType.nameRus }} </span>
      </p>
    </div>
  </div>
</div>
<!-- 152056 -->
<!-- 428385 -->
<!-- 148493 -->
