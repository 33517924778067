import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { Routes, RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { MainComponent } from './main/main.component';
import { StudentTestComponent } from './student-test/student-test.component';
import { AddUserComponent } from './add-user/add-user.component';
import { apiRoutes } from './config/api.routes';
import { NewIncidentComponent } from './new-incident/new-incident.component';
// import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgxLoadingModule } from 'ngx-loading';
import { OperatorsComponent } from './operators/operators.component';
import { StudentsComponent } from './students/students.component';
import { AuthorizationComponent } from './authorization/authorization.component';
import { StreamSearchComponent } from './stream-search/stream-search.component';
import { EditUsersComponent } from './edit-users/edit-users.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { AppnctComponent } from './appnct/appnct.component';
import { Nqt1Component } from './nqt1/nqt1.component';
import { Nqt2Component } from './nqt2/nqt2.component';
import { Nqt3Component } from './nqt3/nqt3.component';
import { Nqt5Component } from './nqt5/nqt5.component';
import { Nqt6Component } from './nqt6/nqt6.component';
import { Nqt7Component } from './nqt7/nqt7.component';
import { Nqt8Component } from './nqt8/nqt8.component';
import { Nqt9Component } from './nqt9/nqt9.component';
import { Nqt10Component } from './nqt10/nqt10.component';
import { Nqt11Component } from './nqt11/nqt11.component';
import { Nqt12Component } from './nqt12/nqt12.component';
import { Nqt13Component } from './nqt13/nqt13.component';
import { StatNqtComponent } from './stat-nqt/stat-nqt.component';
import { IncidentComponent } from './incident/incident.component';
import { IncidentForOperComponent } from './incident-for-oper/incident-for-oper.component';
import { IncidentForNctComponent } from './incident-for-nct/incident-for-nct.component';
import { IncidentForIbComponent } from './incident-for-ib/incident-for-ib.component';
import { IncidentForEditopenComponent } from './incident-for-editopen/incident-for-editopen.component';
import { ProbtestComponent } from './probtest/probtest.component';
import { BackPayComponent } from "./back-pay/back-pay.component";
import { FilterPipe } from "./filter.pipe";
import { FilterPipeModule } from "ngx-filter-pipe";

const routes: Routes =[
  { path: '', redirectTo: '/main', pathMatch: 'full' },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'appnct', component: AppnctComponent },
  { path: 'main', component: MainComponent, children:[
    { path: 'add-user', component: AddUserComponent },
      { path: 'incident', component: IncidentComponent },
      { path: 'back-pay', component: BackPayComponent },
    { path: 'incident-for-oper', component: IncidentForOperComponent },
    { path: 'incident-for-nct', component: IncidentForNctComponent },
    { path: 'incident-for-ib', component: IncidentForIbComponent },
    { path: 'incident-for-editopen', component: IncidentForEditopenComponent },
    { path: 'student-test', component: StudentTestComponent },
    { path: 'operators', component: OperatorsComponent },
    { path: 'students', component: StudentsComponent },
    { path: 'stream-search', component: StreamSearchComponent },
      { path: 'new-incident', component: NewIncidentComponent},
    { path: 'edit-users', component: EditUsersComponent },
    { path: '1', component: Nqt1Component }, // +   1
    { path: 'stat-nqt', component: StatNqtComponent }, // +   1
    { path: '11', component: Nqt11Component }, // +  2
    { path: '3', component: Nqt3Component }, // +   3
      // , data: {jobType: 3}
    { path: '2', component: Nqt2Component }, // +   4
    { path: '5', component: Nqt5Component }, // +   5
    { path: '8', component: Nqt8Component }, // +   6
    { path: '9', component: Nqt9Component }, // +   7
    { path: '10', component: Nqt10Component }, // +  8
    { path: '6', component: Nqt6Component }, //  +  9
    { path: '7', component: Nqt7Component }, // +-  10
    { path: '12', component: Nqt12Component }, //
    { path: '13', component: Nqt13Component }, //
    { path: 'probtest', component: ProbtestComponent}


  ]},
]

// const maskConfigFunction: () => Partial<IConfig> = () => {
//   return {
//     validation: true,
//   };
// };

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    StudentTestComponent,
    AddUserComponent,
    OperatorsComponent,
    StudentsComponent,
    AuthorizationComponent,
    StreamSearchComponent,
    EditUsersComponent,
    BackPayComponent,
    UnauthorizedComponent,
    FilterPipe,
    AppnctComponent,
    NewIncidentComponent,
    Nqt1Component,
    Nqt2Component,
    Nqt3Component,
    // Nqt4Component,
    Nqt5Component,
    Nqt6Component,
    Nqt7Component,
    Nqt8Component,
    Nqt9Component,
    Nqt10Component,
    Nqt11Component,
    Nqt12Component,
    Nqt13Component,
    StatNqtComponent,
    IncidentComponent,
    IncidentForOperComponent,
    IncidentForNctComponent,
    IncidentForIbComponent,
    IncidentForEditopenComponent,
    ProbtestComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FilterPipeModule,
    BrowserAnimationsModule,
    NgxLoadingModule.forRoot({}),
    NgxPaginationModule,
    SimpleNotificationsModule.forRoot({
      position: ["bottom", "right"],
      showProgressBar: true,
      timeOut: 15000,
      pauseOnHover: true
    }),
    // NgxMaskModule.forRoot(maskConfigFunction),
    FormsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'ru',
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    ReactiveFormsModule,
    AppRoutingModule,
    RouterModule.forRoot(routes)
  ],
  providers: [apiRoutes],
  bootstrap: [AppComponent]
})
export class AppModule { }
