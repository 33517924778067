import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TokenServiceService {

  constructor() { }

  authToken(token: string): boolean {
    localStorage.setItem('token', 'token');
    return true;
  }

  getTok(): any {
      let headers = {
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'userInfoToken': '' + localStorage.getItem('userInfoToken')
     }
     return headers;
  }

  getHeaderAuth(): any {
    if (localStorage.getItem('token')) {
      let userTok: any = localStorage.getItem('userInfoToken');
      let headers = {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'userInfoToken': '' + localStorage.getItem('userInfoToken')
        })
      }
      return headers;
    }
    return this.getHeaderNotAuth();
  }


  getUser(): any {
    if (localStorage.getItem('token')) {
      let headers = {
        headers: {
          'userInfoToken': localStorage.getItem('userInfoToken')
        }
      }
      return headers;
    }
    return this.getHeaderNotAuth();
  }

  getHeaderNotAuth(): any {
    return {};
  }

}
