import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Routes } from '@angular/router';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.css']
})
export class UnauthorizedComponent implements OnInit {

  constructor(
    private route: ActivatedRoute, 
    public router: Router) { }

  ngOnInit(): void {
  }

  back(){
    localStorage.clear();
    window.location.href = './';
  }

}
