import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, ReactiveFormsModule, FormGroup, Validators, FormArrayName, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute, } from '@angular/router';
import { StudentTestControllerService } from '../services/student-test-controller.service';
import { AutheticationControllerService } from '../services/authetication-controller.service';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { IDropdownSettings } from 'ng-multiselect-dropdown';


@Component({
  selector: 'app-incident-for-nct',
  templateUrl: './incident-for-nct.component.html',
  styleUrls: ['./incident-for-nct.component.css']
})
export class IncidentForNctComponent {

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public sanitizer: DomSanitizer,
    private main: StudentTestControllerService,
    public translate: TranslateService,
    private _notifications: NotificationsService) { }

  public loading = false;
  form!: FormGroup;
  formPeresad!: FormGroup;
  dropdownSettings: IDropdownSettings = {};
  resetSessionTypeId: any;
  studentId!: number;
  selectedItems: any = [];

  showPeresadka = false;
  clicked = false;
  testDate: any;
  timeMask = '00:00';
  iin: any;
  testOrgEntity: any;
  lastname: any;
  placeNum: any;
  remoteStudentTestId: any;
  patronymic: any;
  testPeriodForStatementsDtoList: any = [];
  statementId: any;
  studentTestStatusTypeId: any;
  testFinishedAt: any;
  studentTestStatusTypeName: any;
  testLangId: any;
  time: string = '00:00';
  notice!: string;
  testLangName: any;
  testOrgName: any;
  testStartedAt: any;
  testTypeId: any;
  testTimeLeftInMinutes: any;
  auditNum: any;
  fileIdToDelete: any;
  createdAt: any;
  testPeriodList: any;
  firstname: any;
  resetSessionList: any;
  statId: any;
  num!: number;
  namesIds: any;
  allUsersList: any;
  dropdownList: any;
  actionTypeIds: any = [];
  studentTest: any;
  zaprosInc = Array<any>();
  studentTestId: any = [];
  checkedCount = 0;
  currentPageArray: any = [];
  checkeds: any = [];
  allInc: any;
  checked = false;
  actionTypeId: any;
  student: any;
  id: any;
  actionType: any = [];
  statusForIBNCT: any;
  role: any;
  withStatusNeYavilsya = false;
  userRole: any;
  document: any;
  statusListForUsers: any;
  statementStatusId: any;
  testPeriodId: any;
  checkId = false;
  statementsTime: any;
  addTime: any;
  addAppealTime: any;
  disabled = false;
  checkApel: any;
  isChecked = false;
  childActionId: any;
  parentActionId: any
  parentList: any;
  ActionTreeList: any;
  oblLists: any;
  periodOrgLists: any;
  checkedAll = true;
  statFiles: any[] = [];
  child: any[] = [];
  showPeresadkaOneWind = false;
  roomPlaceNumber: any;
  posadOblList: any;
  roomId: any;
  oblId: any;
  testOrgId: any;
  testOrgLists: any;
  freePlaceList: any;
  auditPlaceList: any;
  roomFreeNumber: any;
  transferList: any;
  testOrgList: any;
  testPeriodOrgList: any;

  ngOnInit(): void {

    this.statId = this.route.snapshot.queryParamMap.get('statementId');

    this.form = new FormGroup({
      resetSessionTypeId: new FormControl(),
      studentTestId: new FormControl(),
      num: new FormControl(),
      childActionId: new FormControl(),
      parentActionId: new FormControl(),
      additionalFiles: new FormArray([]),
      actionTypeIds: new FormArray([]),
      statementId: new FormControl(),
      addTime: new FormControl(),
      testDate: new FormControl(),
      actionTypeId: new FormControl(),
      statId: new FormControl(),
      checked: new FormControl(false),
      checkedAll: new FormControl(false),
      id: new FormControl(),
      statementStatusId: new FormControl(),
      notice: new FormControl(),
      testPeriodId: new FormControl(),
      statementsTime: new FormControl(),
      addAppealTime: new FormControl(),
    });

    this.formPeresad = new FormGroup({
      // oblId: new FormControl(0),
      studentTestId: new FormControl(0),
      testOrgId: new FormControl(0),
      testPeriodId: new FormControl(),
      id: new FormControl(),
      oblIds: new FormControl(),
      // testOrgId: new FormControl(),
      // testPeriod: new FormControl(),
      // list: new FormArray([]),
      withStatusNeYavilsya: new FormControl(false),
      selectAll: new FormControl(false),
    });

    this.changeStatusStatement();

  }


  changeStatusStatement() {
    this.main.changeStatusStatement(this.statId)
      .subscribe((res: any) => {
        this.allInc = res;
        this.form.get('id')!.setValue(+this.statId);
        this.form.get('resetSessionTypeId')!.setValue(this.allInc.resetSessionTypeId);
        this.form.get('testPeriodId')?.setValue(this.allInc.testPeriod.id);
        this.form.get('statementId')!.setValue(this.allInc.statementId);
        this.form.get('actionTypeId')!.setValue(this.allInc.actionTypeId);
        this.form.get('notice')!.setValue(this.allInc.notice);
        this.form.get('addTime')!.setValue(this.allInc.addTime);
        this.form.get('statementsTime')!.setValue(this.allInc.statementsTime);
        this.form.get('addAppealTime')!.setValue(this.allInc.addAppealTime);
        this.form.get('childActionId')!.setValue(this.allInc.childActionId);
        this.form.get('parentActionId')!.setValue(this.allInc.parentActionId);
        this.addTime = this.allInc.addTime;
        this.addAppealTime = this.allInc.addAppealTime
        this.actionType = this.allInc.actionType;
        this.studentTest = this.allInc.studentTest;
        this.childActionId = this.allInc.childActionId;
        this.parentActionId = this.allInc.parentActionId;

        this.findAllResetSessionType(1);

        this.findAllStatementsActionTree();

        this.findStatementsStatusForOperatorNctAndIb();

        this.statementAttachedFiles();

        this.parentList = res.actionType;
        this.actionTypeIds = res.actionType;

        this.studentTest.forEach((element: any) => {
          element.isChecked = true;
        });

        res.actionType.forEach((element: any) => {
          this.actionsArray.push(this.actionSaveUser(
            element.childActionId,
            element.parentActionId,
            element.parentActionNameKaz,
            element.parentActionNameRus,
            element.childActionNameKaz,
            element.childActionNameRus,
            element.checked
          ));
        });

        this.form.get('actionTypeIds')!.value.forEach((element: any) => {
          if (element.checked == true && element.childActionId == 3) {
            this.checkId = true;
          }
          if (element.checked == true && element.childActionId == 8) {
            this.checkApel = true;
          }
        });
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
          this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
      });
  }

  findAllStatementsActionTree() {
    this.main.findAllStatementsActionTree()
      .subscribe((res: any) => {
        this.ActionTreeList = [];
        this.ActionTreeList = res;


        this.ActionTreeList.forEach((e: any) => {
          if (this.form.get('parentActionId')!.value == e.parentActionId) {
            this.child = [
              {
                parentActionId: e.parentActionId,
                childActionId: e.childActionId,
                checked: true
              }
            ]

          }

        })

      }, (err: any) => {
      });
  }

  actionSaveUser(childActionId: any, parentActionId: any, parentActionNameRus: any, parentActionNameKaz: any, childActionNameKaz: any, childActionNameRus: any, checked: boolean = false): FormGroup {
    return new FormGroup({
      childActionId: new FormControl(childActionId),
      parentActionId: new FormControl(parentActionId),
      parentActionNameRus: new FormControl(parentActionNameRus),
      parentActionNameKaz: new FormControl(parentActionNameKaz),
      childActionNameKaz: new FormControl(childActionNameKaz),
      childActionNameRus: new FormControl(childActionNameRus),
      checked: new FormControl(checked),
    })
  }

  // formsOfActions() {
  //   this.parentList.forEach((element: any) => {
  //     this.actionsArray.push(this.actionSaveUser(
  //       element.childActionId,
  //       element.parentActionId,
  //       element.parentActionNameKaz,
  //       element.parentActionNameRus,
  //       element.childActionNameKaz,
  //       element.childActionNameRus,
  //       element.checked
  //     ));
  //   });
  // }
  get actionsArray(): any { return this.form.get('actionTypeIds') as FormArray }

  findChildActionByParentId(action: any) {
    // this.actionsArray.controls = [];
    this.main.findChildActionByParentId(action.parentActionId)
      .subscribe((res: any) => {
        this.parentList = [];
        this.parentList = res;
        this.formsOfActions(action);
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
          this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
      });
  }

  formsOfActions(action: any) {


    if (this.parentList.length > 0) {
      this.parentList.forEach((element: any) => {
        this.actionsArray.push(this.actionSaveUser(
          true,
          element.childActionId,
          element.childActionNameKaz,
          element.childActionNameRus,
          element.parentActionId,
          element.parentActionNameKaz,
          element.parentActionNameRus,

        ));
      });
    } else {
      this.actionsArray.push(this.actionSaveUser(
        true,
        action.childActionId,
        action.childActionNameKaz,
        action.childActionNameRus,
        action.parentActionId,
        action.parentActionNameKaz,
        action.parentActionNameRus,

      ));
    }

  }

  findAllResetSessionType(code: any) {
    this.main.findAllResetSessionType()
      .subscribe((res: any) => {
        this.resetSessionList = [];
        this.resetSessionList = res;
        if (code == 0) {
          this.form.get('resetSessionTypeId')!.setValue(0)
        } else {
          this.form.get('resetSessionTypeId')!.setValue(this.allInc.resetSessionTypeId.id)
        }
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
          this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
      });
  }

  childcheckchange(event: any, act: any) {
    this.parentList.forEach((obj: any) => {
      if (obj.childActionId == act.childActionId) {
        obj.checked = event.target.checked;
      }
    });
  }

  approvedOrRejectedFromOperatorNctAndIb() {
    let studentTestList: any = [];


    this.studentTest.forEach((element: any) => {
      studentTestList.push({
        studentTestId: element.studentTestId,
        checked: true
      })

    });

    let obj = {
      studentTestList: studentTestList,
      actionTypeIds: this.child,
      statementId: this.form.get('statementId')?.value,
      testPeriodId: this.form.get('testPeriodId')?.value,
      statementsTime: this.form.get('statementsTime')?.value,
      resetSessionTypeId: this.allInc.resetSessionTypeId.id,
      addTime: this.form.get('addTime')?.value,
      addAppealTime: this.form.get('addAppealTime')?.value,
      notice: this.form.get('notice')?.value,
      checkedAll: this.form.get('checkedAll')?.value,
      statementStatusId: this.form.get('statementStatusId')?.value,
      additionalFiles: this.form.get('additionalFiles')?.value,

    };

    this.form.get('actionTypeIds')?.patchValue(this.parentList);

    let checkedStudent: any = [];
    this.studentTest.forEach((element: any) => {
      if (element.studentTestId != null && typeof element.studentTestId !== "undefined" && element.isChecked == true) {
        checkedStudent.push(element.studentTestId)
      }
      if (element.isChecked == false) {
        // this.studentTest.splice(element.studentTestId)
      }
    });
    // if (this.checkId == true) {
    //   this.form.get('addTime')!.setValue(this.addTime);
    // } else {
    //   this.form.get('addTime')!.setValue("");
    // }
    // if (this.checkApel == true) {
    //   this.form.get('addAppealTime')!.setValue(this.addAppealTime);
    // } else {
    //   this.form.get('addAppealTime')!.setValue("");
    // }
    this.form.get('studentTestId')?.setValue(checkedStudent)

    this.id = this.studentTestId;
    this.main.approvedOrRejectedFromOperatorNctAndIb(obj)
      .subscribe((res: any) => {
        this.router.navigate(['/main/incident']);
        this.translate.currentLang == 'ru' ? this._notifications.create('Успешно', 'Исполнено', NotificationType.Success, 5000) :
          this._notifications.create('Сәтті', 'пайдаланушы бұғатталған', NotificationType.Success);
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
          this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
      });
  }

  checkAllOptions() {
    if (!this.checkedAll) {
      this.studentTest.forEach((element: any) => {
        element.isChecked = true;
        if (this.checkedAll = true) {
          this.checkedCount = 0;
        }
      });
      this.checkedAll = true;
    } else {
      this.studentTest.forEach((element: any) => {
        element.isChecked = false;
        this.studentTestId = [];
      });
      this.checkedAll = false;
    }
  }

  checkStudent(event: any, student: any) {
    this.checkedCount = 0;
    if (event.target.checked == true) {
      for (let i = 0; i < this.studentTest.length; i++) {
        // if (this.studentTest[i] === student) {
        this.studentTestId.push({
          studentTestId: student.studentTest[i],
          checked: student.studentTest[i].isChecked = true
        });
        // }
      }
      console.log(this.studentTestId);

    } else {
      for (let i = 0; i < this.studentTest.length; i++) {
        // if (this.studentTest[i] === student) {
        this.studentTest[i].checked = false;
        this.checkedAll = false;
      }
      // }
    }
    this.studentTest.forEach((element: any) => {
      if (element.isChecked) {
        this.checkedCount++;
      }
    });
    this.studentTest.forEach((element: any) => {
      if (element.isChecked == false) {
        this.checkedAll = false;

      }
    });
  }

  findStatementsStatusForOperatorNctAndIb() {
    this.main.findStatementsStatusForOperatorNctAndIb()
      .subscribe((res: any) => {
        this.statusListForUsers = [];
        this.statusListForUsers = res;
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
          this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
      });
  }

  returnFunc(actions: any) {
    this.form.get('actionTypeIds')!.value.forEach((element: any) => {
      if (actions.childActionId == 3 && element.childActionId == 3) {
        if (element.checked == true && element.childActionId == 3) {
          this.checkId = false;
        }

        if (element.checked == false && element.childActionId == 3) {
          this.checkId = true
        }
      }

      if (actions.childActionId == 8 && element.childActionId == 8) {
        if (element.checked == true && element.childActionId == 8) {
          this.checkApel = false;
        }

        if (element.checked == false && element.childActionId == 8) {
          this.checkApel = true
        }
      }
    });
  }


  statementAttachedFiles() {
    this.main.statementAttachedFiles(this.allInc.statementId)
      .subscribe((res: any) => {
        this.statFiles = res;
        this.addFiles();
      })
  }

  get files(): any { return this.form.get('additionalFiles') };

  addFiles() {
    let obj = null;

    this.statFiles.forEach((element: any) => {
      obj = this.files;
      obj.push(
        new FormGroup({
          filename: new FormControl(element.filename, [Validators.required]),
          id: new FormControl(element.id),
          ord: new FormControl(element.ord),
          data: new FormControl(element.data)
        })
      );
    });
  }

  downloadFile(fileId: any, name: any, index: number) {
    this.main.statementAttachedFiles(fileId)
      .subscribe((res: any) => {
        const byteArray = new Uint8Array(
          atob(res[index].data).split('').map((char: any) => char.charCodeAt(0))
        );
        const blob = new Blob([byteArray], { type: 'application/octet-stream' });
        const data = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = data;
        link.download = name;
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        setTimeout(function () {
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      }, (err: any) => {
      });
  }

  addFile() {
    let obj = null;
    obj = this.files;
    obj.push(
      new FormGroup({
        filename: new FormControl('', [Validators.required]),
        id: new FormControl(0),
        ord: new FormControl(0),
        data: new FormControl()
      })
    );
  }

  changeListener($event: any, i: number): void {
    let obj: any = null;

    obj = this.files;

    const file: File = $event.target.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      obj.controls[i].get('filename').setValue(file.name);
      let imgData: any = myReader.result;

      obj.controls[i].get('data').setValue(imgData.toString().substring(imgData.toString().indexOf(',') + 1));
    }
    myReader.readAsDataURL(file);
  }

  dwnldCheck(id: number): boolean {
    return this.files.controls[id].get('filename').value !== '';
  }

  removeFile() {
    this.translate.currentLang == 'ru' ? this._notifications.create('Файл', 'успешно удален', NotificationType.Success, 5000) : this._notifications.create('Файл', 'сәтті жойылды', NotificationType.Success, 5000);
    let obj = null;

    obj = this.files;

    obj.removeAt(this.fileIdToDelete);
  }

  peresadka() {
    this.showPeresadka = false;
    let data: any = [];
    this.studentTest.forEach((element: any) => {
      if (element.checked == true) {
        data.push(
          {
            studentTestId: element.studentTestId,
            testOrgId: this.allInc.testOrgEntity.id,
            testPeriodId: this.testPeriodId
          }
        )
      }
    });
    this.main.incidentBronTransfer(this.statId, data)
      .subscribe((res: any) => {
        this.router.navigate(['/main/incident']);
        this.approvedOrRejectedFromOperatorNctAndIb();
        // this.findStudent();
        this.studentTest = [];
        this.translate.currentLang == 'ru' ? this._notifications.create('Успешно', 'Пересадка прошла успешно', NotificationType.Success, 5000) :
          this._notifications.create('Сәтті', 'Техникалық хатшы қосылды', NotificationType.Success, 5000);
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
          this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
      });
  }

  showPeresadkaOne(studentTestId: any) {
    if (this.allInc.studentTest.length == 1) {
      this.showPeresadkaOneWind = true
      this.findPlaceInfoForTransfer(studentTestId)

    } else {
      // this.showPeresadka = true
      this.showModalPeresad()
    }
  }

  peresadkaOne() {
    this.showPeresadkaOneWind = false;
    let data: any = [];
    this.studentTest.forEach((element: any) => {
      if (element.checked == true) {
        data.push(
          {
            studentTestId: element.studentTestId,
            testOrgId: this.allInc.testOrgEntity.id,
            testPeriodId: this.testPeriodId,
            roomId: this.roomId,
            roomPlaceNumber: this.roomPlaceNumber
          }
        )
      }
    });
    this.main.incidentBronTransfer(this.statId, data)
      .subscribe((res: any) => {
        this.router.navigate(['/main/incident']);
        this.approvedOrRejectedFromOperatorNctAndIb();

        // this.findStudent();
        this.studentTest = [];
        this.translate.currentLang == 'ru' ? this._notifications.create('Успешно', 'Пересадка прошла успешно', NotificationType.Success, 5000) :
          this._notifications.create('Сәтті', 'Техникалық хатшы қосылды', NotificationType.Success, 5000);
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
          this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
      });
  }

  peresadkaWindow(code: number) {
    this.loading = true;
    let checkedCount: number = 0;
    this.studentTest.forEach((element: any) => {
      if (element.checked == true, element.selectAll == true) {
        checkedCount = checkedCount + 1;
      }
    });
    if (checkedCount == 0) {
      this.showPeresadka = false;
    } else {
      this.showPeresadka = true;
    }
  }

  showModalPeresad() {
    this.findAllOblPOsads(1);
    // this.findAllTestOrgs(1);
    this.oblId = this.allInc.testOrgEntity.oblId;
    this.testOrgId = this.allInc.testOrgEntity.id;
    this.testPeriodId = this.form.get('testPeriodId')!.value;
    let checkedCount: number = 0;
    let studentListStudentId: number = 0;
    this.studentTest.forEach((element: any) => {
      checkedCount = checkedCount + 1;
      studentListStudentId = element.studentTestId;
      if (this.studentTestId == element.studentTestId) {
        element.selectAll = true;
        element.isChecked = true;
      }
    });
    this.showPeresadka = true;

  }

  findAllOblPOsads(code: any) {
    this.loading = true;
    this.oblLists = [];
    this.main.findAllOblForInc()
      .subscribe((res: any) => {
        this.oblLists = res;
        if (code == 0) {
          this.oblId = 0;
        } else {
          this.oblId = this.allInc.testOrgEntity.oblId;
        }
        this.findAllTestOrgs(1);
      }, (err: any) => {
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
          this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
      });
  }

  findAllTestOrgs(code: number) {
    this.testOrgLists = [];
    this.main.findAllTestOrgs(this.oblId)
      .subscribe((res: any) => {
        this.testOrgLists = res;
        if (code == 0) {
          this.testOrgId = 0;
        } else {
          this.testOrgId = this.allInc.testOrgEntity.id;
        }
        this.findAllTestPeriodByTestOrgs(1);
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
          this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
      });
  }

  findAllTestPeriodByTestOrgs(code: any) {
    this.loading = true;
    this.periodOrgLists = [];
    this.main.findAllTestPeriodByTestOrgs(this.testOrgId)
      .subscribe((res: any) => {
        this.loading = false;
        this.periodOrgLists = res;
        if (code == 0) {
          this.testPeriodId = 0;
        } else {
          this.testPeriodId = this.form.get('testPeriodId')!.value;
        }
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
          this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
        this.loading = false;
      });
  }

  roomNumbers(code: any){
    this.auditPlaceList = [];
    this.main.roomNumber(this.testPeriodId, this.testOrgId)
      .subscribe((res: any) => {
        this.auditPlaceList = res;
        if(code == 0){
          this.roomId = 0;
          this.roomPlaceNumber = 0;
        }else{
          this.roomId = this.transferList.roomId;
          this.placeNumber(1);
        }
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
          this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
      });
  }

  placeNumber(code: any){
    this.loading = true;
    this.freePlaceList = [];
    this.main.roomPlaceNumber(this.testPeriodId, this.testOrgId, this.roomId)
      .subscribe((res: any) => {
        this.loading = false;
        this.freePlaceList = res;
        if(code == 0){
          this.roomPlaceNumber = 0;
          this.roomFreeNumber = 0;
        }else{
          this.roomPlaceNumber = this.transferList.roomPlaceNumber;
        }
      }, (err: any) => {
        this.loading = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
          this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
      });
  }

  findAllOblPOsad(code: any){
    this.posadOblList = [];
    this.main.findAllOblPOsad()
      .subscribe((res: any) => {
        this.posadOblList = res;
        if(code == 0){
          this.oblId = 0;
          this.testOrgList = [];
          this.testPeriodOrgList = [];
          this.auditPlaceList = [];
          this.freePlaceList = [];
          this.roomPlaceNumber = 0;
        }else{
          this.oblId = this.transferList.obl.id;
          this.findAllTestOrg(1);
        }
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
          this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
      });
  }

  findAllTestOrg(code: any){
    this.testOrgList = [];
    this.main.findAllTestOrg(this.oblId)
      .subscribe((res: any) => {
        this.testOrgList = res;
        if(code == 0){
          this.testOrgId = 0;
          this.testPeriodOrgList = [];
          this.auditPlaceList = [];
          this.freePlaceList = [];
          this.roomPlaceNumber = 0;
        }else{
          this.testOrgId = this.transferList.testOrgEntity.id;
          this.form.get('testPeriodId')!.setValue(this.transferList.testPeriodId)
          this.findAllTestPeriodByTestOrg(1);
        }
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
          this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
      });
  }

  findAllTestPeriodByTestOrg(code: any){
    this.testPeriodOrgList = [];
    this.main.findAllTestPeriodByTestOrg(this.testOrgId)
      .subscribe((res: any) => {
        this.testPeriodOrgList = res;
        if(code == 0){
          this.testPeriodId = 0;
          this.auditPlaceList = [];
          this.freePlaceList = [];
          this.roomPlaceNumber = 0;
        }else{
          this.testPeriodId = this.transferList.testPeriod.id;
          this.roomNumbers(1);
        }
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.response, NotificationType.Error, 5000) :
          this._notifications.create('Қате', err.error.response, NotificationType.Error, 5000);
      });
  }

  findPlaceInfoForTransfer(studentTestId: any){
    this.main.findPlaceInfoForTransfer(studentTestId)
      .subscribe ((res: any) => {
        this.transferList = res;
        this.roomFreeNumber = this.transferList.roomPlaceNumber;
        this.findAllOblPOsad(1);
      }, (err: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
          this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
      });
  }

  closePeresad() {
    // this.changeStatusStatement();
    this.showPeresadka = false
  }

  closePeresadOne() {
    // this.changeStatusStatement();
    this.showPeresadkaOneWind = false
  }

}


