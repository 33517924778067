<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

<!-- <form [formGroup]="formPeresad" action=""> -->

<div class="">
  <div (click)="showPeresadka = !showPeresadka" *ngIf="showPeresadka" class="w-full h-screen bg-black top-0 left-0 z-20 bg-opacity-25 fixed"></div>
  <div *ngIf="showPeresadka" class="text-center border-2 p-4 top-0 h-80 left-0 z-20 classA fixed z-30 w-1/2 bg-white">

    <div class="text-3xl"> {{ 'Transfer' | translate }} </div>

    <div class="text-left">
      <div class="my-2 flex">
        <label class="lableClassEs"> {{ 'Obl' | translate }} </label>
        <select (change)="findAllTestOrgNct(0)" [(ngModel)]="oblId" [ngModelOptions]="{standalone: true}" class="rounded-lg border-2 mr-20 flex-grow w-full px-3 lg:text-xs lg:text-base text-gray-500 font-thin">
          <option [value]="0" disabled selected> {{ 'Obl' | translate }} </option>
          <option *ngFor="let item of oblLists" [value]="item.id">
            <span *ngIf="translate.currentLang == 'kz'"> {{ item.nameKaz }} </span>
            <span *ngIf="translate.currentLang == 'ru'"> {{ item.nameRus }} </span>
          </option>
        </select>
      </div>

      <div class="my-2 flex">
        <label class="lableClassEs"> {{ 'PPent:' | translate }} </label>
        <select (change)="findAllTestPeriodByTestOrgs(0)" [(ngModel)]="vptId" [ngModelOptions]="{standalone: true}" class="rounded-lg border-2 mr-20 flex-grow w-full px-3 lg:text-xs lg:text-base text-gray-500 font-thin">
          <option [value]="0" disabled selected> {{ 'PPent' | translate }} </option>
          <option *ngFor="let item of testOrgLists" [value]="item.vptId">
            <span *ngIf="translate.currentLang == 'kz'"> {{ item.codeId }} - {{ item.nameKaz }} </span>
            <span *ngIf="translate.currentLang == 'ru'"> {{ item.codeId }} - {{ item.nameRus }} </span>
          </option>
        </select>
      </div>

      <div class="my-2 flex">
        <label class="lableClassEs"> {{ 'Stream' | translate }} </label>
        <select  [(ngModel)]="testPeriodId" [ngModelOptions]="{standalone: true}" class="rounded-lg border-2 mr-20 flex-grow w-full px-3 lg:text-xs lg:text-base text-gray-500 font-thin">
          <option [value]="0" disabled selected> {{ 'Stream' | translate }} </option>
          <option *ngFor="let item of periodOrgLists" [value]="item.id">
            {{ item.testDate }} - {{ item.startTestTime }} - {{ item.enterTestTime }} - свободные места на потоке {{ item.freePlaceCount }}
          </option>
        </select>
      </div>
    </div>
    <div class="flex my-2">
      <label class="lableClassEs"> При статусе "Не явился" </label>
      <input [(ngModel)]="withStatusNeYavilsya" [ngModelOptions]="{standalone: true}" class="w-5 h-5 ml-5 mt-3" type="checkBox">
    </div>
    <!-- <div class="flex my-2">
      <label class="lableClass"> Выбрать всех </label>
          <input [(ngModel)]="selectAll" [ngModelOptions]="{standalone: true}" class="w-5 h-5 ml-5 mt-3" type="checkBox">
    </div> -->

    <div class="flex">
      <div class="w-1/2">
        <button (click)="peresadka()"
                class="w-56 my-10 rounded-lg btn text-center btn-outline-primary text-white btn-lg btn-block bg-red-400 p-1">
          Пересадить
        </button>
      </div>

      <div class="w-1/2">
        <button *ngIf="showPeresadka" (click)="closePeresad()"
                class="w-56 my-10 rounded-lg btn bg-blue-400 text-center text-white btn-outline-primary btn-lg btn-block p-1">
          {{ 'Cancellation' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- для одного тестируемого -->
<div class="">
  <div (click)="showPeresadkaOneWind = !showPeresadkaOneWind" *ngIf="showPeresadkaOneWind" class="w-full h-screen bg-black top-0 left-0 z-20 bg-opacity-25 fixed"></div>
  <div *ngIf="showPeresadkaOneWind" class="text-center border-2 p-4 top-0 h-80 left-0 z-20 classA fixed z-30 w-1/2 bg-white">
    <div class="text-3xl"> {{ 'Transfer' | translate }} </div>
    <div class="text-left">
      <div class="my-2 flex">
        <div class="lableClassEs"> {{ 'Obl' | translate }} </div>
        <select (change)="findAllTestOrg(0)" [(ngModel)]="oblId" [ngModelOptions]="{standalone: true}"
                class="rounded-lg border-2 mr-20 flex-grow w-full px-3 lg:text-xs lg:text-base text-gray-500 font-thin">
          <option [value]="0" disabled selected> {{ 'Obl' | translate }} </option>
          <option *ngFor="let item of posadOblList" [value]="item.oblId">
            <span *ngIf="translate.currentLang == 'kz'"> {{ item.nameKaz }} </span>
            <span *ngIf="translate.currentLang == 'ru'"> {{ item.nameRus }} </span>
          </option>
        </select>
      </div>

      <div class="my-2 flex">
        <label class="lableClassEs"> {{ 'PPent:' | translate }} </label>
        <select (change)="findAllTestPeriodByTestOrg(0)" [(ngModel)]="id" [ngModelOptions]="{standalone: true}"
                class="rounded-lg border-2 mr-20 flex-grow w-full px-3 lg:text-xs lg:text-base text-gray-500 font-thin">
          <option [value]="0" disabled selected> {{ 'PPent:' | translate }} </option>
          <option *ngFor="let item of testOrgList" [value]="item.vptId">
            <span *ngIf="translate.currentLang == 'kz'"> {{ item.nameKaz }} - {{ item.code }} </span>
            <span *ngIf="translate.currentLang == 'ru'"> {{ item.nameRus }} - {{ item.code }} </span>
          </option>
        </select>
      </div>

      <div class="my-2 flex">
        <label class="lableClassEs"> {{ 'Stream' | translate }} </label>
        <select (change)="roomNumbers(0)" [(ngModel)]="testPeriodId" [ngModelOptions]="{standalone: true}"
                class="rounded-lg border-2 mr-20 flex-grow w-full px-3 lg:text-xs lg:text-base text-gray-500 font-thin">
          <option [value]="0" disabled selected> {{ 'Stream' | translate }} </option>
          <option *ngFor="let item of testPeriodOrgList" [value]="item.id">
            {{ item.testDate }} - {{ item.startTestTime }} - {{ item.enterTestTime }} - свободные места {{
              item.freePlaceCount }}, в аудиторным фонде {{ item.freePlaceCountByRoom }}
          </option>
        </select>
      </div>

      <div class="my-2 flex">
        <label class="lableClassEs"> {{ 'Audit' | translate }} </label>
        <select (change)="placeNumber(0)" [(ngModel)]="roomId" [ngModelOptions]="{standalone: true}"
                class="rounded-lg border-2 mr-20 flex-grow w-full px-3 lg:text-xs lg:text-base text-gray-500 font-thin">
          <option [value]="0" disabled selected> {{ 'Audit' | translate }} </option>
          <option *ngFor="let item of auditPlaceList" [value]="item.id">
            {{ 'NumAudit' | translate }} {{ item.roomNumber }} - {{ 'FreePlaceAudit' | translate }} {{
              item.roomFreePlace }}
          </option>
        </select>
      </div>

      <div class="my-2 flex">
        <label class="lableClassEs"> {{ 'place' | translate }} </label>
        <select [(ngModel)]="roomPlaceNumber" [ngModelOptions]="{standalone: true}"
                class="rounded-lg border-2 mr-20 flex-grow w-full px-3 lg:text-xs lg:text-base text-gray-500 font-thin">
          <option [value]="0" disabled selected> {{ 'place' | translate }} </option>
          <option [value]="roomFreeNumber" disabled selected> {{ 'place' | translate }} на котором сейчас сидит
            тестируемый - {{ roomFreeNumber }}
          </option>
          <option *ngFor="let item of freePlaceList" [value]="item.roomPlaceNumber">
            {{ 'place' | translate }} {{ item.roomPlaceNumber }} - "{{ 'placeDisab' | translate }} {{
              item.placePriorityId }}"
          </option>
        </select>
      </div>
      <div class="flex my-2">
        <label class="lableClassEs"> При статусе "Не явился" </label>
        <input [(ngModel)]="withStatusNeYavilsya" [ngModelOptions]="{standalone: true}" class="w-5 h-5 ml-5 mt-3" type="checkBox">
      </div>

      <div class="flex">
        <div class="w-1/2">
          <button (click)="peresadkaOne()"
                  class="w-56 my-10 rounded-lg btn text-center btn-outline-primary text-white btn-lg btn-block bg-red-400 p-1">
            Пересадить
          </button>
        </div>
        <!--
                  <div class="w-1/2">
                    <button (click)="approvedOrRejectedFromOperatorNctAndIb()"
                      class="w-56 my-10 rounded-lg btn text-center btn-outline-primary text-white btn-lg btn-block bg-red-400 p-1">
                      Исполнить
                    </button>
                  </div> -->

        <div class="w-1/2">
          <button *ngIf="showPeresadkaOneWind" (click)="closePeresadOne()"
                  class="w-56 my-10 rounded-lg btn bg-blue-400 text-center text-white btn-outline-primary btn-lg btn-block p-1">
            {{ 'Cancellation' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- </form> -->


<form [formGroup]="form" class="formClass">
  <div id="whoobe-1yakq"
       class="bg-white text-base w-2/3 rounded-lg shadow whoobeClass m-auto -brown-400 border border-gray-300 mt-10 flex flex-col">
    <div id="whoobe-pa28x" class="p-4 flex boxClass flex-col">
      <div class="flex ml-48 text-center mt-5 mb-10">
        <div class="w-2/3 text-2xl font-hairline uppercase tracking-widest"> Данные об внештатной ситуации </div>
      </div>
      <div class="">
        <div class="mx-3 my-5">
          <div class="flex">
            <label class="w-44"> Код </label>
            <input formControlName="statementId" type="text" class="ml-3 pl-2 bg-gray-300 w-12 rounded-lg">
          </div>
        </div>
        <div class="mx-3 my-5">
          <div class="flex">
            <label class="w-44"> Пункт </label>
            <div class="ml-3 px-2 bg-gray-300 rounded-lg">
              <span *ngIf="translate.currentLang == 'kz'"> {{ allInc?.testVptEntity?.codeId || '' }} - {{
                  allInc?.testVptEntity?.nameKaz || '' }} </span>
              <span *ngIf="translate.currentLang == 'ru'"> {{ allInc?.testVptEntity?.codeId || '' }} - {{
                  allInc?.testVptEntity?.nameRus || '' }} </span>
            </div>
          </div>
        </div>
        <div class="mx-3 my-5">
          <div class="flex">
            <label class="w-44"> Поток </label>
            <div class="ml-3 px-2 bg-gray-300 rounded-lg">
              {{ allInc?.testPeriod?.testDate || '' }} - {{ allInc?.testPeriod?.startTestTime || '' }}
            </div>
          </div>
        </div>
        <div class="mx-3 my-5">
          <div class="flex">
            <label class="w-44"> Время внештатной ситуации </label>
            <input formControlName="statementsTime" placeholder="00:00" type="text"
                   class="h-7 ml-3 px-2 rounded-lg bg-gray-300">
          </div>
        </div>
        <div class="mx-3 my-5">
          <div class="flex">
            <label class="w-44"> Внештатная ситуация </label>
            <div class="ml-3 px-2 bg-gray-300 rounded-lg">
              <span *ngIf="translate.currentLang == 'kz'"> {{ allInc?.resetSessionTypeId?.nameKaz }} </span>
              <span *ngIf="translate.currentLang == 'ru'"> {{ allInc?.resetSessionTypeId?.nameRus }} </span>
            </div>
          </div>
        </div>
        <div class="mx-3 my-5">
          <div>Запрос для внештатной ситуации
            <div class="mr-3 p-6">
              <div style="display: flex;" *ngFor="let actions of ActionTreeList; index as i">
                <fieldset>
                  <div>
                    <input (click)="findChildActionByParentId(actions)" [attr.disabled]="true" formControlName="parentActionId" class="mr-1 w-4 h-4"
                           [value]="actions.parentActionId" type="radio">
                    <label>
                      <span *ngIf="translate.currentLang == 'kz'"> - {{ actions.parentActionNameKaz }}
                      </span>
                      <span *ngIf="translate.currentLang == 'ru'"> - {{ actions.parentActionNameRus }}
                      </span>
                    </label>
                  </div>
                </fieldset>
              </div>
              <div  *ngIf="this.form.get('parentActionId')!.value != 0">
                <div>
                  <div>
                    <div class="mt-2">
                      <div style="display: flex;" *ngFor="let act of parentList; index as i">
                        <input style="display: none;" [attr.disabled]="true" (click)="returnFunc(act)" [value]="act.checked" class="mr-1 w-4 h-4" [checked]="act.checked" type="checkbox">
                        <label>
                          <!-- <span *ngIf="translate.currentLang == 'kz'"> - {{ act.childActionNameKaz }} </span>
                          <span *ngIf="translate.currentLang == 'ru'"> - {{ act.childActionNameRus }} </span> -->
                        </label>
                        <div *ngIf="act.parentActionId == 2">
                          Кол-во добавочного времени в минутах
                          <input [attr.disabled]="true" formControlName="addTime"
                                 onkeyup="this.value = this.value.replace(/[^\d]/g,'');" [step]="5" [min]="5" [max]="160"
                                 type="text" class="border-2 w-24 mx-2 px-2 rounded-lg bg-gray-300">
                        </div>
                        <div *ngIf=" act.childActionId == 9">
                          <input [attr.disabled]="true" formControlName="addAppealTime"
                                 onkeyup="this.value = this.value.replace(/[^\d]/g,'');" [step]="5" [min]="5" [max]="160"
                                 type="text" class="border-2 mx-2 w-24 px-2 rounded-lg bg-gray-300">
                          Кол-во добавочного времени апелляция
                        </div>

                      </div>
                    </div>
                  </div>
                  <div *ngIf="this.form.get('parentActionId')!.value == 12" class="mx-3 my-1">
                    <div class="flex">
                      Добавочное время тестирования
                      <input onkeyup="this.value = this.value.replace(/[^\d]/g,'');" [step]="5"
                             [min]="5" [max]="160" formControlName="addTime" class="ml-3 bg-gray-300 rounded-lg" type="number">
                    </div>
                  </div>
                  <div *ngIf="this.form.get('parentActionId')!.value == 13" class="mx-3 my-1">
                    <div class="flex">
                      Добавочное время для апелляции
                      <input onkeyup="this.value = this.value.replace(/[^\d]/g,'');" [step]="5"
                             [min]="5" [max]="160" formControlName="addAppealTime" class="ml-3 bg-gray-300 rounded-lg" type="number">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mx-3 my-5">
          <div class="flex">
            Причина <textarea class="ml-3 pl-2 bg-gray-300 w-96 h-32" formControlName="notice"></textarea>
          </div>
        </div>

        <div class="modal-window mt-8 p-2 bg-white rounded-sm shadow-lg">
          <div class="flex">
            <button type="button" class="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded" (click)="addFile()">{{ 'addFile' | translate }}</button>
            <button type="button" class="text-white py-2 px-4 rounded">{{ 'addFile' | translate }}</button>
          </div>
          <span class="add-files-scroll">
          <div formArrayName="additionalFiles" class="w-full mt-4 controls-border" *ngFor="let item of files.controls; index as i">
            <div [formGroup]="files.controls[i]">
              <span> {{ 'NameFile' | translate }}: <input [disabled]="true" class="mb-4 p-2 w-full" type="text" readonly formControlName="filename"></span>
              <div class="f-btn-group">
                <div class="relative flex">
                  <input (change)="changeListener($event, i)" class="w-full absolute top-0 left-0 h-full opacity-0 mb-4" type="file"/>

                  <!-- <div class="update-btn bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded">
                    <p>
                      <span *ngIf="dwnldCheck(i); else elseBlock"> {{ 'ReplaceFile' | translate }}</span>
                      <ng-template #elseBlock><span> {{ 'UploadFile' | translate }}</span></ng-template>
                    </p>
                  </div> -->

                  <div *ngIf="dwnldCheck(i)" class="update-btn bg-blue-500 relative hover:bg-blue-700 text-white py-2 px-4 rounded">

                    <button (click)="downloadFile(files.controls[i].value.id, files.controls[i].value.filename, i)" class="w-full absolute top-0 left-0 h-full opacity-0 mb-4" type="file"> </button>
                      <p>
                        <span class=""> {{ 'DownloadFile' | translate }} </span>
                        <ng-template #elseBlock><span> {{ 'UploadFile' | translate }}</span></ng-template>
                      </p>
                    </div>

                  <p *ngIf="!dwnldCheck(i)" class="text-red-500 text-sm text-left">* {{ 'FileNotAdd' | translate }}</p>
                </div>
              </div>

              <!-- <div class="s-btn-group">
                <button type="button" title="Удалить файл" class="x-btn bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" (click)="removeFile()">
                  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash" class="svg-inline--fa fa-trash fa-w-14" role="img" viewBox="0 0 448 512"><path fill="currentColor" d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"/></svg>
                </button>
              </div> -->

            </div>
          </div>
         </span>
        </div>

        <div *ngIf="allInc?.sprStatementsStatusEntity?.id == 2 || allInc?.sprStatementsStatusEntity?.id == 3" class="mx-3 my-5">
          <div class="flex">
            <p class="w-60">
              Решение орг.отдела:
            </p>
            <select class="bg-gray-300 rounded-lg" formControlName="statementStatusId">
              <option disabled [value]="0">
                Решение орг.отдела:
              </option>
              <option [value]="item.id" *ngFor="let item of statusListForUsers">
                <span *ngIf="translate.currentLang == 'kz'"> {{ item.nameKaz }} </span>
                <span *ngIf="translate.currentLang == 'ru'"> {{ item.nameRus }} </span>
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="flex">
      <div *ngIf="this.form.get('statementStatusId')!.value == 4 && this.form.get('parentActionId')!.value != 6 && (allInc?.sprStatementsStatusEntity?.id == 2 || allInc?.sprStatementsStatusEntity?.id == 3)">
        <button (click)="approvedOrRejectedFromOperatorNctAndIb()"
                class="w-64 rounded-lg h-8 bg-blue-300">
          Отправить специалисту ИБ
        </button>
      </div>
      <div class="flex">
        <div *ngIf="this.form.get('statementStatusId')!.value == 5 && (allInc?.sprStatementsStatusEntity?.id == 2 || allInc?.sprStatementsStatusEntity?.id == 3)" class="ml-2">
          <button (click)="approvedOrRejectedFromOperatorNctAndIb()" class="w-64 rounded-lg h-8 bg-blue-300">
            Подтвердить отклонение
          </button>
        </div>
      </div>
      <div *ngIf="this.form.get('statementStatusId')!.value == 4 && this.form.get('parentActionId')!.value == 6 && (allInc?.sprStatementsStatusEntity?.id == 2 || allInc?.sprStatementsStatusEntity?.id == 3)" class="flex">
        <div class="ml-2">
          <button *ngIf="this.form.get('statementStatusId')!.value == 4 && allInc?.studentTest?.length > 1" (click)="showModalPeresad()" class="w-64 rounded-lg h-8 bg-blue-300">
            Отправить специалисту ИБ
          </button>
          <button *ngIf="this.form.get('statementStatusId')!.value == 4 && allInc?.studentTest?.length == 1" (click)="showPeresadkaOne(studentTest[0].studentTestId)" class="w-64 rounded-lg h-8 bg-blue-300">
            Отправить специалисту ИБ
          </button>
        </div>
      </div>
      <div class="flex">
        <div class="ml-2">
          <button [routerLink]="['/main/incident']" class="w-64 rounded-lg h-8 bg-blue-300">
            Вернуться к списку
          </button>
        </div>
      </div>
    </div>
    <div class="px-3 py-4 scroll-table-body overflow-x-auto flex">
      <table class="table min-w-full">
        <thead>
        <tr>
          <th>
            <input [attr.disabled]="true" [checked]="checkedAll == true" (click)="checkAllOptions()" class="w-4 h-4" type="checkbox" >
          </th>
          <th scope="col" class="py-3 text-center text-xs font-medium text-gray-500 uppercase">
            {{ 'IIN' | translate }}
          </th>
          <th scope="col" class="py-3 text-center text-xs font-medium text-gray-500 uppercase">
            ФИО
          </th>
          <th scope="col" class="py-3 text-center text-xs font-medium text-gray-500 uppercase">
            Аудитория и место
          </th>
          <th scope="col" class="py-3 text-center text-xs font-medium text-gray-500 uppercase">
            {{ 'StudentStatus' | translate }}
          </th>
          <th scope="col" class="py-3 text-center text-xs font-medium text-gray-500 uppercase">
            Внештатная ситуация
          </th>
        </tr>
        </thead>
        <tbody class="bg-white border-2.border-gray-200">
        <tr class="border-2 border-gray-200" *ngFor="let students of studentTest | filter: searchText ">
          <td class="px-6 py-4 text-center">
            <div class="text-sm text-gray-900">
              <input [attr.disabled]="true" (change)="checkStudent($event, students)" class="w-4 h-4"
                     [checked]="students.isChecked" type="checkbox">
            </div>
          </td>
          <td class="py-4 text-center">
            <div class="text-sm text-gray-900">
              {{ students.iin }}
            </div>
          </td>
          <td class="py-4 text-center">
            <div class="text-sm text-gray-900 ml-20 w-72">
              {{ students.student }}
            </div>
          </td>
          <td class="py-4 text-center">
            <div class="text-sm text-gray-900 ">
              аудитория - {{ students.newRoomId }}, место - {{ students.newRoomPlaceNumber }}
            </div>
          </td>
          <td class="py-4 text-center">
            <div class="text-sm text-gray-900 w-60 ml-9">
              <span *ngIf="translate.currentLang == 'kz'"> {{ students.testStatus.nameKaz }} </span>
              <span *ngIf="translate.currentLang == 'ru'"> {{ students.testStatus.nameRus }} </span>
            </div>
          </td>
          <td class="py-4 text-center">
            <div class="text-sm text-gray-900 w-60 ml-10">
              <span *ngIf="translate.currentLang == 'kz'"> {{ allInc.resetSessionTypeId.nameKaz }} </span>
              <span *ngIf="translate.currentLang == 'ru'"> {{ allInc.resetSessionTypeId.nameRus }} </span>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</form>
