<div class="text-center mt-80">
    <label class="text-3xl">
        {{ 'NoConnectionAppnct' | translate }} 
        <p> {{ 'RunAProgramm' | translate }} </p>
    </label>
    <div class="flex margClass">
      <a class="w-1/6" [routerLink]="ref">
         <button (click)="CheckConnection()" class="mt-20 mx-20 buttonA rounded-lg bg-blue-300 p-2">
              {{ 'CheckConnection' | translate }}
         </button>
      </a>
      <a class="w-1/6" [routerLink]="ref">
        <button (click)="back()" class="mt-20 mx-20 rounded-lg buttonB bg-blue-300 p-2">
             {{ 'buttText' | translate }}
        </button>
     </a>
    </div>
    <div class="cursor-pointer underline cvet mt-16 text-base text-blue">
      <span ><a target="_blank" href="./../../assets/setup_appnct.exe"> 
       Нажмите чтобы установить программу. 
      </a> </span>
</div>