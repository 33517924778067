
  import { Component, KeyValueDiffers, OnInit } from '@angular/core';
  import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from "@angular/forms";
  import { Router, ActivatedRoute } from '@angular/router';
  import { StudentTestControllerService } from '../services/student-test-controller.service';
  import { apiRoutes } from '../config/api.routes';
  import { TranslateService } from '@ngx-translate/core';
  import { NotificationsService, NotificationType } from 'angular2-notifications';

  @Component({
    selector: 'app-nqt6',
    templateUrl: './nqt6.component.html',
    styleUrls: ['./nqt6.component.css']
  })
  export class Nqt6Component implements OnInit {

    iin!: number;
    id: any;
    firstname!: string;
    patronymic!: string;
    phoneNumber!: string;
    oblList: any;
    oblId: any;
    jobTypeId: any;
    studentInfo: any;
    categoryList: any;
    pretendedCategoryList: any;
    studentId: any;
    raionList: any;
    jobTypeList: any;
    jobList: any;
    date: string = '00.00.0000';
    eduPeriod: string = '0000';
    langList: any = [];
    eduTypeList: any;
    specialityList: any;
    isInvalid = false;
    studentTestId: any;
    testVptList: any;
    periodList: any;
    vptList: any;
    eduSpecialityId: any;
    currentCategoryList: any;
    update: any;
    dopustimPeremennya: any;
    parentId: any;
    hasChildren: any;
    code: any;
    schoolId: any;
    instituteList: any;
    katoOblList: any;
    KATOOblList: any = [];
    KATOCityList = [];
    KATOSeloList = [];
    KATORaionList = [];
    schoolTypeId: any;
    testOrg = [];
    testPeriod = [];
    specialityTypeList: any;
    KATOs: any = [];
    fromCategoryId: any;
    isFirstSelect: any
    schoolList: any;
    KATOsubject = [];
    collegList: any;
    pseudoId: any = [];
    showNasPunkt = true;
    lastChild = false;
    pageLangId: any;
    raionId: any;
    role: any;
    jobId: any;
    public loading = false;
    objDiffer: any;
    katoIndex: any;
    fromCategoryValidTo: string = '00.00.0000';
    toCategoryList: any;
    toCategoryId: any;
    kato: any;
    pstList: any;
    testOrgId: any;
    testPeriodId: any;
    testLangId: any;
    specList: any;
    subjectList:any[] = [];
    subjectCountArray: any = [];
    subjectCount: any;
    sort: any;
    subjectId: any;
    employmentTypeId: any;
    employmentList: any;
    oblLists: any;
    eduInstituteOblId: any;
    instituteLists: any;
    eduInstituteId: any;
    oblSchoolList: any;
    eduSchoolOblId: any;
    eduSchoolRaionId: any;
    eduSchoolId: any;
    collegeSpecList: any;
    testOrgOblId: any;
    jobsId: any;

    form!: FormGroup;

    constructor( private studentTest: StudentTestControllerService,
                 private router: Router,
                 private route: ActivatedRoute,
                 private apiRoutes: apiRoutes,
                 private fb: FormBuilder,
                 public translate: TranslateService,
                 private _notifications: NotificationsService,
                 private differs:  KeyValueDiffers) { }

    ngOnInit(): void {
      this.objDiffer = {};


      if(localStorage.getItem('role') == 'ROLE_ADMIN'){
        this.role = 'admin';
      }

      if(localStorage.getItem('role') == 'ROLE_OPERATOR_NCT'){
        this.role = 'operatorNqt';
      }

      if(localStorage.getItem('role') == 'ROLE_OPERATOR'){
        this.role = 'operator';
      }


      if(localStorage.getItem('Lang') == 'ru'){
        this.pageLangId = 2;
      }else{
        this.pageLangId = 1;
      }

      this.form = new FormGroup({
        studentTestId: new FormControl(0),
        iin: new FormControl(0),
        id: new FormControl(0),
        subjectCountArray: new FormArray([]),
        firstname: new FormControl(''),
        toCategoryId: new FormControl(),
        jobTypeId: new FormControl(),
        lastname: new FormControl(''),
        patronymic: new FormControl(''),
        phoneNumber: new FormControl(0),
        specialityId: new FormControl(0),
        raionId: new FormControl(0),
        schoolList: new FormControl(0),
        eduTypeId: new FormControl(),
        raionList: new FormControl(0),
        testLangId: new FormControl(0),
        oblId: new FormControl(0),
        schoolTypeId: new FormControl(0),
        testOrgId: new FormControl(),
        subjectId: new FormControl(),
        bin: new FormControl(0),
        jobId: new FormControl(),
        eduPeriodStartYear: new FormControl(''),
        firstLevelKato: new FormControl(),
        schoolId: new FormControl(0),
        fromCategoryId: new FormControl(),
        pretendedCategoryId: new FormControl(0),
        diplomaSpecialityName: new FormControl(''),
        eduPeriodFinishYear: new FormControl(0),
        isInvalid: new FormControl(0),
        specialityTypeId: new FormControl(0),
        subjects: new FormArray([]),
        auditId: new FormControl(0),
        eduInstituteId: new FormControl(),
        eduInstituteOblId: new FormControl(),
        placeNum: new FormControl(0),
        kato: new FormArray ([]),
        testStatusId: new FormControl(0),
        testVptId: new FormControl(0),
        testPeriodId: new FormControl(),
        ord: new FormControl(0),
        fromCategoryValidTo: new FormControl(),
        eduSchoolId: new FormControl(),
        collegList: new FormControl(0),
        optionalSubjectCount: new FormControl(0),
        employmentTypeId: new FormControl(),
        eduSchoolOblId: new FormControl(),
        eduSchoolRaionId: new FormControl(),
        eduSpecialityId: new FormControl(),
        testOrgOblId: new FormControl(),
      });
      this.studentTestId = this.route.snapshot.queryParamMap.get('studentTestId');

      this.getShort();

    }

    getShort(){
      this.studentTest.getShort(this.studentTestId)
        .subscribe((res: any) => {
          this.studentInfo = res.data;
          this.form.get('studentTestId')!.setValue(+this.studentTestId);
          this.form.get('iin')!.setValue(this.studentInfo.iin);
          this.form.get('firstname')!.setValue(this.studentInfo.firstname);
          this.form.get('lastname')!.setValue(this.studentInfo.lastname);
          this.form.get('patronymic')!.setValue(this.studentInfo.patronymic);
          this.form.get('phoneNumber')!.setValue(this.studentInfo.phoneNumber);
          this.form.get('diplomaSpecialityName')!.setValue(this.studentInfo.diplomaSpecialityName);
          this.form.get('eduPeriodFinishYear')!.setValue(this.studentInfo.eduPeriodFinishYear);
          this.form.get('eduPeriodStartYear')!.setValue(this.studentInfo.eduPeriodStartYear);
          this.form.get('isInvalid')!.setValue(this.studentInfo.isInvalid);
          this.form.get('oblId')!.setValue(this.studentInfo.oblId);
          this.form.get('fromCategoryId')!.setValue(this.studentInfo.fromCategoryId);
          this.form.get('specialityId')!.setValue(this.studentInfo.specialityId);
          this.form.get('toCategoryId')!.setValue(this.studentInfo.toCategoryId);
          this.form.get('jobTypeId')!.setValue(6);
          this.form.get('testOrgId')!.setValue(this.studentInfo.testOrgId);
          this.form.get('jobId')!.setValue(this.studentInfo.jobId);
          this.form.get('testStatusId')!.setValue(this.studentInfo.testStatusId);
          this.form.get('id')!.setValue(this.studentInfo.id);
          this.form.get('eduInstituteId')!.setValue(this.studentInfo.eduInstituteId);
          this.form.get('eduSpecialityId')!.setValue(this.studentInfo.eduSpecialityId);
          this.form.get('eduTypeId')!.patchValue(this.studentInfo.eduTypeId);
          this.form.get('eduInstituteOblId')!.setValue(this.studentInfo.eduInstituteOblId);
          this.form.get('fromCategoryValidTo')!.setValue(this.studentInfo.fromCategoryValidTo);
          this.form.get('subjectId')!.setValue(this.studentInfo.subjectId);
          this.form.get('schoolId')!.setValue(this.studentInfo.schoolId);
          this.form.get('testVptId')!.setValue(this.studentInfo.testVptId);
          this.form.get('eduSchoolId')!.setValue(this.studentInfo.eduSchoolId);
          this.form.get('raionId')!.setValue(this.studentInfo.raionId);
          this.form.get('schoolTypeId')!.setValue(this.studentInfo.schoolTypeId);
          this.form.get('ord')!.setValue(this.studentInfo.ord);
          this.form.get('testLangId')!.setValue(this.studentInfo.testLangId);
          this.form.get('testPeriodId')!.setValue(this.studentInfo.testPeriodId);
          this.form.get('eduSchoolOblId')!.setValue(this.studentInfo.eduSchoolOblId);
          this.form.get('employmentTypeId')!.setValue(this.studentInfo.employmentTypeId);
          this.form.get('eduSchoolRaionId')!.setValue(this.studentInfo.eduSchoolRaionId);
          this.form.get('eduSpecialityId')!.setValue(this.studentInfo.eduSpecialityId);
          this.form.get('testOrgOblId')!.setValue(this.studentInfo.testOrgOblId);

          this.employmentType();

          this.selectEduTypeId();

          this.getsubjectCount();

          this.findAllLangEntity();

          // if(this.form.get('eduTypeId')!.value == 1){
          //   this.studentTest.getTipoSubjectLang(this.form.get('jobTypeId')!.value, this.form.get('employmentTypeId')!.value, 1, this.studentInfo.specialityId, this.studentInfo.subjects?.subjectId)
          //   .subscribe((res: any) => {
          //       this.langList = res.items;
          //     this.subjects.controls[0].get('subjectId')!.setValue(this.studentInfo.subjects[0]?.subjectId);
          //       this.form.get('testLangId')!.patchValue(this.studentInfo.testLangId);
          //   }, (err: any) =>{
          //  });
          // }else{
          //   this.studentTest.getTipoSubjectLang(this.form.get('jobTypeId')!.value, this.form.get('employmentTypeId')!.value, this.form.get('eduTypeId')!.value, this.form.get('specialityId')!.value, this.studentInfo.subjects?.subjectId)
          //   .subscribe((res: any) => {
          //       this.langList = res.items;
          //       this.subjects.controls[0].get('subjectId')!.setValue(this.studentInfo.subjects[0].subjectId);
          //       this.form.get('testLangId')!.patchValue(this.studentInfo.testLangId);
          //   }, (err: any) =>{
          //   });
          // }

          this.getOblList(1);

          this.punktList(1);

          this.testPeriodList(1);

        }, (err: any) => {
        });
    }

    forEmploymentType(){
      if(this.form.get('employmentTypeId')!.value == 1){
        this.getCategory(1);
        this.toCategory(1);
        this.getJobTypeList(1);
        this.getKATOOblList(this.studentInfo.oblId);
        this.setJob(this.studentInfo.jobTypeId, this.studentInfo.kato);
      }

      if(this.form.get('employmentTypeId')!.value == 2){
        this.getOblStud(1);
        this.institutes(1);
        this.instituteSpec(1);
      }

      if(this.form.get('employmentTypeId')!.value == 3){
        this.getOblSchool(1);
        this.getRaionList(1);
        this.getCollege(1);
        this.collegeSpec(1);
      }
    }

    employmentType(){
      this.studentTest.employmentType(this.form.get('jobTypeId')!.value)
        .subscribe((res: any) => {
          this.employmentList = res.items;
          if(this.form.get('employmentTypeId')!.value == 1){
            this.getCategory(1);
            this.toCategory(1);
            this.getJobTypeList(1);
            this.getKATOOblList(this.studentInfo.oblId);
            this.setJob(this.studentInfo.jobTypeId, this.studentInfo.kato);
          }
          if(this.form.get('employmentTypeId')!.value == 2){
            this.getOblStud(1);
            this.institutes(1);
            this.instituteSpec(1);
          }
          if(this.form.get('employmentTypeId')!.value == 3){
            this.getOblSchool(1);
            this.getRaionList(1);
            this.getCollege(1);
            this.collegeSpec(1);
          }
        }, (err: any) => {
        });
    }

    getOblStud(code: any){
      this.studentTest.getOblStud(this.form.get('jobTypeId')!.value, this.form.get('employmentTypeId')!.value)
        .subscribe((res: any) => {
          this.oblLists = [];
          this.oblLists = res.items;
          if(code == 0){
            this.form.get('eduInstituteOblId')!.setValue(0);
          }else{
            this.form.get('eduInstituteOblId')!.setValue(this.studentInfo.eduInstituteOblId);
          }
        }, (err: any) => {
        });
    }

    institutes(code: any){
      this.studentTest.instituteList(this.form.get('jobTypeId')!.value, this.form.get('employmentTypeId')!.value, this.form.get('eduInstituteOblId')!.value)
        .subscribe((res: any) => {
          this.instituteLists = [];
          this.instituteLists = res.items;
          if(code == 0){
            this.form.get('eduInstituteId')!.setValue(0);
          }else{
            this.form.get('eduInstituteId')!.setValue(this.studentInfo.eduInstituteId);
          }
        }, (err: any) => {
        });
    }

    instituteSpec(code: any){
      this.studentTest.instituteSpec(this.form.get('jobTypeId')!.value)
        .subscribe((res: any) => {
          this.instituteList = [];
          this.instituteList = res.items;
          if(code == 0){
            this.form.get('eduSpecialityId')!.setValue(0);
          }else{
            this.form.get('eduSpecialityId')!.setValue(this.studentInfo.eduSpecialityId);
          }
        }, (err: any) => {
        });
    }

    getOblSchool(code: any){
      this.studentTest.getOblSchool(this.form.get('jobTypeId')!.value, this.form.get('employmentTypeId')!.value)
        .subscribe((res: any) => {
          this.oblSchoolList = [];
          this.oblSchoolList = res.items;
          if(code == 0){
            this.form.get('eduSchoolOblId')!.setValue(0);
          }else{
            this.form.get('eduSchoolOblId')!.setValue(this.studentInfo.eduSchoolOblId);
          }
        }, (err: any) => {
        });
    }

    getOblList(code: any){
      this.studentTest.getOblList(this.form.get('jobTypeId')!.value, this.form.get('employmentTypeId')!.value)
        .subscribe((res: any) => {
          this.oblList = [];
          this.oblList = res.items;
          if(code == 0){
            this.form.get('testOrgOblId')!.setValue(0);
          }else{
            this.form.get('testOrgOblId')!.setValue(this.studentInfo.testOrgOblId);
          }
        }, (err: any) => {
        });
    }

    getRaionList(code: any){
      this.studentTest.getRaionList(this.form.get('jobTypeId')!.value, this.form.get('employmentTypeId')!.value, this.form.get('eduSchoolOblId')!.value)
        .subscribe((res: any) => {
          this.raionList = [];
          this.raionList = res.items;
          if(code == 0){
            this.form.get('eduSchoolRaionId')!.setValue(0);
          }else{
            this.form.get('eduSchoolRaionId')!.setValue(this.studentInfo.eduSchoolRaionId);
          }
        }, (err: any) => {
        });
    }

    getCollege(code: any){
      this.studentTest.getCollege(this.form.get('jobTypeId')!.value, this.form.get('employmentTypeId')!.value, this.form.get('eduSchoolOblId')!.value, this.form.get('eduSchoolRaionId')!.value)
        .subscribe((res: any) => {
          this.collegList = [];
          this.collegList = res.items;
          if(code == 0){
            this.form.get('eduSchoolId')!.setValue(0);
          }else{
            this.form.get('eduSchoolId')!.setValue(this.studentInfo.eduSchoolId);
          }
        }, (err: any) => {
        });
    }

    collegeSpec(code: any){
      this.studentTest.collegeSpec(this.form.get('jobTypeId')!.value)
        .subscribe((res: any) => {
          this.collegeSpecList = [];
          this.collegeSpecList = res.items;
          if(code == 0){
            this.form.get('eduSpecialityId')!.setValue(0);
          }else{
            this.form.get('eduSpecialityId')!.setValue(this.studentInfo.eduSpecialityId);
          }
        }, (err: any) => {
        });
    }

    getCategory(code: any){
      this.studentTest.getCategory(this.form.get('jobTypeId')!.value, this.form.get('employmentTypeId')!.value)
        .subscribe((res: any) => {
          this.categoryList = [];
          this.categoryList = res.items;
          if(code == 0){
            this.form.get('fromCategoryId')!.setValue(0);
          }else{
            this.form.get('fromCategoryId')!.setValue(this.studentInfo.fromCategoryId);
          }
        }, (err: any) => {
        });
    }

    toCategory(code: any){
      this.studentTest.toCategory(this.form.get('jobTypeId')!.value, this.form.get('employmentTypeId')!.value)
        .subscribe((res: any) => {
          this.toCategoryList = [];
          this.toCategoryList = res.items;
          if(code == 0){
            this.form.get('toCategoryId')!.setValue(0);
          }else{
            this.form.get('toCategoryId')!.setValue(this.studentInfo.toCategoryId);
          }
        }, (err: any) => {
        });
    }

    getJobTypeList(code: any){
      this.studentTest.jobType()
        .subscribe((res: any) => {
          this.jobTypeList = res.items;
        }, (err: any) => {
        });
    }

    setJob(jobId: any, kato: any){
      this.KATOs = [];
      this.jobId = jobId;
      this.form.get('firstLevelKato')!.setValue(kato[0]);
      kato.forEach((element: any) => {
        this.studentTest.katoList(this.jobId, element)
          .subscribe((resp: any) => {
            if(resp.data.items.length > 0){
              this.KATOs.push(resp.data.items);
            }
            this.KATOs.sort();
            this.KATOs.reverse();
          }, (err: any) => {

          });

      });
      this.katoGetter.controls = [];

      this.pseudoId = [];

      kato.forEach((elem: any) => {
        this.pseudoId.push(elem);
      });

      this.getJobItems(kato[kato.length-1], true);
    }

    rekursiya(kato: any, index: any){
      const element = kato[index];
      this.studentTest.getRaionList(this.form.get('jobTypeId')!.value, this.form.get('employmentTypeId')!.value, element)
        .subscribe((resp: any) => {
          if(resp.length > 0){
            this.KATOs.push(resp);
            this.katoIndex = this.katoIndex + 1;
            this.rekursiya(kato, this.katoIndex);
          }
        }, (err: any) => {
        });
      if(this.katoIndex == kato.length - 1){
      }
    }

    searchJob(bin: any){
      this.studentTest.searchJobByBin(bin)
        .subscribe((res: any) => {
          this.KATOs = [];
          this.jobId = res.jobId;
          this.form.get('firstLevelKato')!.setValue(res.kato[0]);
          res.kato.forEach((element: any) => {
            this.studentTest.getRaionList(this.form.get('jobTypeId')!.value, this.form.get('employmentTypeId')!.value, element)
              .subscribe((resp: any) => {
                if(resp.length > 0){
                  this.KATOs.push(resp);
                  this.KATOs.sort();
                  this.KATOs.reverse();
                }
              }, (err: any) => {
                this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
                  this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
              });
          });

          this.katoGetter.controls = [];
          this.pseudoId = [];
          res.kato.forEach((elem: any) => {
            this.pseudoId.push(elem);
          });
          this.getJobItems(res.kato[res.kato.length-1], true);
        }, (err: any) => {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) :
            this._notifications.create('Қате', err.error.errorMessage.kk, NotificationType.Error, 5000);
        });
    }

    get katoGetter() : FormArray { return this.form.get("kato") as FormArray;}

    getKATOsList(i: any, j: any, isFirstSelect: any, code: any){

      let id: number = 0;
      if(code == 0){
        this.form.get('jobId')!.setValue(0);
      }else{
        this.form.get('jobId')!.setValue(this.studentInfo.jobId);
      }
      if(isFirstSelect){
        id = this.form.get('firstLevelKato')!.value;
        this.lastChild = false;
      }else{
        id = this.KATOs[i][j.value].id;
        this.lastChild = !this.KATOs[i][j.value].hasChildren;
      }
      if(!this.lastChild || isFirstSelect){
        this.studentTest.katoList(this.form.get('jobTypeId')!.value, id)
          .subscribe((res: any) => {
            if(isFirstSelect){
              this.KATOs = [];
              this.pseudoId = [];
              this.KATOs.push(res.data.items);
            };

            if(isFirstSelect == false && this.lastChild){
              this.KATOs.splice(i+1, 1);
              this.KATOs.push(res.data.items);
            };
            if(isFirstSelect == false && this.KATOs[i][j.value].hasChildren){
              this.KATOs.splice(i+1, 2);
              this.KATOs.push(res.data.items);
            };
          }, (err: any) => {

          });
      }
      if(!isFirstSelect && !this.KATOs[i][j.value].hasChildren){
        this.KATOs.splice(i+1, this.KATOs.length);
        this.getJobItems(id, false);
      }else{
        this.lastChild = false;
      };
    }

    getJobItems(nasPunkId: any, binSearch: any){
      if(this.showNasPunkt == true){
        this.studentTest.katoCodeList(this.form.get('jobTypeId')!.value, nasPunkId)
          .subscribe((res: any) => {
            this.jobList = [];
            this.jobList = res.items;
          }, (err: any) => {
          });
      }
      if(binSearch == true){
        setTimeout(() => {
          this.form.get('jobId')!.setValue(this.studentInfo.jobId);
        }, 1000);
      }
    }

    getKATOOblList(firstLevelKato: number){
      this.studentTest.katoList(this.form.get('jobTypeId')!.value, 0)
        .subscribe((res: any) => {
          this.KATOOblList = res.data.items;
          this.form.get('firstLevelKato')!.setValue(this.studentInfo.kato[0]);
        }, (err: any) => {

        });
    }

    checkEduPeriod(){
      if(this.form.get('eduPeriodStartYear')!.value < 1960){
        this.form.get('eduPeriodStartYear')!.setValue(1960);
      }
      if(this.form.get('eduPeriodFinishYear')!.value > 2024){
        this.form.get('eduPeriodFinishYear')!.setValue(2024);
      }
    }

    get subjects() : FormArray { return this.form.get("subjects") as FormArray;}

    getTipoSubjectsLang(i: any, specialityId: any, specialitySubject: any){
      this.form.get('testLangId')!.setValue(0);
      if(this.form.get('eduTypeId')!.value == 1){
        this.studentTest.getTipoSubjectLang(this.form.get('jobTypeId')!.value, this.form.get('employmentTypeId')!.value, 1, specialityId, this.subjects.controls[i].get('subjectId')!.value)
          .subscribe((res: any) => {
            this.langList = res.items;
          }, (err: any) =>{
          });
      } else {
        this.studentTest.getTipoSubjectLang(this.form.get('jobTypeId')!.value, this.form.get('employmentTypeId')!.value, this.form.get('eduTypeId')!.value, specialityId, specialitySubject)
          .subscribe((res: any) => {
            this.langList = res.items;
          }, (err: any) =>{
          });
      };
    }

    findAllLangEntity() {
      this.studentTest.findAllLangEntity(this.form.get('jobTypeId')!.value)
        .subscribe((res: any) => {
          this.langList = res;
        })
    }
    getsubjectCount(){
      this.studentTest.subjCount(this.form.get('jobTypeId')!.value, this.form.get('employmentTypeId')!.value,)
        .subscribe((res: any) => {
          this.subjectCount = res.data.subjectCount;
          this.subjectCountLoop();
        }, (err: any) => {
        });
    }

    subjectCountLoop(){
      this.subjectCountArray = [];
      let subj = this.form.controls["subjects"] as FormArray;
      for(let i = ++this.subjectCountArray.length; i <= this.subjectCount; i++){
        this.studentTest.subjList(this.form.get('jobTypeId')!.value, this.form.get('employmentTypeId')!.value, i)
          .subscribe((res: any) => {

            this.subjectCountArray.push(res.items);
            console.log(res.items);
          }, (err: any) =>{
          });
        this.addSubject(i);
      }
    }

    setSubjectValue(i: any, value: any){
      this.subjects.controls[i].get('subjectId')!.setValue(value);
      this.getTipoSubjectsLang(i, 0, 0);
    }



    newSubject(i: any, subjectId: any, testLangId: any): FormGroup {
      return new FormGroup({
        ord: new FormControl(i),
        subjectId: new FormControl(subjectId),
        langId: new FormControl('')
      });
    }

    setSubjectLangIdValue(status: any, i: any, value: any){
      this.subjects.controls[i].get('langId')!.setValue(value);
    }

    addSubject(i: any) {
      this.subjects.push(this.newSubject(i, this.studentInfo.subjects.subjectId, this.form.get('testLangId')!.value));
      this.subjectCountArray.push(this.subjects);
    }

    setSpecialitySubjectValue(event: any){
      this.form.get('specialityId')?.setValue(+event.target.value);
      this.form.get('testLangId')?.setValue(-1);

      this.studentTest.getTipoSoSubjectsItems(this.form.get('jobTypeId')!.value, this.form.get('employmentTypeId')!.value, this.form.get('eduTypeId')!.value,this.form.get('specialityId')!.value, 1, this.pageLangId)
        .subscribe((res: any) => {
          this.subjects.controls[0].get('subjectId')!.setValue(res.items[0].id);
          this.getTipoSubjectsLang(1, this.form.get('specialityId')!.value, this.subjects.controls[0].get('subjectId')?.value);
        }, (err: any) => {
        });
    }

    selectEduTypeId(){
      if(+this.form.get('eduTypeId')!.value == 1){
        this.subjectList = [];
        this.langList = [];
        this.studentTest.getTipoSoSubjectsItems(this.form.get('jobTypeId')!.value, this.form.get('employmentTypeId')!.value, 1, 0, 1, this.pageLangId)
          .subscribe((res: any) => {
            this.subjectList = res.items;

          }, (err: any) => {
          });
      }else{
        this.subjectList = [];
        this.langList = [];
        this.studentTest.getSpecialityItems(this.form.get('jobTypeId')!.value, this.form.get('employmentTypeId')!.value, this.form.get('eduTypeId')!.value)
          .subscribe((res: any) => {
            this.subjectList = res.items;
          }, (err: any) => {
          });
      }
    }

    punktList(code: any){
      this.studentTest.testOrgList(this.form.get('jobTypeId')!.value, this.form.get('employmentTypeId')!.value, this.form.get('testOrgOblId')!.value)
        .subscribe((res: any) => {
          this.pstList = [];
          this.pstList = res.items;
          if(code == 0){
            this.form.get('testOrgId')!.setValue(0)
          }else{
            this.form.get('testOrgId')!.setValue(this.studentInfo.testOrgId)
          }
        }, (err: any) => {
        });
    }

    testPeriodList(code: any){
      this.studentTest.findAllTestPeriodByTestVptId(this.form.get('testOrgId')!.value)
        .subscribe((res: any) => {
          this.periodList = res;
            this.form.get('testPeriodId')!.setValue(this.studentInfo.testPeriodId);
        }, (err: any) => {
        });
    }

    editUser(){
      this.studentTest.updateStudent(this.form.get('studentTestId')!.value, this.form.value)
        .subscribe((res: any) => {
          this.translate.currentLang == 'ru' ? this._notifications.create('Успешно', 'пользователь редактирован', NotificationType.Success, 5000) :
            this._notifications.create('Сәтті', 'пайдаланушы бұғатталған', NotificationType.Success);
          this.update = res.studentTestId;
          this.router.navigate(['/main/students']);
        },(err: any) => {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.message, NotificationType.Error, 5000) :
            this._notifications.create('Қате', err.error.message, NotificationType.Error, 5000);
        });
    }
  }



