<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<form [formGroup]="form" class="formClass">
    <div id="whoobe-1yakq"
        class="bg-white text-base w-2/3 rounded-lg shadow whoobeClass m-auto -brown-400 border border-gray-300 mt-10 flex flex-col">
        <div id="whoobe-pa28x" class="p-4 flex boxClass flex-col">
            <div class="flex ml-48 text-center mt-5 mb-10">
                <div class="w-2/3 text-2xl font-hairline uppercase tracking-widest"> Оформление внештатной ситуации
                </div>
            </div>
            <div class="flex">
                <div class="flex mt-5" style="width: 500px;">
                    <p class="ml-3" style="width: 195px;">
                        Дата тестирования
                    </p>
                    <select (change)="getStudentListFromOnlineTest()" class="rounded-lg bg-gray-300 ml-2" [value]="0"
                        formControlName="testPeriodId">
                        <option [value]="0" disabled selected>
                            Дата тестирования
                        </option>
                        <option [value]="item.id" *ngFor="let item of testPeriodList">
                            {{ item.name }}
                        </option>

                    </select>
                </div>
            </div>

            <div class="mx-3 my-5">
                <div class="flex">
                    <label class="w-48"> Время внештатной ситуации </label>
                    <input formControlName="statementsTime" placeholder="00:00" type="time"
                        class="h-7 ml-2 px-2 rounded-lg bg-gray-300">
                </div>
            </div>
            <div class="mx-3 my-5">
                <div class="flex">
                    <label class="w-48"> Внештатная ситуация </label>
                    <select formControlName="resetSessionTypeId" class="w-64 bg-gray-300 rounded-lg border-2 ml-2 mb-5">
                        <option disabled selected [value]="0">
                            Внештатная ситуация
                        </option>
                        <option *ngFor="let item of resetSessionList" [value]="item.id">
                            <span *ngIf="translate.currentLang == 'kz'"> {{ item.nameKaz }} </span>
                            <span *ngIf="translate.currentLang == 'ru'"> {{ item.nameRus }} </span>
                        </option>
                    </select>
                </div>
            </div>
            <div>Запрос для внештатной ситуации
                <div class="mr-3 p-6">
                    <div style="display: flex;" *ngFor="let actions of ActionTreeList; index as i">
                        <fieldset>
                            <div>
                                <input (click)="findChildActionByParentId(actions)" formControlName="parentActionId"
                                    class="mr-1 w-4 h-4" [value]="actions.parentActionId" type="radio">
                                <label>
                                    <span *ngIf="translate.currentLang == 'kz'"> - {{ actions.parentActionNameKaz }}
                                    </span>
                                    <span *ngIf="translate.currentLang == 'ru'"> - {{ actions.parentActionNameRus }}
                                    </span>
                                </label>
                            </div>
                        </fieldset>
                    </div>
                    <div formArrayName="actionTypeIds" *ngIf="this.form.get('parentActionId')!.value != null">
                        <div class="mt-2">
                            <div style="display: flex;" *ngFor="let actions of parentList; index as i">
                                <div style="display: none;">
                                    <div [formGroup]="actionsArray.controls[i]">
                                        <input [attr.disabled]="true ? '' : null" (click)="returnFunc(actions)"
                                            formControlName="checked" class="mr-1 w-4 h-4" type="checkbox">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="this.form.get('parentActionId')!.value == 2">
                        <input formControlName="addTime"
                            onkeyup="this.value = this.value.replace(/[^\d]/g,'');" [step]="5" [min]="5" [max]="160"
                            type="text" class="border-2 w-24 mx-2 px-2 rounded-lg bg-gray-300">
<!--                      [mask]="timeForTestMask"-->
                        Кол-во добавочного времени в минутах
                    </div>
                    <div *ngIf="this.form.get('parentActionId')!.value == 9">
                        <input formControlName="addAppealTime"
                            onkeyup="this.value = this.value.replace(/[^\d]/g,'');" [step]="5" [min]="5" [max]="160"
                            type="text" class="border-2 mx-2 w-24 px-2 rounded-lg bg-gray-300">
                      <!--                      [mask]="timeForTestMask"-->

                        Кол-во добавочного времени апелляции в минутах
                    </div>
                </div>
            </div>
            <div *ngIf="this.form.get('parentActionId')!.value == 12" class="mx-3 my-1">
                <div class="flex">
                    Добавочное время тестирования
                    <input  onkeyup="this.value = this.value.replace(/[^\d]/g,'');" [step]="5"
                        [min]="5" [max]="160" formControlName="addTime" class="ml-3 bg-gray-300 rounded-lg" type="number">
                </div>
              <!--                            [mask]="timeForTestMask"-->

            </div>
            <div *ngIf="this.form.get('parentActionId')!.value == 13" class="mx-3 my-1">
                <div class="flex">
                    Добавочное время для апелляции
                    <input  onkeyup="this.value = this.value.replace(/[^\d]/g,'');" [step]="5"
                        [min]="5" [max]="160" formControlName="addAppealTime" class="ml-3 bg-gray-300 rounded-lg" type="number">
                  <!--                            [mask]="timeForTestMask"-->

                </div>
            </div>

            <div class="mx-3 my-5">
                <div class="flex">
                    Причина <textarea formControlName="notice" class="ml-3 bg-gray-300 w-96 h-32"></textarea>
                </div>
            </div>
            <span class="add-files-scroll">
                <div class="flex">
                    <button type="button" class="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded"
                        (click)="addFile()">{{ 'addFile' | translate }}</button>
                    <button type="button" class="text-white py-2 px-4 rounded">{{ 'addFile' | translate }}</button>
                </div>
                <div formArrayName="additionalFiles" class="w-full mt-4 controls-border"
                    *ngFor="let item of filesExam.controls; index as i">
                    <div [formGroup]="filesExam.controls[i]">
                        <span> {{ 'NameFile' | translate }}: <input [disabled]="true" class="mb-4 p-2 w-full"
                                type="text" readonly formControlName="filename"></span>
                        <div class="f-btn-group">
                            <div class="relative flex">
                                <input (change)="changeListenerExam($event, i)"
                                    class="w-full absolute top-0 left-0 h-full opacity-0 mb-4" type="file" />
                                <div class="update-btn bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded">
                                    <p>
                                        <span *ngIf="dwnldCheckExam(i); else elseBlock"> {{ 'ReplaceFile' | translate
                                            }}</span>
                                        <ng-template #elseBlock><span> {{ 'UploadFile' | translate
                                                }}</span></ng-template>
                                    </p>
                                </div>
                                <p *ngIf="!dwnldCheckExam(i)" class="text-red-500 text-sm text-left">* {{ 'FileNotAdd' |
                                    translate }}</p>
                            </div>
                        </div>

                        <div class="s-btn-group">
                            <button type="button" title="Удалить файл"
                                class="x-btn bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                (click)="removeFileExam()">
                                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false"
                                    data-prefix="fas" data-icon="trash" class="svg-inline--fa fa-trash fa-w-14"
                                    role="img" viewBox="0 0 448 512">
                                    <path fill="currentColor"
                                        d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </span>
            <div class="my-5 flex">
                <label class="cursor-pointer w-auto mt-1"> {{ 'SearchByIIN' | translate }} </label>
                <input [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}" id="iin"
                    class="rounded-lg bg-white border-2 border-gray-300 ml-3" type="text" placeholder="ИИН">
            </div>
            <div class="flex">
                <div>
                    <button (click)="operatorSendStatement(false)" class="w-64 rounded-lg h-8 bg-blue-300">
                        Сохранить черновик
                    </button>
                </div>
                <div class="ml-2">
                    <button (click)="operatorSendStatement(true)" class="w-64 rounded-lg h-8 bg-blue-300">
                        Сохранить и отправить в НЦТ
                    </button>
                </div>
                <div class="flex">
                    <div class="ml-2">
                        <button [routerLink]="['/main/incident']" class="w-64 rounded-lg h-8 bg-blue-300">
                            Вернуться к списку
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="this.form.get('testPeriodId')!.value != null"
                class="px-3 py-4 scroll-table-body overflow-x-auto flex">
                <table class="table min-w-full">
                    <thead>
                        <tr>
                            <th>
                                <input [checked]="isAllChecked" class="w-4 h-4" (click)="checkAllOptions()"
                                    type="checkbox">
                            </th>
                            <th scope="col" class="py-3 text-center text-xs font-medium text-gray-500 uppercase">
                                {{ 'IIN' | translate }}
                            </th>

                            <th scope="col" class="py-3 text-center text-xs font-medium text-gray-500 uppercase">
                                ФИО
                            </th>

                            <th scope="col" class="py-3 text-center text-xs font-medium text-gray-500 uppercase">
                                {{ 'LangTest' | translate }}
                            </th>

                            <th scope="col" class="py-3 text-center text-xs font-medium text-gray-500 uppercase">
                                {{ 'StudentStatus' | translate }}
                            </th>

                            <th scope="col" class="py-3 text-center text-xs font-medium text-gray-500 uppercase">
                                Адрес
                            </th>

                            <th scope="col" class="py-3 text-center text-xs font-medium text-gray-500 uppercase">
                                Место
                            </th>

                            <th scope="col" class="py-3 text-center text-xs font-medium text-gray-500 uppercase">
                                Дата тестирования
                            </th>

                            <th scope="col" class="py-3 text-center text-xs font-medium text-gray-500 uppercase">
                                Аудитория
                            </th>

                            <th scope="col" class="py-3 text-center text-xs font-medium text-gray-500 uppercase">
                                Дата начала тестирования
                            </th>

                            <th scope="col" class="py-3 text-center text-xs font-medium text-gray-500 uppercase">
                                Дата конца тестирования
                            </th>
                        </tr>
                    </thead>
                    <tbody class="bg-white border-2.border-gray-200">
                        <tr class="border-2 border-gray-200" *ngFor="let student of studentList | filter: searchText ">
<!--                          | filter: iin-->
                            <td class="px-6 py-4 text-center">
                                <div class="text-sm text-gray-900">
                                    <input class="w-4 h-4" [checked]="student.isChecked"
                                        (change)="checkStudent($event, student)" type="checkbox">
                                </div>
                            </td>

                            <td class="py-4 text-center">
                                <div class="text-sm text-gray-900">
                                    {{ student.iin }}
                                </div>
                            </td>

                            <td class="py-4 text-center">
                                <div class="text-sm text-gray-900 w-60">
                                    <span *ngIf="translate.currentLang == 'kz'"> {{ student.lastname }} {{
                                        student.firstname }} {{ student.patronymic }} </span>
                                    <span *ngIf="translate.currentLang == 'ru'"> {{ student.lastname }} {{
                                        student.firstname }} {{ student.patronymic }} </span>
                                </div>
                            </td>

                            <td class="py-4 text-center">
                                <div class="w-60 text-sm text-gray-900">
                                    {{ student.testLangName }}
                                </div>
                            </td>

                            <td class="py-4 text-center">
                                <div class="text-sm text-gray-900 w-60">
                                    {{ student.studentTestStatusTypeName }}
                                </div>
                            </td>

                            <td class="py-4 text-center">
                                <div class="text-sm text-gray-900 w-60">
                                    аудитория - {{ student.auditNum }}, место - {{ student.placeNum }}
                                </div>
                            </td>

                            <td class="py-4 text-center">
                                <div class="text-sm text-gray-900 w-60">
                                    {{ student.testOrgName }}
                                </div>
                            </td>

                            <td class="py-4 text-center">
                                <div class="text-sm text-gray-900 w-60">
                                    {{ student.testDate }}
                                </div>
                            </td>

                            <td class="py-4 text-center">
                                <div class="text-sm text-gray-900 w-60">
                                    {{ student.testStartedAt }}
                                </div>
                            </td>

                            <td class="py-4 text-center">
                                <div class="text-sm text-gray-900 w-60">
                                    {{ student.testFinishedAt }}
                                </div>
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</form>
<!--  <div class="mx-3 p-6">
            <div style="display: flex;" formArrayName="actionTypeIds" *ngFor="let actions of zaprosInc; index as i">
                <div>
                    <div [formGroup]="actionsArray.controls[i]">
                        <input (click)="returnFunc(actions)" formControlName="checked" class="mr-1 w-4 h-4" [value]="actions.checked" type="checkbox">
                        <label>
                            <span *ngIf="translate.currentLang == 'kz'"> - {{ actions.nameKaz }} </span>
                            <span *ngIf="translate.currentLang == 'ru'"> - {{ actions.nameRus }} </span>
                        </label>
                    </div>
                </div>
                <div *ngIf="checkId == true && actions.actionTypeId == 3">
                    <input [(ngModel)]="addTime" [ngModelOptions]="{standalone: true}" [mask]="timeForTestMask" onkeyup="this.value = this.value.replace(/[^\d]/g,'');" [step]="5" [min]="5" [max]="160" type="text" class="border-2 w-24 mx-2 px-2 rounded-lg bg-gray-300">
                    Кол-во добавочного времени в минутах
                </div>
                <div *ngIf="checkApel == true && actions.actionTypeId == 8">
                    <input [(ngModel)]="addAppealTime" [ngModelOptions]="{standalone: true}" [mask]="timeForTestMask" onkeyup="this.value = this.value.replace(/[^\d]/g,'');" [step]="5" [min]="5" [max]="160" type="text" class="border-2 mx-2 w-24 px-2 rounded-lg bg-gray-300">
                    Кол-во добавочного времени апелляция
                </div>
            </div>
        </div>-->
